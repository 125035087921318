import {
    getBackendOptions,
    MultiBackend,
    Tree
} from "@minoru/react-dnd-treeview";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import UnfoldLessDoubleIcon from '@mui/icons-material/UnfoldLessDouble';
import UnfoldMoreDoubleIcon from '@mui/icons-material/UnfoldMoreDouble';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { DndProvider } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";
import { FaRegSave } from "react-icons/fa";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getTabInfo, postReportSequenceUpdate } from '../../BaseModels/MasterData';
import CustomButton from '../../CustomElements/CustomButton/CustomButton';
import BackendTopMenu from '../BackendTopMenu/BackendTopMenu';
import { UDContext } from './../../../App';
import { CustomDragPreview } from "./CustomDragPreview";
import { CustomNode } from "./CustomNode";
import st from "./NavigationOrder.module.css";
import { Placeholder } from "./Placeholder";

function NavigationOrder() {
    const UserDetail = useContext(UDContext);
    const [treeData, setTreeData] = useState([]);
    const [sequence, setSequence] = useState([]);
    const [Expanded, setExpanded] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        if (UserDetail !== null) {
            getNavigationData();
        }
    }, [UserDetail]);

    const getNavigationData = async () => {
        try {
            getTabInfo().then(res => {
                if (res.status) {
                    let AllNavigationData = res.data;
                    let MenuTransform = [];
                    AllNavigationData.forEach(pages => {
                        if (pages.page_name !== "Home") {
                            MenuTransform.push({
                                "id": pages.page_id,
                                "parent": 0,
                                "droppable": true,
                                "text": pages.page_name,
                                "data": {
                                    "report_id": "0",
                                    "type": "page",
                                    "fileType": "page",
                                }
                            });
                            pages.section.forEach(sections => {
                                MenuTransform.push({
                                    "id": pages.page_id + "/" + sections.section_id,
                                    "parent": pages.page_id,
                                    "droppable": true,
                                    "text": sections.section_name,
                                    "data": {
                                        "report_id": "0",
                                        "type": "section",
                                        "fileType": "sections",
                                    }
                                });
                                sections.report.forEach(dashboards => {
                                    MenuTransform.push({
                                        "id": pages.page_id + "/" + sections.section_id + "/" + dashboards.report_id,
                                        "parent": pages.page_id + "/" + sections.section_id,
                                        "droppable": false,
                                        "text": dashboards.title,
                                        "data": {
                                            "report_id": dashboards.report_id,
                                            "type": "dashboards",
                                            "fileType": "dashboards",
                                            "page_id": pages.page_id,
                                            "section_id": sections.section_id
                                        }
                                    });
                                });
                            });
                        }
                    });
                    setTreeData(MenuTransform)
                    setTimeout(handleExpand, 1000);
                } else {
                    toast.error(res.message);
                }
            });
        } catch (error) {
            toast.error("Something went wrong");
        }
    }

    const handleDrop = (newTree) => {
        setTreeData(newTree);
        let pages = [];
        newTree.forEach(element => {
            if (!String(element.id).includes('/')) {
                pages.push(element.id);
            }
        });
        let newOrder = [];
        let seq = 1
        pages.forEach(page => {
            let sections = newTree.filter(x => String(x.parent) === String(page));
            sections.forEach(section => {
                let reports = newTree.filter(x => String(x.parent) === String(section.id));
                reports.forEach(report => {
                    let obj = {};
                    let page_section_report = report.parent.split("/");
                    obj.page_id = page_section_report[0];
                    obj.section_id = page_section_report[1];
                    obj.report_id = report.data.report_id;
                    obj.seq = seq++;
                    newOrder.push(obj);
                });
            });
        });
        setSequence(newOrder);
    };


    const handleExpand = () => {
        if (Expanded) {
            ref.current?.closeAll();
        } else {
            ref.current?.openAll();
        }
        setExpanded(!Expanded);
    }

    const btnSaveOrder_onClick = () => {
        try {
            let _sequence = [...sequence];
            postReportSequenceUpdate(_sequence).then(res => {
                if (res.status) {
                    toast.success(res?.data);
                } else {
                    toast.error("Error! Could not save navigation order!");
                }
            });
        } catch (error) {
            toast.error("Error! Could not save navigation order!");
        }
    }


    return (
        <>
            <BackendTopMenu />
            <div className="mainContainer">
                <div className="row mt-2 mb-2">
                    <div className="col-lg-6">
                        <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
                            <Link className={st.navigateItem} to={"/backend"}>Admin Home</Link>
                            <Link className={st.navigateItem} to={"/backend/navigationOrder"}>Navigation</Link>
                        </Breadcrumbs>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <br />
                        <div className="hide-bullet" style={{ backgroundColor: '#ffffff', padding: '5px' }}>

                            <DndProvider backend={MultiBackend} options={getBackendOptions()} debugMode={true}>
                                <div className={st.app}>
                                    <div className={st.buttonContainer}>
                                        {
                                            Expanded ?
                                                <CustomButton type="info" onClick={handleExpand} icon={<UnfoldLessDoubleIcon />}>Collapse All</CustomButton>
                                                :
                                                <CustomButton type="info" onClick={handleExpand} icon={<UnfoldMoreDoubleIcon />}>Expand All</CustomButton>
                                        }
                                        {
                                            sequence.length > 0 ? <CustomButton type="primary" onClick={btnSaveOrder_onClick} icon={<FaRegSave />}>Save</CustomButton> : null
                                        }
                                    </div>
                                    <Tree
                                        ref={ref}
                                        tree={treeData}
                                        rootId={0}
                                        extraAcceptTypes={[NativeTypes.TEXT]}
                                        render={(node, { depth, isOpen, onToggle }) => (
                                            <CustomNode
                                                node={node}
                                                depth={depth}
                                                isOpen={isOpen}
                                                onToggle={onToggle}
                                            />
                                        )}
                                        dragPreviewRender={(monitorProps) => (
                                            <CustomDragPreview monitorProps={monitorProps} />
                                        )}
                                        onDrop={handleDrop}
                                        classes={{
                                            root: st.treeRoot,
                                            draggingSource: st.draggingSource,
                                            placeholder: st.placeholderContainer
                                        }}
                                        sort={false}
                                        insertDroppableFirst={false}
                                        canDrop={(tree, { dragSource, dropTargetId, dropTarget }) => {
                                            let dropTargetSplit = String(dropTargetId).split("/");
                                            if (dropTargetSplit.length !== 2) {
                                                return false;
                                            } else if (dragSource?.data?.type !== "dashboards") {
                                                return false;
                                            } else if (dragSource?.parent === dropTargetId) {
                                                return true;
                                            }
                                        }}
                                        dropTargetOffset={5}
                                        placeholderRender={(node, { depth }) => (
                                            <Placeholder node={node} depth={depth} />
                                        )}
                                    />
                                </div>
                            </DndProvider>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <ToastContainer autoClose={1000} />
        </>
    );
}

export default NavigationOrder;
