import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UDContext } from '../../../App';
import { getDescription } from '../../BaseModels/MasterData';
import SummaryDetails1 from './SummaryDetails1/SummaryDetails1';
import SummaryDetails2 from './SummaryDetails2/SummaryDetails2';
import SummaryDetails3 from './SummaryDetails3/SummaryDetails3';
import SummaryDetails4 from './SummaryDetails4/SummaryDetails4';
import SummaryDetails5 from './SummaryDetails5/SummaryDetails5';
import SummaryDetails6 from './SummaryDetails6/SummaryDetails6';


const SummaryDetails = ({ div_id, selected_design_id }) => {
    const UserDetail = useContext(UDContext);
    const [data, setData] = useState({
        "description_id": 1,
        "div_id": 1,
        "description": "End-to-End Visualization Reporting Platform",
        "background_image": "",
        "background_color": "",
        "alignment": "center",
        "description_height": "",
        "description_font": "",
        "tutorial": false,
        "tutorial_link": "",
        "created_date": "",
        "created_by": "",
        "updated_date": "",
        "updated_by": "",
        "description_title": "E2EViz"
    });
    const [IsVisible, setIsVisible] = useState(false);

    useEffect(() => {
        getDescriptionData();
    }, []);



    const getDescriptionData = async () => {
        try {
            getDescription(div_id).then((response) => {
                if (response.status) {
                    setData(response.data);
                    setIsVisible(true);
                } else {
                    toast.error(response.message);
                }
            });
        } catch (error) {
            toast.error("Something went wrong");
        }
    }


    return (
        <div style={{ minHeight: '100px' }}>
            {
                IsVisible ? <>
                    {selected_design_id === 1 ? <SummaryDetails1 key="1" description_information={data} /> : <></>}
                    {selected_design_id === 2 ? <SummaryDetails2 key="2" description_information={data} /> : <></>}
                    {selected_design_id === 3 ? <SummaryDetails3 key="3" description_information={data} /> : <></>}
                    {selected_design_id === 4 ? <SummaryDetails4 key="4" description_information={data} /> : <></>}
                    {selected_design_id === 5 ? <SummaryDetails5 key="5" description_information={data} /> : <></>}
                    {selected_design_id === 6 ? <SummaryDetails6 key="6" description_information={data} /> : <></>}
                </> : <>
                </>
            }
        </div>
    );
};

export default SummaryDetails;