import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { toast } from 'react-toastify';
import { UDContext } from '../../../../App';
import playVideoImage from './../../../../images/playVideoImage.png';
import { BaseUrlAPI, getBase64, postTutorialVideoViewerInsert } from './../../../BaseModels/MasterData';
import { useContainerDimensions } from './../../../BaseModels/ResizeWindow';
import st from './DashboardVideo.module.css';

function DashboardVideo({ IsVisible, onClose, title, videoUrl, report_id }) {
    const { width, height } = useContainerDimensions()
    const [showModal, setShowModal] = useState(false);
    const wrapperRef = useRef(null);
    const UserDetail = useContext(UDContext);
    const [TutorialVideoUrl, setTutorialVideoUrl] = useState("");
    const [IsDoNotShowAgainVisible, setIsDoNotShowAgainVisible] = useState(false);

    useEffect(() => {
        if (IsVisible) {
            if (videoUrl !== "") {
                const token = localStorage.getItem('token');
                if (token) {
                    getBase64(BaseUrlAPI + "videos" + videoUrl + "?token=" + token).then((data) => {
                        setTutorialVideoUrl(data);
                        setShowModal(true);
                    });
                }
            }
        } else {
            setShowModal(false);
        }
    }, [IsVisible, videoUrl]);






    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                onClose();
                setShowModal(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);

    const btnClose_onClick = async () => {
        try {
            if (IsDoNotShowAgainVisible) {
                postTutorialVideoViewerInsert(report_id).then((response) => {
                    if (response.status) {
                        toast.success(response.data);
                    }
                });
            }
            onClose();
        } catch (error) {
            toast.error("Something went wrong");
        }
    }




    return (
        showModal &&
        <Draggable className={st.rootContainer}
            handle=".handle">
            <div className={st.tutorialVideoContainer} style={
                width < 502 ? {
                    left: (width / 2) - ((width / 100) * 40)
                } : {}
            }>
                <div className={st.tutorialVideoHeader}>
                    <div className={`${st.tutorialVideoTitle} ${"handle"}`}>{title}</div>
                    <HighlightOffIcon className={st.tutorialVideoClose} onClick={onClose} />
                </div>
                <div className={st.tutorialVideoBody}>
                    {
                        TutorialVideoUrl === "" ? <div>Loading...</div> :
                            <video className={st.tutorialVideo} poster={playVideoImage} controls>
                                <source src={TutorialVideoUrl} type="video/mp4" />
                            </video>
                    }

                </div>
                <div className={st.tutorialVideoFooter}>
                    <div>
                        <FormControlLabel control={
                            <Checkbox size="small" checked={IsDoNotShowAgainVisible}
                                onChange={(event) => setIsDoNotShowAgainVisible(event.target.checked)}
                            />} label="Do not show again" />
                    </div>
                    <Button variant="contained" size="small" onClick={btnClose_onClick}>
                        Close
                    </Button>
                </div>
            </div>
        </Draggable>
    );
}

export default DashboardVideo;
