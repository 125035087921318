import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import VideocamIcon from '@mui/icons-material/Videocam';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import logo from './../../../images/e2eviz-header-image.png';
import playVideoImage from './../../../images/playVideoImage.png';
import tutorial_video from './../../../videos/Platform_Overview.mp4';
import { UDContext } from '../../../App';
import ModalPopup from '../../CustomElements/ModalPopup/ModalPopup';
import { useContainerDimensions } from './../../BaseModels/ResizeWindow'
import AdminSearchBoxFullInput from './AdminSearchBoxFullInput/AdminSearchBoxFullInput';
import st from './BackendTopMenu.module.css';
import BackendMenuMobile from './BackendMenuMobile/BackendMenuMobile';

const pages = [
    { pageName: 'Admin Home', slug: '/backend', icon: 'fas fa-home' },
    { pageName: 'Reports', slug: '/backend/dashboards', icon: 'fas fa-chart-pie' },
    { pageName: 'Page List', slug: '/backend/pageList', icon: 'fas fa-layer-group' },
    { pageName: 'Navigation', slug: '/backend/navigationOrder', icon: 'fas fa-compass' },
    { pageName: 'Announcement', slug: '/backend/news', icon: 'fa-solid fa-bullhorn' },
    // { pageName: 'Pages', slug: '/backend/landingPageCreation', icon: 'fas fa-scroll' },
    // { pageName: 'Exception', slug: '/backend/exception' },
    { pageName: 'User Access', slug: '/backend/adminUser', icon: 'fas fa-key' },
    { pageName: 'Report Access', slug: '/backend/reportAccess', icon: 'fas fa-universal-access' },
    { pageName: 'Traffic View', slug: '/backend/trafficView', icon: 'fas fa-chart-line' },
    { pageName: 'Alert', slug: '/backend/alert', icon: 'fas fa-stopwatch' },
    { pageName: 'AI Assistant Feedback', slug: '/backend/ai-feedback', icon: 'fas fa-robot' },
];



function BackendTopMenu() {
    const { width, height } = useContainerDimensions()
    const UserDetail = useContext(UDContext);
    const ref = useRef(null)
    const { slug } = useParams();
    const location = useLocation();
    const navigate = useNavigate();




    const [TopBarHeight, setTopBarHeight] = useState(0)

    useEffect(() => {
        setTopBarHeight(ref.current.clientHeight)
    })


    useEffect(() => {
        if (UserDetail !== null) {
            if (!UserDetail?.user?.admin) {
                navigate("/404");
            }
        }
    }, [UserDetail])



    const [IsTutorialVideoVisible, setIsTutorialVideoVisible] = useState(false);



    const btnHomePage_onClick = () => {
        navigate("/");
        window.location.reload();
    }
    const btnPage_onClick = (page) => {
        navigate(page.slug);
        // window.location.reload();
    }









    const btnTutoraiVideo_onClose = () => {
        setIsTutorialVideoVisible(false);
    }



    return (
        <>

            <div className={st.AppBar} ref={ref}>
                {
                    width < 992 ?
                        <>
                            <BackendMenuMobile TopBarHeight={TopBarHeight} pages={pages} />
                        </>
                        :
                        <>
                            <div className={`${st.AppBarTopContainer} ${'mainContainer'}`}>
                                <div className={st.logoLink} onClick={btnHomePage_onClick}>
                                    <img src={logo} alt="logo" className={st.logo} />
                                </div>
                                <div className={st.searchBoxContainer}>
                                    <AdminSearchBoxFullInput />
                                </div>
                                <div className={st.actionBarRootContainer}>
                                    <Button size="small" startIcon={<VideocamIcon />} sx={{ display: 'none', color: "#ffffff", textTransform: 'capitalize' }} onClick={() => setIsTutorialVideoVisible(true)}>
                                        Take a tour
                                    </Button>
                                    <ModalPopup IsVisible={IsTutorialVideoVisible} onClose={btnTutoraiVideo_onClose}>
                                        <div className={st.tutorialVideoContainer}>
                                            <div className={st.tutorialVideoHeader}>
                                                <div className={st.tutorialVideoTitle}>E2EViz Platform Tutorial</div>
                                                <HighlightOffIcon className={st.tutorialVideoClose} onClick={btnTutoraiVideo_onClose} />
                                            </div>
                                            <div className={st.tutorialVideoBody}>
                                                <video className={st.tutorialVideo} poster={playVideoImage} controls>
                                                    <source src={tutorial_video} type="video/mp4" />
                                                </video>
                                            </div>
                                        </div>
                                    </ModalPopup>
                                    <span style={{ display: 'none' }} className={st.actionBarSaparator}>|</span>
                                    <div className={st.userDrailContainer}>
                                        <Avatar sx={{ height: 25, width: 25 }} alt={UserDetail?.user?.given_name} className={st.userAvatar}>{UserDetail?.user?.firstChar}</Avatar>
                                        <div className={st.userName}>{UserDetail?.user?.user_id}</div>
                                    </div>
                                </div>
                            </div>
                            <div className={`${st.pagesRootContainer}`}>
                                <div className={`${st.pagesContainer} ${'mainContainer'}`}>
                                    {
                                        pages.map((page, index) => (
                                            <div key={page.pageName} className={st.pageWrapper}>
                                                <div className={`${st.pageContainer} ${page.slug === location.pathname ? st.ActivePageContainer : ''}`}
                                                    onClick={() => { btnPage_onClick(page) }}>
                                                    <div className={st.pageIcon}>
                                                        <i className={page.icon}></i>
                                                    </div>
                                                    <div className={st.pageName}>
                                                        {page.pageName}
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </>
                }

            </div>
            <div style={{ height: TopBarHeight - 5 }}></div>
        </>
    );
}
export default BackendTopMenu;