import { useState } from 'react';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import { addDays, addMonths, format } from 'date-fns';
import { DateRangePicker } from 'rsuite';
import "rsuite/dist/rsuite.css";

import styles from './GlobalFilters.module.css';

const PROJECT_START_DATE = new Date(2024, 0, 1);
const predefinedDateRanges = [
    {
        label: 'Last 7 Days',
        value: [addDays(new Date(), -7), new Date()],
        placement: 'left'
    },
    {
        label: 'Last 30 Days',
        value: [addDays(new Date(), -30), new Date()],
        placement: 'left'
    },
    {
        label: 'Last 2 Months',
        value: [addMonths(new Date(), -2), new Date()],
        placement: 'left'
    },
    {
        label: 'Last 3 Months',
        value: [addMonths(new Date(), -3), new Date()],
        placement: 'left'
    },
    {
        label: 'Last 6 Months',
        value: [new Date(Math.max(addMonths(new Date(), -6), PROJECT_START_DATE)), new Date()],
        placement: 'left'
    },
];
const userRoles = ['Business User', 'DTI User'];


export const useGlobalFilters = () => {
    const [filterRoles, setFilterRoles] = useState(userRoles[0]);

    const defaultDateRange = [addDays(new Date(), -30), new Date()];
    const [filterDates, setFilterDates] = useState(defaultDateRange.map(x => format(x, 'yyyy-MM-dd')));
    const [filterDatesSelected, setFilterDatesSelected] = useState(false);

    const allowedDateRange = DateRangePicker.allowedRange(
        new Date(Math.max(addMonths(new Date(), -6), PROJECT_START_DATE)),
        addDays(new Date(), 1)
    );

    const handleDateChange = (dateRange) => {
        setFilterDates(dateRange.map(x => format(x, 'yyyy-MM-dd')));
    }

    const GlobalFilterComponent = (
        <div className='row'>

            <div className='col-lg-3 mb-2'>
                <TextField
                    sx={{ m: 1, width: '100%' }} size="small"
                    select
                    label="Role"
                    variant="outlined"
                    value={filterRoles}
                    onChange={(e) => {
                        setFilterRoles(e.target.value);
                    }}
                >
                    {userRoles.map((role) => (
                        <MenuItem key={role} value={role}>
                            {role}
                        </MenuItem>
                    ))}
                </TextField>
            </div>

            <div className='col-lg-3 mb-2 mt-2'>
                <div className={`${styles.date_filter_label_wrapper} ${filterDatesSelected ? styles.date_filter_label_wrapper_focus : ''}`}>
                    <DateRangePicker
                        showOneCalendar
                        defaultValue={defaultDateRange}
                        onChange={handleDateChange}
                        ranges={predefinedDateRanges}
                        cleanable={false}
                        className={styles.date_filter_wrapper}
                        onOpen={() => setFilterDatesSelected(true)}
                        onClose={() => setFilterDatesSelected(false)}
                        shouldDisableDate={allowedDateRange}
                    />
                    <span className={styles.date_filter_label}>Date Range</span>
                </div>
            </div>

        </div>
    );

    return [filterRoles, filterDates, GlobalFilterComponent];
}
