import React, { useContext, useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { UDContext } from '../../../App';
import { getNewsInfo } from '../../BaseModels/MasterData';
import st from './CarouselNews.module.css';
import CarouselNews1 from './CarouselNews1/CarouselNews1';
import CarouselNews2 from './CarouselNews2/CarouselNews2';
import CarouselNews3 from './CarouselNews3/CarouselNews3';




const CarouselNews = ({ div_id, selected_design_id }) => {
    const UserDetail = useContext(UDContext);
    const [AllNewsData, setAllNewsData] = useState([]);
    useEffect(() => {
        if (UserDetail !== null) {
            getAllNews();
        }
    }, [UserDetail]);


    const getAllNews = async () => {
        getNewsInfo().then((response) => {
            if (response.status) {
                if (response.data.length > 0) {
                    let _data = response.data.filter((news) => news.news_type === "HighLevel" && news.public === true);
                    setAllNewsData(_data);
                } else {
                    setAllNewsData([]);
                }
            }
        });
    }


    return (
        <>
            {
                AllNewsData.length > 0 ? <>
                    <br />
                    <div className="mainContainer">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className={st.favouriteWidgetsTitle}>Announcement</div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-lg-12">
                                {selected_design_id === 1 ? <CarouselNews1 key="1" carosel_information={AllNewsData} /> : <></>}
                                {selected_design_id === 2 ? <CarouselNews2 key="2" carosel_information={AllNewsData} /> : <></>}
                                {selected_design_id === 3 ? <CarouselNews3 key="2" carosel_information={AllNewsData} /> : <></>}
                            </div>
                        </div>
                    </div>
                    <br />
                </> : <></>
            }

        </>
    );
};

export default CarouselNews;