import parse from 'html-react-parser';
import React, { useContext, useEffect, useState } from 'react';
import { UDContext } from '../../../../App';
import ImageLogo from './../../../../images/ImageLogo.svg';
import btBlueWithCircle from './../../../../images/MicrosoftTeams-image.png';
import st from './SummaryDetails6.module.css';



const Image = ({ src, alt, ...props }) => {
    const [loaded, setLoaded] = useState(false);
    return (
        <>
            <img src={src} alt={alt} {...props}
                style={loaded ? { ...props.style } : { ...props.style, display: 'none' }}
                onLoad={() => { setLoaded(true); }} />
            {loaded ? null : <img src={ImageLogo} alt={alt} {...props} />}
        </>

    );
};




const SummaryDetails6 = ({ description_information }) => {
    const UserDetail = useContext(UDContext);
    const [ImageWidth, setImageWidth] = useState("600px");
    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);
    const [IsTutorialVisible, setIsTutorialVisible] = useState(false);
    const btnOpenTutorial_onClick = () => {
        setIsTutorialVisible(true);
    }
    const btnCloseTutorial_onClick = () => {
        setIsTutorialVisible(false);
    }

    useEffect(() => {
        const handleWindowResize = () => {
            let systemWidth = window.innerWidth;
            setWindowSize([systemWidth, window.innerHeight]);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    useEffect(() => {
        let systemWidth = windowSize[0];
        setImageWidth((systemWidth / 2) + "px");
    }, [windowSize]);




    return (
        <React.Fragment key={description_information}>
            <div className={`${st.landingPageHeaderbg}`}>
                <div className={st.bgHeaderImage} style={{ backgroundImage: `url(${btBlueWithCircle})` }}></div>
                <div className={st.landingPageHeaderContainer}>
                    <div className="mainContainer">
                        <div className="row">
                            <div className="col-sm-12 col-lg-6">
                                <div>
                                    <br />
                                    <div className={st.titleContainer}>
                                        <span className={st.headerWelComeText}>
                                            Welcome to
                                        </span>&nbsp;
                                        <span className={st.headerWelComeNameText}>
                                            {description_information?.description_title}
                                        </span>
                                    </div>
                                    <div className={st.textSummery}>
                                        {
                                            parse(description_information?.description)
                                        }
                                    </div>                                    
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-6">
                                <div className={st.headerImageContainer}>
                                    {/* <Image src={baseUrl + "images" + description_information?.background_image + "?token=" + UserDetail?.user?.token}
                                        alt={"playVideoImage"} className={`${st.headerImage}`} style={{ width: ImageWidth }} /> */}
                                    <Image src={description_information?.background_image}
                                        alt={"playVideoImage"} className={`${st.headerImage}`} style={{ width: ImageWidth }} />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* <div className={st.headerNote}>
                For query or concerns, connect to resolver group
            </div> */}
            <br />
        </React.Fragment>
    );
};

export default SummaryDetails6;
