import { BsFillMotherboardFill } from "react-icons/bs";
import { FaAndroid, FaApple, FaChrome, FaDesktop, FaEdgeLegacy, FaFirefox, FaLinux, FaSafari, FaWindows } from "react-icons/fa";


function getIcon(name) {
    let icon = null;
    let iconName = String(name).toLowerCase();
    switch (iconName) {
        case "windows":
        case "windows 10":
            icon = <FaWindows style={{ color: "#0074cd" }} />;
            break;
        case "linux":
            icon = <FaLinux style={{ color: "#fbbc04" }} />;
            break;
        case "mac":
        case "ios":
        case "iOS 16.6":
        case "apple":
            icon = <FaApple style={{ color: "#1a73e8" }} />;
            break;
        case "android":
            icon = <FaAndroid style={{ color: "#94c248" }} />;
            break;
        case "chrome":
            icon = <FaChrome style={{ color: "#d73429" }} />;
            break;
        case "firefox":
            icon = <FaFirefox style={{ color: "#bf4426" }} />;
            break;
        case "edge":
        case "microsoft edge":
            icon = <FaEdgeLegacy style={{ color: "#3580cd" }} />;
            break;
        case "safari":
        case "mobile safari":
            icon = <FaSafari style={{ color: "#198cef" }} />;
            break;
        case "mobile":
        case "iphone":
        case "phone":
            icon = <FaSafari style={{ color: "#9fc137" }} />;
            break;
        case "desktop":
        case "generic desktop":
            icon = <FaDesktop style={{ color: "#1675c8" }} />;
            break;
        default:
            icon = <BsFillMotherboardFill style={{ color: "#3b3d3f" }} />;
            break;
    }
    return icon;
}

export { getIcon };

