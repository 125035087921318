import React, { useContext, useState } from 'react';
import { FaExternalLinkAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { UDContext } from '../../../../App';
import { BaseUrlAPI } from '../../../BaseModels/MasterData';
import loaderImg from './../../../../images/loading_loader.gif';
import st from './FeaturedDashboard2.module.css';

const Image = ({ src, loaderImage, alt, ...props }) => {
    const [loaded, setLoaded] = useState(false);
    return (
        <>
            <img src={src} alt={alt} {...props}
                style={loaded ? {} : { display: 'none' }}
                onLoad={() => { setLoaded(true); }} />
            {loaded ? null : <img src={loaderImage} alt={alt} {...props} />}
        </>

    );
};

const FeaturedDashboard2 = ({ user_fav_report }) => {
    const UserDetail = useContext(UDContext);
    const navigate = useNavigate();
    const btnfavouriteWidget_onClick = (item) => {
        navigate("/dashboard/" + item.slug);
    }

    return (
        <>
            <div className="row">
                {
                    user_fav_report?.map((item, index) => {
                        return (
                            <div className="col-lg-2 col-md-3 col-sm-6" key={index} onClick={() => btnfavouriteWidget_onClick(item)}>
                                <div className={st.imageContainer}>
                                    <Image loaderImage={loaderImg}
                                        src={BaseUrlAPI + "images" + item.report_image + "?token=" + UserDetail?.user?.token}
                                        alt={item.title} className={st.favouriteWidgetImage} />
                                </div>
                                <div className={st.FavouriteWidgetTitle} >
                                    {item.title}
                                    <FaExternalLinkAlt className={st.topFavouriteshareIcon} />
                                </div>
                                <br />
                            </div>
                        );
                    })
                }
            </div>
        </>
    );
};

export default FeaturedDashboard2;
