import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UDContext } from '../../../App';
import { getReportUsage } from '../../BaseModels/MasterData';
import st from './FeaturedDashboard.module.css';
import FeaturedDashboard1 from './FeaturedDashboard1/FeaturedDashboard1';
import FeaturedDashboard2 from './FeaturedDashboard2/FeaturedDashboard2';
import FeaturedDashboard3 from './FeaturedDashboard3/FeaturedDashboard3';
import FeaturedDashboard4 from './FeaturedDashboard4/FeaturedDashboard4';



const FeaturedDashboard = ({ div_id, selected_design_id }) => {
    const UserDetail = useContext(UDContext);
    const [data, setData] = useState([]);
    const [IsVisible, setIsVisible] = useState(false);
    useEffect(() => {
        if (UserDetail !== null) {
            getCaroselData();
        }
    }, [UserDetail]);



    const getCaroselData = async () => {
        try {
            getReportUsage(6).then((response) => {
                if (response.status) {
                    setData(response.data);
                    setIsVisible(true);
                }
            });
        } catch (error) {
            toast.error("Something went wrong");
        }
    }


    return (
        IsVisible &&
        <>
            <br />
            <div className="mainContainer">
                <div className="row">
                    <div className="col-lg-12">
                        <div className={st.favouriteWidgetsTitle}>Feature dashboard</div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-lg-12">
                        {selected_design_id === 1 ? <FeaturedDashboard1 key="1" user_fav_report={data} /> : <></>}
                        {selected_design_id === 2 ? <FeaturedDashboard2 key="2" user_fav_report={data} /> : <></>}
                        {selected_design_id === 3 ? <FeaturedDashboard3 key="3" user_fav_report={data} /> : <></>}
                        {selected_design_id === 4 ? <FeaturedDashboard4 key="4" user_fav_report={data} /> : <></>}
                    </div>
                </div>
            </div>
        </>
    );
};

export default FeaturedDashboard;