import SendIcon from '@mui/icons-material/Send';
import IconButton from '@mui/material/IconButton';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { postAIAssistant, getAIAssistantChatById, AIDashboardSave } from './../../../BaseModels/MasterData';
import { chatStore } from './../ChatBot';
import botBlueImage from './../HumBot.png';
import { cloneDeep } from 'lodash';
import { UDContext } from '../../../../App';
import UserMessage from '../UserMessage/UserMessage';
import BotAnswer from '../BotAnswer/BotAnswer';
import Tooltip from '@mui/material/Tooltip';
import { MdCreateNewFolder, MdDashboardCustomize } from "react-icons/md";
import { FaListCheck } from "react-icons/fa6";
import FeedbackForm from '../FeedbackForm/FeedbackFormSingle';
import { FaCheckCircle } from "react-icons/fa";
import { ImRadioUnchecked } from "react-icons/im";
import { v4 as uuid4 } from 'uuid';
import GlobalContext from '../../store';
import { delay } from '../../../BaseModels/utility';
import st from './SingleWindow.module.css';

/**
 Message object:
 Structured:
 {
    "messageId",
    "sessionId",
    "createdTimestamp",
    "question": "string",
    "answer": "string",
    "sources": Array<Object>,
    "sqlQuery": "string",
    "tableData": Array<Object>,
    "shareFlag": true,
    "feedbackFlag": "Neutral|Like|Dislike",
    "feedbackMessage": "string",
    "status": "string",
}
 */


export const TAB_NAMES = {
    BUSINESS: 'Business',
    TALK_TO_DATA: 'Talk to data',
}


const SingleWindow = () => {
    const { setStoreData } = useContext(GlobalContext);
    const { chatObj, setChatObj } = useContext(chatStore);
    const UserDetail = useContext(UDContext);

    const inputRefs = useRef(null);
    const [textMessage, setTextMessage] = useState('');
    const [IsLoading, setIsLoading] = useState(false);
    const [IsHistory, setIsHistory] = useState(false);
    const [activeTab, setActiveTab] = useState(TAB_NAMES.BUSINESS);


    const { conversations, selectedSessionId } = chatObj.aiChats;
    const chatMessages = conversations[selectedSessionId] ? conversations[selectedSessionId].messages : [];
    const userName = String(UserDetail?.user?.name).trim().split(',').reverse()[0].trim();
    const userId = UserDetail?.user?.user_id;

    const location = useLocation();
    const queryParams = React.useMemo(() => new URLSearchParams(location.search), [location.search]);
    const isOpenAIWindow = location.pathname.toLocaleLowerCase() === '/openai';
    const isGenieSingleWindow = location.pathname.toLocaleLowerCase() === '/ai-ass';
    const isTabbedWindow = location.pathname.toLocaleLowerCase() === '/ai-assistant';
    const useBertClassifier = queryParams.has("bert");


    const updateChatObj = (obj) => {
        const newObj = { ...chatObj.aiChats, ...obj };
        setChatObj({ aiChats: newObj });
    }

    const scrollChatToBottom = (ms = 500) => {
        setTimeout(() => {
            const el = inputRefs.current;
            if (!el) return;
            el.scrollTo({
                behavior: 'smooth',
                top: el.scrollHeight - el.clientHeight,
            });
        }, ms);
    }

    const scrollChatToTop = (ms = 500) => {
        setTimeout(() => {
            const el = inputRefs.current;
            if (!el) return;
            el.scrollTo({
                behavior: 'smooth',
                top: 0,
            });
        }, ms);
    }


    const btnSendMessage_onClick = async () => {
        if (!textMessage || !textMessage.trim()) {
            return;
        }
        setIsLoading(true);
        const query = textMessage.trim();
        let classifyEngine = 'default';
        let runtimeEngine = 'openai';
        if (isTabbedWindow) {
            classifyEngine = 'manual';
            runtimeEngine = activeTab === TAB_NAMES.BUSINESS? 'unstructured': 'structured-genie';
        }
        else if (isOpenAIWindow) {
            classifyEngine = 'manual';
            runtimeEngine = 'structured-openai';
        }
        else {
            if (useBertClassifier)
                classifyEngine = 'bert';
            if (isGenieSingleWindow)
                runtimeEngine = 'genie';
        }

        const response = await postAIAssistant(query, classifyEngine, runtimeEngine, selectedSessionId || '');
        if (!response.status) {
            toast.error("Invalid response from API!");
            setIsLoading(false);
            return;
        }
        const messageId = response.data.message_id;
        const sessionId = response.data.session_id;
        const createdTimestamp = response.data.created_at;
        setChatObj((_state) => {
            const _aiChats = cloneDeep(_state.aiChats);
            if (!_aiChats.conversations[sessionId])
                _aiChats.conversations[sessionId] = {
                    "sessionId": sessionId,
                    "createdTimestamp": createdTimestamp,
                    "title": query,
                    "messages": []
                };
            _aiChats.conversations[sessionId].messages.push({
                "messageId": messageId,
                "sessionId": sessionId,
                "createdTimestamp": createdTimestamp,
                "shareFlag": false,
                "question": query,
                "feedbackFlag": "Neutral",
                "feedbackMessage": "",
                "completed": false,
            });
            _aiChats.selectedSessionId = sessionId;
            return { ..._state, aiChats: _aiChats };
        })
        setTextMessage('');
        scrollChatToBottom();
        const getChatUpdate = async (frequency, count = 0) => {
            await delay(frequency);
            const response = await getAIAssistantChatById(messageId);
            if (!response.status) {
                toast.error("Invalid response from API!");
                setIsLoading(false);
                return;
            }
            const resObj = response.data;
            const completed = ['completed', 'errored'].includes(resObj.status) || count >= 45;
            setChatObj((_state) => {
                const _aiChats = cloneDeep(_state.aiChats);
                const conversationObj = _aiChats.conversations[sessionId];
                const messageIndex = conversationObj.messages.findIndex(x => x.messageId === messageId);
                const messageObj = conversationObj.messages[messageIndex];
                messageObj.answer = resObj.response_text;
                messageObj.sqlQuery = resObj.response_query;
                messageObj.sources = resObj.response_sources ? JSON.parse(resObj.response_sources) : null;
                messageObj.tableData = resObj.response_table ? JSON.parse(resObj.response_table) : null;
                messageObj.completed = completed;
                messageObj.shareFlag = resObj.status === 'completed';
                return { ..._state, aiChats: _aiChats };
            })
            scrollChatToBottom();
            if (completed)
                setIsLoading(false);
            else
                getChatUpdate(frequency, count + 1);
        }
        getChatUpdate(2000); // every 2 seconds
    }

    const handleNewChat = () => {
        updateChatObj({ selectedSessionId: null });
        setTextMessage('');
        setIsHistory(false);
    }

    const handleShowHistory = () => {
        if (Object.keys(conversations).length > 0) {
            setIsHistory(val => !val);
            scrollChatToTop(200);
        } else {
            toast.warn('No chat history found!', { autoClose: 2500 });
        }
    }

    const handleConversationSelect = (conversation) => {
        updateChatObj({ selectedSessionId: conversation.sessionId });
        setTextMessage('');
        setIsHistory(false);
    }

    const toggleShare = (sessionId, messageId) => {
        setChatObj((_state) => {
            const _aiChats = cloneDeep(_state.aiChats);
            const conversationObj = _aiChats.conversations[sessionId];
            const messageIndex = conversationObj.messages.findIndex(x => x.messageId === messageId);
            const _msgObj = conversationObj.messages[messageIndex];
            _msgObj.shareFlag = !_msgObj.shareFlag;
            return { ..._state, aiChats: _aiChats };
        })
    }

    const aiDashboard = async (sessionId, messageId) => {
        const newObj = { ...chatObj.aiChats };
        const _talkToData = cloneDeep(newObj);
        const conversationObj = _talkToData.conversations[sessionId];
        const messageIndex = conversationObj.messages.findIndex(x => x.messageId === messageId);
        const messageObj = conversationObj.messages[messageIndex];
        let content = messageObj?.tableData;
        if (content) {
            let _ColumnsList = [];
            Object.keys(content[0]).forEach((element, index) => {
                let objCol = {};
                objCol.name = element;
                objCol.type_name = "STRING";
                objCol.id = index;
                _ColumnsList.push(objCol);
            });
            let obj = {};
            obj["unique_id"] = uuid4();
            obj["conference_id"] = sessionId;
            obj["message_id"] = messageId;
            obj["title"] = messageObj?.question;
            obj["sub_title"] = messageObj?.question;
            obj["description"] = messageObj?.question;
            obj["columns_names"] = _ColumnsList;
            obj["graphType"] = -1;
            obj["yAxis"] = [];
            obj["xAxis"] = -1;
            obj["user"] = userId;
            let response = await AIDashboardSave(obj);
            if (response) {
                toast.success("AI Dashboard created successfully!");
                setStoreData(obj => ({ ...obj, showAIDashboard: true }));
            }
        } else {
            toast.error("No data found to create AI Dashboard!");
        }
    }

    const historyPanel = (
        <div className={st.historyContainer}>
            <div className={st.historyBodyContainer}>
                {
                    Object.values(conversations)
                        .sort((a, b) => b.createdTimestamp.localeCompare(a.createdTimestamp))
                        .map((conversation) => {
                            return (
                                <div key={conversation.sessionId}
                                    className={st.historyItemContainer}
                                    onClick={() => handleConversationSelect(conversation)}
                                >
                                    <div className={st.historyItemMessage}>
                                        <span>{conversation.title}</span>
                                    </div>
                                </div>
                            );
                        })
                }
            </div>
        </div>
    )

    const chatPanel = (<>
        {!selectedSessionId &&
            <>
                <br />
                <div className={`${st.chatMesgRootContainer} ${st.chatMesgRootContainerBot}`}>
                    <div className={st.cbmBotImageContainer}>
                        <img src={botBlueImage} alt="bot" />
                    </div>
                    <div className={st.cbmMessage}>
                        Hi {userName}! I am your virtual agent.
                        <br />
                        How may I help you?
                    </div>
                    <div style={{ width: '30px' }}></div>
                </div>
            </>
        }
        {
            chatMessages.map((message) => (
                <div key={message.messageId} className={st.cbMsgDetailsContainer}>
                    <div className={st.cbMsgDetailsActionItems}>
                        {
                            message.tableData && message?.tableData?.length > 0 &&
                            <div className={st.shareContainer}
                                onClick={() => aiDashboard(selectedSessionId, message.messageId)}
                            >
                                <div className={st.shareLabel}>
                                    <MdDashboardCustomize className={`${st.shareCheck} ${st.shareCheckYellow} zoomIn`} />
                                </div>
                                <strong>AI Dashboard</strong>
                            </div>
                        }
                        {
                            message.completed &&
                            <div className={st.shareContainer}>
                                <div className={st.shareLabel} onClick={() => toggleShare(selectedSessionId, message.messageId)}>
                                    {
                                        message.shareFlag ?
                                            <FaCheckCircle className={`${st.shareCheck} ${st.shareCheckGreen} zoomIn`} />
                                            :
                                            <ImRadioUnchecked className={`${st.shareCheck} ${st.shareCheckRed} zoomIn`} />
                                    }
                                    <strong>Share</strong>
                                </div>
                            </div>
                        }
                    </div>
                    <UserMessage content={message.question} username={userName} />
                    <BotAnswer message={message} />
                    {
                        message.completed &&
                        <FeedbackForm messageObj={message} />
                    }
                </div>
            ))
        }
    </>)

    return (
        <div className={st.rootContainer}>
            {
                isTabbedWindow &&
                <div className={`${st.tabContainer}`}>
                    <div className={`${st.tabItem} ${activeTab === TAB_NAMES.BUSINESS ? st.activeTab : ''}`}
                        onClick={(e) => setActiveTab(TAB_NAMES.BUSINESS)}
                    >{TAB_NAMES.BUSINESS}</div>
                    <div className={`${st.tabItem} ${activeTab === TAB_NAMES.TALK_TO_DATA ? st.activeTab : ''}`}
                        onClick={(e) => setActiveTab(TAB_NAMES.TALK_TO_DATA)}
                    >{TAB_NAMES.TALK_TO_DATA}</div>
                </div>
            }
            <div className={st.chatRootContainer} ref={inputRefs}>
                {!IsHistory && chatPanel}
                {IsHistory && historyPanel}
            </div>
            <div>
                <div className={st.chatActionButtonsRootContainer}>
                    <div className={st.actionButtonItem}>
                        <Tooltip title="Create New Chat" placement="top">
                            <IconButton size="small" onClick={handleNewChat} disabled={IsLoading}>
                                <MdCreateNewFolder className={st.actionButton} />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div className={st.actionButtonItem}>
                        <Tooltip title="Chat History" placement="top">
                            <IconButton size="small" onClick={handleShowHistory} disabled={IsLoading}>
                                <FaListCheck className={st.actionButton} />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
                <div className={st.controlRootContainer}>
                    {
                        !IsLoading ? <>
                            <textarea className={st.inputControl} placeholder="Type your question here"
                                value={textMessage}
                                onChange={(e) => setTextMessage((e.target.value || '').replace(/(?:\r\n|\r|\n)$/g, ''))}
                                onKeyUp={(e) => { if (e.key === "Enter") btnSendMessage_onClick(); }}
                            ></textarea>
                            <IconButton aria-label="Go" size="large" className={st.buttonSave}
                                style={{
                                    backgroundColor: textMessage ? 'var(--primary-color-2)' : 'var(--gray-color-3)',
                                    cursor: textMessage ? 'pointer' : 'not-allowed',
                                }}
                                onClick={btnSendMessage_onClick}
                            >
                                <SendIcon fontSize="inherit" />
                            </IconButton>
                        </> : <>
                            <span className={st.loader}></span>
                        </>
                    }
                </div>
            </div>
        </div>
    );
};

export default SingleWindow;