import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import { UDContext } from './../../../App';
import DesktopNav12 from './DesktopNav/DesktopNav12/DesktopNav12';
import MobileNav2 from './MobileNav2/MobileNav2';

const TopMenu = (props) => {
    const UserDetail = useContext(UDContext);

    const navigate = useNavigate();

    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);

    const [UserDetailData, setUserDetailData] = useState({});
    const [AllDashboardData, setAllDashboardData] = useState([]);
    const [MenuData, setMenuData] = useState([]);
    const [AllNewsData, setAllNewsData] = useState([]);


    useEffect(() => {

        if (UserDetail !== null) {
            if (!UserDetail?.user?.authenticated) {
                navigate("/404");
            } else {
                setUserDetailData(UserDetail.user);
                setAllDashboardData(UserDetail.AllDashboardData);
                setMenuData(UserDetail.AllMenu);
                setAllNewsData(UserDetail.AllNews);
            }

        }
    }, [UserDetail]);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    return (
        <>
            {
                windowSize[0] > 992 ?
                    <DesktopNav12 AllDashboardData={AllDashboardData} MenuData={MenuData} NewsData={AllNewsData} UserDetailData={UserDetailData} /> :
                    <MobileNav2 AllDashboardData={AllDashboardData} MenuData={MenuData} NewsData={AllNewsData} UserDetailData={UserDetailData} />
            }
        </>
    );
};

export default TopMenu;
