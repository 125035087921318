import AccountBoxIcon from '@mui/icons-material/AccountBox';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonIcon from '@mui/icons-material/Person';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { MdMenu } from "react-icons/md";
import { useNavigate, useLocation } from "react-router-dom";
import { useContainerDimensions } from './../../../BaseModels/ResizeWindow'
import { UDContext } from '../../../../App';
import logo from './../../../../images/e2eviz-header-image.png';
import st from './BackendMenuMobile.module.css';


function BackendMenuMobile({ TopBarHeight, pages }) {
    const { width, height } = useContainerDimensions()
    const elementRef = useRef(null)
    const UserDetail = useContext(UDContext);
    const navigate = useNavigate();
    const location = useLocation();
    const [IsUserMenuVisible, setIsUserMenuVisible] = useState(false);
    const [IsSectionVisible, setIsSectionVisible] = useState(false);

    const btnHomePage_onClick = () => {
        navigate("/");
        window.location.reload();
    }

    const btnSideMenuOpen_onClick = () => {
        setIsSectionVisible(!IsSectionVisible);
    }

    const btnUserMenuOptionVisibleToggle_onClick = () => {
        setIsUserMenuVisible(!IsUserMenuVisible);
    }

    const btnUserMenuOptionVisibleClose_onClick = () => {
        setIsUserMenuVisible(false);
    }

    const btnSectionClose = () => {
        setIsSectionVisible(false);
    }

    return (
        <>
            <div className={st.rootContainer}>
                <div className={st.menuActionButtonContainer}>
                    <IconButton aria-label="Menu" size="large" sx={{ color: '#ffffff' }} onClick={btnSideMenuOpen_onClick}>
                        <MoreVertIcon fontSize="inherit" />
                    </IconButton>
                </div>
                <div onClick={btnHomePage_onClick} className={st.logoLinkContainer}>
                    <img src={logo} alt="logo" className={st.logo} style={{ width: width < 500 ? width / 4 : '' }} />
                </div>
                <div className={st.actionButtonRootContainer}>
                    <div className={st.actionButtonContainer}>
                        <div className={st.actionButtonWrapper}>

                        </div>
                        <div className={st.actionButtonWrapper}>
                            <IconButton aria-label="User" size="large" sx={{ color: '#ffffff' }} onClick={btnUserMenuOptionVisibleToggle_onClick}>
                                <PersonIcon fontSize="inherit" />
                                <div className={st.userPopupMenuContainer} style={{ display: IsUserMenuVisible ? 'block' : 'none' }}
                                    onMouseLeave={() => setIsUserMenuVisible(false)}>
                                    <div className={st.userPopuMenuDetail}>
                                        <div onClick={btnUserMenuOptionVisibleClose_onClick} className={st.userPopuMenuItem}><AccountBoxIcon /> {UserDetail?.user?.user_id}</div>
                                    </div>
                                </div>
                            </IconButton>
                        </div>
                        <div className={st.actionButtonWrapper} style={{ display: 'none' }}>
                            <IconButton aria-label="Menu" size="large" sx={{ color: '#ffffff' }} onClick={btnSideMenuOpen_onClick}>
                                <MdMenu fontSize="inherit" />
                            </IconButton>
                        </div>
                        <div className={st.actionButtonWrapper}></div>
                        <div className={st.actionButtonWrapper}></div>
                        <div className={st.actionButtonWrapper}></div>
                    </div>
                </div>
            </div>
            <Drawer BackdropProps={{ invisible: true }}
                anchor={'left'} open={IsSectionVisible} onClose={btnSectionClose}
                sx={{
                    '&.MuiDrawer-root .MuiDrawer-paper': { marginTop: TopBarHeight + "px", boxShadow: '2px 2px 2px 0px #01235a' },
                    height: 'calc(100% - 60px)',
                }}>
                <Box sx={{ width: 280 }} className={st.sideMenuRootContainer}>
                    {
                        pages.map((page, index) => (
                            <div key={index} className={`${st.pageSideMenuContainer} ${location.pathname === page.slug ? st.pageSideMenuContainerActive : ""}`}>
                                <div className={st.pageSideMenuIconContainer}>
                                    <i className={page.icon}></i>
                                </div>
                                <div className={st.pageSideMenuNameContainer}>
                                    <div onClick={() => navigate(page.slug)} className={st.pageSideMenuName}>{page.pageName}</div>
                                </div>
                            </div>
                        ))
                    }
                </Box>
            </Drawer>
        </>
    );
}

export default BackendMenuMobile;