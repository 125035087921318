import React, { useContext, useEffect, useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UDContext } from '../../../../App';
import { BaseUrlAPI, getReportUsage } from '../../../BaseModels/MasterData';
import ImageLogo from './../../../../images/ImageLogo.svg';
import st from './SummaryDetails5.module.css';


const Image = ({ src, loaderImage, alt, ...props }) => {
    const [loaded, setLoaded] = useState(false);
    return (
        <>
            <img src={src} alt={alt} {...props}
                style={loaded ? {} : { display: 'none' }}
                onLoad={() => { setLoaded(true); }} />
            {loaded ? null : <img src={ImageLogo} alt={alt} {...props} />}
        </>

    );
};



const SummaryDetails5 = ({ description_information }) => {
    const UserDetail = useContext(UDContext);
    const [data, setData] = useState([]);

    useEffect(() => {
        if (UserDetail !== null) {
            getCaroselData();
        }
    }, [UserDetail]);

    const getCaroselData = async () => {
        try {
            getReportUsage(6).then((response) => {
                if (response.status) {
                    setData(response.data);
                } else {
                    toast.error(response.message);
                }
            });
        } catch (error) {
            toast.error("Something went wrong");
        }
    }

    return (
        <React.Fragment key={description_information}>
            <br />
            <div className="mainContainer">
                <div className="row">
                    <div className="col-lg-12">
                        <Carousel
                            additionalTransfrom={0}
                            arrows
                            autoPlay
                            autoPlaySpeed={3000}
                            centerMode={false}
                            className={st.carouselClass}
                            dotListClass=""
                            draggable
                            focusOnSelect={false}
                            infinite
                            itemClass=""
                            keyBoardControl
                            minimumTouchDrag={80}
                            pauseOnHover
                            renderArrowsWhenDisabled={false}
                            renderButtonGroupOutside={false}
                            renderDotsOutside={false}
                            responsive={{
                                desktop: {
                                    breakpoint: {
                                        max: 3000,
                                        min: 1024
                                    },
                                    items: 6,
                                    partialVisibilityGutter: 40
                                },
                                mobile: {
                                    breakpoint: {
                                        max: 464,
                                        min: 0
                                    },
                                    items: 1,
                                    partialVisibilityGutter: 30
                                },
                                tablet: {
                                    breakpoint: {
                                        max: 1024,
                                        min: 464
                                    },
                                    items: 2,
                                    partialVisibilityGutter: 30
                                }
                            }}
                            rewind={false}
                            rewindWithAnimation={false}
                            rtl={false}
                            shouldResetAutoplay
                            showDots={true}
                            sliderClass=""
                            slidesToSlide={1}
                            swipeable
                        >
                            {
                                data.map((dashboard, index) => {
                                    return (
                                        <div key={index} className={st.rootContainer}>
                                            <div className={st.dashboardContainer}>
                                                <div className={st.imagecontainer}>
                                                    <Image loaderImage={ImageLogo}
                                                        src={BaseUrlAPI + "images" + dashboard?.report_image + "?token=" + UserDetail?.user?.token}
                                                        alt={dashboard?.url + ".png"} className={st.reportImage} />
                                                </div>
                                                <div className={st.detailContainer}>
                                                    <div className={st.title}>{dashboard?.title}</div>
                                                    <div className={st.description}>{dashboard?.description}</div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </Carousel>
                    </div>
                </div>
            </div>

            <br />
        </React.Fragment>
    );
};

export default SummaryDetails5;
