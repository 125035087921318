import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import React, { useEffect, useRef, useState } from 'react';
import st from './ReportSelect.module.css';

const ReportSelect = ({ menuData, setSelectedDashboard, validReportIds }) => {
    const ref = useRef(null);
    const [IsSectionsVisible, setIsSectionsVisible] = useState(false);
    const [SectionData, setSectionData] = useState([]);
    const [PageName, setPageName] = useState('');
    const [TopMenuSectionTab, setTopMenuSectionTab] = useState('0');
    const [height, setHeight] = useState(0);

    useEffect(() => {
        setHeight(ref.current.clientHeight)
    })

    const btnTopMenuSectionTab_onChange = (index) => {
        setTopMenuSectionTab(index);
    };

    const btnPage_onMouseEnter = (page) => {
        setPageName(page.pageName);
        setIsSectionsVisible(true);
        setTopMenuSectionTab('0');
        const chunkSize = 5;
        page.data.forEach(section => {
            section.chunkData = [];
            const reportData = section.data.filter(x => validReportIds.has(x.dashboardid));
            for (let i = 0; i < reportData.length; i += chunkSize) {
                const chunk = reportData.slice(i, i + chunkSize);
                section.chunkData.push(chunk);
            }
        });
        setSectionData(page.data);
    }

    useEffect(() => {
        if (IsSectionsVisible === false) {
            setPageName('');
        }
    }, [IsSectionsVisible, setPageName]);


    const onDashboardClick = (dashboard) => {
        setIsSectionsVisible(false);
        setSelectedDashboard(dashboard);
    }

    return (
        <>
            <div className={st.AppBar} ref={ref}>
                <div className={`${st.pagesContainer}`}>
                    {
                        menuData.map((page, index) => (
                            <div key={page.pageName} className={st.pageWrapper}>
                                <div className={`${st.pageContainer} ${page.pageName === PageName ? st.ActivePageContainer : ''}`}
                                    onMouseEnter={() => { btnPage_onMouseEnter(page) }}>
                                    <div className={st.pageName}>
                                        {page.pageName}
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div >
            <div className={`${st.menuSectionsContainer} ${'swing-in-top-fwd'}`}
                style={{ display: IsSectionsVisible ? 'block' : 'none' }}>
                <div className={st.menuSectionsTabsDetailContainer} onMouseLeave={() => { setIsSectionsVisible(false) }}>
                    <div className={st.menuSectionsTabs}>
                        {
                            SectionData.map((section, index) => (
                                <React.Fragment key={index}>
                                    <div key={index} onMouseEnter={() => btnTopMenuSectionTab_onChange(index)} className={`${st.menuSectionsTab} ${String(index) === String(TopMenuSectionTab) ? st.tabActive : ''}`}>
                                        <div>{section.sectionName}</div>
                                        <KeyboardArrowRightIcon />
                                    </div>
                                </React.Fragment>
                            ))
                        }
                    </div>
                    {
                        SectionData.map((section, index) => (
                            <div key={index} className={st.menuSectionsDataContainer} style={{ display: String(TopMenuSectionTab) === String(index) ? 'block' : 'none' }}>
                                <div className={st.menuSectionsDataWrapper}>
                                    {
                                        section.chunkData.map((data, indexChunk) => (
                                            <div key={indexChunk} >
                                                {
                                                    data.map((dataSection, indexData) => (
                                                        <div key={indexData} className={`${st.menuItemDetailContainer} ${st.menuItemLink}`}
                                                            onClick={() => { onDashboardClick(dataSection) }}>
                                                            {dataSection.title}
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div style={{ height: height - 20 }}></div>
        </>
    );
}

export default ReportSelect;

