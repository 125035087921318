import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// let baseUrl = 'https://e2eviz-development.amgen.com/api/';
// let baseUrl = "http://127.0.0.1:8080/api/";
let baseUrl = process.env.REACT_APP_API_BASE_URL;
let MatomoSiteId = process.env.WDS_SOCKET_PATH;

function apiResponseTime(startTime, endTime, url, method) {
    try {
        const urlObj = new URL(url);
        const searchParams = urlObj.searchParams;
        if (searchParams.has('token')) {
            searchParams.delete('token');
            url = urlObj.toString();
        }
        let obj = {
            "url": url,
            "method": method,
            "startTime": startTime,
            "endTime": endTime
        }
        var _paq = window._paq = window._paq || [];
        let currentTime = new Date().getTime();
        _paq.push(['setCustomVariable', currentTime, "API_CALL", JSON.stringify(obj), "visit"]);
    } catch (error) {
        console.error('Error while log in matomo:', error);
    }
}

function getData(url) {
    return new Promise(async (resolve, reject) => {
        try {
            let startTime = new Date().getTime();
            const response = await fetch(baseUrl + "api/" + url);
            if (!response.ok) {
                toast("Error: Posting Data to API", { type: 'error', autoClose: 1000 });
                // throw new Error('Failed to fetch data from API');
            }
            const data = await response.json();
            let endTime = new Date().getTime();
            apiResponseTime(startTime, endTime, baseUrl + "api/" + url, "GET");
            resolve(data);
        } catch (error) {
            console.error('Error fetching data from API:', error);
            reject(error);
        }
    });
}
function getIpAddress() {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch("https://api.ipify.org/?format=json");
            if (!response.ok) {
                toast("Error: Posting Data to API", { type: 'error', autoClose: 1000 });
                // throw new Error('Failed to fetch data from API');
            }
            const data = await response.json();
            resolve(data.ip);
        } catch (error) {
            console.error('Error fetching data from API:', error);
            reject(error);
        }
    });
}

function postData(url, data) {
    return new Promise(async (resolve, reject) => {
        try {
            let startTime = new Date().getTime();
            const response = await fetch(baseUrl + "api/" + url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            if (!response.ok) {
                toast("Error: Posting Data to API", { type: 'error', autoClose: 1000 });
                // throw new Error('Failed to post data to API');
            }
            const responseData = await response.json();
            let endTime = new Date().getTime();
            apiResponseTime(startTime, endTime, baseUrl + "api/" + url, "POST");
            resolve(responseData);
        } catch (error) {
            console.error('Error posting data to API:', error);
            reject(error);
        }
    });
}
function postData_return_blob(url, data) {
    return new Promise(async (resolve, reject) => {
        try {
            let startTime = new Date().getTime();
            const response = await fetch(baseUrl + "api/" + url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            if (!response.ok) {
                toast("Error: Posting Data to API", { type: 'error', autoClose: 1000 });
                // throw new Error('Failed to post data to API');
            }
            const responseData = await response.blob();
            let endTime = new Date().getTime();
            apiResponseTime(startTime, endTime, baseUrl + "api/" + url, "POST");
            resolve(responseData);
        } catch (error) {
            console.error('Error posting data to API:', error);
            reject(error);
        }
    });
}

/**
 * make a GET request using fetch with optional parameters
 * @param {string} url 
 * @param {RequestInit} params Optional extra parameters for fetch.
 *  Accepts `queryParams` object.
 */
const getDataP = async (url, params = { queryParams: { token: '' } }) => {
    try {
        let startTime = new Date().getTime();
        const queryString = new URLSearchParams(params.queryParams).toString();
        const response = await fetch(`${baseUrl}api/${url}?${queryString}`, params);
        if (!response.ok) {
            // toast("Error: Fetch Data from API", { type: 'error', autoClose: 1000 });
            throw new Error('Failed to fetch data from API');
        }
        const data = await response.json();
        let endTime = new Date().getTime();
        apiResponseTime(startTime, endTime, `${baseUrl}api/${url}?${queryString}`, "GET");
        return data;
    } catch (error) {
        console.error('Error fetching data from API:', error);
        throw error;
    }
}

/**
 * make a POST request using fetch with optional parameters
 * @param {string} url 
 * @param {RequestInit} params Optional extra parameters for fetch.
 *  Accepts `queryParams` and `data` object.
 */
const postDataP = async (url, params = { queryParams: { token: '' }, data: null }) => {
    try {
        let startTime = new Date().getTime();
        const queryString = new URLSearchParams(params.queryParams).toString();
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            ...params
        };
        if (params.data)
            options['body'] = JSON.stringify(params.data);
        const response = await fetch(`${baseUrl}api/${url}?${queryString}`, options);
        if (!response.ok) {
            throw new Error('Failed to post data to API');
        }
        const data = await response.json();
        let endTime = new Date().getTime();
        apiResponseTime(startTime, endTime, `${baseUrl}api/${url}?${queryString}`, "POST");
        return data;
    } catch (error) {
        console.error('Error fetching data from API:', error);
        throw error;
    }
}

const getRaw = async (url, params = { queryParams: { token: '' } }) => {
    try {
        let startTime = new Date().getTime();
        const queryString = new URLSearchParams(params.queryParams).toString();
        const response = await fetch(`${baseUrl}api/${url}?${queryString}`, params);
        if (!response.ok) {
            // toast("Error: Fetch Data from API", { type: 'error', autoClose: 1000 });
            throw new Error('Failed to fetch data from API');
        }
        let endTime = new Date().getTime();
        apiResponseTime(startTime, endTime, `${baseUrl}api/${url}?${queryString}`, "GET");
        return response;
    } catch (error) {
        console.error('Error fetching data from API:', error);
        throw error;
    }
}

export { baseUrl, getData, getDataP, getIpAddress, MatomoSiteId, postData, postDataP, postData_return_blob, getRaw };

