import { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { MdInfoOutline } from 'react-icons/md';


const InfoButton = ({ content }) => {
    const [showTooltip, setShowTooltip] = useState(false);

    return (
        <Tooltip
            title={
                <div style={{ whiteSpace: 'pre-line' }}>{content}</div>
            }
            open={showTooltip}
            onOpen={() => setShowTooltip(true)}
            onClose={() => setShowTooltip(false)}
            disableFocusListener
            disableTouchListener
        >
            <span
                onClick={() => setShowTooltip(!showTooltip)}
            >
                <MdInfoOutline />
            </span>
        </Tooltip>
    );
}

export default InfoButton;