import Button from '@mui/material/Button';
import React, { useEffect, useState } from 'react';

const CustomButton = ({ icon, children, type, ...rest }) => {
    const [IconBgColor, setIconBgColor] = useState('#0063c3');
    const [TextBgColor, setTextBgColor] = useState('#156dd3');

    useEffect(() => {
        switch (type) {
            case 'primary':
                setIconBgColor('#0063c3');
                setTextBgColor('#156dd3');
                break;
            case 'secondary':
                setIconBgColor('#6c757d');
                setTextBgColor('#7d8790');
                break;
            case 'success':
                setIconBgColor('#218838');
                setTextBgColor('#319748');
                break;
            case 'warning':
                setIconBgColor('#ffc107');
                setTextBgColor('#e6b729');
                break;
            case 'danger':
                setIconBgColor('#dc3545');
                setTextBgColor('#e1404f');
                break;
            case 'info':
                setIconBgColor('#17a2b8');
                setTextBgColor('#2ca7ba');
                break;
            case 'dark':
                setIconBgColor('#343a40');
                setTextBgColor('#404449');
                break;
            default:
                setIconBgColor('#0063c3');
                setTextBgColor('#156dd3');
                break;
        }
    }, [type]);

    return (
        <>
            <Button variant="contained" endIcon={icon} {...rest} size="small" color={type}>
                {children}
            </Button>
        </>
    );
};

export default CustomButton;
