
/**
 * Returns a promise that resolves after given milliseconds 
 * @param {number} ms
 * @returns 
 */
export const delay = ms => new Promise(res => setTimeout(res, ms));


export const testURL = (str) => {
    const urlRegex = new RegExp(/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/is);
    return Boolean(str.match(urlRegex));
}


export const formatHeader = (name) => {
    let newName = "";
    if (name) {
        name = String(name);
        let nameArray = name.split('_');
        nameArray.forEach(word => {
            if (word) {
                if (word === word.toUpperCase()) {
                    newName += word + " ";
                } else {
                    newName += word.charAt(0).toUpperCase() + word.slice(1) + " ";
                }
            }
        });
        newName = String(newName).trim();
    }
    if (newName === "") {
        newName = name;
        return newName;
    } else {
        return newName;
    }
};
