import { schemeTableau10 } from 'd3-scale-chromatic';
import React, { useEffect, useState } from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip, } from 'recharts';
import { getIcon } from '../../../BaseModels/IconType';
import { getMatomoDeviceKPI } from '../../../BaseModels/MasterData';
import { useContainerDimensions } from '../../../BaseModels/ResizeWindow';
import st from './DeviceKPI.module.css';

const DeviceKPI = ({ dates, roles }) => {
    const [rawData, setRawData] = useState([]);
    const [chartData, setChartData] = useState([]);
    const { width, _ } = useContainerDimensions();

    useEffect(() => {
        const ac = new AbortController();
        getMatomoDeviceKPI(dates[0], dates[1], roles, ac.signal)
            .then((response) => {
                if (!response.status) return;
                response.data.forEach(OSType => {
                    OSType.icon = getIcon(OSType.os);
                    OSType.data.forEach(browserType => {
                        browserType.icon = getIcon(browserType.browser);
                    });
                });
                setRawData(response.data);
            });
        return () => ac.abort();
    }, [dates, roles]);

    useEffect(() => {
        const len = rawData.length;
        let chunkSize = 0;
        if (width > 1300) {
            if (Math.ceil(len / 4) === Math.ceil(len / 3))
                chunkSize = 3;
            else
                chunkSize = 4;
        }
        else if (width > 1000) {
            if (Math.ceil(len / 3) === Math.ceil(len / 2))
                chunkSize = 2;
            else
                chunkSize = 3;
        }
        else if (width > 700) {
            chunkSize = 2;
        }
        else {
            chunkSize = 1;
        }

        const chunks = [];
        for (let i = 0; i < len; i += chunkSize) {
            let chunk = rawData.slice(i, i + chunkSize);
            let colWidth = Math.round(12 / chunk.length);
            chunk.forEach(x => {
                x.colWidth = colWidth;
            })
            chunks.push(chunk);
        }
        setChartData(chunks);

    }, [width, rawData])

    const CustomTooltip = ({ active, payload, }) => {
        if (active && payload && payload.length) {
            return (
                <div className={st.tooltip}>
                    <p>{payload[0].payload.devicetype}</p>
                </div>
            );
        }
        return null;
    };

    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, fill, payload }) => {
        const RADIAN = Math.PI / 180;
        const radius = 25 + innerRadius + (outerRadius - innerRadius);
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        return (
            <text
                x={x}
                y={y}
                fill={fill}
                fontSize="12px"
                textAnchor={x > cx ? "start" : "end"}
                dominantBaseline="central"
            >
                <tspan x={x} y={y + (y > cy ? 0 : -12)}>{payload.browser}</tspan>
                <tspan x={x} y={y + (y > cy ? 12 : 0)}>({payload.visitcount})</tspan>
            </text>
        );
    };


    return (
        <div>
            {
                chartData.length ?
                    chartData.map((rowData, idx1) => (
                        <div className='row' key={idx1} >
                            {
                                rowData.map((OSType, idx2) => {
                                    return (
                                        <div className={`col-${OSType.colWidth} p-2`} key={idx2}>
                                            <div className={st.card}>
                                                <div className={st.OsTitle}>{OSType.os}</div>
                                                <div className={st.graphDetailContainer}>
                                                    <div className={st.detailContainer}>
                                                        <div className={st.detail}>
                                                            <div className={st.OsIconContainer}>
                                                                {OSType.icon}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={st.detailCountContainer}>
                                                        <div className={st.detailCount}>
                                                            <div className={st.OsCount}>
                                                                Total Visit: {OSType.total_visit}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={st.graphContainer}>
                                                        <ResponsiveContainer width="100%" height="100%">
                                                            <PieChart >
                                                                <Pie
                                                                    data={OSType.data}
                                                                    cx="50%"
                                                                    cy="50%"
                                                                    innerRadius={40}
                                                                    outerRadius={60}
                                                                    fill="#8884d8"
                                                                    paddingAngle={3}
                                                                    minAngle={3}
                                                                    dataKey="visitcount"
                                                                    label={renderCustomizedLabel}
                                                                >
                                                                    {OSType.data.map((entry, idx2) => (
                                                                        <Cell
                                                                            key={`pu-cell-${idx2}`}
                                                                            style={{ outline: 'none', userSelect: 'none' }}
                                                                            fill={schemeTableau10[idx2 % schemeTableau10.length]}
                                                                            className={st.slice}
                                                                        />
                                                                    ))}
                                                                </Pie>
                                                                <Tooltip content={<CustomTooltip />} />
                                                            </PieChart>
                                                        </ResponsiveContainer>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            }

                        </div>
                    ))


                    :
                    <div className={st.card}>
                        <div className={st.detail}>
                            {"No Device KPI data found."}
                        </div>
                    </div>
            }
        </div>
    );
};

export default DeviceKPI;
