import Switch from '@mui/material/Switch';
import * as htmlToImage from "html-to-image";
import React, { createRef, useState } from 'react';


import BackendTopMenu from '../../BackendTopMenu/BackendTopMenu';
import DescriptionInformation from './DescriptionInformation/DescriptionInformation';
import FavoriteDashboardCreation from './FavoriteDashboardCreation/FavoriteDashboardCreation';
const createFileName = (extension = "", ...names) => {
    if (!extension) {
        return "";
    }

    return `${names.join("")}.${extension}`;
};

const LandingPageCreation = (props) => {
    const [IsDescriptionInformationVisible, setIsDescriptionInformationVisible] = React.useState(false);
    const [IsYourFavoriteDashboardVisible, setIsYourFavoriteDashboardVisible] = React.useState(false);

    const IsDescriptionInformationVisible_onChange = (e) => {
        setIsDescriptionInformationVisible(e.target.checked);
    };
    const IsYourFavoriteDashboardVisible_onChange = (e) => {
        setIsYourFavoriteDashboardVisible(e.target.checked);
    };

    const [SummaryDetailsPreviewImage, setSummaryDetailsPreviewImage] = useState();
    const [SummarySectionDescription, setSummarySectionDescription] = useState("");

    const ref = createRef(null);
    const SummarySectionDescription_onChange = async (e) => {
        setSummarySectionDescription(e.target.value)
        const dataURI = await htmlToImage.toJpeg(ref.current);
        setSummaryDetailsPreviewImage(dataURI);
    }


    return (
        <>
            <BackendTopMenu />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 col-lg-12">
                        <h3>Is Description Information Visible
                            <Switch
                                checked={IsDescriptionInformationVisible}
                                onChange={IsDescriptionInformationVisible_onChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </h3>
                    </div>
                </div>
                {IsDescriptionInformationVisible ? <DescriptionInformation /> : null}
                <div className="row">
                    <div className="col-sm-12 col-lg-12">
                        <h3>Is Your Favorite Dashboard Visible
                            <Switch
                                checked={IsYourFavoriteDashboardVisible}
                                onChange={IsYourFavoriteDashboardVisible_onChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </h3>
                    </div>
                </div>
                {IsYourFavoriteDashboardVisible ? <FavoriteDashboardCreation /> : null}
            </div>

            <br />
            <br />
            <br />

        </>
    );
};

export default LandingPageCreation;
