import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip, } from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import zoomPlugin from "chartjs-plugin-zoom";
import React, { useCallback, useEffect, useState } from 'react';
import { Bar } from "react-chartjs-2";
import { updateGraph } from './../../../../../BaseModels/MasterData';


const BarGraph = ({ data, options, msg_id }) => {
    ChartJS.register(CategoryScale, LinearScale, BarElement, ChartDataLabels, Title, Tooltip, Legend, zoomPlugin);

    const [ChartData, setChartData] = useState(data);
    const [ChartOptions, setChartOptions] = useState(options);

    useEffect(() => {
        if (data && options && msg_id) {
            setChartData(data);
            setChartOptions(options);
        }
    }, [data, options, msg_id]);

    

    return (
        <>
            <Bar id={"stackD" + msg_id} data={ChartData} options={ChartOptions} width={"100%"} height="300px" />
        </>
    );
};

export default BarGraph;