import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UDContext } from '../../../App';
import { getCarosel } from '../../BaseModels/MasterData';
import Carousel1 from './Carousel1/Carousel1';



const Carousels = ({ div_id, selected_design_id }) => {
    const UserDetail = useContext(UDContext);
    const [data, setData] = useState([]);

    useEffect(() => {
        getCaroselData();
    }, []);



    const getCaroselData = async () => {
        try {
            getCarosel(div_id).then((response) => {
                if (response.status) {
                    setData(response.data);
                }
            });
        } catch (error) {
            toast.error("Something went wrong");
        }
    }


    return (
        <>
            <br />
            {selected_design_id === 1 ? <Carousel1 key="1" carosel_information={data} /> : <></>}
            <br />
        </>
    );
};

export default Carousels;