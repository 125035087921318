import React, { useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer, Tooltip, } from 'recharts';
import InfoButton from '../InfoButton';
import { getMonthlyTraffic6Month } from '../../../BaseModels/MasterData';
import styles from './SixMonthlyTraffic.module.css';


const SixMonthlyTraffic = ({ dates, roles }) => {
    const fontSizeLegends = 12;
    const colorLegends = 'black';

    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        const ac = new AbortController();
        getMonthlyTraffic6Month(dates[0], dates[1], roles, ac.signal)
            .then((response) => {
                if (response.status) {
                    setChartData(response.data);
                }
            });
        return () => ac.abort();
    }, [dates, roles]);

    const CustomTooltip = ({ active, payload, }) => {
        if (active && payload && payload.length) {
            return (
                <div className={styles.tooltip}>
                    <p>{payload[0].payload.month}</p>
                    <p>Visits: {payload[0].payload.total_visit}</p>
                </div>
            );
        }
        return null;
    };

    const CustomizedAxisLabel = ({ x, y, payload }) => {
        const [month, year] = payload.value.split('-');
        const label = `${month.substring(0, 3)}-${year.substring(2)}`
        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0}
                    dy={14}
                    fill={colorLegends}
                    fontSize={fontSizeLegends}
                    fontWeight='bold'
                    textAnchor='middle'
                >
                    {label}
                </text>
            </g>
        );
    }

    const CustomizedDataLabel = ({ x, y, value }) => {
        return (
            <g transform={`translate(${x},${y})`}>
                <text
                    x={0}
                    y={-14}
                    fill={colorLegends}
                    fontSize={fontSizeLegends}
                    textAnchor='middle'
                    dominantBaseline='central'
                    fontWeight='bold'
                >
                    {value}
                </text>
            </g>
        );
    };

    return (
        <>
            <div className={styles.header}>
                <div className={styles.title}>Monthly Traffic 6 Month</div>
                <InfoButton
                    content={'This section display user activity trends and patterns over each month'}
                />
            </div>
            <div className={styles.wrapper}>
                <div className={styles.chart_container}>
                    <ResponsiveContainer width='100%' height='100%'>
                        <LineChart
                            data={chartData}
                            margin={{
                                top: 20,
                                right: 30,
                                left: 10,
                                bottom: 5,
                            }}
                        >
                            <YAxis type='number' />
                            <XAxis
                                type='category'
                                interval={0}
                                tick={<CustomizedAxisLabel />}
                                dataKey='month'
                            />
                            <Line
                                type="linear"
                                dataKey="total_visit"
                                stroke="#8884d8"
                                strokeWidth={5}
                                label={<CustomizedDataLabel />}
                            />
                            <Tooltip content={<CustomTooltip />} />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </>
    );
};

export default SixMonthlyTraffic;