import React from 'react';
import parse from 'html-react-parser';
import st from './SummaryDetails4.module.css';





const SummaryDetails4 = ({ description_information }) => {

    return (
        <React.Fragment key={description_information}>
            <div className={`${st.bgImage}`} style={{ backgroundImage: `url(${description_information?.background_image})` }}>
                <div className="mainContainer">
                    <div className={st.headerCard}>
                        <div className="row">
                            <div className={"col-sm-12 col-lg-12"}>
                                <span className={st.headerWelComeText}></span>
                                <span className={st.headerWelComeReportText}>{description_information?.description_title}</span>
                                <div className={st.headerWelComeTextDetail}>
                                    {parse(description_information?.description)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default SummaryDetails4;
