import React, { useContext, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { UDContext } from '../../../../App';
import { BaseUrlAPI } from '../../../BaseModels/MasterData';
import ImageLogo from './../../../../images/ImageLogo.svg';
import st from './DashboardPageItem3.module.css';

const Image = ({ src, alt, ...props }) => {
    const [loaded, setLoaded] = useState(false);
    return (
        <>
            <img src={src} alt={alt} {...props}
                style={loaded ? {} : { display: 'none' }}
                onLoad={() => { setLoaded(true); }} />
            {loaded ? null : <img src={ImageLogo} alt={alt} {...props} />}
        </>

    );
};

const DashboardPageItem3 = ({ sections }) => {
    const UserDetail = useContext(UDContext);
    const navigate = useNavigate();
    const btnfavouriteWidget_onClick = (item) => {
        navigate("/dashboard/" + item.slug);
    }
    return (
        <>
            <div className="mainContainer">
                <br />
                <div className={st.sectionRootContainer}>
                    {
                        sections?.map((section, index) => (
                            // <div key={index} className={st.sectionContainer} style={{ width: (100 / sections.length) + "%" }}>
                            <div key={index} className={st.sectionContainer}>
                                <div className={st.sectionHeaderContainer}>
                                    <span className={st.sectionHeader}>
                                        {section.section_name}
                                    </span>
                                </div>
                                <div className={st.sectionBodyContainer}>
                                    {
                                        section.report.map((report, index) => (
                                            <div className={st.reportRootContainer} key={index} onClick={() => btnfavouriteWidget_onClick(report)}>
                                                <div className={st.reportContainer}>
                                                    <Image src={BaseUrlAPI + "images" + report?.report_image + "?token=" + UserDetail?.user?.token}
                                                        alt={report?.title} className={`${st.cardImage}`} />
                                                    <div className={st.reportDetails}>
                                                        <div className={st.reportTitle}>
                                                            {report?.title}
                                                        </div>
                                                        <span className={st.reportTitleToolTip}>{report?.title}</span>
                                                        <div className={st.reportDescription}>{report?.description}</div>
                                                        {/* <span className={st.reportDescriptionToolTip} style={{ width: (100 / sections.length) + "%" }}>{report?.description}</span> */}
                                                        <span className={st.reportDescriptionToolTip}>{report?.description}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    );
};

export default DashboardPageItem3;