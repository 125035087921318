import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { FaShareAltSquare } from 'react-icons/fa';
import { FiMaximize2, FiMinimize2 } from 'react-icons/fi';
import { IoIosCloseCircle } from 'react-icons/io';
import { useNavigate, useLocation } from "react-router-dom";
import { components } from 'react-select';
import Async from "react-select/async";
import { toast } from 'react-toastify';
import { getUserSearch, shareAIAssistantChats, getAIAssistantChats, openAIRefresh } from '../../BaseModels/MasterData';
import { delay } from '../../BaseModels/utility';
import ModalPopup from '../../CustomElements/ModalPopup/ModalPopup';
import { UDContext } from './../../../App';
import { default as botImage } from './HumBot.png';
import st from './ChatBot.module.css';
import SingleWindow from './SingleWindow/SingleWindow';



export const chatStore = createContext({
    chatObj: {},
    setChatObj: () => null
});


const optionCache = {};

const ChatBot = () => {
    const UserDetail = useContext(UDContext);
    const [chatObj, _setChatObj] = useState({
        aiChats: {
            conversations: {},
            selectedSessionId: null,
        },
    });
    const setChatObj = (x) => {
        if (typeof x === 'function') {
            _setChatObj(x);
        }
        else {
            _setChatObj(oldObj => ({ ...oldObj, ...x }))
        }

    };
    const [chatBotOpen, setChatBotOpen] = useState(false);
    const [IsMaximize, setIsMaximize] = useState(false);
    const [IsShareModelOpen, setIsShareModelOpen] = useState(false);
    const [SearchUserMail, setSearchUserMail] = useState('');
    const [SelectedUserList, setSelectedUserList] = useState([]);
    const [FilteredData, setFilteredData] = useState([]);
    const [InputSearchText, setInputSearchText] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [selectedMessages, setSelectedMessages] = useState([]);
    const navigate = useNavigate();


    const { AllDashboardData } = UserDetail;

    const btnOpenModel = () => {
        setShowModal(true);
    }
    const btnCloseModel = () => {
        setShowModal(false);
    }

    const inputSearchBox_onChange = (e) => {
        setInputSearchText(e.target.value);
    }

    useEffect(() => {
        setInterval(openAIRefresh, Math.floor(10 + Math.random() * 10) * 60 * 1000) // call every 10-20 minutes
        getAIAssistantChats()
            .then((response) => {
                if (!response.status) {
                    toast.error('Could not fetch chat history from API')
                    return;
                }
                const chatSessions = {};
                response.data.forEach((item) => {
                    if (!item.status) return;
                    if (!chatSessions[item.session_id])
                        chatSessions[item.session_id] = {
                            "sessionId": item.session_id,
                            "title": "",
                            "createdTimestamp": item.session_created_at,
                            "messages": [],
                        }
                    chatSessions[item.session_id].messages.push({
                        "messageId": item.message_id,
                        "sessionId": item.session_id,
                        "createdTimestamp": item.created_at,
                        "sessionCreatedTimestamp": item.session_created_at,
                        "question": item.query,
                        "answer": item.response_text,
                        "sources": item.response_sources ? JSON.parse(item.response_sources) : null,
                        "sqlQuery": item.response_query,
                        "tableData": item.response_table ? JSON.parse(item.response_table) : null,
                        "shareFlag": true,
                        "feedbackFlag": item.feedback_flag,
                        "feedbackMessage": item.feedback_message,
                        "status": item.status,
                        "completed": true
                    })
                })
                Object.keys(chatSessions).forEach((sessionID) => {
                    const conversation = chatSessions[sessionID];
                    conversation.messages.sort((a, b) => a.createdTimestamp.localeCompare(b.createdTimestamp));
                    conversation.title = conversation.messages[0].question;
                })
                setChatObj({
                    aiChats: {
                        conversations: chatSessions,
                        selectedSessionId: null,
                    },
                })
            })
    }, [])

    useEffect(() => {
        if (Array.isArray(AllDashboardData) && AllDashboardData.length > 0) {
            if (InputSearchText.trim()) {
                const toSearch = InputSearchText.trim().toLowerCase();
                const results = [];
                AllDashboardData.forEach(element => {
                    for (const key in element) {
                        if (element[key]) {
                            if (String(element[key]).toLowerCase().includes(toSearch)) {
                                results.push(element);
                            }
                        }
                    }
                });
                let _FilteredData = results.filter((obj, index) => {
                    return index === results.findIndex(o => obj.dashboardid === o.dashboardid);
                });
                setFilteredData(_FilteredData);
            } else {
                setInputSearchText('');
                setFilteredData([]);
            }
        } else {
            setInputSearchText('');
            setFilteredData([]);
        }
    }, [InputSearchText, AllDashboardData]);

    const btnsearchResultItem_onClick = (slug) => {
        navigate("/dashboard/" + slug);
        setShowModal(false);
        setInputSearchText('');
        setFilteredData([]);
    }


    const btnShowBot_onClick = () => {
        setChatBotOpen(true);
    }
    const btnCloseBot_onClick = () => {
        setChatBotOpen(false);
    }
    const btnMaximizeBot_onClick = () => {
        setIsMaximize(true);
    }
    const btnMinimizeBot_onClick = () => {
        setIsMaximize(false);
    }

    const DEBOUNCE_TIMER = 350; // ms
    const fetchRef = useRef(null);

    // It debounces user input and cache it for later use
    const loadOptions = async (str) => {
        const name = str.replace(/[/:?#[\]!~$&''()*,; ]/g, '');
        if (name.length < 2) return [];
        if (fetchRef.current) fetchRef.current.abort();
        const ac = new AbortController();
        fetchRef.current = ac;
        await delay(DEBOUNCE_TIMER);
        if (ac.signal.aborted) return [];
        if (optionCache[name]) return optionCache[name];
        const response = await getUserSearch(name, ac.signal);
        if (ac.signal.aborted) return [];
        if (!response.status && !Array.isArray(response.data)) return [];
        const options = response.data.slice(0, 15);
        options.forEach((item) => {
            item.fullName = item.givenName + ' ' + item.surname;
        });
        optionCache[name] = options;
        return options;
    }

    const onFilterTextChange = (str) => {
        const name = str.replace(/[/:?#[\]!~$&''()*,; ]/g, '');
        setSearchUserMail(name);
    };

    const [mailSending, setMailSending] = useState(false);

    const handleShareSend = () => {
        if (selectedMessages.length < 1) {
            toast.warn('No message to share!');
            return;
        }
        if (SelectedUserList.length < 1) {
            toast.warn('Please select at least one user to share!');
            return;
        }
        setMailSending(true)
        const selectedUsers = SelectedUserList.map(x => x.mail);
        shareAIAssistantChats(selectedUsers, selectedMessages)
            .then((response) => {
                if (response.status) {
                    toast.success('Chats shared successfully');
                    setIsShareModelOpen(false);
                    setSelectedUserList([]);
                } else {
                    toast.error(response.message);
                }
            }).catch((error) => {
                toast.error("Error: Something went wrong!");
            }).finally(() => {
                setMailSending(false);
            });
    }

    const CustomOption = ({ innerProps, innerRef, isDisabled, data }) =>
        isDisabled ? null : (
            <div ref={innerRef} {...innerProps} className={st.userSearchItem} >
                <span className={st.username}>{data.fullName}</span>
                <span className={st.usermail}>{data.mail}</span>
            </div>
        );

    const CustomMultiValue = (props) => (
        <Tooltip title={props.data.mail} placement='top'>
            <span>
                <components.MultiValueContainer {...props} />
            </span>
        </Tooltip>
    );

    const handleShareClick = () => {
        const messageIds = chatObj.aiChats.selectedSessionId ?
            chatObj.aiChats.conversations[chatObj.aiChats.selectedSessionId].messages
                .filter(x => x.shareFlag).map(x => x.messageId)
            : [];
        if (messageIds.length < 1) {
            toast.warn('No message to share!');
            return;
        }
        setSelectedMessages(messageIds);
        setIsShareModelOpen(true);
    }

    const handleShareModalClose = () => {
        setIsShareModelOpen(false);
        setSelectedUserList([]);
    }

    return (
        <>
            <chatStore.Provider value={{ chatObj, setChatObj }}>
                {
                    chatBotOpen ? <div className={`${st.chatBotMessageRootContainer} ${IsMaximize ? st.chatBotMaximize : st.chatBotMinimize} ${st.slideInUp} d-flex flex-column`}>
                        <div className={`${st.chatBotHeaderRootContainer}`}>
                            <img src={botImage} alt='bot' className={st.botHeaderImage} />
                            <div className={`${st.chatBotHeaderName}`}>
                                {"Smart Supply Chain"}
                            </div>
                            <div className={`${st.chatBotHeaderIconContainer}`}>
                                <div className={`${st.chatBotHeaderIcon}`}>
                                    <Tooltip title="Report Search" placement="top">
                                        <SearchIcon fontSize="inherit" onClick={btnOpenModel} />
                                    </Tooltip>
                                </div>
                                <div className={`${st.chatBotHeaderIcon}`} onClick={handleShareClick}>
                                    <FaShareAltSquare />
                                </div>
                                {
                                    IsMaximize ?
                                        <div className={`${st.chatBotHeaderIcon}`} onClick={btnMinimizeBot_onClick}>
                                            <FiMinimize2 />
                                        </div>
                                        :
                                        <div className={`${st.chatBotHeaderIcon}`} onClick={btnMaximizeBot_onClick}>
                                            <FiMaximize2 />
                                        </div>
                                }
                                <div className={`${st.chatBotHeaderIcon}`} onClick={btnCloseBot_onClick}>
                                    <IoIosCloseCircle />
                                </div>
                            </div>

                        </div>
                        <div className={st.chatWindowContainer} >
                            <SingleWindow />
                        </div>
                    </div>
                        :
                        <div className={`${st.chatBotImageRootContainer} ${st.zoomIn}`} onClick={btnShowBot_onClick}>
                            <div className={`${st.chatBotImageContainer}`} onClick={btnShowBot_onClick}>
                                <img src={botImage} alt='bot' className={st.botImage} />
                            </div>
                        </div>
                }
                <ModalPopup IsVisible={IsShareModelOpen} onClose={handleShareModalClose}>
                    <div className={st.shareRootContainer}>
                        <div className={st.shareHeaderContainer}>
                            <div className={st.shareImageContainer}>
                                <img src={botImage} alt='bot' className={st.shareImage} />
                            </div>
                            <div className={st.shareNameContainerTitle}>{"Sharon "}
                                <span className={st.shareNameContainerSubTitle}>Supply Chain AI Assistant</span>
                            </div>
                            <div className={st.shareHeaderButtonContainer} onClick={(e) => setIsShareModelOpen(false)}>
                                <IoIosCloseCircle />
                            </div>
                        </div>
                        <div className={st.shareBodyMailFormContainer}>
                            <div className={st.shareBodyMailFormLabel}>Share with others</div>
                            <Async
                                loadOptions={loadOptions}
                                inputValue={SearchUserMail}
                                onInputChange={onFilterTextChange}
                                value={SelectedUserList}
                                onChange={setSelectedUserList}
                                getOptionValue={x => x["mail"]}
                                getOptionLabel={x => x["fullName"]}
                                isMulti
                                components={{ Option: CustomOption, MultiValueContainer: CustomMultiValue }}
                                hideSelectedOptions
                                autoFocus
                                isClearable={false}
                                captureMenuScroll
                                disabled={mailSending}
                            />
                        </div>
                        <div className={st.shareBodyButtonFormContainer}>
                            <Button variant='contained'
                                onClick={handleShareSend}
                                size='small'
                                color='primary'
                                disabled={mailSending}
                            >Send</Button>
                        </div>
                    </div>
                </ModalPopup>
                <ModalPopup IsVisible={showModal} onClose={btnCloseModel}>
                    <div className={st.searchBoxRootContainer} onMouseLeave={() => setInputSearchText("")}>
                        <div className={st.searchBoxContainer}>
                            <div className={st.searchBoxHeader}>
                                <div className={st.searchBoxHeaderTitle}>
                                    <SearchIcon /> Report Search
                                </div>
                                <IoIosCloseCircle onClick={btnCloseModel} className={st.searchBoxHeaderCloseButton} />
                            </div>
                            <div className={st.searchBoxBody}>
                                <div className={st.searchBoxBodyContainer}>
                                    <div className={st.searchBoxBodyContainerSearchBox}>
                                        <input type="text" placeholder="Search"
                                            value={InputSearchText}
                                            onChange={(e) => { inputSearchBox_onChange(e) }}
                                            className={st.inputSearchBox} />
                                        {
                                            InputSearchText !== '' ? <IoIosCloseCircle className={st.cancelSearch} onClick={() => setInputSearchText("")} /> : null
                                        }
                                        <div className={st.inputSearchBoxIconContainer}>
                                            <SearchIcon fontSize="inherit" className={st.inputSearchBoxIcon} />
                                        </div>
                                    </div>
                                </div>
                                <div className={st.searchResultContainer}>
                                    <div className={st.searchResultWrapper}>
                                        {
                                            FilteredData.length > 0 ?
                                                FilteredData.map((data, index) => (
                                                    <div key={index} className={st.searchResultItem} onClick={() => { btnsearchResultItem_onClick(data.slug) }}>
                                                        <div className={st.searchResultItemTitle}>{data.title}</div>
                                                        <div className={st.searchResultItemDetail}>
                                                            <div className={st.searchResultItemDetailText}>{data.page + " > " + data.section}</div>
                                                        </div>
                                                    </div>
                                                ))
                                                : InputSearchText !== '' ?
                                                    (<div className={st.searchResultItem}>
                                                        <div className={st.searchResultItemTitle}>No Result Found</div>
                                                    </div>) : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalPopup>
            </chatStore.Provider>
        </>
    );
};

export default ChatBot;