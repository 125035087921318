import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import parse from 'html-react-parser';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { FaVideo } from "react-icons/fa";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { BaseUrlAPI, getBase64 } from '../../../BaseModels/MasterData';
import { useContainerDimensions } from '../../../BaseModels/ResizeWindow';
import ModalPopup from '../../../CustomElements/ModalPopup/ModalPopup';
import TopMenu from '../TopMenu';
import { UDContext } from './../../../../App';
import ImageLogo from './../../../../images/ImageLogo.svg';
import st from './NewsLetter.module.css';

const Image = ({ src, alt, ...props }) => {
    const [loaded, setLoaded] = useState(false);
    return (
        <>
            <img src={src} alt={alt} {...props}
                style={loaded ? { ...props.style } : { ...props.style, display: 'none' }}
                onLoad={() => { setLoaded(true); }} />
            {loaded ? null : <img src={ImageLogo} alt={alt} {...props} />}
        </>

    );
};
const NewsLetter = () => {
    const UserDetail = useContext(UDContext);
    const inputRefs = useRef([]);
    const { width, height } = useContainerDimensions()
    const { news_uid } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [AllNewsData, setAllNewsData] = useState([]);
    const [IsImageVisible, setIsImageVisible] = useState(false);
    const [ImageUrl, setImageUrl] = useState("");
    const [IsVideoLoading, setIsVideoLoading] = useState(false);
    const [IsVideoVisible, setIsVideoVisible] = useState(false);
    const [VideoUrl, setVideoUrl] = useState("");

    useEffect(() => {
        if (UserDetail !== null) {
            setAllNewsData(UserDetail.AllNews);
        }
    }, [UserDetail]);


    useEffect(() => {
        if (AllNewsData.length > 0 && String(news_uid) !== "0" && inputRefs?.current?.length > 0) {
            AllNewsData.forEach((news, index) => {
                if (String(news_uid) === String(news.news_id)) {
                    inputRefs.current[index].focus();
                }
            });
        }

    }, [AllNewsData, inputRefs, news_uid]);


    const btnImageShow_onClick = (news) => {
        getBase64(BaseUrlAPI + "images" + news?.image + "?token=" + UserDetail?.user?.token).then((data) => {
            setImageUrl(data);
            setIsImageVisible(true);
        });
        // setImageUrl(baseUrl + "images" + news?.image + "?token=" + UserDetail?.user?.token);
    }

    const btnVideoShow_onClick = (news) => {
        setIsVideoLoading(true);
        if (!IsVideoVisible) {
            getBase64(BaseUrlAPI + "videos" + news?.video + "?token=" + UserDetail?.user?.token).then((data) => {
                setVideoUrl(data);
                setIsVideoVisible(true);
                setIsVideoLoading(false);
            });
        }
    }



    return (
        <>
            <TopMenu />
            <div className="mainContainer">
                <div className="row mt-2">
                    <div className="col-lg-6">
                        <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
                            <Link className={st.navigateItem} to={"/"}>Page</Link>
                            <Link className={st.navigateItem} to={"/newsletter/0"}>Announcement</Link>
                        </Breadcrumbs>
                    </div>
                </div>
                {
                    AllNewsData.length > 0 ? <>
                        <div className="row mt-2">
                            {
                                AllNewsData.map((news, index) => (
                                    <div key={index} className="col-lg-12 col-sm-12">
                                        <div className={`${"row"} ${st.newsRootContainer} ${String(news_uid) === String(news?.news_id) ? st.newsRootContainerActive : ''}`}>
                                            <div className={news?.image !== "" ? "col-sm-12 col-md-7 col-lg-8" : "col-sm-12 col-md-12 col-lg-12"}>
                                                <div className={st.newsCardBody}>
                                                    <button ref={(el) => (inputRefs.current[index] = el)} className={st.title}>{parse(news?.title)}</button>
                                                    <div className={st.newsUserContainer}>
                                                        <div>{news.created_date}</div>
                                                        {
                                                            news?.video === "" ? "" :
                                                                <>
                                                                    <div className={st.split}>|</div>
                                                                    <Button
                                                                        size='small'
                                                                        disabled={IsVideoLoading}
                                                                        startIcon={<FaVideo />} onClick={() => btnVideoShow_onClick(news)}>
                                                                        Video
                                                                    </Button>
                                                                </>
                                                        }
                                                    </div>
                                                    <p className={st.description}>{parse(news.description)}</p>
                                                </div>
                                            </div>
                                            {
                                                news?.image === "" ? "" : <div className={news?.image !== "" ? "col-sm-12 col-md-5 col-lg-4 p-0" : "col-sm-12 col-md-12 col-lg-12"}>
                                                    <div className={st.ImgContainer}>
                                                        <Image src={BaseUrlAPI + "images" + news?.image + "?token=" + UserDetail?.user?.token} alt="Image" className={st.mediaImageRow} />
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <br />
                                    </div>
                                ))
                            }
                        </div>
                        <ModalPopup IsVisible={IsImageVisible} onClose={() => setIsImageVisible(false)}>
                            <div className={st.mediaContainer}>
                                <Image src={ImageUrl} alt="Image" className={st.mediaImage} />
                            </div>
                        </ModalPopup>
                        <ModalPopup IsVisible={IsVideoVisible} onClose={() => setIsVideoVisible(false)}>
                            <div className={st.mediaContainer}>
                                <video controls className={st.mediaVideo}>
                                    <source src={VideoUrl} type="video/mp4" />
                                </video>
                            </div>
                        </ModalPopup>
                    </> : <>
                        <h3>No record found</h3>
                    </>
                }
            </div>

        </>
    );
};

export default NewsLetter;
