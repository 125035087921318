import React, { useEffect, useState } from 'react';
import { Brush, Bar, BarChart, Cell, LabelList, ResponsiveContainer, XAxis, YAxis, Tooltip } from 'recharts';
import { interpolateBlues } from 'd3-scale-chromatic';
import InfoButton from '../InfoButton';
import { useContainerDimensions } from '../../../BaseModels/ResizeWindow';
import { getMatomoHourlyTraffic } from '../../../BaseModels/MasterData';
import styles from './HourlyTraffic.module.css';


const HourlyTraffic = ({ dates, roles }) => {
    const fontSizeLegends = 12;
    const colorLegends = 'black';
    const colorLollipop = '#6CB05E';

    // graph data
    const [traffic, setTraffic] = useState([]);
    const [pages, setPages] = useState([]);
    // tooltip
    const [ttText, setTtText] = useState('');
    // currently selected bar
    const [barIndex, setBarIndex] = useState(-1);
    const [viewStartIndex, setViewStartIndex] = useState(0);

    const windowSize = useContainerDimensions();
    const isMobile = windowSize.width < 992;

    useEffect(() => {
        const ac = new AbortController();
        getMatomoHourlyTraffic(dates[0], dates[1], roles, ac.signal)
            .then((response) => {
                if (!response.status) return;
                let _pages = new Set();
                let maxVisit = 0, maxVisitIndex = 0;
                response.data.forEach((x, idx) => {
                    x.data.forEach((y) => {
                        x[y.pagetitle] = y.visit;
                        _pages.add(y.pagetitle);
                    });
                    if (x.visit > maxVisit) {
                        maxVisit = x.visit;
                        maxVisitIndex = idx;
                    }
                });
                _pages = Array.from(_pages);
                // generate distinct blue shades using index
                const _pad = Math.max(2, Math.round(_pages.length * 0.3));
                const _len = _pages.length + _pad;
                _pages = _pages.map((p, idx) => ({
                    title: p,
                    color: interpolateBlues((_len - idx) / _len),
                }))
                setPages(_pages);
                setTraffic(response.data);
                setBarIndex(maxVisitIndex);
            });
        return () => ac.abort();
    }, [dates, roles]);

    useEffect(() => {
        if (!isMobile)
            setViewStartIndex(0);
    }, [isMobile, setViewStartIndex]);

    const handleBrushSlide = ({ startIndex, endIndex }) => {
        setBarIndex(0);
        setViewStartIndex(startIndex);
    }

    // in mobile devices show 24 hour format to minimize width
    const time12to24 = (str) => {
        const [t, m] = str.split(' ');
        return new Date(`1/1/2024 ${t}:0 ${m}`).getHours();
    }

    const CustomizedAxisLabel = ({ x, y, payload }) => {
        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0}
                    dy={isMobile ? (payload.index % 2 === 0 ? 12 : 16) : 14}
                    fill={colorLegends}
                    stroke={payload.index === barIndex ? colorLegends : 'none'}
                    fontSize={fontSizeLegends}
                    fontWeight='bold'
                    textAnchor='middle'
                    style={{ cursor: 'pointer' }}
                >
                    {isMobile ? time12to24(payload.value) : payload.value}
                </text>
            </g>
        );
    }

    const CustomizedDataLabel = ({ x, y, width, index, value }) => {
        const radius = 15;
        return (
            <g>
                <circle
                    cx={x + width / 2}
                    cy={y - radius}
                    r={radius}
                    fill={colorLollipop}
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => setBarIndex(index)}
                />
                <text
                    x={x + width / 2}
                    y={y - radius}
                    fill={colorLegends}
                    fontSize={fontSizeLegends}
                    fontWeight='bold'
                    textAnchor='middle'
                    dominantBaseline='middle'
                    cursor='pointer'
                    onClick={(e) => setBarIndex(index)}
                >
                    {value}
                </text>
            </g>
        );
    };

    const CustomTooltip = ({ active }) => {
        if (active && ttText) {
            return (
                <div className={styles.custom_tooltip}>
                    <span>{ttText}</span>
                </div>
            );
        }
        return null;
    };

    return (
        <>
            <div className={styles.header}>
                <div className={styles.title}>Hourly Traffic</div>
                <InfoButton
                    content={'This section highlights User activity trends across different hours of day'}
                />
            </div>
            <div className={styles.wrapper}>
                <div className={styles.chart_container}>
                    <ResponsiveContainer width='100%' height='85%'>
                        <BarChart
                            data={traffic}>
                            <YAxis type='number' hide
                                domain={([_, m]) => [0, m * 1.1]}
                            />
                            <XAxis
                                type='category'
                                interval={0}
                                tick={<CustomizedAxisLabel />}
                                tickLine={false}
                                dataKey='hour'
                                onClick={(_, idx,) => setBarIndex(idx)}
                            />
                            {
                                pages.map((page, idx1) => (

                                    <Bar stackId='visit-count'
                                        key={`bar-vc-${idx1}`}
                                        dataKey={page.title}
                                        fill={page.color}
                                        onMouseEnter={(data,) => {
                                            setTtText(`${page.title}: ${data.payload[page.title]}`);
                                        }}
                                        onMouseLeave={() => {
                                            setTtText('');
                                        }}
                                        onClick={(_, idx2) => setBarIndex(idx2)}
                                        barSize={15}
                                    >
                                        {
                                            traffic.map((_, idx2,) => (
                                                <Cell key={`cell-vc-${idx2}`} className={styles.bar} />
                                            ))
                                        }
                                        {
                                            idx1 !== pages.length - 1 ? null : (
                                                <LabelList
                                                    dataKey='visit'
                                                    content={<CustomizedDataLabel />}
                                                />
                                            )
                                        }
                                    </Bar>
                                ))
                            }
                            <Tooltip cursor={{ fill: 'transparent' }} content={<CustomTooltip />} isAnimationActive={false} />
                            {
                                isMobile &&
                                <Brush
                                    dataKey='hour'
                                    height={10}
                                    startIndex={0}
                                    endIndex={traffic.length > 8 ? 7 : traffic.length}
                                    stroke='#0063c3'
                                    onChange={handleBrushSlide}
                                />
                            }
                        </BarChart>
                    </ResponsiveContainer>
                    {
                        barIndex === -1 ? null : (
                            <ResponsiveContainer width='100%' height='15%'>
                                <BarChart
                                    data={[traffic[viewStartIndex + barIndex]]}
                                    layout='vertical'>
                                    <XAxis type='number' datakey='visit' domain={[0, 'dataMax']} hide />
                                    <YAxis type='category' hide />
                                    {pages.map((page, idx) => (

                                        <Bar stackId='visit-count-zoom'
                                            key={`bar-vcz-${idx}`}
                                            dataKey={page.title}
                                            fill={page.color}
                                            onMouseEnter={(data,) => {
                                                setTtText(`${page.title}: ${data.payload[page.title]}`);
                                            }}
                                            onMouseLeave={() => {
                                                setTtText('');
                                            }}
                                        >
                                            {
                                                traffic.map((_, idx2) => (
                                                    <Cell key={`cell-vcz-${idx2}`} className={styles.bar} />
                                                ))
                                            }
                                        </Bar>
                                    ))}
                                    <Tooltip cursor={{ fill: 'transparent' }} content={<CustomTooltip />} isAnimationActive={false} />
                                </BarChart>
                            </ResponsiveContainer>
                        )
                    }
                </div>
            </div>
        </>
    );
};

export default HourlyTraffic;
