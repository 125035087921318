import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useState } from 'react';
import InfoButton from '../InfoButton';
import { getMatomoActiveUser } from '../../../BaseModels/MasterData';
import { getIcon } from '../../../BaseModels/IconType';

import styles from './ActiveUser.module.css';


const ActiveUser = ({ dates, roles }) => {
    const [columnDefs, setColumnDefs] = useState([
        // {
        //     field: 'userid', headerName: 'Id', cellRenderer:
        //         function (params) {
        //             return (
        //                 <>
        //                     <span className={`${styles.cell}`}>
        //                         <i className='fas fa-envelope' style={{ color: '#FEBE10' }}></i>
        //                         <span>{params.value}</span>
        //                     </span>
        //                 </>
        //             )
        //         },
        //     width: 110
        // },
        {
            field: 'user_name', headerName: 'Name', cellRenderer:
                function (params) {
                    return (
                        <>
                            <div className={`${styles.cell}`}>
                                <i className='fas fa-user' style={{ color: '#318CE7' }}></i>
                                <span>{params.value}</span>
                            </div>
                        </>
                    )
                },
        },
        {
            field: 'insert_date', headerName: 'Date', cellRenderer:
                function (params) {
                    return (
                        <>
                            <span className={`${styles.cell}`}>
                                <i className='fas fa-calendar-plus' style={{ color: '#17B169' }}></i>
                                <span>{params.value}</span>
                            </span>
                        </>
                    )
                },
            width: 150
        },
        {
            field: 'devicemodel', headerName: 'Device', cellRenderer:
                function (params) {
                    return (
                        <>
                            <div className={`${styles.cell}`}>
                                {getIcon(params.value)}
                                <span>{params.value}</span>
                            </div>
                        </>
                    )
                },
            width: 150
        },
        {
            field: 'operatingsystem', headerName: 'OS', cellRenderer:
                function (params) {
                    return (
                        <>
                            <div className={`${styles.cell}`}>
                                {getIcon(params.value)}
                                <span>{params.value}</span>
                            </div>
                        </>
                    )
                },
            width: 150
        },
        {
            field: 'browsername', headerName: 'Browser', cellRenderer:
                function (params) {
                    return (
                        <>
                            <div className={`${styles.cell}`}>
                                {getIcon(params.value)}
                                <span>{params.value}</span>
                            </div>
                        </>
                    )
                },
            width: 150
        },
        {
            field: 'visitcount', headerName: 'Visit'
        }
    ]);
    const [ChartData, setChartData] = useState(null);

    useEffect(() => {
        const ac = new AbortController();
        getMatomoActiveUser(dates[0], dates[1], roles, ac.signal)
            .then((response) => {
                if (response.status) {
                    setChartData(response.data);
                }
            });
        return () => ac.abort();
    }, [dates, roles]);

    return (
        <>
            <div className={styles.header}>
                <div className={styles.title}>Active User</div>
                <InfoButton
                    content={'This section highlights the most engagement users and their activity patterns'}
                />
            </div>
            <div className={styles.wrapper}>
                <div className={styles.chart_container}>
                    <div className='aggrid-header'>
                        <div style={{ height: '390px', width: '100%' }} className='ag-theme-quartz'>
                            <AgGridReact
                                rowData={ChartData}
                                columnDefs={columnDefs}
                                defaultColDef={{
                                    filter: true,
                                    editable: false,
                                    sortable: true
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ActiveUser;
