import { ArcElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, PointElement, Title, Tooltip } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React, { useCallback, useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { updateAIAssistantGraph } from '../../../../BaseModels/MasterData';



const PieGraph = ({ data, options, msg_id }) => {
    ChartJS.register(CategoryScale, LinearScale, PointElement, ArcElement, ChartDataLabels, Title, Tooltip, Legend);

    const [ChartData, setChartData] = useState(data);
    const [ChartOptions, setChartOptions] = useState(options);

    useEffect(() => {
        if (data && options && msg_id) {
            setChartData(data);
            setChartOptions(options);
            DownloadGraph();
        }
    }, [data, options, msg_id]);

    const DownloadGraph = useCallback(() => {
        try {
            setTimeout(() => {
                const canvasSave = document.getElementById('stackD' + msg_id);
                if(!canvasSave) return;
                canvasSave.toBlob(function (blob) {
                    var reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onloadend = function () {
                        var base64data = reader.result;
                        updateAIAssistantGraph(msg_id, base64data)
                    }
                })
            }, 500);
        } catch (error) {

        }
    }, [msg_id])

    return (
        <div style={{ height: '100%', width: 'auto', aspectRatio: '1/1', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Doughnut id={"stackD" + msg_id} data={ChartData} plugins={[ChartDataLabels]} options={ChartOptions} width={"100%"} height="200px" />
        </div>
    );
};

export default PieGraph;