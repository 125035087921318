import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import parse from 'html-react-parser';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UDContext } from '../../../../App';
import { useContainerDimensions } from './../../../BaseModels/ResizeWindow';
import { postTutorialVideoViewerInsert } from './../../../BaseModels/MasterData';
import st from './DashboardText.module.css';

function DashboardText({ IsVisible, onClose, title, description, report_id }) {
    const { width, height } = useContainerDimensions()
    const [showModal, setShowModal] = useState(false);
    const wrapperRef = useRef(null);
    const UserDetail = useContext(UDContext);
    const [IsDoNotShowAgainVisible, setIsDoNotShowAgainVisible] = useState(false);


    useEffect(() => {
        if (IsVisible) {
            setShowModal(true);
        } else {
            setShowModal(false);
        }
    }, [IsVisible]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                onClose();
                setShowModal(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);



    const btnClose_onClick = async () => {
        try {
            if (IsDoNotShowAgainVisible) {
                postTutorialVideoViewerInsert( report_id).then((response) => {
                    if (response.status) {
                        toast.success(response.data);
                    }
                });
            }
            onClose();
        } catch (error) {
            toast.error("Something went wrong");
        }
    }



    return (
        showModal &&
        <Draggable className={st.rootContainer} handle=".handle">
            <div className={st.tutorialVideoContainer} style={
                width < 502 ? {
                    left: (width / 2) - ((width / 100) * 40)
                } : {}
            }>
                <div className={st.tutorialVideoHeader}>
                    <div className={`${st.tutorialVideoTitle} ${"handle"}`}>{title}</div>
                    <HighlightOffIcon className={st.tutorialVideoClose} onClick={onClose} />
                </div>
                <div className={st.tutorialVideoBody}>
                    {parse(description)}
                </div>
                <div className={st.tutorialVideoFooter}>
                    <div>
                        <FormControlLabel control={
                            <Checkbox size="small" checked={IsDoNotShowAgainVisible}
                                onChange={(event) => setIsDoNotShowAgainVisible(event.target.checked)}
                            />} label="Do not show again" />
                    </div>
                    <Button variant="contained" size="small" onClick={btnClose_onClick}>
                        Close
                    </Button>
                </div>
            </div>
        </Draggable>
    );
}

export default DashboardText;
