import React, { useContext, useState } from 'react';
import { FaExternalLinkAlt } from "react-icons/fa";
import { UDContext } from '../../../App';
import { BaseUrlAPI } from '../../BaseModels/MasterData';
import ImageLogo from './../../../images/ImageLogo.svg';
import loaderImg from './../../../images/loading_loader.gif';
import st from './ZoomCard.module.css';

const Image = ({ src, loaderImage, alt, ...props }) => {
    const [loaded, setLoaded] = useState(false);
    return (
        <>
            <img src={src} alt={alt} {...props}
                style={loaded ? {} : { display: 'none' }}
                onLoad={() => { setLoaded(true); }} />
            {loaded ? null : <img src={ImageLogo} alt={alt} {...props} />}
        </>

    );
};

const ZoomCard = ({ dashboard }) => {
    const UserDetail = useContext(UDContext);
    return (
        <>
            <div className={`${st.cardImageContainer}`}>
                <Image loaderImage={loaderImg} src={BaseUrlAPI + "images" + dashboard?.report_image + "?token=" + UserDetail?.user?.token}
                    alt={dashboard?.title + ".png"} className={`${st.cardImage}`} />
            </div>
            <div className={st.titleContainer}>
                <div className={st.title}>{dashboard?.title}</div>
                <FaExternalLinkAlt className={st.dashboardLinkIcon} />
            </div>
        </>
    );
};

export default ZoomCard;