import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UDContext } from '../../../App';
import { getHighlightedAdminFeature } from '../../BaseModels/MasterData';
import DashboardPageItem1 from './DashboardPageItem1/DashboardPageItem1';
import DashboardPageItem2 from './DashboardPageItem2/DashboardPageItem2';
import DashboardPageItem3 from './DashboardPageItem3/DashboardPageItem3';


const DashboardPageItem = ({ page_id, selected_design_id }) => {
    const UserDetail = useContext(UDContext);
    const [data, setData] = useState([]);

    useEffect(() => {
        if (UserDetail !== null) {
            getDashboardPageItemData();
        }
    }, [UserDetail]);



    const getDashboardPageItemData = async () => {
        try {
            getHighlightedAdminFeature(page_id).then((response) => {
                if (response.status) {
                    setData(response.data);
                }
            });
        } catch (error) {
            toast.error("Something went wrong");
        }
    }


    return (
        <>
            {selected_design_id === 1 ? <DashboardPageItem1 sections={data} /> : <></>}
            {selected_design_id === 2 ? <DashboardPageItem2 sections={data} /> : <></>}
            {selected_design_id === 3 ? <DashboardPageItem3 sections={data} /> : <></>}
        </>
    );
};

export default DashboardPageItem;