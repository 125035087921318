import AddIcon from '@mui/icons-material/Add';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Slider from '@mui/material/Slider';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FileUploader } from "react-drag-drop-files";
import { FaEye, FaRegSave, FaTrash } from "react-icons/fa";
import { IoIosCloseCircle } from "react-icons/io";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Link, useNavigate, useParams } from "react-router-dom";
import { Tab, Tabs } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UDContext } from '../../../../App';
import CarouselNews from '../../../CustomElements/CarouselNews/CarouselNews';
import Carousel1 from '../../../CustomElements/Carousels/Carousel1/Carousel1';
import DashboardPageItem from '../../../CustomElements/DashboardPageItem/DashboardPageItem';
import DetailShower from '../../../CustomElements/DetailShower/DetailShower';
import FavoriteDashboard from '../../../CustomElements/FavoriteDashboard/FavoriteDashboard';
import FeaturedDashboard from '../../../CustomElements/FeaturedDashboard/FeaturedDashboard';
import ModalPopup from '../../../CustomElements/ModalPopup/ModalPopup';
import SummaryDetails1 from '../../../CustomElements/SummaryDetails/SummaryDetails1/SummaryDetails1';
import SummaryDetails2 from '../../../CustomElements/SummaryDetails/SummaryDetails2/SummaryDetails2';
import SummaryDetails3 from '../../../CustomElements/SummaryDetails/SummaryDetails3/SummaryDetails3';
import SummaryDetails4 from '../../../CustomElements/SummaryDetails/SummaryDetails4/SummaryDetails4';
import SummaryDetails5 from '../../../CustomElements/SummaryDetails/SummaryDetails5/SummaryDetails5';
import SummaryDetails6 from '../../../CustomElements/SummaryDetails/SummaryDetails6/SummaryDetails6';
import BackendTopMenu from '../../BackendTopMenu/BackendTopMenu';
import Img_Announcement1 from './../../../../images/Announcement/Announcement1.png';
import Img_Announcement2 from './../../../../images/Announcement/Announcement2.png';
import Img_Announcement3 from './../../../../images/Announcement/Announcement3.png';
import Img_SummaryDetails1 from './../../../../images/DescriptionImages/SummaryDetails1.png';
import Img_SummaryDetails2 from './../../../../images/DescriptionImages/SummaryDetails2.png';
import Img_SummaryDetails3 from './../../../../images/DescriptionImages/SummaryDetails3.png';
import Img_SummaryDetails4 from './../../../../images/DescriptionImages/SummaryDetails4.png';
import Img_SummaryDetails5 from './../../../../images/DescriptionImages/SummaryDetails5.png';
import Img_SummaryDetails6 from './../../../../images/DescriptionImages/SummaryDetails6.png';
import Img_FavoriteDashboard1 from './../../../../images/FavoriteDashboard/FavoriteDashboard1.png';
import Img_FavoriteDashboard2 from './../../../../images/FavoriteDashboard/FavoriteDashboard2.png';
import Img_FeaturedDashboard1 from './../../../../images/FeaturedDashboard/FeaturedDashboard1.png';
import Img_FeaturedDashboard2 from './../../../../images/FeaturedDashboard/FeaturedDashboard2.png';
import Img_FeaturedDashboard3 from './../../../../images/FeaturedDashboard/FeaturedDashboard3.png';
import Img_FeaturedDashboard4 from './../../../../images/FeaturedDashboard/FeaturedDashboard4.png';
import ImageLogo from './../../../../images/ImageLogo.svg';
import Img_PriorityFeature1 from './../../../../images/PriorityFeature/PriorityFeature1.png';
import Img_PriorityFeature2 from './../../../../images/PriorityFeature/PriorityFeature2.png';
import Img_PriorityFeature3 from './../../../../images/PriorityFeature/PriorityFeature3.png';
import {
    BaseUrlAPI,
    ImageUpload,
    getCarosel,
    getDescription, getPageDesign, getTabInfo,
    postCaroselInfoDelete,
    postCaroselInfoInsert,
    postDescriptionUpdate,
    postPageDesignActivate,
    postPageDesignSequence,
    postPageDesignUpdate
} from './../../../BaseModels/MasterData';
import st from './PageDesigner.module.css';
const Image = ({ src, alt, ...props }) => {
    const [loaded, setLoaded] = useState(false);
    return (
        <>
            <img src={src} alt={alt} {...props}
                style={loaded ? { ...props.style } : { ...props.style, display: 'none' }}
                onLoad={() => { setLoaded(true); }} />
            {loaded ? null : <img src={ImageLogo} alt={alt} {...props} />}
        </>

    );
};
const toolbarOptions = {
    toolbar: {
        container: [
            [{ 'header': [1, 2, false] }],
            [{ color: [] }, { background: [] }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
            ["link", "image", "video"],
            ["clean"],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ["bold", "italic", "underline"],
        ],
    }
}

const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'color',
]

const PageDesigner = () => {
    const UserDetail = useContext(UDContext);
    const { page_id } = useParams();
    const navigate = useNavigate();
    const [PageDetail, setPageDetail] = useState(null);
    const [PageDesignList, setPageDesignList] = useState();
    const [PageDesign_GridApi, setPageDesign_GridApi] = useState(null);
    const [PageDesign_NewSequence, setPageDesign_NewSequence] = useState([]);
    const [PageDesignItemActive_NewStatusList, setPageDesignItemActive_NewStatusList] = useState([]);
    const containerStyle = useMemo(() => ({ width: '100%', height: '310px' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);


    const [SelectedDescriptionId, setSelectedDescriptionId] = useState(0);
    const [DescriptionDetail, setDescriptionDetail] = useState(
        {
            description_id: "",
            div_id: "",
            description: "",
            background_image: "",
            background_color: "",
            alignment: "",
            description_height: 0,
            description_font: "",
            tutorial: "",
            tutorial_link: "",
            created_date: "",
            created_by: "",
            updated_date: "",
            updated_by: "",
            description_title: "",
        }
    );
    const [DescriptionTest, setDescriptionTest] = useState("");
    const [IsDescriptionPreviewVisible, setIsDescriptionPreviewVisible] = useState(false);


    const [SelectedCaroselId, setSelectedCaroselId] = useState(1);
    const [CaroselDivId, setCaroselDivId] = useState("");
    const [CaroselHeight, setCaroselHeight] = useState(300);
    const [CaroselImageName, setCaroselImageName] = useState("");
    const [CaroselDetailList, setCaroselDetailList] = useState([]);
    const [IsCaroselPreviewVisible, setIsCaroselPreviewVisible] = useState(false);



    const [SelectedFavoriteId, setSelectedFavoriteId] = useState(0);
    const [IsFavoritePreviewVisible, setIsFavoritePreviewVisible] = useState(false);

    const [SelectedFeatureId, setSelectedFeatureId] = useState(0);
    const [IsFeaturePreviewVisible, setIsFeaturePreviewVisible] = useState(false);


    const [SelectedPriorityFeatureId, setSelectedPriorityFeatureId] = useState(0);
    const [IsPriorityFeaturePreviewVisible, setIsPriorityFeaturePreviewVisible] = useState(false);


    const [SelectedAnnouncementId, setSelectedAnnouncementId] = useState(0);
    const [IsAnnouncementPreviewVisible, setIsAnnouncementPreviewVisible] = useState(false);


    const page_design_columns = [
        { headerName: 'Design Id', field: 'div_id', rowDrag: true },
        { headerName: 'Type', field: 'design_type', },
        { headerName: 'Selected Design Id', field: 'selected_design_id', },
        { headerName: 'Activate', field: 'activate', editable: true, },
    ];
    const defaultColDef = useMemo(() => {
        return {
            width: 170,
            filter: true,
            editable: false,
            sortable: true,
            flex: 1,
        };
    }, []);
    const Page_onGridReady = (params) => {
        setPageDesign_GridApi(params.api);
    };
    const PageDesign_RowDragEnd = useCallback((e) => {
        let _PageDesign_NewSequence = [];
        PageDesign_GridApi.forEachNode(function (node) {
            _PageDesign_NewSequence.push({ div_id: node.data.div_id, seq: node.rowIndex });
        });
        setPageDesign_NewSequence(_PageDesign_NewSequence);
    }, [PageDesign_GridApi]);

    const onCellValueChanged = useCallback((event) => {
        getPageDesign(page_id).then(res => {
            if (res.status) {
                let _PageDesignItemActive_NewStatusList = []
                let _PageDesignList = res.data;
                PageDesign_GridApi.forEachNode(function (node) {
                    let div_id = node.data.div_id;
                    let activate = node.data.activate;
                    let _PageDesignDetail = _PageDesignList?.find(x => String(x.div_id) === String(div_id));
                    if (_PageDesignDetail.activate !== activate) {
                        _PageDesignItemActive_NewStatusList.push({ div_id: div_id, activate: activate });
                    }
                });
                setPageDesignItemActive_NewStatusList(_PageDesignItemActive_NewStatusList);
            } else {
                toast.error(res.message);
            }
        });
    }, [PageDesign_GridApi]);


    useEffect(() => {
        if (UserDetail !== null) {
            if (page_id !== undefined) {
                getAllData(page_id);
            }
        }
    }, [UserDetail, page_id]);

    const getAllData = async (page_id) => {
        try {
            getTabInfo().then(res => {
                if (res.status) {
                    let AllNavigationData = res.data;
                    AllNavigationData.forEach(pages => {
                        if (String(pages.page_id) === String(page_id)) {
                            setPageDetail(pages);
                            getPageDesign(page_id).then(res => {
                                if (res.status) {
                                    setPageDesignList(res.data);
                                    res.data.forEach(item => {
                                        BindPageDesignData(item);
                                    });

                                } else {
                                    toast.error(res.message);
                                }
                            });
                        }
                    });
                } else {
                    toast.error(res.message);
                }
            });
        } catch (error) {
            toast.error("Something went wrong");
        }
    }



    const BindPageDesignData = (item) => {
        if (item.design_type === "description") {
            setSelectedDescriptionId(item.selected_design_id - 1);
            getDescription(item.div_id).then((response) => {
                if (response.status) {
                    let _DescriptionDetail = { ...DescriptionDetail, ...response.data };
                    setDescriptionTest(_DescriptionDetail.description)
                    setDescriptionDetail(_DescriptionDetail);
                } else {
                    toast.error(response.message);
                }
            });
        } else if (item.design_type === "carosel") {
            setSelectedCaroselId(item.selected_design_id);
            getCaroselData(item.div_id);
            setCaroselDivId(item.div_id);
        } else if (item.design_type === "favorite") {
            setSelectedFavoriteId(item.selected_design_id - 1);
        } else if (item.design_type === "usage_feature") {
            setSelectedFeatureId(item.selected_design_id - 1);
        } else if (item.design_type === "priority_feature") {
            setSelectedPriorityFeatureId(item.selected_design_id - 1);
        } else if (item.design_type === "announcement") {
            setSelectedAnnouncementId(item.selected_design_id - 1);
        }
    };

    const renderData = (item, index) => {
        if (item.activate && item.design_type === "description") {
            return <React.Fragment key={item.design_type}>
                <div className="row">
                    <div className="col-lg-12">
                        <div className={st.pageSubTitleContainer}>
                            <span className={st.pageSubTitle}>Design Description</span>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className={st.formLabelName}>Select Template For Description</div>
                        <Tabs activeTab={SelectedDescriptionId} onTabClick={(e, index) => { setSelectedDescriptionId(index); }}
                            navBtnClassName={st.tabsList} hideNavBtns={false} hideNavBtnsOnMobile={false}>
                            <Tab className={`${st.imageTabContainer} ${(SelectedDescriptionId + 1) === 1 ? st.imageTabContainerActive : ''}`}>
                                <div className={st.imageItemContainer}>
                                    <Image src={Img_SummaryDetails1} alt="SummaryDetails1" className={st.imageItem} />
                                </div>
                            </Tab>
                            <Tab className={`${st.imageTabContainer} ${(SelectedDescriptionId + 1) === 2 ? st.imageTabContainerActive : ''}`}>
                                <div className={st.imageItemContainer}>
                                    <Image src={Img_SummaryDetails2} alt="SummaryDetails2" className={st.imageItem} />
                                </div>
                            </Tab>
                            <Tab className={`${st.imageTabContainer} ${(SelectedDescriptionId + 1) === 3 ? st.imageTabContainerActive : ''}`}>
                                <div className={st.imageItemContainer}>
                                    <Image src={Img_SummaryDetails3} alt="SummaryDetails3" className={st.imageItem} />
                                </div>
                            </Tab>
                            <Tab className={`${st.imageTabContainer} ${(SelectedDescriptionId + 1) === 4 ? st.imageTabContainerActive : ''}`}>
                                <div className={st.imageItemContainer}>
                                    <Image src={Img_SummaryDetails4} alt="SummaryDetails4" className={st.imageItem} />
                                </div>
                            </Tab>
                            <Tab className={`${st.imageTabContainer} ${(SelectedDescriptionId + 1) === 5 ? st.imageTabContainerActive : ''}`}>
                                <div className={st.imageItemContainer}>
                                    <Image src={Img_SummaryDetails5} alt="SummaryDetails5" className={st.imageItem} />
                                </div>
                            </Tab>
                            <Tab className={`${st.imageTabContainer} ${(SelectedDescriptionId + 1) === 6 ? st.imageTabContainerActive : ''}`}>
                                <div className={st.imageItemContainer}>
                                    <Image src={Img_SummaryDetails6} alt="Img_SummaryDetails6" className={st.imageItem} />
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-lg-12">
                        <div className={st.formContainer}>
                            <div className={st.formLableContainer}>
                                <div className={st.formLabelName}>Description Title</div>
                                <span className={st.formRequied}>*</span>
                                <DetailShower>
                                    <h1>Description Title</h1>
                                </DetailShower>
                            </div>
                            <div className={st.formInputContainer}>
                                <input type="text" name='description_title' placeholder="Enter Title Name" className={st.formInput}
                                    value={DescriptionDetail?.description_title} onChange={DescriptionDetail_onChange} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className={st.formContainer}>
                            <div className={st.formLableContainer}>
                                <div className={st.formLabelName}>Description</div>
                                <span className={st.formRequied}>*</span>
                                <DetailShower>
                                    <h1>Description</h1>
                                </DetailShower>
                            </div>
                            <div className={st.formInputContainer}>
                                <ReactQuill value={DescriptionTest}
                                    theme="snow"
                                    modules={toolbarOptions}
                                    formats={formats} onChange={setDescriptionTest}
                                    className={st.inputCommentData + " ReactQuill-DashboardComment"}></ReactQuill>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className={st.formContainer}>
                            <div className={st.formLableContainer}>
                                <div className={st.formLabelName}>Description Image</div>
                                <span className={st.formRequied}>*</span>
                                <DetailShower>
                                    <h1>Description Image</h1>
                                </DetailShower>
                            </div>
                            <div className={st.formInputContainer}>
                                <div className={st.formInputFileUploadContainer}>
                                    <div className={st.formInputFileUpload}>
                                        <FileUploader handleChange={DescriptionImage_onChange} name="file"
                                            label="Upload or drop a file right here" types={["PNG", "GIF", "JPG", "JPEG"]} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className={st.formContainer}>
                            <div className={st.formLableContainer}>
                                <div className={st.formLabelName}>Description Image Preview</div>
                            </div>
                            <div className={st.formInputContainer}>
                                <Image src={DescriptionDetail?.background_image} alt="Image" className={st.DescriptionPreviewImage} />
                            </div>
                        </div>

                    </div>
                </div>
                <div className="row">
                    <div className={st.bottomActionButtonContainer}>
                        <Button variant="contained" endIcon={<FaEye />} size="small" onClick={btnDescriptionPreview_onClick}>
                            Preview and Save
                        </Button>
                    </div>
                    <ModalPopup IsVisible={IsDescriptionPreviewVisible} onClose={() => setIsDescriptionPreviewVisible(false)}>
                        <div className={st.modelPreviewContainer}>
                            <div className={st.modelPreviewHeaderContainer}>
                                <div>Preview and Save</div>
                                <div>
                                    <IconButton onClick={() => setIsDescriptionPreviewVisible(false)}>
                                        <IoIosCloseCircle />
                                    </IconButton>
                                </div>
                            </div>
                            <div className={st.modelPreviewBodyContainer}>
                                {
                                    DescriptionPreview(String(SelectedDescriptionId + 1), DescriptionDetail)
                                }
                            </div>
                            <div className={st.modelPreviewFooterContainer}>
                                <Button variant="contained" endIcon={<FaRegSave />} size="small" onClick={btnDescriptionSave_onClick}>
                                    Save
                                </Button>
                            </div>
                        </div>
                    </ModalPopup>
                </div>
            </React.Fragment>
        } else if (item.activate && item.design_type === "carosel") {
            return <React.Fragment key={item.design_type}>
                <div className="row">
                    <div className="col-lg-12">
                        <div className={st.pageSubTitleContainer}>
                            <span className={st.pageSubTitle}>Design Carosel</span>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className={st.formLabelName}>Upload New Image</div>
                        <div className={st.caroselInsertContainer}>
                            <div className={st.caroselFileUploadContainer}>
                                <FileUploader handleChange={CaroselImage_onChange} name="file"
                                    label="Upload or drop a file right here" types={["PNG", "GIF", "JPG", "JPEG"]} />
                            </div>
                            <div className={st.caroselButtonContainer}>
                                <Button disabled={CaroselImageName === "" ? true : false} variant="contained" sx={{ height: '100%' }}
                                    onClick={btnAddCaroselImage_onClick}>
                                    <AddIcon />
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className={st.caroselImageListContainer}>
                            {
                                CaroselDetailList.map((item, index) => {
                                    return <div key={item.carosel_id} className={st.caroselImageItemContainer}>
                                        <div className={st.caroselImageContainer}>
                                            <Image src={BaseUrlAPI + "images" + item?.image_link + "?token=" + UserDetail?.user?.token}
                                                alt="Image"
                                                style={{ height: item?.horizontal_padding + "px" }}
                                                className={st.caroselImageItem} />
                                        </div>
                                        <div className={st.caroselImageRemoveContainer} onClick={() => btnDeleteCaroselImage_onClick(item)}>
                                            <FaTrash />
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className={st.bottomActionButtonContainer}>
                        <Button variant="contained" endIcon={<FaEye />}
                            disabled={CaroselDetailList.length > 0 ? false : true}
                            size="small" onClick={() => setIsCaroselPreviewVisible(true)}>
                            Preview and Save
                        </Button>
                    </div>
                    <ModalPopup IsVisible={IsCaroselPreviewVisible} onClose={() => setIsCaroselPreviewVisible(false)}>
                        <div className={st.modelPreviewContainer}>
                            <div className={st.modelPreviewHeaderContainer}>
                                <div>Preview and Save</div>
                                <div>
                                    <IconButton onClick={() => setIsCaroselPreviewVisible(false)}>
                                        <IoIosCloseCircle />
                                    </IconButton>
                                </div>
                            </div>
                            <div className={st.modelPreviewBodyContainer}>
                                <Carousel1 carosel_information={CaroselDetailList} />
                            </div>
                            <div className={st.modelPreviewFooterContainer}>
                                <Slider aria-label="Volume" value={CaroselHeight}
                                    valueLabelDisplay="auto" min={100} max={1200}
                                    step={5} marks
                                    onChange={btnCaroselImageHeight_onChange} />
                                <Button variant="contained" endIcon={<FaRegSave />} size="small" onClick={btnAddCaroselImageList_onClick}>
                                    Save
                                </Button>
                            </div>
                        </div>
                    </ModalPopup>
                </div>
            </React.Fragment>
        } else if (item.activate && item.design_type === "favorite") {
            return <React.Fragment key={item.design_type}>
                <div className="row">
                    <div className="col-lg-12">
                        <div className={st.pageSubTitleContainer}>
                            <span className={st.pageSubTitle}>Design Your Favorite Dashboard</span>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className={st.formLabelName}>Select Template For Favorite Dashboard</div>
                        <Tabs activeTab={SelectedFavoriteId} onTabClick={(e, index) => { setSelectedFavoriteId(index); }}
                            navBtnClassName={st.tabsList} hideNavBtns={false} hideNavBtnsOnMobile={false}>
                            <Tab className={`${st.imageTabContainer} ${(SelectedFavoriteId + 1) === 1 ? st.imageTabContainerActive : ''}`}>
                                <div className={st.imageItemContainer}>
                                    <Image src={Img_FavoriteDashboard1} alt="Img_FavoriteDashboard1" className={st.imageItem} />
                                </div>
                            </Tab>
                            <Tab className={`${st.imageTabContainer} ${(SelectedFavoriteId + 1) === 2 ? st.imageTabContainerActive : ''}`}>
                                <div className={st.imageItemContainer}>
                                    <Image src={Img_FavoriteDashboard2} alt="Img_FavoriteDashboard2" className={st.imageItem} />
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
                <div className="row">
                    <div className={st.bottomActionButtonContainer}>
                        <Button variant="contained" endIcon={<FaEye />} size="small" onClick={() => setIsFavoritePreviewVisible(true)}>
                            Preview and Save
                        </Button>
                    </div>
                    <ModalPopup IsVisible={IsFavoritePreviewVisible} onClose={() => setIsFavoritePreviewVisible(false)}>
                        <div className={st.modelPreviewContainer}>
                            <div className={st.modelPreviewHeaderContainer}>
                                <div>Preview and Save</div>
                                <div>
                                    <IconButton onClick={() => setIsFavoritePreviewVisible(false)}>
                                        <IoIosCloseCircle />
                                    </IconButton>
                                </div>
                            </div>
                            <div className={st.modelPreviewBodyContainer}>
                                <FavoriteDashboard div_id="" selected_design_id={(SelectedFavoriteId + 1)} />
                            </div>
                            <div className={st.modelPreviewFooterContainer}>
                                <Button variant="contained" endIcon={<FaRegSave />} size="small" onClick={btnFavoriteSave_onClick}>
                                    Save
                                </Button>
                            </div>
                        </div>
                    </ModalPopup>
                </div>
            </React.Fragment>
        } else if (item.activate && item.design_type === "usage_feature") {
            return <React.Fragment key={item.design_type}>
                <div className="row">
                    <div className="col-lg-12">
                        <div className={st.pageSubTitleContainer}>
                            <span className={st.pageSubTitle}>Design Feature Dashboard</span>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className={st.formLabelName}>Select Template For Feature Dashboard</div>
                        <Tabs activeTab={SelectedFeatureId} onTabClick={(e, index) => { setSelectedFeatureId(index); }}
                            navBtnClassName={st.tabsList} hideNavBtns={false} hideNavBtnsOnMobile={false}>
                            <Tab className={`${st.imageTabContainer} ${(SelectedFeatureId + 1) === 1 ? st.imageTabContainerActive : ''}`}>
                                <div className={st.imageItemContainer}>
                                    <Image src={Img_FeaturedDashboard1} alt="Img_FeaturedDashboard1" className={st.imageItem} />
                                </div>
                            </Tab>
                            <Tab className={`${st.imageTabContainer} ${(SelectedFeatureId + 1) === 2 ? st.imageTabContainerActive : ''}`}>
                                <div className={st.imageItemContainer}>
                                    <Image src={Img_FeaturedDashboard2} alt="Img_FeaturedDashboard2" className={st.imageItem} />
                                </div>
                            </Tab>
                            <Tab className={`${st.imageTabContainer} ${(SelectedFeatureId + 1) === 3 ? st.imageTabContainerActive : ''}`}>
                                <div className={st.imageItemContainer}>
                                    <Image src={Img_FeaturedDashboard3} alt="Img_FeaturedDashboard3" className={st.imageItem} />
                                </div>
                            </Tab>
                            <Tab className={`${st.imageTabContainer} ${(SelectedFeatureId + 1) === 4 ? st.imageTabContainerActive : ''}`}>
                                <div className={st.imageItemContainer}>
                                    <Image src={Img_FeaturedDashboard4} alt="Img_FeaturedDashboard4" className={st.imageItem} />
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
                <div className="row">
                    <div className={st.bottomActionButtonContainer}>
                        <Button variant="contained" endIcon={<FaEye />} size="small" onClick={() => setIsFeaturePreviewVisible(true)}>
                            Preview and Save
                        </Button>
                    </div>
                    <ModalPopup IsVisible={IsFeaturePreviewVisible} onClose={() => setIsFeaturePreviewVisible(false)}>
                        <div className={st.modelPreviewContainer}>
                            <div className={st.modelPreviewHeaderContainer}>
                                <div>Preview and Save</div>
                                <div>
                                    <IconButton onClick={() => setIsFeaturePreviewVisible(false)}>
                                        <IoIosCloseCircle />
                                    </IconButton>
                                </div>
                            </div>
                            <div className={st.modelPreviewBodyContainer}>
                                <FeaturedDashboard div_id="" selected_design_id={(SelectedFeatureId + 1)} />
                            </div>
                            <div className={st.modelPreviewFooterContainer}>
                                <Button variant="contained" endIcon={<FaRegSave />} size="small" onClick={btnFeatureSave_onClick}>
                                    Save
                                </Button>
                            </div>
                        </div>
                    </ModalPopup>
                </div>
            </React.Fragment>
        } else if (item.activate && item.design_type === "priority_feature") {
            return <React.Fragment key={item.design_type}>
                <div className="row">
                    <div className="col-lg-12">
                        <div className={st.pageSubTitleContainer}>
                            <span className={st.pageSubTitle}>Design Section Dashboard</span>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className={st.formLabelName}>Select Template For Section Dashboard</div>
                        <Tabs activeTab={SelectedPriorityFeatureId} onTabClick={(e, index) => { setSelectedPriorityFeatureId(index); }}
                            navBtnClassName={st.tabsList} hideNavBtns={false} hideNavBtnsOnMobile={false}>
                            <Tab className={`${st.imageTabContainer} ${(SelectedPriorityFeatureId + 1) === 1 ? st.imageTabContainerActive : ''}`}>
                                <div className={st.imageItemContainer}>
                                    <Image src={Img_PriorityFeature1} alt="Img_PriorityFeature1" className={st.imageItem} />
                                </div>
                            </Tab>
                            <Tab className={`${st.imageTabContainer} ${(SelectedPriorityFeatureId + 1) === 2 ? st.imageTabContainerActive : ''}`}>
                                <div className={st.imageItemContainer}>
                                    <Image src={Img_PriorityFeature2} alt="Img_PriorityFeature2" className={st.imageItem} />
                                </div>
                            </Tab>
                            <Tab className={`${st.imageTabContainer} ${(SelectedPriorityFeatureId + 1) === 3 ? st.imageTabContainerActive : ''}`}>
                                <div className={st.imageItemContainer}>
                                    <Image src={Img_PriorityFeature3} alt="Img_PriorityFeature3" className={st.imageItem} />
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
                <div className="row">
                    <div className={st.bottomActionButtonContainer}>
                        <Button variant="contained" endIcon={<FaEye />} size="small" onClick={() => setIsPriorityFeaturePreviewVisible(true)}>
                            Preview and Save
                        </Button>
                    </div>
                    <ModalPopup IsVisible={IsPriorityFeaturePreviewVisible} onClose={() => setIsPriorityFeaturePreviewVisible(false)}>
                        <div className={st.modelPreviewContainer}>
                            <div className={st.modelPreviewHeaderContainer}>
                                <div>Preview and Save</div>
                                <div>
                                    <IconButton onClick={() => setIsPriorityFeaturePreviewVisible(false)}>
                                        <IoIosCloseCircle />
                                    </IconButton>
                                </div>
                            </div>
                            <div className={st.modelPreviewBodyContainer}>
                                <DashboardPageItem page_id={page_id} selected_design_id={(SelectedPriorityFeatureId + 1)} />
                            </div>
                            <div className={st.modelPreviewFooterContainer}>
                                <Button variant="contained" endIcon={<FaRegSave />} size="small" onClick={btnPriorityFeatureSave_onClick}>
                                    Save
                                </Button>
                            </div>
                        </div>
                    </ModalPopup>
                </div>
            </React.Fragment>
        } else if (item.activate && item.design_type === "announcement") {
            return <React.Fragment key={item.design_type}>
                <div className="row">
                    <div className="col-lg-12">
                        <div className={st.pageSubTitleContainer}>
                            <span className={st.pageSubTitle}>Design Announcement</span>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className={st.formLabelName}>Select Template For Announcement</div>
                        <Tabs activeTab={SelectedAnnouncementId} onTabClick={(e, index) => { setSelectedAnnouncementId(index); }}
                            navBtnClassName={st.tabsList} hideNavBtns={false} hideNavBtnsOnMobile={false}>
                            <Tab className={`${st.imageTabContainer} ${(SelectedAnnouncementId + 1) === 1 ? st.imageTabContainerActive : ''}`}>
                                <div className={st.imageItemContainer}>
                                    <Image src={Img_Announcement1} alt="Img_Announcement1" className={st.imageItem} />
                                </div>
                            </Tab>
                            <Tab className={`${st.imageTabContainer} ${(SelectedAnnouncementId + 1) === 2 ? st.imageTabContainerActive : ''}`}>
                                <div className={st.imageItemContainer}>
                                    <Image src={Img_Announcement2} alt="Img_Announcement2" className={st.imageItem} />
                                </div>
                            </Tab>
                            <Tab className={`${st.imageTabContainer} ${(SelectedAnnouncementId + 1) === 3 ? st.imageTabContainerActive : ''}`}>
                                <div className={st.imageItemContainer}>
                                    <Image src={Img_Announcement3} alt="Img_Announcement3" className={st.imageItem} />
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
                <div className="row">
                    <div className={st.bottomActionButtonContainer}>
                        <Button variant="contained" endIcon={<FaEye />} size="small" onClick={() => setIsAnnouncementPreviewVisible(true)}>
                            Preview and Save
                        </Button>
                    </div>
                    <ModalPopup IsVisible={IsAnnouncementPreviewVisible} onClose={() => setIsAnnouncementPreviewVisible(false)}>
                        <div className={st.modelPreviewContainer}>
                            <div className={st.modelPreviewHeaderContainer}>
                                <div>Preview and Save</div>
                                <div>
                                    <IconButton onClick={() => setIsAnnouncementPreviewVisible(false)}>
                                        <IoIosCloseCircle />
                                    </IconButton>
                                </div>
                            </div>
                            <div className={st.modelPreviewBodyContainer}>
                                <CarouselNews page_id={page_id} selected_design_id={(SelectedAnnouncementId + 1)} />
                            </div>
                            <div className={st.modelPreviewFooterContainer}>
                                <Button variant="contained" endIcon={<FaRegSave />} size="small" onClick={btnAnnouncementSave_onClick}>
                                    Save
                                </Button>
                            </div>
                        </div>
                    </ModalPopup>
                </div>
            </React.Fragment>
        } else {
            return <React.Fragment></React.Fragment>
        }
    };

    const btnSavePageDesignSequence_onClick = async () => {
        try {
            let _PageDesign_NewSequence = [...PageDesign_NewSequence];
            postPageDesignSequence(_PageDesign_NewSequence).then(res => {
                if (res.status) {
                    toast.success(res.data.message);
                    setPageDesign_NewSequence([]);
                } else {
                    toast.error(res.message);
                }
            });
        } catch (error) {
            toast.error("Something went wrong");
        }
    }

    const btnSavePageDesignActivate_onClick = async () => {
        try {
            let _PageDesignItemActive_NewStatusList = [...PageDesignItemActive_NewStatusList];
            postPageDesignActivate(_PageDesignItemActive_NewStatusList).then(res => {
                if (res.status) {
                    toast.success(res.data.message);
                    setPageDesignItemActive_NewStatusList([]);
                } else {
                    toast.error(res.message);
                }
            });
        } catch (error) {
            toast.error("Something went wrong");
        }
    }


    useEffect(() => {
        if (DescriptionDetail?.description_id !== "") {
            setDescriptionTest(DescriptionDetail?.description);
        }
    }, [DescriptionDetail]);

    const DescriptionDetail_onChange = (e) => {
        let _DescriptionDetail = { ...DescriptionDetail, [e.target.name]: e.target.value };
        setDescriptionDetail(_DescriptionDetail);
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });


    const DescriptionImage_onChange = async (file) => {
        try {
            if (file) {
                if (file.size > 1024 && file.size < (1024 * 1024) * 5) {//1kb to 2mb
                    let base64 = await toBase64(file)
                    let _DescriptionDetail = { ...DescriptionDetail, "background_image": base64 };
                    setDescriptionDetail(_DescriptionDetail);
                } else {
                    toast.error("Image size should be between 1kb to 1mb");
                }
            }
        } catch (error) {
            toast.error("Something went wrong");
        }
    }


    const DescriptionPreview = (selectedId, data) => {
        switch (selectedId) {
            case "1":
                return <SummaryDetails1 description_information={data}></SummaryDetails1>;
            case "2":
                return <SummaryDetails2 description_information={data}></SummaryDetails2>;
            case "3":
                return <SummaryDetails3 description_information={data}></SummaryDetails3>;
            case "4":
                return <SummaryDetails4 description_information={data}></SummaryDetails4>;
            case "5":
                return <SummaryDetails5 description_information={data}></SummaryDetails5>;
            default:
                return <SummaryDetails6 description_information={data}></SummaryDetails6>;
        }

    }


    const btnDescriptionPreview_onClick = () => {
        let _DescriptionDetail = { ...DescriptionDetail, "description": DescriptionTest };
        setDescriptionDetail(_DescriptionDetail);
        setIsDescriptionPreviewVisible(true);
    }

    const btnDescriptionSave_onClick = async () => {
        try {
            let _DescriptionDetail = { ...DescriptionDetail, "description": DescriptionTest };
            setDescriptionDetail(_DescriptionDetail);
            let _PageDesignList = [...PageDesignList];
            let _PageDesign = _PageDesignList?.find(x => String(x.div_id) === String(_DescriptionDetail.div_id));
            if (_PageDesign) {
                _PageDesign.selected_design_id = SelectedDescriptionId + 1;
                _PageDesign.div_id = String(_PageDesign.div_id);
                postPageDesignUpdate(_PageDesign.div_id, _PageDesign).then(res => {
                    if (res.status) {
                        _DescriptionDetail.div_id = String(_DescriptionDetail.div_id);
                        _DescriptionDetail.tutorial = String(_DescriptionDetail.tutorial);
                        _DescriptionDetail.description_height = 0;
                        postDescriptionUpdate(DescriptionDetail.description_id, _DescriptionDetail).then(res => {
                            if (res.status) {
                                toast.success(res.data.message);
                                setIsDescriptionPreviewVisible(false);
                            } else {
                                toast.error(res.message);
                            }
                        });
                    } else {
                        toast.error(res.message);
                    }
                });
            }
        } catch (error) {
            toast.error("Something went wrong");
        }
    }



    const getCaroselData = async (div_id) => {
        try {
            getCarosel(div_id).then((response) => {
                if (response.status) {
                    setCaroselDetailList(response.data);
                } else {
                    toast.error(response.message);
                }
            });
        } catch (error) {
            toast.error("Something went wrong");
        }
    }
    const CaroselImage_onChange = async (file) => {
        try {
            if (file) {
                if (file.size > 1024 && file.size < (1024 * 1024) * 5) {//1kb to 5mb
                    ImageUpload(file).then(res => {
                        if (res.status) {
                            setCaroselImageName(res.data);
                        } else {
                            toast.error("Error! Could not upload image!");
                        }
                    });
                } else {
                    toast.error("Image size should be between 1kb to 5mb");
                }
            }
        } catch (error) {
            toast.error("Error! Could not upload image!");
        }
    }
    const btnAddCaroselImage_onClick = async () => {
        try {
            if (CaroselImageName !== "" && CaroselDivId !== "") {
                let _CaroselDetailList = [...CaroselDetailList];
                let _CaroselDetail = {
                    "carosel_id": "",
                    "div_id": CaroselDivId,
                    "image_link": CaroselImageName,
                    "alignment": "center",
                    "horizontal_padding": "300",
                    "vertical_padding": "0",
                    "created_date": "",
                    "created_by": "",
                    "updated_date": "",
                    "updated_by": ""
                }
                setCaroselImageName("");
                _CaroselDetailList.push(_CaroselDetail);
                setCaroselDetailList(_CaroselDetailList);
            } else {
                toast.error("Image not found");
            }
        } catch (error) {
            toast.error("Something went wrong");
        }
    }
    const btnCaroselImageHeight_onChange = async (event, newValue) => {
        let _CaroselDetailList = [...CaroselDetailList];
        _CaroselDetailList.forEach(element => {
            element.horizontal_padding = newValue;
        });
        setCaroselHeight(newValue)
    }
    const btnAddCaroselImageList_onClick = async () => {
        try {
            let _CaroselDetailList = [...CaroselDetailList];
            _CaroselDetailList.forEach(element => {
                if (element.carosel_id === "") {
                    element.div_id = String(element.div_id);
                    postCaroselInfoInsert(element).then(res => {
                        if (res.status) {
                            toast.success(res.data.message);
                        } else {
                            toast.error(res.message);
                        }
                    });
                }
            });
            setIsCaroselPreviewVisible(false);
            setTimeout(() => { getCaroselData(CaroselDivId); }, 2000);
        } catch (error) {
            toast.error("Something went wrong");
        }
    }
    const btnDeleteCaroselImage_onClick = async (item) => {
        if (window.confirm("Are you sure you want to delete?")) {
            let _CaroselDetailList = [...CaroselDetailList];
            if (item.carosel_id !== "") {
                postCaroselInfoDelete(item.carosel_id).then(res => {
                    if (res.status) {
                        toast.success(res.data.message);
                        let index = _CaroselDetailList.indexOf(item);
                        if (index > -1) {
                            _CaroselDetailList.splice(index, 1);
                            setCaroselDetailList(_CaroselDetailList);
                        }
                    } else {
                        toast.error("Error! Could not delete image!");
                    }
                });
            } else {
                let index = _CaroselDetailList.indexOf(item);
                if (index > -1) {
                    _CaroselDetailList.splice(index, 1);
                    setCaroselDetailList(_CaroselDetailList);
                }
            }
        }
    }



    const btnFavoriteSave_onClick = async () => {
        try {
            let _PageDesignList = [...PageDesignList];
            let _PageDesign = _PageDesignList?.find(x => x.design_type === "favorite");
            if (_PageDesign) {
                _PageDesign.selected_design_id = SelectedFavoriteId + 1;
                _PageDesign.div_id = String(_PageDesign.div_id);
                postPageDesignUpdate(_PageDesign.div_id, _PageDesign).then(res => {
                    if (res.status) {
                        toast.success(res.data.message);
                        setIsFavoritePreviewVisible(false);
                    } else {
                        toast.error(res.message);
                    }
                });
            }
        } catch (error) {
            toast.error("Something went wrong");
        }
    }

    const btnFeatureSave_onClick = async () => {
        try {
            let _PageDesignList = [...PageDesignList];
            let _PageDesign = _PageDesignList?.find(x => x.design_type === "usage_feature");
            if (_PageDesign) {
                _PageDesign.selected_design_id = SelectedFeatureId + 1;
                _PageDesign.div_id = String(_PageDesign.div_id);
                postPageDesignUpdate(_PageDesign.div_id, _PageDesign).then(res => {
                    if (res.status) {
                        toast.success(res.data.message);
                        setIsFeaturePreviewVisible(false);
                        setTimeout(() => getAllData(page_id), 2000);
                    } else {
                        toast.error(res.message);
                    }
                });
            }
        } catch (error) {
            toast.error("Something went wrong");
        }
    }

    const btnPriorityFeatureSave_onClick = async () => {
        try {
            let _PageDesignList = [...PageDesignList];
            let _PageDesign = _PageDesignList?.find(x => x.design_type === "priority_feature");
            if (_PageDesign) {
                _PageDesign.selected_design_id = SelectedPriorityFeatureId + 1;
                _PageDesign.div_id = String(_PageDesign.div_id);
                postPageDesignUpdate(_PageDesign.div_id, _PageDesign).then(res => {
                    if (res.status) {
                        toast.success(res.data.message);
                        setIsPriorityFeaturePreviewVisible(false);
                        setTimeout(() => getAllData(page_id), 2000);
                    } else {
                        toast.error(res.message);
                    }
                });
            }
        } catch (error) {
            toast.error("Something went wrong");
        }
    }


    const btnAnnouncementSave_onClick = async () => {
        try {
            let _PageDesignList = [...PageDesignList];
            let _PageDesign = _PageDesignList?.find(x => x.design_type === "announcement");
            if (_PageDesign) {
                _PageDesign.selected_design_id = SelectedAnnouncementId + 1;
                _PageDesign.div_id = String(_PageDesign.div_id);
                postPageDesignUpdate(_PageDesign.div_id, _PageDesign).then(res => {
                    if (res.status) {
                        toast.success(res.data.message);
                        setIsAnnouncementPreviewVisible(false);
                        setTimeout(() => getAllData(page_id), 2000);
                    } else {
                        toast.error(res.message);
                    }
                });
            }
        } catch (error) {
            toast.error("Something went wrong");
        }
    }

    return (
        <>
            <BackendTopMenu />
            {
                PageDetail === null ? null : <>
                    <div className="mainContainer">
                        <div className="row mt-2 mb-2">
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
                                    <Link className={st.navigateItem} to={"/backend"}>Admin Home</Link>
                                    <Link className={st.navigateItem} to={"/backend/pageList"}>Page List</Link>
                                    <Link className={st.navigateItem} to={"/backend/pageList"}>{page_id}</Link>
                                </Breadcrumbs>
                            </div>
                        </div>
                        <div className="row mt-2 mb-2">
                            <div className="col-lg-6">
                                <div className={st.pageTitle}>{PageDetail?.page_name} Page Design</div>
                            </div>
                            <div className="col-lg-6"></div>
                        </div>
                        <div className="row mt-2 mb-2">
                            <div className="col-lg-6">
                                <div className={st.listActionButtonContainer}>
                                    <Button variant="contained"
                                        disabled={PageDesign_NewSequence.length > 0 ? false : true}
                                        endIcon={<FaRegSave />} size="small" onClick={btnSavePageDesignSequence_onClick}>
                                        Save Sequence
                                    </Button>
                                    <Button variant="contained"
                                        disabled={PageDesignItemActive_NewStatusList.length > 0 ? false : true}
                                        endIcon={<FaRegSave />} size="small" onClick={btnSavePageDesignActivate_onClick}>
                                        Save Activate
                                    </Button>
                                </div>
                                <div style={containerStyle} className='aggrid-header'>
                                    <div
                                        style={gridStyle}
                                        className="ag-theme-quartz"
                                    >
                                        <AgGridReact
                                            rowData={PageDesignList}
                                            columnDefs={page_design_columns}
                                            defaultColDef={defaultColDef}
                                            rowDragManaged={true}
                                            onRowDragEnd={PageDesign_RowDragEnd}
                                            onGridReady={Page_onGridReady}
                                            onCellValueChanged={onCellValueChanged}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6"></div>
                        </div>
                        {
                            PageDesignList?.map(renderData)
                        }
                    </div>
                </>
            }
            <br />
            <br />
        </>
    );
};

export default PageDesigner;