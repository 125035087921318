import React, { useEffect, useState, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import InfoButton from '../InfoButton';
import { getUserSpecificReportVisit } from '../../../BaseModels/MasterData';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import styles from './UserSpecificReportVisit.module.css';



const UserSpecificReportVisit = ({ dates, roles }) => {
    // const fontSizeLegends = 12;
    // const colorLegends = 'black';

    const [rawData, setRawData] = useState(null);

    // filter users and pages are to be implemented if required
    const [filterUsers, setFilterUsers] = useState([]);
    const [filterPages, setFilterPages] = useState([]);
    const [filterOptions, setFilterOptions] = useState({
        users: [],
        pages: [],
    });

    useEffect(() => {
        const ac = new AbortController();
        getUserSpecificReportVisit(dates[0], dates[1], roles, ac.signal)
            .then((response) => {
                if (!response.status) return;
                const allPages = new Set();
                const _rows = [];

                response.data.forEach((x) => {
                    const _pages = new Set();
                    x.data.forEach((y) => {
                        x[y.title] = y.counter + (x[y.title] || 0);
                        allPages.add(y.title);
                        _pages.add(y.title);
                    });
                    for (const _page of _pages) {
                        _rows.push({
                            userId: x.userid,
                            userName: x.username,
                            page: _page,
                            visits: x[_page],
                            totalVisits: x.total_visit,
                        });
                    }
                });

                setRawData(_rows);
                setFilterOptions({
                    users: response.data.map(({ username }) => ({ label: username, value: username })),
                    pages: Array.from(allPages).map(x => ({ label: x, value: x })),
                });
            });
        return () => ac.abort();
    }, [dates, roles]);

    const rowData = useMemo(() => {
        if (!rawData) return null;
        // Apply filters
        const filterPageValues = filterPages.map(x => x.value);
        const filterUserValues = filterUsers.map(x => x.value);
        let _data = rawData.filter((x) => {
            if (filterPageValues.length && !filterPageValues.includes(x.page))
                return false;
            if (filterUserValues.length && !filterUserValues.includes(x.userName))
                return false;
            return true;
        })
        const compareValues = (a, b) => a > b ? 1 : (a < b ? -1 : 0);
        // sort by total (primary) and presentation (secondary)
        _data.sort((a, b) =>
            a.totalVisits !== b.totalVisits ?
                compareValues(b.totalVisits, a.totalVisits) :
                compareValues(b.visits, a.visits)
        );
        // determine merged cell sizes and locations
        for (let i = _data.length - 1; i >= 0; i--) {
            // debugger
            if (!_data[i + 1] || _data[i + 1].userId !== _data[i].userId) {
                _data[i].rowSpan = 1;
                _data[i].rowLine = true;
                _data[i].siteHidden = false;
            }
            else {
                _data[i].rowSpan = 1 + _data[i + 1].rowSpan;
                _data[i].rowLine = false;
                _data[i].siteHidden = false;
                _data[i + 1].rowSpan = 1;
                _data[i + 1].siteHidden = true;
            }
        }
        return _data;
    }, [rawData, filterPages, filterUsers]);

    const colDefs = useMemo(() => {
        const _colDefs = [
            {
                headerName: 'User ID',
                field: 'userId',
                // pinned: 'left',
                valueFormatter: ({ value, data }) => data.siteHidden ? '' : value,
                width: 90,
                cellRenderer: ({ data, value }) => (
                    data.siteHidden ? null :
                        <>
                            <span className={`${styles.cell}`}>
                                <i className='fas fa-envelope' style={{ color: '#FEBE10' }}></i>
                                <span>{value}</span>
                            </span>
                        </>
                ),
                width: 120
            },
            {
                headerName: 'User Name',
                field: 'userName',
                // pinned: 'left',
                valueFormatter: ({ value, data }) => data.siteHidden ? '' : value,
                width: 150,
                cellRenderer: ({ data, value }) => (
                    data.siteHidden ? null :
                        <>
                            <span className={`${styles.cell}`}>
                                <i className='fas fa-user' style={{ color: '#318CE7' }}></i>
                                <span>{value}</span>
                            </span>
                        </>
                ),
            },
            {
                headerName: 'Page',
                field: 'page',
                width: 250,
                cellRenderer: (params) => (
                    <>
                        <span className={`${styles.cell}`}>
                            <i className='fas fa-file-lines' style={{ color: '#17B169' }}></i>
                            <span>{params.value}</span>
                        </span>
                    </>
                ),
            },
            {
                headerName: 'Visit',
                field: 'visits',
                width: 100,
            },
        ];
        return _colDefs;
    }, []);

    return (
        <>
            <div className={styles.header}>
                <div className={styles.title}>User Specific Report Visit</div>
                <InfoButton
                    content={'This section tracks the frequency and pattern of individual user accessing specific reports'}
                />
            </div>
            <div className={styles.wrapper}>
                <div className={styles.chart_container}>
                    <div className='aggrid-header'>
                        <div style={{ height: '390px', width: '100%' }} className='ag-theme-quartz'>
                            <AgGridReact
                                suppressCellFocus
                                suppressRowDrag
                                suppressMovableColumns
                                suppressHeaderFocus
                                rowData={rowData}
                                columnDefs={colDefs}
                                defaultColDef={{
                                    editable: false,
                                    filter: false,
                                    sortable: false,
                                }}
                            />
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
};

export default UserSpecificReportVisit;
