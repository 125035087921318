const colors = [
    '#01234a',
    '#077a9d',
    '#6B318A',
    '#6C5EB7',
    '#5a8db1',
    '#3372aa',
    '#00338d',
    '#490b3e',
    '#91300a',
    '#289d7c',
    '#4682B4',
    '#00CED1',
    '#2E8B57',
    '#6A5ACD',
    '#5F9EA0',
    '#48D1CC',
    '#B8860B',
    '#00BFFF',
    '#DAA520',
    '#8FBC8F',
    '#556B2F',
    '#FF69B4',
    '#9370DB',
    '#20B2AA',
    '#4169E1',
    '#1E90FF',
    '#8B4513',
    '#66CDAA',
    '#6B8E23',
    '#3CB371',
    '#708090',
    '#778899',
    '#BDB76B',
    '#6495ED',
    '#7B68EE',
    '#BA55D3',
    '#8B008B',
    '#191970',
    '#483D8B',
    '#9400D3',
    '#9932CC',
    '#FF7F50',
    '#DA70D6',
    '#90c38a',
    '#eca895',
    '#F1BB55',
    '#EB9624',
    '#b145c4',
    '#d53798',
    '#ff6d71',
    '#f1b813',
    '#49beff',
    '#f37021',
    '#8A2BE2',
    '#32CD32',
    '#FF8C00',
    '#0099E5',
    '#34BF49',
    '#00A98F',
    '#000000',
    '#CF8D2E',
    '#E4E932',
    '#2C9F45',
    '#371777',
    '#52325D',
    '#511378',
    '#FBB034',
    '#FFDD00',
    '#C1D82F',
    '#00A4E4',
    '#8A7967',
    '#6A737B',
    '#0ABF53',
    '#00112C',
    '#279B37',
    '#008374',
    '#89BA16',
    '#F47721',
    '#7AC143',
    '#00A78E',
    '#00BCE4',
    '#7D3F98',
    '#037EF3',
    '#00C16E',
    '#7552CC',
    '#0CB9C1',
    '#F48924',
    '#FFC845',
    '#52565E',
    '#FFD900',
    '#74D2E7',
    '#48A9C5',
    '#0085AD',
    '#8DB9CA',
    '#4298B5',
    '#005670',
    '#00205B',
    '#009F4D',
    '#84BD00',
    '#EFDF00',
    '#0077C8',
    '#008EAA',
    '#0099CC',
    '#FF9933',
    '#0079C1',
    '#49176D',
    '#00A0AF',
    '#49A942',
    '#050F2C',
    '#003666',
    '#00AEFF',
    '#3369E7',
    '#8E43E7',
    '#FF6C5F',
    '#FFC168',
    '#2DDE98',
    '#1CC7D0',
    '#FF6A00',
    '#0C3866',
    '#49C0B6',
    '#007CC0',
    '#FFC20E',
    '#FF9900',
    '#146EB4',
    '#002663',
    '#4D4F53',
    '#6D6E70',
    '#B4A996',
    '#ECB731',
    '#8EC06C',
    '#537B35',
    '#C4DFF6',
    '#56A0D3',
    '#0091CD',
    '#004B79',
    '#7F181B',
    '#9F9FA3',
    '#1C79C0',
    '#0DD3FF',
    '#0389FF',
    '#A4C639',
    '#7FBB00',
    '#136AD5',
    '#FB8A2E',
    '#00C4FF',
    '#1793D1',
    '#333333',
    '#FFB310',
    '#004D73',
    '#00334E',
    '#007FDB',
    '#832561',
    '#11862F',
    '#BFCA02',
    '#848A8C',
    '#007FBD',
    '#01CD74',
    '#3BE8B0',
    '#1AAFD0',
    '#6A67CE',
    '#FFB900',
    '#00A8E0',
    '#A5ACAF',
    '#013CA6',
    '#A5A9AB',
    '#003366',
    '#0066A1',
    '#231F20',
    '#BFBFBF',
    '#00B2A9',
    '#A626AA',
    '#6639B7',
    '#AEA400',
    '#16214D',
    '#44C7F4',
    '#FF7800',
    '#2D364C',
    '#6534AC',
    '#2529D8',
    '#629AA9',
    '#00AEEF',
    '#00395D',
    '#2A5934',
    '#B3DCFF',
    '#5ECC62',
    '#00AD45',
    '#1769FF',
    '#003B64',
    '#FFF200',
    '#A0AC48',
    '#70B29C',
    '#505050',
    '#A3A3A3',
    '#205081',
    '#61B3DE',
    '#005387',
    '#8CB811',
    '#FDB813',
    '#88ACA1',
    '#788CB6',
    '#A1A1A4',
    '#8F8F8C',
    '#123962',
    '#2754BA',
    '#00AEE6',
    '#799EB2',
    '#B1D4E5',
    '#F57D00',
    '#0033A1',
    '#09347A',
    '#007DC3',
    '#0169A4',
    '#3399CC',
    '#464646',
    '#B7B7B7',
    '#8996A0',
    '#003580',
    '#009FE3',
    '#FEBA02',
    '#2B80FF',
    '#FFCC2F',
    '#00ACEE',
    '#2BAF2B',
    '#543729',
    '#0061D5',
    '#003F87',
    '#075AAA',
    '#01295C',
    '#ACA095',
    '#B9CFED',
    '#A7A9AC',
    '#553A99',
    '#6CBC35',
    '#FD9F3E',
    '#08538C',
    '#168EEA',
    '#76B852',
    '#323B43',
    '#59626A',
    '#00D1B2',
    '#FDBD10',
    '#0066B2',
    '#ED7902',
    '#00AAFF',
    '#472F92',
    '#00C4CC',
    '#6A3BE4',
    '#004977',
    '#613854',
    '#00387B',
    '#F67828',
    '#237F52',
    '#388ED1',
    '#006837',
    '#FA9F1E',
    '#F7931E',
    '#FFF000',
    '#84754E',
    '#DECBA5',
    '#2E1F54',
    '#52057F',
    '#FF6908',
    '#FFC719',
    '#598C14',
    '#335238',
    '#4A8594',
    '#051736',
    '#706357',
    '#B0A696',
    '#FFC907',
    '#2E9DF7',
    '#FF7243',
    '#60AFDC',
    '#F66733',
    '#522D80',
    '#D4C99E',
    '#685C53',
    '#562E19',
    '#86898C',
    '#F9E498',
    '#566127',
    '#3A4958',
    '#B5C327',
    '#109DC0',
    '#213E97',
    '#FFAC25',
    '#F38020',
    '#FAAE40',
    '#404041',
    '#292526',
    '#F37023',
    '#FCB711',
    '#0EB24E',
    '#0087D2',
    '#6561AC',
    '#282828',
    '#616F67',
    '#C68143',
    '#204056',
    '#0EBEFF',
    '#47CF73',
    '#AE63E4',
    '#FCD000',
    '#76DAFF',
    '#D2EA32',
    '#016773',
    '#439FD3',
    '#F6B128',
    '#FFA500',
    '#00A5DC',
    '#004EAF',
    '#2DB928',
    '#057855',
    '#737373',
    '#969696',
    '#689550',
    '#85C446',
    '#C0DE9E',
    '#B9DCA4',
    '#9628C6',
    '#4374B7',
    '#ABB8AF',
    '#98C807',
    '#B1A24A',
    '#EDD812',
    '#EF9421',
    '#8BA753',
    '#30660F',
    '#B8CC1C',
    '#830051',
    '#D9E506',
    '#FF7900',
    '#00C6D7',
    '#766A65',
    '#2292A7',
    '#005696',
    '#1D1D1B',
    '#AE9A64',
    '#8B8C8D',
    '#00D2F3',
    '#263F6A',
    '#182B45',
    '#6B0F24',
    '#193725',
    '#606061',
    '#774AA4',
    '#095280',
    '#00A1F1',
    '#FFED00',
    '#C2FF00',
    '#00C7F2',
    '#C1F1FC',
    '#EBFFAC',
    '#FFC2E5',
    '#FFAAAA',
    '#334858',
    '#94938F',
    '#A3A7A6',
    '#DBC5B0',
    '#F8DFC2',
    '#3399FF',
    '#222222',
    '#00C1B2',
    '#0085C3',
    '#7AB800',
    '#F2AF00',
    '#6E2585',
    '#71C6C1',
    '#5482AB',
    '#009BBB',
    '#444444',
    '#862633',
    '#003A70',
    '#F99104',
    '#00B7C9',
    '#2D72D9',
    '#DBC65D',
    '#0A0A0A',
    '#05CC47',
    '#4DC47D',
    '#181A1B',
    '#FEBD17',
    '#FFCD00',
    '#003F69',
    '#954010',
    '#445C6E',
    '#005238',
    '#97824B',
    '#005BE2',
    '#008BCF',
    '#FDB94E',
    '#F9A852',
    '#F69653',
    '#F38654',
    '#F07654',
    '#ED6856',
    '#56C1AB',
    '#6A6B6A',
    '#2C5770',
    '#0F723A',
    '#C7C8CA',
    '#666666',
    '#00A6D6',
    '#00629B',
    '#003865',
    '#F5DF2E',
    '#5865F2',
    '#57F287',
    '#FEE75C',
    '#EB459E',
    '#2C2F33',
    '#23272A',
    '#0AF167',
    '#EE70A6',
    '#520099',
    '#39EAFD',
    '#0D0735',
    '#2E9FFF',
    '#092E20',
    '#0DB7ED',
    '#384D54',
    '#0B648F',
    '#FDBB30',
    '#ED8B00',
    '#910048',
    '#8ABA56',
    '#FF8833',
    '#00B6E3',
    '#9BA5A8',
    '#007EE5',
    '#7B8994',
    '#47525D',
    '#3D464D',
    '#5654A4',
    '#0077C0',
    '#81CEFF',
    '#00598E',
    '#E37151',
    '#5B9E4D',
    '#4495D4',
    '#001A57',
    '#2DA9D7',
    '#212A3E',
    '#7AC70C',
    '#8EE000',
    '#FAA918',
    '#FFC715',
    '#1CB0F6',
    '#14D4F4',
    '#8549BA',
    '#A560E8',
    '#4C4C4C',
    '#6F6F6F',
    '#BFF199',
    '#FF7404',
    '#6633CC',
    '#99CC00',
    '#6D1D7C',
    '#FF6600',
    '#58595B',
    '#0064D2',
    '#F5AF02',
    '#86B817',
    '#131137',
    '#918978',
    '#0D69AF',
    '#314855',
    '#5CC3E8',
    '#FFDB00',
    '#79CEB8',
    '#87AA14',
    '#40B3FF',
    '#82B541',
    '#39C2D7',
    '#A3C644',
    '#464547',
    '#8E244D',
    '#404040',
    '#01B5DD',
    '#B0DB64',
    '#002561',
    '#0D9DDB',
    '#48B8E7',
    '#2C2B2B',
    '#6A9C84',
    '#790F59',
    '#373B9C',
    '#F784B6',
    '#39B7DD',
    '#CCC900',
    '#33CC99',
    '#4BA6F5',
    '#FDCA30',
    '#F79400',
    '#FF8000',
    '#2DBE60',
    '#7B868A',
    '#2FACB2',
    '#166A8F',
    '#3B4446',
    '#525E61',
    '#AEB6B8',
    '#203143',
    '#DA9A39',
    '#00A0E9',
    '#1877F2',
    '#6CADDF',
    '#004990',
    '#A6A685',
    '#3098DC',
    '#494E58',
    '#4D148C',
    '#2BB24C',
    '#F9B949',
    '#6D2D41',
    '#FF9500',
    '#FFCB00',
    '#00539F',
    '#0095DD',
    '#331E54',
    '#002147',
    '#FF8E00',
    '#955214',
    '#55601C',
    '#005172',
    '#4CC2C4',
    '#343434',
    '#FBB040',
    '#EFC402',
    '#D4891C',
    '#00B22D',
    '#ED713A',
    '#F67C1A',
    '#338D11',
    '#009AE7',
    '#0063DC',
    '#2971B2',
    '#3FA9F5',
    '#989898',
    '#4DC9F6',
    '#F67019',
    '#537BC4',
    '#ACC236',
    '#228AE6',
    '#1C396D',
    '#5B9A68',
    '#0732A2',
    '#2D5BE3',
    '#006400',
    '#FF9C2A',
    '#3949AB',
    '#0F99D6',
    '#00457C',
    '#E8D4A2',
    '#009BFF',
    '#007CC3',
    '#484848',
    '#2F6F7E',
    '#1B212B',
    '#1E72BD',
    '#4A742C',
    '#041E42',
    '#A99260',
    '#212425',
    '#718087',
    '#5BA4E5',
    '#9FBB58',
    '#436F8E',
    '#887D59',
    '#8F9696',
    '#4078C0',
    '#6CC644',
    '#6E5494',
    '#FCA326',
    '#FC6D26',
    '#554488',
    '#46BC99',
    '#F68D42',
    '#3A3133',
    '#663300',
    '#339966',
    '#F36F21',
    '#72635C',
    '#ACA6A2',
    '#7DB701',
    '#FF8A00',
    '#A5C500',
    '#810061',
    '#303600',
    '#D4C78C',
    '#5EC6F2',
    '#003468',
    '#4B1702',
    '#717A83',
    '#40B4E5',
    '#7C868D',
    '#553B08',
    '#4285F4',
    '#34A853',
    '#FBBC05',
    '#099D84',
    '#4D9DB3',
    '#456ACF',
    '#8762C9',
    '#1E8CBE',
    '#D5DF00',
    '#475258',
    '#0B2D27',
    '#82B548',
    '#FBA919',
    '#463014',
    '#8F1010',
    '#204030',
    '#0F403F',
    '#72EF36',
    '#3B3141',
    '#0863B5',
    '#FEC600',
    '#F39100',
    '#954A97',
    '#009EE3',
    '#13A538',
    '#0F9D58',
    '#0087B4',
    '#008200',
    '#205527',
    '#C3C3C3',
    '#0093D0',
    '#3197D6',
    '#394956',
    '#C9C3E6',
    '#6762A6',
    '#00B388',
    '#425563',
    '#FD9827',
    '#A26EEA',
    '#F7AFFF',
    '#5D70EA',
    '#46B7FD',
    '#2A6EBB',
    '#003265',
    '#FFBD0A',
    '#C6AF92',
    '#71685F',
    '#54493F',
    '#38322D',
    '#7AC142',
    '#0096D6',
    '#66AA33',
    '#99CC33',
    '#41811E',
    '#6D6E71',
    '#006E96',
    '#8C8700',
    '#D79100',
    '#006699',
    '#32C24D',
    '#79AFC1',
    '#789240',
    '#7D0100',
    '#8BAAFF',
    '#33CCFF',
    '#FFCC00',
    '#003399',
    '#F5DE50',
    '#85BF25',
    '#2164F4',
    '#000066',
    '#60AB59',
    '#405DE6',
    '#5851DB',
    '#833AB4',
    '#F77737',
    '#FCAF45',
    '#FFDC80',
    '#0D1A2B',
    '#1565C0',
    '#1E88E5',
    '#BCCED6',
    '#428BCA',
    '#0F7DC2',
    '#0C1C2C',
    '#3A82E4',
    '#40B2A4',
    '#085856',
    '#24366E',
    '#143E50',
    '#7E245C',
    '#EECA4A',
    '#365EBF',
    '#252B33',
    '#2F7BBD',
    '#323A45',
    '#8A959E',
    '#5FC9F8',
    '#FECB2E',
    '#FD9426',
    '#147EFB',
    '#53D769',
    '#8E8E93',
    '#1E1F56',
    '#F36D5D',
    '#02A388',
    '#0C0D0D',
    '#F7DF1E',
    '#669C34',
    '#72AF3A',
    '#5091CD',
    '#F9A541',
    '#0769AD',
    '#7ACEF4',
    '#006C54',
    '#00A9C0',
    '#9CB443',
    '#242F3A',
    '#2BDE73',
    '#0F2105',
    '#82BC23',
    '#3DB4E7',
    '#63A541',
    '#236133',
    '#26B6E8',
    '#00B0DF',
    '#00A1E4',
    '#03CCE6',
    '#29C876',
    '#FDCE2E',
    '#DDB321',
    '#0C2340',
    '#00A3E0',
    '#EDE04B',
    '#333F48',
    '#F6EC35',
    '#00C300',
    '#0A66C2',
    '#83941F',
    '#E7A33E',
    '#F5987E',
    '#56687A',
    '#004182',
    '#44712E',
    '#915907',
    '#38434F',
    '#FCE2BA',
    '#232121',
    '#F78822',
    '#F6DB35',
    '#6DC067',
    '#4185BE',
    '#8F499C',
    '#E0D86E',
    '#9EA900',
    '#6EC9E0',
    '#007EA3',
    '#631D76',
    '#1E1E1E',
    '#006FBF',
    '#FF7B00',
    '#008ECF',
    '#09263C',
    '#020B13',
    '#073857',
    '#00A0DF',
    '#000099',
    '#15B6E5',
    '#576396',
    '#FF00BF',
    '#352384',
    '#333447',
    '#F46F25',
    '#168DE2',
    '#FFA930',
    '#239AB9',
    '#52BAD5',
    '#6DC5DC',
    '#373737',
    '#5D5C5D',
    '#34BE5B',
    '#3BB2D0',
    '#3887BE',
    '#8A8ACB',
    '#56B881',
    '#50667F',
    '#41AFA5',
    '#F9886C',
    '#ED6498',
    '#FBB03B',
    '#142736',
    '#28353D',
    '#222B30',
    '#8A8B8C',
    '#C2C0BF',
    '#004A77',
    '#00ADEE',
    '#FF8100',
    '#FFD200',
    '#FFC836',
    '#264F36',
    '#00AB6C',
    '#0A4D8C',
    '#FFE600',
    '#2D3277',
    '#0084FF',
    '#0082FB',
    '#0064E0',
    '#18453B',
    '#008208',
    '#7BBD00',
    '#0B9A6D',
    '#7CBB00',
    '#FFBB00',
    '#783BD2',
    '#0078D7',
    '#9B4DCA',
    '#606C76',
    '#52AAD8',
    '#273A4B',
    '#1D2D3B',
    '#A086D3',
    '#000088',
    '#C6D6DF',
    '#589636',
    '#EEB111',
    '#E87D1E',
    '#94CE08',
    '#00386B',
    '#969491',
    '#949483',
    '#F47B7B',
    '#EF9020',
    '#00AF3E',
    '#85B7E2',
    '#29245C',
    '#FFD616',
    '#E990AB',
    '#0081B4',
    '#96CBB3',
    '#91BE3E',
    '#39A6DD',
    '#333C41',
    '#5C92FA',
    '#4D4E53',
    '#813867',
    '#66336E',
    '#341539',
    '#5677FC',
    '#9013FE',
    '#FFC208',
    '#00CC99',
    '#1FADC5',
    '#2E51A2',
    '#00758F',
    '#F29111',
    '#2B697A',
    '#93DEE8',
    '#5A2E14',
    '#0B3D91',
    '#FFD659',
    '#007AC2',
    '#0071BF',
    '#E1AC26',
    '#6347B2',
    '#368DD5',
    '#70AF1E',
    '#7E887A',
    '#00AFD8',
    '#7B858E',
    '#221F1F',
    '#39BD00',
    '#F3EC19',
    '#207C88',
    '#AAC1BF',
    '#4C4D4F',
    '#2A2A2A',
    '#44355B',
    '#31263E',
    '#221E22',
    '#FF8200',
    '#19975D',
    '#013369',
    '#005EB8',
    '#504847',
    '#27A770',
    '#4BAD31',
    '#F5DC00',
    '#75AF42',
    '#A98C66',
    '#12191C',
    '#6F7F8D',
    '#8C8C8C',
    '#215732',
    '#6CC24A',
    '#44883E',
    '#124191',
    '#0390D8',
    '#003466',
    '#557665',
    '#4E2A84',
    '#836EAA',
    '#401F68',
    '#B6ACD1',
    '#342F2E',
    '#716C6B',
    '#BBB8B8',
    '#765438',
    '#EC7F22',
    '#F8B22A',
    '#FFD430',
    '#FFC61E',
    '#00337F',
    '#003082',
    '#FFC917',
    '#76B900',
    '#51007A',
    '#DBE800',
    '#7C878E',
    '#6E6E6E',
    '#ED812B',
    '#008272',
    '#001F2D',
    '#008FD4',
    '#696969',
    '#8D989A',
    '#744DA8',
    '#1FB3E0',
    '#49C219',
    '#F4DC2A',
    '#EEB417',
    '#08107B',
    '#DFB226',
    '#777777',
    '#1B5FAA',
    '#90CEF1',
    '#EEAF00',
    '#9C9E9F',
    '#7F7F7F',
    '#060606',
    '#171717',
    '#004B91',
    '#00274D',
    '#0076C0',
    '#000007',
    '#D6E03D',
    '#E5C5BB',
    '#0F58A8',
    '#005483',
    '#F96854',
    '#052D49',
    '#22D0B8',
    '#34C950',
    '#8DE86E',
    '#003087',
    '#009CDE',
    '#012169',
    '#ED6B06',
    '#008B5D',
    '#364395',
    '#FF6900',
    '#EE7200',
    '#004883',
    '#3AA4C6',
    '#616365',
    '#75D1E0',
    '#7DBA00',
    '#00A950',
    '#F8971D',
    '#F7D417',
    '#4A245E',
    '#F26649',
    '#0E5FD8',
    '#0EA0DB',
    '#8892BE',
    '#4F5B93',
    '#99CC99',
    '#5DBE57',
    '#0000E6',
    '#00A160',
    '#FFC425',
    '#6585ED',
    '#F5756C',
    '#98AFC0',
    '#2F3148',
    '#414F5A',
    '#E5A00D',
    '#282A2D',
    '#50BCB6',
    '#00A3E2',
    '#1BA548',
    '#FDC800',
    '#F1860E',
    '#0099FF',
    '#17AD49',
    '#36454F',
    '#251B5B',
    '#25B9D7',
    '#FBBB22',
    '#68737F',
    '#BBBFC3',
    '#318BFF',
    '#1885BF',
    '#EE7F2D',
    '#534540',
    '#988F8C',
    '#00B27F',
    '#505264',
    '#8A90C7',
    '#44403F',
    '#696965',
    '#AE946D',
    '#C28E0E',
    '#98700D',
    '#FFDE57',
    '#4584B6',
    '#646464',
    '#6B5AED',
    '#262A3E',
    '#63EBE4',
    '#4257B2',
    '#3CCFCF',
    '#66CEFF',
    '#8CC04B',
    '#00FF00',
    '#0054A4',
    '#00D8FF',
    '#F29023',
    '#4D5255',
    '#5F99CF',
    '#0A2239',
    '#01B287',
    '#FF9F1C',
    '#854C9E',
    '#999999',
    '#F58021',
    '#F58025',
    '#3A6E8F',
    '#87B2D8',
    '#8D8B00',
    '#C1D72F',
    '#F36E21',
    '#513127',
    '#F76902',
    '#0BA6AB',
    '#6F1AB1',
    '#680021',
    '#939598',
    '#A1CD3D',
    '#003E70',
    '#62B0D9',
    '#0098DB',
    '#ECC400',
    '#FDD800',
    '#3F1A0A',
    '#EDD51C',
    '#1B5692',
    '#43A756',
    '#DE8A3D',
    '#74A2D7',
    '#F0C33B',
    '#9FC54D',
    '#FFFF00',
    '#F26522',
    '#073590',
    '#F1C933',
    '#2091EB',
    '#111111',
    '#00DC06',
    '#EC8A00',
    '#1798C1',
    '#1428A0',
    '#75787B',
    '#0689D8',
    '#FFC600',
    '#C800A1',
    '#685BC7',
    '#0057B8',
    '#00A9E0',
    '#009CA6',
    '#00B140',
    '#C8AA76',
    '#4D2177',
    '#84A40B',
    '#004851',
    '#009A44',
    '#0095C8',
    '#003DA5',
    '#002855',
    '#AD1AAC',
    '#7C3A2D',
    '#8C857B',
    '#1A7BBA',
    '#1F4FA3',
    '#004488',
    '#0088FF',
    '#FFAA00',
    '#FF7700',
    '#9911AA',
    '#AADD22',
    '#242424',
    '#949494',
    '#0168B3',
    '#96BF48',
    '#479CCF',
    '#2D3538',
    '#009999',
    '#F26B21',
    '#68B8BE',
    '#00800D',
    '#00AFF0',
    '#00338D',
    '#6ECADC',
    '#E9A820',
    '#3EB991',
    '#0077B5',
    '#7C9CB0',
    '#41B7D8',
    '#FFFC00',
    '#6CB33F',
    '#4A494D',
    '#29C4D0',
    '#F29556',
    '#84AFA2',
    '#72C427',
    '#00AAF2',
    '#008ACE',
    '#0C88B1',
    '#D8A158',
    '#1C5F4A',
    '#7C8285',
    '#BFBDB0',
    '#B1B134',
    '#BBD634',
    '#DBE3B6',
    '#B2C8BD',
    '#165B65',
    '#697D99',
    '#96B8DB',
    '#00A4E8',
    '#A4DBDB',
    '#FDD666',
    '#DC9018',
    '#DF8F2D',
    '#F58268',
    '#F4979C',
    '#FEDF00',
    '#A7A8AA',
    '#FF8800',
    '#FC00C1',
    '#1DB954',
    '#191414',
    '#FFE100',
    '#75DD66',
    '#59CB59',
    '#2BB656',
    '#0CA750',
    '#364141',
    '#28C101',
    '#1E5397',
    '#376DB6',
    '#4CA2DA',
    '#91D8F4',
    '#F48024',
    '#222426',
    '#BCBBBB',
    '#2C8AEC',
    '#00704A',
    '#3B5A6F',
    '#828A87',
    '#9DB7C4',
    '#838F97',
    '#002054',
    '#79BDE9',
    '#FFC423',
    '#BC9B6A',
    '#F97200',
    '#010101',
    '#8B8B64',
    '#BBBDC0',
    '#0F90FA',
    '#00AFE1',
    '#FF7300',
    '#489E3B',
    '#FABD42',
    '#595A5C',
    '#00447C',
    '#FDB913',
    '#E36F1E',
    '#FFCD1A',
    '#F06D1F',
    '#FFA633',
    '#B8B8B8',
    '#72A114',
    '#9DCE0A',
    '#879B89',
    '#2580C3',
    '#1C2537',
    '#25DD85',
    '#339900',
    '#0088CC',
    '#899197',
    '#500000',
    '#003C71',
    '#5B6236',
    '#744F28',
    '#998542',
    '#332C2C',
    '#707373',
    '#D6D3C4',
    '#EE8421',
    '#8C8A8A',
    '#293F6F',
    '#A67A00',
    '#26313B',
    '#4E5860',
    '#A6ABAF',
    '#006B95',
    '#4F5B65',
    '#B3812A',
    '#00745E',
    '#086556',
    '#8560A8',
    '#431C69',
    '#555555',
    '#00A480',
    '#ED9F40',
    '#6A76AC',
    '#17170E',
    '#534B38',
    '#A6A480',
    '#66BD00',
    '#6FBC6D',
    '#47535B',
    '#0079BF',
    '#70B500',
    '#FF9F1A',
    '#F2D600',
    '#C377E0',
    '#FF78CB',
    '#00C2E0',
    '#51E898',
    '#C4C9CC',
    '#00AF87',
    '#5EAB1F',
    '#F37324',
    '#F8CC1B',
    '#72B043',
    '#007F4E',
    '#35465C',
    '#355EBE',
    '#1EC95B',
    '#043623',
    '#9146FF',
    '#1DA1F2',
    '#14171A',
    '#657786',
    '#AAB8C2',
    '#98CE1E',
    '#D2DE61',
    '#3178C6',
    '#00273F',
    '#FF8700',
    '#09091A',
    '#C0C0C8',
    '#1FBAD6',
    '#77216F',
    '#5E2750',
    '#2C001E',
    '#AEA79F',
    '#003262',
    '#3B7EA1',
    '#FDB515',
    '#C4820E',
    '#052049',
    '#18A3AC',
    '#90BD31',
    '#178CCB',
    '#10167F',
    '#FF9600',
    '#000064',
    '#F57814',
    '#E6D7AA',
    '#7C81B8',
    '#FF967D',
    '#FFC87D',
    '#222C37',
    '#00CCCC',
    '#FFF600',
    '#19E3B1',
    '#FF7F33',
    '#009CD1',
    '#3B515B',
    '#46166B',
    '#EEB211',
    '#006C68',
    '#97A4AF',
    '#005BBB',
    '#E56A54',
    '#FFC72C',
    '#EBEC00',
    '#00A69C',
    '#AD841F',
    '#6DA04B',
    '#006570',
    '#2F9FD0',
    '#002F56',
    '#007C41',
    '#FFDB05',
    '#0072CF',
    '#EA7125',
    '#55A51C',
    '#8F2BBC',
    '#00B1C1',
    '#004B8D',
    '#D1CDB8',
    '#0082CA',
    '#199051',
    '#E76829',
    '#0021A5',
    '#131F33',
    '#005DAA',
    '#00274C',
    '#FFCB05',
    '#4B9CD3',
    '#007FAE',
    '#13294B',
    '#767676',
    '#151515',
    '#154733',
    '#FEE123',
    '#FFD100',
    '#003B71',
    '#005F86',
    '#43695B',
    '#F2A900',
    '#382F2D',
    '#D6D2C4',
    '#005493',
    '#F5AA1C',
    '#002754',
    '#4B2E83',
    '#B7A57A',
    '#85754D',
    '#FFD54F',
    '#FFBD00',
    '#98C646',
    '#694D88',
    '#5382AF',
    '#D06D34',
    '#5F9A38',
    '#474D52',
    '#C822B0',
    '#351C15',
    '#FFB500',
    '#64A70B',
    '#333366',
    '#218748',
    '#3388FF',
    '#3285DC',
    '#F07355',
    '#59267C',
    '#8F5DB7',
    '#A5CFD5',
    '#81CD50',
    '#9F2AF8',
    '#AAAAAA',
    '#3C9CD7',
    '#162221',
    '#1AB7EA',
    '#00B488',
    '#1E91D0',
    '#222221',
    '#A896A0',
    '#7A3671',
    '#1A1F71',
    '#F7B600',
    '#31577A',
    '#14ACF2',
    '#0AC578',
    '#45668E',
    '#4A4D4E',
    '#9C2AA0',
    '#00B0CA',
    '#007C92',
    '#A8B400',
    '#FECB00',
    '#EB9800',
    '#003057',
    '#115740',
    '#65665C',
    '#517891',
    '#212721',
    '#42B883',
    '#35495E',
    '#9E7E38',
    '#82231C',
    '#511536',
    '#443E67',
    '#375669',
    '#456525',
    '#59786C',
    '#3D3C1D',
    '#625750',
    '#F37520',
    '#489CD4',
    '#2774A6',
    '#35393D',
    '#007DC6',
    '#79B9E7',
    '#F47421',
    '#76C143',
    '#FFC120',
    '#414B56',
    '#00A2E1',
    '#1C2D37',
    '#4EC7C4',
    '#00959F',
    '#3B9BCC',
    '#EEA642',
    '#7BB32E',
    '#199FDA',
    '#005195',
    '#028948',
    '#FFD400',
    '#0067B3',
    '#003369',
    '#075E54',
    '#128C7E',
    '#25D366',
    '#DCF8C6',
    '#34B7F1',
    '#4AAE20',
    '#6CC751',
    '#225F1E',
    '#F8CC07',
    '#FF9300',
    '#37AEF3',
    '#0076BA',
    '#005888',
    '#8732CD',
    '#33482C',
    '#2FA5D6',
    '#0063BF',
    '#636466',
    '#002050',
    '#B4009E',
    '#5C2D91',
    '#107C10',
    '#68217A',
    '#3C3C3C',
    '#71B02F',
    '#5B009C',
    '#21759B',
    '#0087BE',
    '#78DCFA',
    '#00AADC',
    '#005082',
    '#87A6BC',
    '#A8BECE',
    '#668EAA',
    '#4F748E',
    '#3D596D',
    '#2E4453',
    '#F0821E',
    '#4AB866',
    '#F0B849',
    '#622599',
    '#0054A0',
    '#AABA0A',
    '#DD7500',
    '#3D8E33',
    '#FF3399',
    '#FCD116',
    '#E66760',
    '#8C88CD',
    '#69C5E4',
    '#FFDF8B',
    '#61E064',
    '#2B96F1',
    '#9EA2A2',
    '#52B043',
    '#F59714',
    '#06B3E8',
    '#7A7E85',
    '#6138F5',
    '#1F69FF',
    '#008558',
    '#026466',
    '#CFDC00',
    '#3299CC',
    '#410093',
    '#16A314',
    '#3B6FBA',
    '#9B59B6',
    '#8E44AD',
    '#2980B9',
    '#F1C40F',
    '#16A085',
    '#34495E',
    '#3498DB',
    '#2ECC71',
    '#1ABC9C',
    '#3A9691',
    '#363533',
    '#FD7622',
    '#FFC43E',
    '#5F6C72',
    '#499DF3',
    '#13D0AB',
    '#00363D',
    '#F79A3E',
    '#EB6651',
    '#30AABC',
    '#37B8AF',
    '#78A300',
    '#F0CA28',
    '#9DBC7A',
    '#1277E1',
    '#2D2D2D',
    '#2D8CFF',
    '#F26D21',
    '#FF9D3B'
];

export default colors;