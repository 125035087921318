import React, { useEffect, useState } from 'react';
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip, } from 'recharts';
import { schemeTableau10 } from 'd3-scale-chromatic';
import InfoButton from '../InfoButton';
import { getPageWiseUsage } from '../../../BaseModels/MasterData';
import NoDataPlaceHolder from '../NoDataPlaceholder';
import styles from './PageWiseUsage.module.css';

const PageWiseUsage = ({ dates, roles }) => {
    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        const ac = new AbortController();
        getPageWiseUsage(dates[0], dates[1], roles, ac.signal)
            .then((response) => {
                if (response.status) {
                    setChartData(response.data.filter(x => x.counter));
                }
            });
        return () => ac.abort();
    }, [dates, roles]);

    const CustomTooltip = ({ active, payload, }) => {
        if (active && payload && payload.length) {
            return (
                <div className={styles.tooltip}>
                    <p>{payload[0].payload.page_name}</p>
                </div>
            );
        }
        return null;
    };

    const renderCustomizedLabel = ({
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        payload,
        fill
    }) => {
        const RADIAN = Math.PI / 180;
        // eslint-disable-next-line
        const radius = 25 + innerRadius + (outerRadius - innerRadius);
        // eslint-disable-next-line
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        // eslint-disable-next-line
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        return (
            <text
                x={x}
                y={y}
                fill={fill}
                fontSize='12px'
                textAnchor={x > cx ? 'start' : 'end'}
                dominantBaseline='central'
            >
                <tspan x={x} y={y}>{payload.page_name} ({payload.counter})</tspan>
            </text>
        );
    };

    return (
        <>
            <div className={styles.header}>
                <div className={styles.title}>Page Wise Usage</div>
                <InfoButton
                    content={'This section highlights the frequency and pattern of user interactions with various pages'}
                />
            </div>
            <div className={styles.wrapper}>
                <div className={styles.chart_container}>
                    {chartData.length ?
                        <ResponsiveContainer width='100%' height='100%'>
                            <PieChart >
                                <Pie
                                    data={chartData}
                                    innerRadius={100}
                                    outerRadius={125}
                                    fill='#8884d8'
                                    paddingAngle={3}
                                    minAngle={3}
                                    dataKey='counter'
                                    label={renderCustomizedLabel}
                                >
                                    {chartData.map((entry, index) => (
                                        <Cell
                                            key={`pu-cell-${index}`}
                                            style={{ outline: 'none' }}
                                            fill={schemeTableau10[index % schemeTableau10.length]}
                                            className={styles.slice}
                                        />
                                    ))}
                                </Pie>
                                <Tooltip content={<CustomTooltip />} />
                            </PieChart>
                        </ResponsiveContainer>
                        : <NoDataPlaceHolder />}
                </div>
            </div>
        </>
    );
};

export default PageWiseUsage;
