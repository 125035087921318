import MessageIcon from '@mui/icons-material/Message';
import PersonIcon from '@mui/icons-material/Person';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import parse from 'html-react-parser';
import React, { useContext, useEffect, useState } from 'react';
import { IoSend } from "react-icons/io5";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UDContext } from '../../../../App';
import { getCommentInfo, postCommentInformationInsert } from '../../../BaseModels/MasterData';

import st from './DashboardComment.module.css';

const DashboardComment = ({ DashboardDetail }) => {
    const UserDetail = useContext(UDContext);
    const { slug } = useParams();
    const { hash } = useLocation();
    const navigate = useNavigate();
    const [TableauDashboardDetail, setTableauDashboardDetail] = useState(null);
    const [CommentData, setCommentData] = useState("");
    const [CommentListData, setCommentListData] = useState([]);
    const [IsProgressVisible, setIsProgressVisible] = useState(false);
    const [IsPostButtonDisable, setIsPostButtonDisable] = useState(true);
    const [IsCancelButtonDisable, setIsCancelButtonDisable] = useState(true);

    const toolbarOptions = {
        toolbar: {
            container: [
                [{ color: [] }, { background: [] }],
                [{ script: "sub" }, { script: "super" }],
                [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
                ["link", "image", "video"],
                ["clean"],
                [{ list: "ordered" }, { list: "bullet" }],
                ["bold", "italic", "underline"],
            ],
        }
    }

    useEffect(() => {
        if (DashboardDetail) {
            setTableauDashboardDetail(DashboardDetail);
        }
    }, [DashboardDetail, hash, slug]);


    useEffect(() => {
        setCommentData("");
        setIsPostButtonDisable(true);
        setIsCancelButtonDisable(true);
    }, [slug]);

    useEffect(() => {
        if (TableauDashboardDetail != null) {
            getCommentListData(TableauDashboardDetail.dashboardid)
        }
    }, [TableauDashboardDetail]);

    const getCommentListData = async (dashboardid) => {
        try {
            getCommentInfo(dashboardid).then((response) => {
                if (response.status) {
                    setCommentListData(response.data);
                }
            });
        } catch (error) {
            toast.error("Error: Not Fetch", { autoClose: 1000 })
        }
    }

    useEffect(() => {
        if (CommentData.length > 0) {
            let _commentText = CommentData.replace(/<[^>]+>/g, '');
            if (_commentText.trim() !== '') {
                setIsPostButtonDisable(false);
                setIsCancelButtonDisable(false);
            } else {
                setIsPostButtonDisable(true);
                setIsCancelButtonDisable(true);
            }
        } else {
            setIsPostButtonDisable(true);
            setIsCancelButtonDisable(true);
        }
    }, [CommentData]);

    const btnCancel_onClick = () => {
        setCommentData("");
        setIsPostButtonDisable(true);
        setIsCancelButtonDisable(true);
    }

    const btnAddNewComment_onClick = async () => {
        try {
            if (CommentData.length > 0) {
                let _commentText = CommentData.replace(/<[^>]+>/g, '');
                if (_commentText !== '') {
                    setIsProgressVisible(true);
                    let obj = {
                        "report_id": TableauDashboardDetail.dashboardid,
                        "comment": CommentData
                    }
                    postCommentInformationInsert(obj).then((response) => {
                        if (response.status) {
                            toast.success(response.data)
                            setCommentData("");
                            setIsPostButtonDisable(true);
                            setIsCancelButtonDisable(true);
                            setIsProgressVisible(false);
                            setTimeout(() => { getCommentListData(TableauDashboardDetail.dashboardid) }, 2000);
                        } else {
                            toast.error(response.message)
                            setIsProgressVisible(false);
                        }
                    });
                }
            }
        } catch (error) {
            toast.error("Error: Not Save")
        }
    }


    return (
        <>
            <div className="mainContainer">
                <div className="row">
                    <div className="col-lg-12">
                        <div className={st.addCommentTitleContainer}>
                            <div className={st.userAvtarContainer}>
                                <MessageIcon className={st.userAvtar} />
                            </div>
                            <div className={st.addCommentTitle}>
                                <div className={st.username}>
                                    {UserDetail?.user?.given_name}
                                </div>
                            </div>
                        </div>
                        <div className={st.messageSummery}>
                            maximum limit 500 characters
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <ReactQuill value={CommentData} modules={toolbarOptions} onChange={setCommentData} className={st.inputCommentData + " ReactQuill-DashboardComment"}></ReactQuill>
                        {/* <textarea maxLength={500} value={CommentData} onChange={(e) => setCommentData(e.target.value)} className={st.textareaComment} ></textarea> */}
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-lg-12">
                        <div className={st.addCommentButtonContainer}>
                            {/* <CustomButton type="primary" icon={<IoSend />} onClick={btnAddNewComment_onClick}>Post</CustomButton> */}
                            <Button variant="outlined" disabled={IsCancelButtonDisable}
                                size="small" onClick={btnCancel_onClick}>
                                Cancel
                            </Button>
                            {
                                !IsProgressVisible ?
                                    <Button variant="contained" disabled={IsPostButtonDisable}
                                        size="small" onClick={btnAddNewComment_onClick} endIcon={<IoSend />}>
                                        Post
                                    </Button>
                                    : <CircularProgress />
                            }

                        </div>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-lg-12">
                        <div className={st.commentListContainer}>
                            {
                                CommentListData.map((item, index) => (
                                    <div key={index} className={st.commentList}>
                                        <div className={st.commentListHeader}>
                                            <div className={st.commentListHeaderUser}>
                                                <PersonIcon />
                                                {item.created_by}
                                            </div>
                                            <div className={st.commentListHeaderDate}>
                                                <Tooltip title="UTC timezone">
                                                    {item.updated_date}
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div className={st.commentListData}>
                                            {parse(item.comment)}
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <br />
        </>
    );
};

export default DashboardComment;
