import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UDContext } from './../../../App';
import { getTutorialVideoViewer, postUsageReportInsert } from './../../BaseModels/MasterData';
import DashboardText from './DashboardText/DashboardText';
import DashboardVideo from './DashboardVideo/DashboardVideo';
import EmbedFromCurrentProject from './EmbedFromCurrentProject/EmbedFromCurrentProject';
import EmbedFromOtherSource from './EmbedFromOtherSource/EmbedFromOtherSource';
import TableauDashboard from './TableauDashboard/TableauDashboard';

const Dashboard = () => {
    const UserDetail = useContext(UDContext);

    const { slug } = useParams();
    const { hash } = useLocation();
    const navigate = useNavigate();
    const [UserData, setUserData] = useState({});
    const [LoadDashboard, setLoadDashboard] = useState(null)
    const [IsVideoVisible, setIsVideoVisible] = useState(false);
    const [IsTextVisible, setIsTextVisible] = useState(false);
    const [TableauDashboardDetail, setTableauDashboardDetail] = useState({
        "dashboardid": 0,
        "page": "",
        "section": "",
        "title": "",
        "slug": "",
        "description": "",
        "type": "",
        "url": "",
        "height": "800",
        "top_tool_bar_visible": "",
        "bottom_tool_bar_visible": "",
        "share_tableau_viz_visible": "",
        "custom_views_visible": "",
        "download_visible": "",
        "download_csv_visible": "",
        "download_image_visible": "",
        "download_pdf_visible": "",
        "download_excel_visible": "",
        "download_ppt_visible": "",
        "report_builder_visible": "",
        "report_image": "",
        "report_video": "",
        "tutorial_text": "",
        "user_id": ""
    });
    const [FavoriteDashboards, setFavoriteDashboards] = useState([]);

    useEffect(() => {
        if (UserDetail !== null) {
            setUserData(UserDetail.user);
            let DashboardData = UserDetail.AllDashboardData.find(x => x.slug === slug);
            if (DashboardData) {
                setTableauDashboardDetail(prevData => ({ ...prevData, ...DashboardData }));
                switch (DashboardData.type) {
                    case "Tableau":
                        setLoadDashboard(<TableauDashboard DashboardDetail={DashboardData} FavoriteDashboards={FavoriteDashboards} />);
                        saveFeatureDashboard(DashboardData.dashboardid, UserDetail.user.user_id);
                        break;
                    case "Embed_From_Other_Source":
                        setLoadDashboard(<EmbedFromOtherSource DashboardDetail={DashboardData} />);
                        saveFeatureDashboard(DashboardData.dashboardid, UserDetail.user.user_id);
                        break;
                    case "Embed_From_Current_Project":
                        setLoadDashboard(<EmbedFromCurrentProject DashboardDetail={DashboardData} />);
                        saveFeatureDashboard(DashboardData.dashboardid, UserDetail.user.user_id);
                        break;
                    default:
                        navigate("/404");
                        break;
                }
            } else {
                navigate("/404");
            }
        }
    }, [slug, hash, UserDetail]);

    useEffect(() => {
        if (UserDetail !== null) {
            if (hash === "") {
                let DashboardData = UserDetail.AllDashboardData.find(x => x.slug === slug);
                getDashboardsVideo(DashboardData);
            } else {
                setIsVideoVisible(false);
                setIsTextVisible(false);
            }
        }
    }, [UserDetail, TableauDashboardDetail, slug, hash]);

    const getDashboardsVideo = async (DashboardData) => {
        try {
            if (DashboardData) {
                if (DashboardData.report_video.length > 0) {
                    let _IsVideoVisible = false;
                    getTutorialVideoViewer(DashboardData?.dashboardid).then((response) => {
                        if (response.status) {
                            if (response.data.status) {
                                if (!IsVideoVisible) {
                                    _IsVideoVisible = true;
                                }
                            }
                            setIsVideoVisible(_IsVideoVisible);
                        }
                    });
                } else if (DashboardData.tutorial_text.length > 0) {
                    let _IsTextVisible = false;
                    getTutorialVideoViewer(DashboardData?.dashboardid).then((response) => {
                        if (response.status) {
                            if (response.data.status) {
                                if (!IsTextVisible) {
                                    _IsTextVisible = true;
                                }
                            }
                            setIsTextVisible(_IsTextVisible);
                        }
                    });
                } else {
                    setIsVideoVisible(false);
                    setIsTextVisible(false);
                }
            }
        } catch (error) {
            toast.error("Something went wrong");
        }
    }

    const saveFeatureDashboard = (dashboardid, user_id) => {
        try {
            postUsageReportInsert(dashboardid, user_id);
        }
        catch (error) {
            console.error(error);
        }
    }







    return (
        <>
            {/* <EmbedFromOtherSource DashboardDetail={TableauDashboardDetail} /> */}
            {LoadDashboard}
            {/* {TableauDashboardDetail.type === "Tableau" && <TableauDashboard />}
            {TableauDashboardDetail.type === "Embed_From_Other_Source" && <EmbedFromOtherSource DashboardDetail={TableauDashboardDetail} />}
            {TableauDashboardDetail.type === "Embed_From_Current_Project" && <EmbedFromCurrentProject DashboardDetail={TableauDashboardDetail} />} */}
            <DashboardVideo
                IsVisible={IsVideoVisible} onClose={() => setIsVideoVisible(false)}
                title={TableauDashboardDetail?.title} videoUrl={TableauDashboardDetail?.report_video}
                report_id={TableauDashboardDetail?.dashboardid}
            />
            <DashboardText
                IsVisible={IsTextVisible} onClose={() => setIsTextVisible(false)}
                title={TableauDashboardDetail?.title} description={TableauDashboardDetail?.tutorial_text}
                report_id={TableauDashboardDetail?.dashboardid}
            />
        </>
    );
};

export default Dashboard;
