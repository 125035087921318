import AccountTreeIcon from '@mui/icons-material/AccountTree';
import DescriptionIcon from "@mui/icons-material/Description";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import FolderIcon from "@mui/icons-material/Folder";
import ListAltIcon from "@mui/icons-material/ListAlt";
import React from "react";

export const TypeIcon = (props) => {
  // if (props.droppable) {
  //   return <FolderIcon />;
  // }

  switch (props.fileType) {
    case "dashboards":
      return <DragIndicatorIcon />;
    case "sections":
      return <AccountTreeIcon />;
    case "page":
      return <FolderIcon />;
    case "csv":
      return <ListAltIcon />;
    case "text":
      return <DescriptionIcon />;
    default:
      return <FolderIcon />;
  }
};
