import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { AiOutlineFullscreen, AiOutlineFullscreenExit } from "react-icons/ai";
import { FaRegStar, FaShareSquare, FaStar } from "react-icons/fa";
import { IoReloadCircle, IoShareSocialSharp } from "react-icons/io5";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UDContext } from '../../../../App';
import { postFavReportDelete, postFavReportInsert } from '../../../BaseModels/MasterData';
import GNP_Actuals_Vs_Plan_Details from '../../../TableauDashboards/Global_Supply_Chain/GNP_Actuals_Vs_Plan_Details/GNP_Actuals_Vs_Plan_Details';
import GNP_Demand_Dashboard from '../../../TableauDashboards/Global_Supply_Chain/GNP_Demand_Dashboard/GNP_Demand_Dashboard';
import TopMenu from '../../TopMenu/TopMenu';
import DashboardComment from '../DashboardComment/DashboardComment';
import st from './EmbedFromCurrentProject.module.css';

function EmbedFromCurrentProject({ DashboardDetail }) {
    const { slug } = useParams();
    const { hash } = useLocation();
    const navigate = useNavigate();
    const UserDetail = useContext(UDContext);
    const elementRef = useRef(null);

    const [TableauDashboardDetail, setTableauDashboardDetail] = useState(null);
    const [IsFavorite, setIsFavorite] = useState(false);
    const [IsReportBuilderOpen, setIsReportBuilderOpen] = useState(false);
    const [IsDownloadOptionOpen, setIsDownloadOptionOpen] = useState(false);
    const screen1 = useFullScreenHandle();
    const [IsFullScreen, setIsFullScreen] = useState(false);
    const reportChange = useCallback((state, handle) => {
        setIsFullScreen(state)
    }, [screen1]);

    const [LoadDashboard, setLoadDashboard] = useState(null);

    useEffect(() => {
        if (DashboardDetail) {
            switch (DashboardDetail.url) {
                case "GNPActualsVsPlan":
                    setLoadDashboard(<GNP_Actuals_Vs_Plan_Details />);
                    break;
                case "GNP_Demand_Dashboard":
                    setLoadDashboard(<GNP_Demand_Dashboard />);
                    break;
                default:
                    navigate("/404");
                    break;
            }
            setTableauDashboardDetail(DashboardDetail);
        }
    }, [DashboardDetail]);

    const btnIsFavorite_onClick = async () => {
        try {
            setIsFavorite(!IsFavorite);
            let favoriteObj = { user_id: UserDetail?.user?.user_id, dashboardid: TableauDashboardDetail?.dashboardid };
            if (!IsFavorite) {
                postFavReportInsert(TableauDashboardDetail?.dashboardid, UserDetail?.user?.user_id).then((response) => {
                    if (response.status) {
                        toast.success(response.data);
                    }
                });
            } else {
                postFavReportDelete(TableauDashboardDetail?.dashboardid, UserDetail?.user?.user_id).then((response) => {
                    if (response.status) {
                        toast.success(response.data);
                    }
                });
            }
        } catch (error) {
            toast.error("Error: Favorite Dashboard", { type: 'error', autoClose: 1000 });
        }
    }


    return (
        <>
            <FullScreen handle={screen1} onChange={reportChange}>
                <TopMenu />
                <div className="mainContainer">
                    <div className="row mt-3 mb-3">
                        <div className="col-lg-6">
                            <span className={st.dashboardTitleName}>
                                {DashboardDetail?.title}
                            </span>
                        </div>
                        <div className="col-lg-6">
                            <div className={st.tableauTopActionButtonContainer}>
                                <div className={st.tableauTopActionButtonIconContainer}>
                                    <Tooltip title="Favorite">
                                        <IconButton onClick={btnIsFavorite_onClick}>
                                            <FaStar style={{ display: !IsFavorite ? 'none' : 'block' }} className={`${st.tableauTopActionButtonIcon} ${st.tableauTopActionButtonIconActive}`} />
                                            <FaRegStar style={{ display: IsFavorite ? 'none' : 'block' }} className={st.tableauTopActionButtonIcon} />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <div className={st.tableauTopActionButtonIconContainer} >
                                    <Tooltip title="Full Screen">
                                        <IconButton>
                                            <AiOutlineFullscreen className={st.tableauTopActionButtonIcon} style={{ display: IsFullScreen ? 'none' : 'block' }} onClick={screen1.enter} />
                                            <AiOutlineFullscreenExit className={`${st.tableauTopActionButtonIcon} ${st.tableauTopActionButtonIconActive}`} style={{ display: !IsFullScreen ? 'none' : 'block' }} onClick={screen1.exit} />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <div className={st.tableauTopActionButtonIconContainer}>
                                    <Tooltip title="Reload">
                                        <IconButton onClick={() => window.location.reload(false)}>
                                            <IoReloadCircle className={st.tableauTopActionButtonIcon} />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <div className={st.tableauTopActionButtonIconContainer}>
                                    <Tooltip title="Share Page">
                                        <IconButton onClick={() => { navigator.clipboard.writeText(window.location.href); toast("Dashboard url copied"); }}>
                                            <IoShareSocialSharp className={st.tableauTopActionButtonIcon} />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            {LoadDashboard}
                        </div>
                    </div>
                </div>
                <br />
                <DashboardComment DashboardDetail={TableauDashboardDetail} />
            </FullScreen>
        </>
    );
}

export default EmbedFromCurrentProject;
