import React, { useContext, useState } from 'react';
import parse from 'html-react-parser';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useNavigate } from "react-router-dom";
import { UDContext } from '../../../../App';
import { BaseUrlAPI } from '../../../BaseModels/MasterData';
import ImageLogo from './../../../../images/ImageLogo.svg';
import st from './CarouselNews3.module.css';
const Image = ({ src, alt, ...props }) => {
    const [loaded, setLoaded] = useState(false);
    return (
        <>
            <img src={src} alt={alt} {...props}
                style={loaded ? { ...props.style } : { ...props.style, display: 'none' }}
                onLoad={() => { setLoaded(true); }} />
            {loaded ? null : <img src={ImageLogo} alt={alt} {...props} />}
        </>

    );
};
const CarouselNews3 = ({ carosel_information }) => {
    const navigate = useNavigate();
    const UserDetail = useContext(UDContext);
    return (
        <>
            <Carousel
                additionalTransfrom={0}
                arrows
                autoPlay
                autoPlaySpeed={3000}
                centerMode={false}
                className=""
                containerClass="container-with-dots"
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                pauseOnHover
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={{
                    desktop: {
                        breakpoint: {
                            max: 3000,
                            min: 1024
                        },
                        items: 3,
                        partialVisibilityGutter: 40
                    },
                    mobile: {
                        breakpoint: {
                            max: 464,
                            min: 0
                        },
                        items: 1,
                        partialVisibilityGutter: 30
                    },
                    tablet: {
                        breakpoint: {
                            max: 1024,
                            min: 464
                        },
                        items: 2,
                        partialVisibilityGutter: 30
                    }
                }}
                rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay
                showDots
                sliderClass=""
                slidesToSlide={1}
                swipeable
            >
                {
                    carosel_information.map((item, index) => {
                        return (
                            <React.Fragment key={index}>
                                <div className={st.newsRootContainer}>
                                    <div className={st.newsContainer}>
                                        {
                                            item?.image !== "" ? <div className={st.newsImageContainer}>
                                                <Image
                                                    src={BaseUrlAPI + "images" + item?.image + "?token=" + UserDetail?.user?.token}
                                                    alt={item.image} className={st.newsImage} />
                                            </div> : <></>
                                        }
                                        <div className={st.newsDetailContainer}>
                                            <h3 className={st.newsTitle} onClick={() => navigate("/newsletter/" + item.news_id)}>{item.title}</h3>
                                            {
                                                item?.image === "" ? <p className={st.newsDescription}>{parse(item.description)}</p>  : <></>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        );
                    })
                }
            </Carousel>
        </>
    );
};

export default CarouselNews3;
