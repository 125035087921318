import React from 'react';
import st from './LoadingFullScreen.module.css';
import e2eVizLoading from './../../../images/e2eVizLoading.gif'
const LoadingFullScreen = ({ visible }) => {
    return (
        <>
            {visible && (
                <div className={st.container}>
                    <div className={st.imgcontainer}>
                        <img src={e2eVizLoading} alt="Loading" className={st.imgLoad} />
                    </div>
                </div>
            )}
        </>
    );
};

export default LoadingFullScreen;