import React from 'react';
import e2eVizLoading from './../../../images/e2eVizLoading.gif'
import st from './LoadingImage.module.css'

const LoadingImage = ({ visible }) => {
    return (
        <>
            {visible &&
                <>
                    <div className={st.container}>
                        <img src={e2eVizLoading} alt="Loading" className={st.loadingImage} />
                    </div>
                </>
            }
        </>
    );
};

export default LoadingImage;
