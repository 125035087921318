import React, { useEffect, useState } from 'react';
import { Bar, BarChart, Cell, LabelList, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import InfoButton from '../InfoButton';
import { getMatomoTopUsers } from '../../../BaseModels/MasterData';
import NoDataPlaceHolder from '../NoDataPlaceholder';

import styles from './TopUsers.module.css';


const TopUsers = ({ dates, roles }) => {
    const fontSizeLegends = 12;
    const colorLegends = 'black';
    const colorBarFill = ['#82C3A8', '#BCA034', '#A16666', '#669BAF', '#789682'];
    const colorBarFillCount = colorBarFill.length;

    const [topUsers, setTopUsers] = useState([]);

    useEffect(() => {
        const ac = new AbortController();
        getMatomoTopUsers(dates[0], dates[1], roles, ac.signal)
            .then((response) => {
                if (response.status)
                    setTopUsers(response.data);
            });
        return () => ac.abort();
    }, [dates, roles]);

    const CustomizedDataLabel = ({ x, y, width, height, index, value }) => {
        const radius = 15;
        return (
            <g>
                <text
                    x={x + 5}
                    y={y + height / 2}
                    fill={colorLegends}
                    fontSize={fontSizeLegends}
                    fontWeight='bold'
                    textAnchor='left'
                    dominantBaseline='middle'
                >
                    {value}
                </text>
            </g>
        );
    };

    return (
        <>
            <div className={styles.header}>
                <div className={styles.title}>Top User</div>
                <InfoButton
                    content={'This section highlights the most active users and their engagement trends'}
                />
            </div>
            <div className={styles.wrapper}>
                <div className={styles.chart_container}>
                    {topUsers.length ?
                        <ResponsiveContainer width='100%' height='100%'>
                            <BarChart
                                data={topUsers}
                                layout='vertical'>
                                <XAxis type='number' hide />
                                <YAxis
                                    type='category'
                                    interval={0}
                                    tick={{ fontSize: fontSizeLegends, fill: colorLegends, fontWeight: 'bold' }}
                                    tickLine={false}
                                    dataKey='visitcount'
                                />
                                <Bar dataKey='visitcount'>
                                    {
                                        topUsers.map((_, idx) => (
                                            <Cell key={`cell-tu-${idx}`} fill={colorBarFill[idx % colorBarFillCount]} className={styles.bar} />
                                        ))
                                    }
                                    <LabelList
                                        dataKey='fullname'
                                        content={<CustomizedDataLabel />}
                                    />
                                </Bar>
                            </BarChart>
                        </ResponsiveContainer>
                        : <NoDataPlaceHolder />}
                </div>
            </div>
        </>
    );
};

export default TopUsers;
