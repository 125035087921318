import React, { useState, useMemo, useCallback } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import botBlueImage from '../HumBot.png';
import st from './BotAnswer.module.css';
import GraphDetails from "./GraphDetails";
import IconButton from '@mui/material/IconButton';
import { RiRefreshLine } from "react-icons/ri";
import { FaDownload } from "react-icons/fa";
import { exportToExcel } from 'react-json-to-excel';
import { cloneDeep } from 'lodash';
import { testURL, formatHeader } from '../../../BaseModels/utility';
import Linkify from 'react-linkify';


const BotAnswer = ({ message }) => {
    const hasSomeData = message['answer'] || message['sqlQuery'] || message['tableData'];
    const textData = message['answer'] ? message['answer'] :
        (message.completed ? (hasSomeData ? '' : 'No answer.') : 'Thinking...');
    const sources = message['sources'];
    const query = message['sqlQuery'];
    const tableData = message['tableData'];
    const [IsDownloadLoading, setIsDownloadLoading] = React.useState(false);

    const [graphData, setGraphData] = useState(message['tableData']);

    const btnDownload_onClick = () => {
        setIsDownloadLoading(true);
        exportToExcel(tableData, 'Sharon');
        setTimeout(() => {
            setIsDownloadLoading(false);
        }, 3000);
    }

    const extractDataFromGrid = useCallback(({ api }) => {
        const _rowData = [];
        api.forEachNodeAfterFilterAndSort((node) => {
            _rowData.push(cloneDeep(node.data));
        });
        setGraphData(_rowData);
    }, [setGraphData])

    const defaultColDef = useMemo(() => ({
        width: 170,
        filter: true,
        editable: false,
        sortable: true,
        flex: 1,
    }), []);

    return (
        <div className={st.sharonMessage}>
            <div className={st.sharonImgContainer}>
                <img src={botBlueImage} alt="Bot" className={st.sharonMessageImage} />
            </div>
            <div className={st.sharonMessageItem}>
                <Linkify componentDecorator={(decoratedHref, decoratedText, key) =>
                (
                    <a target="_blank" href={decoratedHref} key={key}>
                        {decoratedText}
                    </a>
                )}>
                    {textData}
                </Linkify>
                {
                    Array.isArray(sources) &&
                    <div className={`d-flex align-items-center flex-wrap gap-1 ${st.chatSourceContainer}`}>
                        <span className={st.chatSourceTitle}>Sources:</span>
                        {
                            sources.map((item, idx) => {
                                const linkURL = item.file_path.trim();
                                return (
                                    testURL(linkURL) ?
                                        <a target="_blank" href={linkURL} key={idx} className={st.chatSourceItem}>
                                            <span>{item.application.trim()}-{item.file_type.trim()}</span>
                                        </a> :
                                        <span key={idx} className={st.chatSourceItem}>{linkURL}</span>
                                );
                            })
                        }
                    </div>
                }
                {
                    query &&
                    <details>
                        <summary>Query</summary>
                        <div className={st.query}>
                            {query}
                        </div>
                    </details>
                }
                {
                    Array.isArray(tableData) && tableData.length > 0 &&
                    <>
                        <div className={st.tableDataContainer}>
                            <div style={{ height: '200px' }} className='ag-theme-quartz w-100'>
                                <AgGridReact
                                    columnDefs={Object.keys(tableData[0]).map(k => ({ field: k, headerName: formatHeader(k) }))}
                                    rowData={tableData}
                                    defaultColDef={defaultColDef}
                                    onFirstDataRendered={extractDataFromGrid}
                                    onSortChanged={extractDataFromGrid}
                                    onFilterChanged={extractDataFromGrid}
                                />
                            </div>
                            <div className={st.tableDataActionButtonContainer}>
                                {
                                    tableData.length > 0 &&
                                    <IconButton color="primary" onClick={btnDownload_onClick} disabled={IsDownloadLoading} size="small">
                                        {IsDownloadLoading ? <RiRefreshLine className='rotate-center' /> : <FaDownload />}
                                    </IconButton>
                                }
                            </div>
                        </div>
                        <GraphDetails data={graphData} msg_id={message.messageId} />
                    </>
                }
            </div>
        </div>
    );
};

export default BotAnswer;
