import React from 'react';

const GNP_Actuals_Vs_Plan_Details = () => {
    return (
        <div>
            <h1>GNP Actuals Vs Plan Details</h1>
        </div>
    );
};

export default GNP_Actuals_Vs_Plan_Details;
