import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useContext, useEffect, useMemo, useState, useRef } from 'react';
import { FaEdit, FaRegSave } from "react-icons/fa";
import { IoIosCloseCircle, IoMdTrash } from "react-icons/io";
import { MultiSelect } from "react-multi-select-component";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UDContext } from '../../../App';
import {
    getDRT_ProjectList,
    getSearchNameByUserId,
    getUserAccessInfo, 
    getUserSearch,
    postDRT_ProjectInfoDelete,
    postDRT_ProjectInfoInsert, postDRT_ProjectInfoUpdate,
    postUserAccessDelete,
    postUserAccessInsert,
    postUserAccessUpdate,
    postUserAccessAdminUpdate
} from '../../BaseModels/MasterData';
import { useContainerDimensions } from '../../BaseModels/ResizeWindow';
import { delay } from '../../BaseModels/utility';
import ModalPopup from '../../CustomElements/ModalPopup/ModalPopup';
import BackendTopMenu from '../BackendTopMenu/BackendTopMenu';
import st from './AdminAccess.module.css';


const optionCache = {};

const AdminAccess = () => {
    const { width, height } = useContainerDimensions()
    const UserDetail = useContext(UDContext);
    const [IsSaveLoadingVisible, setIsSaveLoadingVisible] = useState(false);
    const [IsProjectListPopupVisible, setIsProjectListPopupVisible] = useState(false);
    const [gridApiProject, setGridApiProject] = useState(null);
    const [ProjectId, setProjectId] = useState("");
    const [ProjectName, setProjectName] = useState("");
    const [DrtProjectList, setDrtProjectList] = useState([]);
    const [DDLProjectList, setDDLProjectList] = useState([]);
    const [DDLSelectedProjectList, setDDLSelectedProjectList] = useState([]);
    const [userMap, setUserMap] = useState(new Map());
    const [UserObject, setUserObject] = useState({
        "id": 0,
        "user_id": "",
        "user_name": "",
        "user_email": "",
        "access_role": "",
        "created_date": "",
        "created_by": "",
        "updated_date": "",
        "updated_by": "",
        "drt_project": []
    });
    const [UserSearchList, setUserSearchList] = useState([]);
    const [AdminAccessId, setAdminAccessId] = useState("");
    const [quickFilterText, setQuickFilterText] = useState("");
    const [UserRole, setUserRole] = useState("");
    const [ErrorMsg, setErrorMsg] = useState("");
    const [gridApi, setGridApi] = useState(null);
    const [isRequestPending, setIsRequestPending] = useState(false);
    const columnDefs = [
        { headerName: 'User Id', field: 'user_id', sortable: true, filter: true, pinned: 'left', width: 120 },
        { headerName: 'Name', field: 'user_name', sortable: true, filter: true },
        { headerName: 'Mail', field: 'user_email', hide: true },
        { headerName: 'Role', field: 'access_role', sortable: true, filter: true },
        { headerName: 'Admin', field: 'is_admin', sortable: true, filter: true, editable: !isRequestPending },
        {
            headerName: 'DRT Project', field: 'drt_project', width: 200, valueFormatter: () => "",
            cellRenderer:
                function (params) {
                    return (
                        <>
                            {
                                params.value.length > 0 ? <>
                                    {
                                        <select className={st.ddlDRTProjectSelect} defaultValue={""}>
                                            <option value="" disabled>
                                                {params.value.filter(x => x.status).length} DRT Project
                                            </option>
                                            {
                                                params.value.map((item, index) => {
                                                    if (item.status === true) {
                                                        return <option key={item.project_name} disabled value={item.project_name}>{item.project_name}</option>
                                                    } else {
                                                        return null
                                                    }
                                                })
                                            }
                                        </select>
                                    }
                                </> : <></>
                            }
                        </>
                    )
                }, sortable: true, filter: false
        },
        { headerName: 'Added By', field: 'created_by', sortable: true, filter: true },
        { headerName: 'Change By', field: 'updated_by', sortable: true, filter: true },
        { headerName: 'Date', field: 'updated_date', filter: 'agDateColumnFilter', sortable: true },
        {
            headerName: 'Action', field: 'user_id',
            cellRenderer:
                function (params) {
                    return (
                        <>
                            <div className={st.tableActionButtonContainer}>
                                <div className={st.btnActionContainer}>
                                    <Tooltip title="Edit" placement="top">
                                        <IconButton onClick={() => { btnEditUser_onClick(params.value) }} className={st.btnAction}>
                                            <FaEdit />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <div className={st.btnActionContainer}>
                                    <Tooltip title="Delete" placement="top">
                                        <IconButton onClick={() => { btnDeleteAdminUser_onClick(params.value) }} className={st.btnAction}>
                                            <IoMdTrash />
                                        </IconButton>
                                    </Tooltip>
                                </div>

                            </div>
                        </>
                    )
                },
            sortable: false,
            filter: false,
            width: 110,
            pinned: 'right'
        }
    ];
    const columnDefsProject = [
        { headerName: 'Name', field: 'project_name', sortable: true, filter: true },
        {
            headerName: 'Action', field: 'project_id',
            cellRenderer:
                function (params) {
                    return (
                        <>
                            <div className={st.tableActionButtonContainer}>
                                <div className={st.btnActionContainer}>
                                    <Tooltip title="Edit">
                                        <IconButton onClick={() => { btnEditProject_onClick(params.value) }} className={st.btnAction}>
                                            <FaEdit />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <div className={st.btnActionContainer}>
                                    <Tooltip title="Delete">
                                        <IconButton onClick={() => { btnDeleteProject_onClick(params.value) }} className={st.btnAction}>
                                            <IoMdTrash />
                                        </IconButton>
                                    </Tooltip>
                                </div>

                            </div>
                        </>
                    )
                },
            sortable: false,
            filter: false,
            width: 110
        }
    ];
    const defaultColDef = useMemo(() => {
        let obj = {
            width: 170,
            filter: true,
            editable: false,
            sortable: true
        }
        if (width > 992) {
            obj.flex = 1;
        }
        return obj;
    }, []);

    useEffect(() => {
        if (UserDetail !== null) {
            getAdminList();
        }
    }, [UserDetail]);


    const getAdminList = async () => {
        try {
            getUserAccessInfo().then(response => {
                if (response.status && Array.isArray(response.data)) {
                    const _userMap = new Map(); 
                    response.data.forEach(user => {
                        _userMap.set(user.user_id, user);
                    })
                    setUserMap(_userMap);
                }
            });
            getDRT_ProjectList().then(response => {
                if (response.status) {
                    let _data = response.data;
                    setDrtProjectList(_data);
                    let _DDLProjectList = _data.map(({ project_id, project_name, status }) => ({ value: project_id, label: project_name }));
                    setDDLProjectList(_DDLProjectList);
                }
            });

        } catch (error) {
            toast.error("Error in getting data");
        }
    }

    const DEBOUNCE_TIMER = 350; // ms
    const fetchRef = useRef(null);

    const getSearchUser = async (str) => {
        const name = str.replace(/[/:?#[\]!~$&''()*,; ]/g, '');
        if (name.length < 2) return [];
        if (fetchRef.current) fetchRef.current.abort('Input debounced');
        const ac = new AbortController();
        fetchRef.current = ac;
        await delay(DEBOUNCE_TIMER);
        if (ac.signal.aborted) return [];
        if (optionCache[name]) return optionCache[name];
        const response = await getUserSearch(name, ac.signal);
        if (ac.signal.aborted) return [];
        let options = response.data;
        if (!response.status && !Array.isArray(options)) return [];
        options = options.slice(0, 15);
        options.forEach((item) => {
            item.fullName = item.givenName + ' ' + item.surname;
        });
        optionCache[name] = options;
        return options;
    }

    const valueRenderer = (selected) => {
        if (!selected.length) {
            return <span style={{ color: '#000000' }}>Digital Review Tracker Admin Access</span>;
        } else {
            return selected.map(({ label }) => <span className={st.drtSelectedProjectItem} key={label}>{label}</span>);
        }
    };


    const btnEditUser_onClick = async (user_id) => {
        try {
            const matchedUser = userMap.get(user_id);
            if (matchedUser) {
                UserExists_Bind(matchedUser);
            } else {
                setAdminAccessId("");
                setErrorMsg("");
                setDDLSelectedProjectList([]);
                setUserRole("");
            }
        } catch (error) {
            toast.error("Error in getting data");
        }
    }

    const UserExists_Bind = (user_data) => {
        setAdminAccessId(user_data.id);
        setQuickFilterText(user_data.user_email);
        setUserRole(user_data.access_role);
        setUserObject(user_data);
        setUserSearchList([]);
        if (user_data.drt_project.length > 0) {
            let _SelectedProjectList = user_data.drt_project.filter(x => x.status === true).map(item => {
                return { value: item.project_id, label: item.project_name };
            });
            setDDLSelectedProjectList(_SelectedProjectList);
        } else {
            setDDLSelectedProjectList([]);
        }
    }

    const btnSelectedUser = async (user) => {
        setQuickFilterText(user.mail);
        setUserSearchList([]);
        let _NewAdminUserId = user.mail;
        if (_NewAdminUserId.includes("@")) {
            _NewAdminUserId = _NewAdminUserId.split("@")[0];
        }
        const matchedUser = userMap.get(_NewAdminUserId);
        if (matchedUser) {
            toast.info("This user is already available in the list.");
            setErrorMsg("This user is already available in the list.");
            UserExists_Bind(matchedUser);
        } else {
            let _UserObject = { ...UserObject };
            _UserObject.user_id = _NewAdminUserId;
            _UserObject.user_name = user.displayName;
            _UserObject.user_email = user.mail;
            _UserObject.access_role = UserRole;
            setUserObject(_UserObject)
            setAdminAccessId("");
            setErrorMsg("");
        }
    }

    const btnAddNewMemberCancel_onClick = () => {
        setQuickFilterText("");
        setUserRole("");
        setDDLSelectedProjectList([]);
        setAdminAccessId("");
        setErrorMsg("");
        setUserSearchList([]);
        gridApi.updateGridOptions({ quickFilterText: "" });
        getAdminList();
    }

    const btnAddNewMember_onClick = () => {
        try {
            if (quickFilterText === "") {
                toast.error("Enter the user mail");
                return;
            } else if (UserRole === "") {
                toast.error("Select the user role");
                return;
            } else {
                let _NewAdminUserId = quickFilterText;
                if (_NewAdminUserId.includes("@")) {
                    _NewAdminUserId = _NewAdminUserId.split("@")[0];
                }
                const matchedUser = userMap.get(_NewAdminUserId);
                setIsSaveLoadingVisible(true);
                getSearchNameByUserId(_NewAdminUserId).then(responseUserSearch => {
                    if (responseUserSearch.status) {
                        if (responseUserSearch?.data?.display_name !== "GUEST" && responseUserSearch?.data?.display_name !== "") {
                            let obj = {
                                "user_id": _NewAdminUserId,
                                "user_name": responseUserSearch?.data?.display_name,
                                "user_email": responseUserSearch?.data?.email,
                                "access_role": UserRole,
                                "project_id": DDLSelectedProjectList.map(x => String(x.value))
                            }
                            if (matchedUser) {
                                obj.id = matchedUser.id;
                                postUserAccessUpdate(obj).then(response => {
                                    if (response.status) {
                                        toast.success(response.data.message);
                                        getAdminList();
                                        setQuickFilterText("");
                                        setUserRole("");
                                        setDDLSelectedProjectList([]);
                                        setUserSearchList([]);
                                        gridApi.updateGridOptions({ quickFilterText: "" });
                                    } else {
                                        toast.error(response.message);
                                    }
                                    setIsSaveLoadingVisible(false);
                                });
                            } else {
                                postUserAccessInsert(obj).then(response => {
                                    if (response.status) {
                                        toast.success(response.data.message);
                                        getAdminList();
                                        setQuickFilterText("");
                                        setUserRole("");
                                        setDDLSelectedProjectList([]);
                                        setUserSearchList([]);
                                        gridApi.updateGridOptions({ quickFilterText: "" });
                                    } else {
                                        toast.error(response.message);
                                    }
                                    setIsSaveLoadingVisible(false);
                                });
                            }
                        } else {
                            toast.error("User not found");
                            setIsSaveLoadingVisible(false);
                        }
                    } else {
                        toast.error(responseUserSearch.message);
                        setIsSaveLoadingVisible(false);
                    }
                });
            }
        } catch (error) {
            toast.error("Error in saving data");
        }
    }

    const onCellValueChanged = useCallback((event) => {
        if (!gridApi || !UserDetail) return;
        const userID = event.data['user_id'];
        const userName = event.data['user_name'];
        if (event.colDef.field === 'is_admin') {
            setIsRequestPending(true);
            const isAdmin = event.newValue;
            const payload = {
                'user_id': userID,
                'is_admin': isAdmin,
            }
            postUserAccessAdminUpdate(payload)
                .then((response) => {
                    if (response.status) {
                        const message = isAdmin ?
                            `Admin access added to ${userName}` :
                            `Admin access removed from ${userName}`;
                        toast.success(message);
                    } else {
                        toast.error(response.message);
                    }
                    setIsRequestPending(false);
                });
        }
    }, [gridApi, UserDetail]);

    const btnDeleteAdminUser_onClick = async (user_id) => {
        if (window.confirm("Are you sure you want to delete?")) {
            try {
                const matchedUser = userMap.get(user_id);
                if (matchedUser) {
                    postUserAccessDelete(user_id).then(response => {
                        if (response.status) {
                            toast.success(response?.data?.message);
                            getAdminList();
                            setQuickFilterText("");
                            setUserRole("");
                            setDDLSelectedProjectList([]);
                            gridApi.updateGridOptions({ quickFilterText: "" });
                        } else {
                            toast.error("Error! Could not delete user!");
                        }
                    });
                } else {
                    toast.error("User not found");
                }
            } catch (error) {
                toast.error("Error! Could not delete user!");
            }
        }
    }

    const handleQuickFilterTextChange = useCallback((e) => {
        let _value = e.target.value;
        setQuickFilterText(_value);
        gridApi.updateGridOptions({ quickFilterText: _value });
        if (!_value || _value.length < 2) return;
        const possibleUserId = _value.split("@")[0];
        const matchedUser = userMap.get(possibleUserId);
        if (!matchedUser) {
            setAdminAccessId("");
            setUserRole("");
            setDDLSelectedProjectList([]);
            setErrorMsg("");
            getSearchUser(_value).then(setUserSearchList);
            return;
        }
        if (AdminAccessId === matchedUser.id) return;
        if (fetchRef.current) fetchRef.current.abort('User match found');
        setAdminAccessId(matchedUser.id);
        setUserRole(matchedUser.access_role);
        setUserSearchList([]);
        if (matchedUser.drt_project.length > 0) {
            const _SelectedProjectList = matchedUser.drt_project
                .filter(x => x.status === true)
                .map(item => ({ value: item.project_id, label: item.project_name }));
            setDDLSelectedProjectList(_SelectedProjectList);
        } else {
            setDDLSelectedProjectList([]);
        }
        setErrorMsg("This user is already available in the list.");
    }, [gridApi, userMap, setAdminAccessId, setUserRole, setUserSearchList, setDDLSelectedProjectList, setErrorMsg]);

    const Project_onGridReady = useCallback((params) => {
        setGridApiProject(params.api)
    }, []);

    const Project_onCellValueChanged = useCallback((event) => {
        //event.data
    }, []);

    const txtProjectName_onChange = (e) => {
        let _ProjectName = e.target.value;
        setProjectName(_ProjectName);
        if (_ProjectName === "") {
            setProjectId("");
        }
    }

    const btnSaveProject_onClick = async () => {
        try {
            if (ProjectId === "") {
                let obj = {
                    "project_name": ProjectName
                }
                postDRT_ProjectInfoInsert(obj).then(response => {
                    if (response.status) {
                        getAdminList();
                        toast.success(response.data);
                        setProjectId("");
                        setProjectName("");
                    }
                });
            } else {
                let obj = {
                    "project_id": ProjectId,
                    "project_name": ProjectName
                }
                postDRT_ProjectInfoUpdate(obj).then(response => {
                    if (response.status) {
                        toast.success(response.data);
                        setProjectId("");
                        setProjectName("");
                        getAdminList();
                    }
                });
            }
        } catch (error) {
            toast.error("Error in saving project");
        }
    }

    const btnEditProject_onClick = async (project_id) => {
        setProjectId(project_id);
        let _Project = DrtProjectList.find(x => x.project_id === project_id);
        if (_Project !== undefined) {
            setProjectName(_Project.project_name);
        }
    }

    const btnDeleteProject_onClick = async (project_id) => {
        try {
            if (window.confirm("Are you sure you want to delete?")) {
                postDRT_ProjectInfoDelete(project_id).then(response => {
                    if (response.status) {
                        toast.success(response.data);
                        setQuickFilterText("");
                        setUserRole("");
                        setDDLSelectedProjectList([]);
                        setAdminAccessId("");
                        setErrorMsg("");
                        gridApi.updateGridOptions({ quickFilterText: "" });
                        getAdminList();
                    }
                });
            }
        } catch (error) {
            toast.error("Error in deleting project");
        }
    }


    return (
        <>
            <BackendTopMenu />
            <div className="mainContainer">
                <div className="row mt-2 mb-2">
                    <div className="col-lg-6">
                        <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
                            <Link className={st.navigateItem} to={"/backend"}>Admin Home</Link>
                            <Link className={st.navigateItem} to={"/backend/adminUser"}>User Access</Link>
                        </Breadcrumbs>
                    </div>
                </div>
                <div className="row mt-2 mb-2">
                    <div className="col-lg-6">
                        <div className={st.pageTitle}>User Access</div>
                    </div>
                    <div className="col-lg-6">
                        {/* <div className={st.topRightButtonContainer}>
                            <Tooltip title="Manage Digital Review Tracker Report List">
                                <Button size="medium" endIcon={<BsTicketDetailedFill />}
                                    sx={{ textTransform: 'capitalize' }} onClick={() => setIsProjectListPopupVisible(true)}>
                                    Manage DRT Report List
                                </Button>
                            </Tooltip>
                        </div> */}
                    </div>
                </div>
                <div className={st.toolbarContainer}>
                    <div className={`${st.errorMessage} tracking-in-expand`}>
                        {ErrorMsg}
                    </div>
                    <div className="row mb-1">
                        <div className="col-lg-4 mb-2">
                            <div className={st.userSearchContainer} onMouseLeave={() => setUserSearchList([])}>
                                <input type="text" placeholder="User" className="form-control"
                                    disabled={!userMap.size}
                                    value={quickFilterText} onChange={handleQuickFilterTextChange}
                                    autoComplete='off'
                                    autoCorrect='off'
                                    autoCapitalize='off'
                                    spellCheck={false}
                                />
                                {
                                    UserSearchList.length > 0 ?
                                        <div className={st.userSearchListContainer}>
                                            {UserSearchList.map((item, index) => {
                                                return (
                                                    <div key={index} className={st.userSearchItem} onClick={() => btnSelectedUser(item)}>
                                                        <span className={st.username}>{item.givenName} {item.surname}</span>
                                                        <span className={st.usermail}>{item.mail}</span>
                                                    </div>
                                                )
                                            })}
                                        </div> : null
                                }
                            </div>

                        </div>
                        <div className="col-lg-2 mb-2">
                            <select className="form-control" name="access_role" value={UserRole} onChange={(e) => setUserRole(e.target.value)}>
                                <option value="" disabled>Select Role</option>
                                <option value="Business User">Business User</option>
                                {/* <option value="ADMIN">ADMIN</option> */}
                                <option value="DTI User">DTI User</option>
                            </select>
                        </div>
                        <div className="col-lg-4 mb-2">
                            <MultiSelect
                                options={DDLProjectList}
                                value={DDLSelectedProjectList}
                                onChange={setDDLSelectedProjectList}
                                labelledBy="Select DRT Project"
                                valueRenderer={valueRenderer}
                            />
                        </div>
                        <div className="col-lg-2 mb-2">
                            <div className={st.actionButtonContainer}>
                                {
                                    IsSaveLoadingVisible ? <CircularProgress /> :
                                        <Button variant="contained" onClick={btnAddNewMember_onClick}
                                            disabled={(quickFilterText === "" || UserRole === "") ? true : false}
                                            endIcon={<FaRegSave />} size="small"
                                            color={AdminAccessId !== "" ? "secondary" : "primary"}>
                                            {AdminAccessId === "" ? "Add" : "Save"}
                                        </Button>
                                }
                                {
                                    <Button variant="outlined" onClick={btnAddNewMemberCancel_onClick}
                                        endIcon={<IoIosCloseCircle />} size="small"
                                        color="primary">
                                        Cancel
                                    </Button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="row">
                    <div className="col-lg-12">
                        <div className='aggrid-header'>
                            <div
                                style={{ height: (height - 250) + "px", maxHeight: '60vh', minHeight: '300px', width: '100%' }}
                                className="ag-theme-quartz">
                                <AgGridReact
                                    rowData={userMap.size ? Array.from(userMap.values()) : null}
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    onGridReady={({api}) => setGridApi(api)}
                                    onCellValueChanged={onCellValueChanged}
                                    includeHiddenColumnsInQuickFilter
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalPopup IsVisible={IsProjectListPopupVisible} onClose={() => setIsProjectListPopupVisible(false)}>
                <div className={st.projectRootContainer}>
                    <div className={st.projectContainer}>
                        <div className={st.projectHeaderContainer}>
                            <div className={st.projectHeader}>DRT Project</div>
                            <IoIosCloseCircle className={st.btnClose} onClick={() => setIsProjectListPopupVisible(false)} />
                        </div>
                        <div className={st.projectBodyContainer}>
                            <div className={st.addProjectContainer}>
                                <input type="text" placeholder="Project Name" className="form-control form-control-sm" value={ProjectName} onChange={txtProjectName_onChange} />
                                <Button variant="contained" size="small" startIcon={<FaRegSave />}
                                    disabled={ProjectName === "" ? true : false}
                                    color={ProjectId === "" ? "primary" : "secondary"}
                                    onClick={btnSaveProject_onClick}>
                                    {ProjectId === "" ? "Add" : "Save"}
                                </Button>
                            </div>
                            <br />
                            <div className='aggrid-header'>
                                <div style={{ height: (height / 2) + "px", minHeight: '300px', width: '100%' }} className="ag-theme-quartz">
                                    <AgGridReact
                                        rowData={DrtProjectList}
                                        columnDefs={columnDefsProject}
                                        defaultColDef={{
                                            width: 170,
                                            filter: true,
                                            editable: false,
                                            sortable: true,
                                            flex: 1,
                                        }}
                                        onGridReady={Project_onGridReady}
                                        onCellValueChanged={Project_onCellValueChanged}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={st.projectFooterContainer}></div>
                    </div>
                </div>
            </ModalPopup>
        </>
    );
};

export default AdminAccess;
