import React, { useEffect, useState } from 'react';
import { Bar, BarChart, Cell, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import InfoButton from '../InfoButton';
import { getMatomoTopReport } from '../../../BaseModels/MasterData';
import NoDataPlaceHolder from '../NoDataPlaceholder';

import styles from './TopReport.module.css';


const TopReport = ({ dates, roles }) => {
    const fontSizeLegends = 12;
    const colorLegends = 'black';
    const colorBarFill = ['#82C3A8', '#BCA034', '#A16666', '#669BAF', '#789682'];
    const colorBarFillCount = colorBarFill.length;

    const [topReport, setTopReport] = useState([]);

    useEffect(() => {
        const ac = new AbortController();
        getMatomoTopReport(dates[0], dates[1], roles, ac.signal)
            .then((response) => {
                if (response.status)
                    setTopReport(response.data);
            });
        return () => ac.abort();
    }, [dates, roles]);

    const CustomizedAxisTick = (props) => {
        const { x, y, payload } = props;
        let data = payload.value;
        if (data.length > 21) {
            data = data.substring(0, 21) + '...';
        }

        return (
            <g transform={`translate(${x},${y})`}>
                <text
                    x={0}
                    y={0}
                    dy={5}
                    textAnchor='end'
                    fill='#666'
                    fontSize='12px'
                >
                    {data}
                </text>
            </g>
        );
    };
    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className={styles.TooltipMain}>
                    <span className={styles.TooltipLabel}>{`${label} : ${payload[0].value}`}</span>
                </div>
            );
        }

        return null;
    };

    return (
        <>
            <div className={styles.header}>
                <div className={styles.title}>Top Report</div>
                <InfoButton
                    content={'This section highlights most frequently accessed and impactful reports'}
                />
            </div>
            <div className={styles.wrapper}>
                <div className={styles.chart_container}>
                    {topReport.length ?
                        <ResponsiveContainer width='100%' height='100%'>
                            <BarChart
                                data={topReport}
                                layout='vertical'
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 100,
                                    bottom: 5,
                                }}>
                                <XAxis type='number' hide />
                                <YAxis
                                    type='category'
                                    interval={0}
                                    tick={<CustomizedAxisTick />}
                                    dataKey='title'
                                />
                                <Tooltip cursor={{ fill: 'transparent' }} content={<CustomTooltip />} />
                                <Bar dataKey='counter' name='Total Visits'>
                                    {
                                        topReport.map((_, idx) => (
                                            <Cell key={`cell-tu-${idx}`} fill={colorBarFill[idx % colorBarFillCount]} className={styles.bar} />
                                        ))
                                    }
                                    <LabelList
                                        dataKey='counter'
                                        fontWeight='bold'
                                        position='insideLeft'
                                        fontSize={fontSizeLegends}
                                        fill={colorLegends}
                                    />
                                </Bar>
                                {/* <Bar dataKey='nb_uniq_visitors' name='Uniq Visitor' barSize={20} xAxisId={0} fill='#08306b' fillOpacity={0.7}>
                            </Bar> */}
                            </BarChart>
                        </ResponsiveContainer>
                        : <NoDataPlaceHolder />}
                </div>
            </div>
        </>
    );
};

export default TopReport;