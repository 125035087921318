import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import FlipCard from '../../FlipCard/FlipCard';
import FlipCard2 from '../../FlipCard2/FlipCard2';

const Image = ({ src, loaderImage, alt, ...props }) => {
    const [loaded, setLoaded] = useState(false);
    return (
        <>
            <img src={src} alt={alt} {...props}
                style={loaded ? {} : { display: 'none' }}
                onLoad={() => { setLoaded(true); }} />
            {loaded ? null : <img src={loaderImage} alt={alt} {...props} />}
        </>

    );
};

const FeaturedDashboard3 = ({ user_fav_report }) => {
    const navigate = useNavigate();
    const btnfavouriteWidget_onClick = (item) => {
        navigate("/dashboard/" + item.slug);
    }

    return (
        <>
            <div className="row">
                {
                    user_fav_report?.map((item, index) => {
                        return (
                            <div className="col-lg-2 col-md-4 col-sm-6" key={index} onClick={() => btnfavouriteWidget_onClick(item)}>
                                <FlipCard2 dashboard={item} />
                            </div>
                        );
                    })
                }
            </div>
        </>
    );
};

export default FeaturedDashboard3;
