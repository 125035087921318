import Switch from '@mui/material/Switch';
import React, { useState } from 'react';
import SummarySection1 from './../../images/SummarySection1.png';
import SummarySection2 from './../../images/SummarySection2.png';
import SummarySection3 from './../../images/SummarySection3.png';

import SummaryDetails1 from '../../../../CustomElements/SummaryDetails/SummaryDetails1/SummaryDetails1';
import SummaryDetails2 from '../../../../CustomElements/SummaryDetails/SummaryDetails2/SummaryDetails2';
import SummaryDetails3 from '../../../../CustomElements/SummaryDetails/SummaryDetails3/SummaryDetails3';
import st from './DescriptionInformation.module.css';


const DescriptionInformation = (props) => {
    const [SummaryType, setSummaryType] = useState(1);
    const [SummarySectionDetails, setSummarySectionDetails] = useState({
        description_id: "",
        div_id: "",
        description: "",
        //background_image: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACcAAAAgCAIAAAAexPbaAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAFiUAABYlAUlSJPAAAAAvSURBVEhL7c1BEQAwEAOh+jedqmDusxjg7UKr1qq1aq1aq9aqtWqtWqvWql2s2wd+qpIzVcu4ZAAAAABJRU5ErkJggg==",
        background_image: "",
        background_color: "#0063C3",
        alignment: "",
        description_height: "",
        description_font: "",
        tutorial: true,
        tutorial_link: "",
        created_date: "",
        created_by: "",
        updated_date: "",
        updated_by: ""
    });

    const btnSelectSummaryType_onClick = (typeid) => {
        setSummaryType(typeid);
    }

    const SummarySectionDetails_description_onChange = (e) => {
        let _SummarySectionDetails = { ...SummarySectionDetails };
        _SummarySectionDetails.description = e.target.value;
        setSummarySectionDetails(_SummarySectionDetails);
    }
    const SummarySectionDetails_background_color_onChange = (e) => {
        let _SummarySectionDetails = { ...SummarySectionDetails };
        _SummarySectionDetails.background_color = e.target.value;
        _SummarySectionDetails.background_image = "";
        setSummarySectionDetails(_SummarySectionDetails);
    };
    const SummarySectionDetails_background_image_onChange = (e) => {
        let _SummarySectionDetails = { ...SummarySectionDetails };
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            _SummarySectionDetails.background_image = reader.result;
            _SummarySectionDetails.background_color = "";
            setSummarySectionDetails(_SummarySectionDetails);
        }
    };
    const SummarySectionDetails_tutorial_onChange = (e) => {
        let _SummarySectionDetails = { ...SummarySectionDetails };
        _SummarySectionDetails.tutorial = e.target.checked;
        setSummarySectionDetails(_SummarySectionDetails);
    };


    return (
        <>
            <div className="row">
                <div className="col-sm-12 col-lg-12">
                    <h3>Choose Summary Section</h3>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 col-lg-4">
                    <div className={st.SummarySectionImageContainer} onClick={()=>btnSelectSummaryType_onClick(1)}>
                        <img src={SummarySection1} alt="SummarySection1" className={st.SummarySectionImage} />
                    </div>
                    <br />
                </div>
                <div className="col-sm-12 col-lg-4">
                    <div className={st.SummarySectionImageContainer} onClick={()=>btnSelectSummaryType_onClick(2)}>
                        <img src={SummarySection3} alt="SummarySection1" className={st.SummarySectionImage} />
                    </div>
                    <br />
                </div>
                <div className="col-sm-12 col-lg-4">
                    <div className={st.SummarySectionImageContainer} onClick={()=>btnSelectSummaryType_onClick(3)}>
                        <img src={SummarySection2} alt="SummarySection1" className={st.SummarySectionImage} />
                    </div>
                    <br />
                </div>                
            </div>
            <div className="row">
                <div className="col-sm-12 col-lg-12">
                    <textarea value={SummarySectionDetails?.description} onChange={SummarySectionDetails_description_onChange} className={st.SummarySectionDescription}></textarea>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 col-lg-12">
                    Background
                    <input type="color" value={SummarySectionDetails?.background_color} onChange={SummarySectionDetails_background_color_onChange} />
                    <input type="file" onChange={SummarySectionDetails_background_image_onChange} />
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 col-lg-12">
                    Tutorial
                    <Switch
                        checked={SummarySectionDetails?.tutorial}
                        onChange={SummarySectionDetails_tutorial_onChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12 col-lg-12">
                    <div className={st.SummarySectionPreview}>
                        {SummaryType === 1 ? <SummaryDetails1 description_information={SummarySectionDetails} /> : null}                     
                        {SummaryType === 2 ? <SummaryDetails2 description_information={SummarySectionDetails} /> : null}  
                        {SummaryType === 3 ? <SummaryDetails3 description_information={SummarySectionDetails} /> : null}  
                    </div>
                </div>
            </div>
        </>
    );
};

export default DescriptionInformation;
