import React, { useEffect, useState } from 'react';
import ImageLogo from './../../../../images/ImageLogo.svg';
import st from './ExportReportBuilderDataList.module.css';

const Image = ({ src, alt, ...props }) => {
    const [loaded, setLoaded] = useState(false);
    return (
        <>
            <img src={src} alt={alt} {...props}
                style={loaded ? {} : { display: 'none' }}
                onLoad={() => { setLoaded(true); }} />
            {loaded ? null : <img src={ImageLogo} alt={alt} {...props} />}
        </>
    );
};
const ExportReportBuilderDataList = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const storedData = localStorage.getItem('ReportBuilderDataList');
        if (storedData) {
            setData(JSON.parse(storedData));
        }
    }, []);

    return (
        <div className={st.rootContainer}>
            {data.length > 0 ? (
                <div>
                    {data.map((item, index) => (
                        <>
                            <div key={index} className={st.reportItemContainer}>
                                <Image src={item.url + ".png"} alt="img" className={st.imgItem} />
                            </div>
                            <div className={st.pageBrack}></div>
                        </>

                    ))}
                </div>
            ) : null}
        </div>
    );
};

export default ExportReportBuilderDataList;
