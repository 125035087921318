import React, { useEffect, useRef, useState } from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link } from 'react-router-dom';
import TopUsers from './TopUsers/TopUsers';
import PauseUse from './PauseUse/PauseUse';
import BackendTopMenu from '../BackendTopMenu/BackendTopMenu';
import PageWiseUsage from './PageWiseUsage/PageWiseUsage';
import HourlyTraffic from './HourlyTraffic/HourlyTraffic';
import ActiveUser from './ActiveUser/ActiveUser';
import DeviceStat from './DeviceStat/DeviceStat';
import TopReport from './TopReport/TopReport';
import UserSpecificReportVisit from './UserSpecificReportVisit/UserSpecificReportVisit';
import DeviceKPI from './DeviceKPI/DeviceKPI';
import SixMonthlyTraffic from './SixMonthlyTraffic/SixMonthlyTraffic';
import { useGlobalFilters } from './GlobalFilters/GlobalFilters';
import { getMatomoRefreshDate } from '../../BaseModels/MasterData';

import st from './MatomoGraphs.module.css';

const pageSections = ['Top User', 'Inactive User', 'Hourly Traffic', 'Active User', 'Top Report',
    'Page Wise Usage', 'Device Stat', 'Monthly Traffic', 'User Specific Report'];


const MatomoGraphs = (props) => {
    const divRefs = useRef({});
    const [RefreshDate, setRefreshDate] = useState("");
    const [filterRoles, filterDates, GlobalFilters] = useGlobalFilters();

    useEffect(() => {
        // makes all recharts pie chart inner area non-selectable 
        setTimeout(() => {
            document.querySelectorAll(`.mainContainer .recharts-layer.recharts-pie`)
                .forEach(el => {
                    el.tabIndex = -1;
                    el.style.outline = 'none';
                });
        }, 2000);
        // LastRefreshDate();
    });

    const LastRefreshDate = () => {
        try {
            const ac = new AbortController();
            getMatomoRefreshDate(ac.signal)
                .then((response) => {
                    if (response.status)
                        setRefreshDate(response?.data?.insert_date);
                });
            return () => ac.abort();
        } catch (error) {

        }
    }

    return (
        <>
            <BackendTopMenu />
            <div className='mainContainer'>
                <div className='row mt-2 mb-2'>
                    <div className='col-lg-6'>
                        <Breadcrumbs aria-label='breadcrumb' separator={<NavigateNextIcon fontSize='small' />}>
                            <Link className={st.navigateItem} to={'/backend'}>Admin Home</Link>
                            <Link className={st.navigateItem} to={'/backend/trafficView'}>Traffic View</Link>
                        </Breadcrumbs>
                    </div>
                    <div className='col-lg-6'>
                        <div className='text-end'>{RefreshDate !== "" ? "Last Refresh " + RefreshDate : ""}</div>
                    </div>
                </div>
                <div className='row'>
                    <div className={st.jump_item_container} >
                        {
                            pageSections.map((title, idx) => (
                                <div key={`cbn-${idx}`}
                                    className={st.jump_item}
                                    onClick={(e) => {
                                        if (divRefs.current && divRefs.current[title]) {
                                            window.scrollTo({
                                                top: divRefs.current[title].offsetTop - 100,
                                                left: 0,
                                            })
                                        }
                                    }}>
                                    {title}
                                </div>
                            ))
                        }
                    </div>
                </div>

                <div className='row'>
                    {GlobalFilters}
                </div>


                <DeviceKPI dates={filterDates} roles={filterRoles} />
                <div className='row'>
                    <div className='col-sm-12 col-lg-6'
                        ref={(el) => (divRefs.current['Top User'] = el)}
                    >
                        <TopUsers dates={filterDates} roles={filterRoles} />
                    </div>
                    <div className='col-sm-12 col-lg-6'
                        ref={(el) => (divRefs.current['Inactive User'] = el)}
                    >
                        <PauseUse roles={filterRoles} />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm-12'
                        ref={(el) => (divRefs.current['Hourly Traffic'] = el)}
                    >
                        <HourlyTraffic dates={filterDates} roles={filterRoles} />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm-12 col-lg-6'
                        ref={(el) => (divRefs.current['Active User'] = el)}
                    >
                        <ActiveUser dates={filterDates} roles={filterRoles} />
                    </div>
                    <div className='col-sm-12 col-lg-6'
                        ref={(el) => (divRefs.current['Top Report'] = el)}
                    >
                        <TopReport dates={filterDates} roles={filterRoles} />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm-12 col-lg-6'
                        ref={(el) => (divRefs.current['Page Wise Usage'] = el)}
                    >
                        <PageWiseUsage dates={filterDates} roles={filterRoles} />
                    </div>
                    <div className='col-sm-12 col-lg-6'
                        ref={(el) => (divRefs.current['Device Stat'] = el)}
                    >
                        <DeviceStat dates={filterDates} roles={filterRoles} />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm-12 col-lg-6'
                        ref={(el) => (divRefs.current['Monthly Traffic'] = el)}
                    >
                        <SixMonthlyTraffic dates={filterDates} roles={filterRoles} />
                    </div>
                    <div className='col-sm-12 col-lg-6'
                        ref={(el) => (divRefs.current['User Specific Report'] = el)}
                    >
                        <UserSpecificReportVisit dates={filterDates} roles={filterRoles} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default MatomoGraphs;