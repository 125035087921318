import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import LinearProgress from '@mui/material/LinearProgress';
import Tooltip from '@mui/material/Tooltip';
import { toPng } from 'html-to-image';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { AiOutlineFullscreen, AiOutlineFullscreenExit } from "react-icons/ai";
import { FaRegStar, FaShareSquare, FaStar, FaDownload, FaFilePdf } from "react-icons/fa";
import { IoReloadCircle, IoShareSocialSharp } from "react-icons/io5";
import Iframe from 'react-iframe';
import { toast } from 'react-toastify';
import { IoIosCloseCircle } from "react-icons/io";
import { FaFileCsv, FaImage } from "react-icons/fa6";
import { PiProjectorScreenChartFill } from "react-icons/pi";
import { RiCamera2Fill, RiFilePpt2Fill } from "react-icons/ri";
import 'react-toastify/dist/ReactToastify.css';
import { UDContext } from '../../../../App';
import ImageLogo from './../../../../images/ImageLogo.svg';
import {
    BaseUrlAPI, deleteReportBuilderImages, getReportBuilderImages, downloadReportBuilderDocument,
    uploadReportBuilderImageCustom, postFavReportDelete, postFavReportInsert
} from '../../../BaseModels/MasterData';
import TopMenu from '../../TopMenu/TopMenu';
import DashboardComment from '../DashboardComment/DashboardComment';
import ModalPopup from '../../../CustomElements/ModalPopup/ModalPopup';
import st from './EmbedFromOtherSource.module.css';

const IFRAME_ACTIONS = {
    DOWNLOAD_CSV: 'download_csv',
    DOWNLOAD_IMAGE: 'download_image',
    DOWNLOAD_PDF: 'download_pdf',
    SET_DOWNLOAD_LIST: 'set_download_list',
    REQUEST_IMAGE: 'request_image',
    RESPONSE_IMAGE: 'response_image',
}


const Image = ({ src, alt, ...props }) => {
    const [loaded, setLoaded] = useState(false);
    return (
        <>
            <img src={src} alt={alt} {...props}
                style={loaded ? {} : { display: 'none' }}
                onLoad={() => { setLoaded(true); }} />
            {loaded ? null : <img src={ImageLogo} alt={alt} {...props} />}
        </>
    );
};

const EmbedFromOtherSource = ({ DashboardDetail }) => {
    const UserDetail = useContext(UDContext);
    const iframeRef = useRef(null);

    const [IsFavorite, setIsFavorite] = useState(false);
    const [IsReportBuilderOpen, setIsReportBuilderOpen] = useState(false);
    const [IsReportBuilderCaptureLoadingOpen, setIsReportBuilderCaptureLoadingOpen] = useState(false);
    const [IsDownloadOptionOpen, setIsDownloadOptionOpen] = useState(false);
    const screen1 = useFullScreenHandle();
    const [IsFullScreen, setIsFullScreen] = useState(false);
    const reportChange = useCallback((state, handle) => {
        setIsFullScreen(state)
    }, [screen1]);
    const [ReportBuilderDataList, setReportBuilderDataList] = useState([]);
    const [IsDownloadFileOpen, setIsDownloadFileOpen] = useState(false);
    const [DownloadFileDataList, setDownloadFileDataList] = useState([]);
    const [pageName, setPageName] = useState('');

    useEffect(() => {
        if (DashboardDetail) {
            if (UserDetail?.AllFavorites?.length > 0) {
                let FavoriteDashboards = UserDetail?.AllFavorites;
                let check = FavoriteDashboards.filter((item) => item.dashboardid === DashboardDetail.dashboardid);
                if (check.length > 0) {
                    setIsFavorite(true);
                } else {
                    setIsFavorite(false);
                }
            }
        }
    }, [DashboardDetail]);

    const btnIsFavorite_onClick = async () => {
        try {
            setIsFavorite(!IsFavorite);
                let favoriteObj = { user_id: UserDetail?.user?.user_id, dashboardid: DashboardDetail?.dashboardid };
                if (!IsFavorite) {
                    postFavReportInsert( DashboardDetail?.dashboardid, UserDetail?.user?.user_id).then((response) => {
                        if (response.status) {
                            toast.success(response.data);
                        }
                    });
                } else {
                    postFavReportDelete( DashboardDetail?.dashboardid, UserDetail?.user?.user_id).then((response) => {
                        if (response.status) {
                            toast.success(response.data);
                        }
                    });
                }
        } catch (error) {
            toast.error("Error: Favorite Dashboard", { type: 'error', autoClose: 1000 });
        }
    }
    const btnDownloadOptionToggle = () => {
        setIsDownloadOptionOpen(!IsDownloadOptionOpen);
    }
    const btnDownload_csv_onClick = async () => {
        setIsDownloadFileOpen(true);
        setIsDownloadOptionOpen(false);
    }
    const btnDownload_file_onClick = (chartId) => {
        const message = {
            'action': IFRAME_ACTIONS.DOWNLOAD_CSV,
            'page_name': pageName,
            'chart_id': chartId,
        }
        if (!iframeRef || !iframeRef.current) return;
        const iframe = iframeRef.current.getElementsByTagName('iframe')[0];
        if (!iframe) return;
        iframe.contentWindow.postMessage(message, "*");
        setIsDownloadFileOpen(false);
    }
    const btnDownload_excel_onClick = async () => {

    }

    const btnDownload_image_onClick = () => {
        const message = {
            'action': IFRAME_ACTIONS.DOWNLOAD_IMAGE,
            'page_name': pageName,
        }
        if (!iframeRef || !iframeRef.current) return;
        const iframe = iframeRef.current.getElementsByTagName('iframe')[0];
        if (!iframe) return;
        iframe.contentWindow.postMessage(message, "*");
        setIsDownloadOptionOpen(false);
    }
    const btnDownload_pdf_onClick = async () => {
        const message = {
            'action': IFRAME_ACTIONS.DOWNLOAD_PDF,
            'page_name': pageName,
        }
        if (!iframeRef || !iframeRef.current) return;
        const iframe = iframeRef.current.getElementsByTagName('iframe')[0];
        if (!iframe) return;
        iframe.contentWindow.postMessage(message, "*");
        setIsDownloadOptionOpen(false);
    }
    const btnDownload_ppt_onClick = async () => {

    }

    const getReportBuilderImagesData = async () => {
        getReportBuilderImages().then((response) => {
            if (response.status) {
                setReportBuilderDataList(response.data);
            } else {
                toast.error("Error: Report Builder", { type: 'error', autoClose: 1000 });
            }
        });
    }

    const btnReportBuilderCapture_onClick = () => {
        setIsReportBuilderCaptureLoadingOpen(true);
        const message = {
            'action': IFRAME_ACTIONS.REQUEST_IMAGE,
            'page_name': pageName,
        }
        if (!iframeRef || !iframeRef.current) return;
        const iframe = iframeRef.current.getElementsByTagName('iframe')[0];
        if (!iframe) return;
        iframe.contentWindow.postMessage(message, "*");
    }

    const btnExportReportBuilderDataList = (type) => {
        downloadReportBuilderDocument(type);
    }

    const btnClearExportReportBuilderDataList = () => {
        if (window.confirm("Are you sure you want to clear?")) {
            setIsReportBuilderCaptureLoadingOpen(true);
            let _ReportBuilderDataList = [...ReportBuilderDataList];
            if (_ReportBuilderDataList.length > 0) {
                let _ReportBuilderIds = _ReportBuilderDataList.map(x => x.id);
                deleteReportBuilderImages({ "img_id": _ReportBuilderIds }).then((response) => {
                    if (response.status) {
                        getReportBuilderImagesData();
                    } else {
                        toast.error("Error: Clear Report Builder", { type: 'error', autoClose: 1000 });
                    }
                    setIsReportBuilderCaptureLoadingOpen(false);
                });
            }
        }
    }

    useEffect(() => {
        getReportBuilderImagesData();
    }, []);

    useEffect(() => {
        const messageHandler = (event) => {
            if (typeof event.data === 'object') {
                if (event.data['action'] === IFRAME_ACTIONS.SET_DOWNLOAD_LIST) {
                    const items = event.data['csv_items'];
                    setDownloadFileDataList(items);
                    setPageName(event.data['page_name']);
                    setIsReportBuilderCaptureLoadingOpen(false);
                }
                else if (event.data['action'] === IFRAME_ACTIONS.RESPONSE_IMAGE) {
                    uploadReportBuilderImageCustom(event.data['file'], event.data['report_url'])
                        .catch(() => {
                            toast.error("Error: Save Report Builder", { type: 'error', autoClose: 1000 });
                        })
                        .finally(() => {
                            getReportBuilderImagesData();
                            setIsReportBuilderCaptureLoadingOpen(false);
                        });
                }
            }
        }
        window.addEventListener('message', messageHandler);
        return () => window.removeEventListener('message', messageHandler);
    }, []);

    return (
        <>
            <FullScreen handle={screen1} onChange={reportChange}>
                <TopMenu />
                <div className="mainContainer">
                    <div className="row mt-3 mb-3">
                        <div className="col-lg-6">
                            <span className={st.dashboardTitleName}>
                                {DashboardDetail?.title}
                            </span>
                        </div>
                        <div className="col-lg-6">
                            <div className={st.tableauTopActionButtonContainer}>
                                <div className={st.tableauTopActionButtonIconContainer}>
                                    <Tooltip title="Favorite">
                                        <IconButton onClick={btnIsFavorite_onClick}>
                                            <FaStar style={{ display: !IsFavorite ? 'none' : 'block' }} className={`${st.tableauTopActionButtonIcon} ${st.tableauTopActionButtonIconActive}`} />
                                            <FaRegStar style={{ display: IsFavorite ? 'none' : 'block' }} className={st.tableauTopActionButtonIcon} />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                
                                {/* <div className={DashboardDetail.report_builder_visible ? st.tableauTopActionButtonIconContainer : st.tableauTopActionButtonIconContainerDisplayNone}>
                                    <Tooltip placement="top" title="Report Builder">
                                        <IconButton onClick={() => setIsReportBuilderOpen(true)}>
                                            <PiProjectorScreenChartFill className={st.tableauTopActionButtonIcon} />
                                        </IconButton>
                                    </Tooltip>
                                    <ModalPopup IsVisible={IsReportBuilderOpen} onClose={() => setIsReportBuilderOpen(false)}>
                                        <div className={st.reportBuilderRootContainer}>
                                            <div className={st.reportBuilderContainer}>
                                                <div className={st.reportBuilderHeader}>
                                                    <div className={st.reportBuilderHeaderTitle}><PiProjectorScreenChartFill /> Report Builder</div>
                                                    <IoIosCloseCircle onClick={() => setIsReportBuilderOpen(false)} className={st.reportBuilderHeaderCloseButton} />
                                                </div>
                                                <div className={st.reportBuilderBody}>
                                                    <div className={st.reportBuilderImageContainer}>
                                                        {
                                                            ReportBuilderDataList.map((item, index) => (
                                                                <Image key={item.id}
                                                                    src={BaseUrlAPI + "images/getimage/" + item?.image_id}
                                                                    alt={item.url}
                                                                    className={st.reportBuilderImage} />
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                                {
                                                    IsReportBuilderCaptureLoadingOpen ?
                                                        <LinearProgress />
                                                        :
                                                        <div className={st.reportBuilderFooter}>
                                                            <Button variant="contained" size='small' onClick={btnReportBuilderCapture_onClick} endIcon={<RiCamera2Fill />}>
                                                                Capture
                                                            </Button>
                                                            {ReportBuilderDataList.length > 0 ?
                                                                <div className={st.reportBuilderExportContainer}>
                                                                    <ButtonGroup
                                                                        disableElevation
                                                                        variant="contained"
                                                                        size='small'
                                                                        aria-label="Disabled button group"
                                                                    >
                                                                        <Button onClick={() => btnExportReportBuilderDataList("ppt")}>.PPT</Button>
                                                                        <Button onClick={() => btnExportReportBuilderDataList("pdf")}>.PDF</Button>
                                                                    </ButtonGroup>
                                                                    <Button onClick={btnClearExportReportBuilderDataList} variant="contained" size='small' color="error">Clear</Button>
                                                                </div>
                                                                : null}
                                                        </div>
                                                }

                                            </div>
                                        </div>
                                    </ModalPopup>
                                </div> */}

                                <div className={DashboardDetail?.download_visible ? st.tableauTopActionButtonIconContainer : st.tableauTopActionButtonIconContainerDisplayNone}>
                                    <FaDownload className={st.tableauTopActionButtonIcon} onClick={btnDownloadOptionToggle} />
                                    <div className={st.downloadItemRootContainer} style={{ display: IsDownloadOptionOpen ? 'block' : 'none' }} onMouseLeave={() => setIsDownloadOptionOpen(false)}>
                                        <div className={st.downloadItemContainer} onClick={btnDownload_csv_onClick} style={{ display: DashboardDetail?.download_csv_visible ? 'flex' : 'none' }}>
                                            <FaFileCsv className={st.downloadItemIcon} />
                                            <div className={st.downloadItemTitle}>CSV</div>
                                        </div>
                                        <div className={st.downloadItemContainer} onClick={btnDownload_image_onClick} style={{ display: DashboardDetail?.download_image_visible ? 'flex' : 'none' }}>
                                            <FaImage className={st.downloadItemIcon} />
                                            <div className={st.downloadItemTitle}>Image</div>
                                        </div>
                                        <div className={st.downloadItemContainer} onClick={btnDownload_pdf_onClick} style={{ display: DashboardDetail?.download_pdf_visible ? 'flex' : 'none' }}>
                                            <FaFilePdf className={st.downloadItemIcon} />
                                            <div className={st.downloadItemTitle}>PDF</div>
                                        </div>
                                        {/* <div className={st.downloadItemContainer} onClick={btnDownload_excel_onClick} style={{ display: DashboardDetail?.download_excel_visible ? 'flex' : 'none' }}>
                                            <AiFillFileExcel className={st.downloadItemIcon} />
                                            <div className={st.downloadItemTitle}>Excel</div>
                                        </div>
                                        <div className={st.downloadItemContainer} onClick={btnDownload_ppt_onClick} style={{ display: DashboardDetail?.download_ppt_visible ? 'flex' : 'none' }}>
                                            <RiFilePpt2Fill className={st.downloadItemIcon} />
                                            <div className={st.downloadItemTitle}>PPT</div>
                                        </div> */}
                                    </div>
                                </div>
                                <ModalPopup IsVisible={IsDownloadFileOpen} onClose={() => setIsDownloadFileOpen(false)}>
                                    <div className={st.dfRootContainer}>
                                        <div className={st.dfContainer}>
                                            <div className={st.dfHeader}>
                                                <div className={st.dfHeaderTitle}><FaDownload /> Download</div>
                                                <IoIosCloseCircle onClick={() => setIsDownloadFileOpen(false)} className={st.dfHeaderCloseButton} />
                                            </div>
                                            <span className={st.dfnote}>Please click below item to download worksheet</span>
                                            <div className={st.dfBody}>
                                                <div className={st.dfDownloadContainer}>
                                                    {
                                                        DownloadFileDataList.map((item, index) => (
                                                            <div key={index} onClick={() => btnDownload_file_onClick(item.id)} className={st.dfItem}>
                                                                <span className={st.dfItemName}>{item.label}</span>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ModalPopup>
                                <div className={st.tableauTopActionButtonIconContainer} >
                                    <Tooltip title="Full Screen">
                                        {
                                            IsFullScreen ?
                                                <IconButton onClick={screen1.exit} >
                                                    <AiOutlineFullscreenExit className={`${st.tableauTopActionButtonIcon} ${st.tableauTopActionButtonIconActive}`} />
                                                </IconButton>
                                                : <IconButton onClick={screen1.enter} >
                                                    <AiOutlineFullscreen className={st.tableauTopActionButtonIcon} />
                                                </IconButton>
                                        }
                                    </Tooltip>
                                </div>
                                <div className={st.tableauTopActionButtonIconContainer}>
                                    <Tooltip title="Reload">
                                        <IconButton onClick={() => window.location.reload(false)}>
                                            <IoReloadCircle className={st.tableauTopActionButtonIcon} />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <div className={st.tableauTopActionButtonIconContainer}>
                                    <Tooltip title="Share Report">
                                        <IconButton onClick={() => { navigator.clipboard.writeText(DashboardDetail?.url); toast("Report url copied"); }}>
                                            <FaShareSquare className={st.tableauTopActionButtonIcon} />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <div className={st.tableauTopActionButtonIconContainer}>
                                    <Tooltip title="Share Page">
                                        <IconButton onClick={() => { navigator.clipboard.writeText(window.location.href); toast("Dashboard url copied"); }}>
                                            <IoShareSocialSharp className={st.tableauTopActionButtonIcon} />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div ref={iframeRef}>
                                {/* {DashboardDetail ? (
                                    
                                    <iframe src={DashboardDetail?.url} title="Embedded Content" height={DashboardDetail?.height + "px"} width="100%" />
                                ) : (
                                    <p>Loading...</p>
                                )} */}
                                {DashboardDetail ? (
                                    <Iframe url={DashboardDetail?.url} width="100%" height={DashboardDetail?.height + "px"} />
                                ) : (
                                    <p>Loading...</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <DashboardComment DashboardDetail={DashboardDetail} />
            </FullScreen>
        </>
    );
};

export default EmbedFromOtherSource;
