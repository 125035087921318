import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getDataP, postData, postDataP } from './APICAllModel';

const isTokenValid = async (token) => true;
const isTokenExpired = async (response) => false;


export const getReportNames = async (abortSignal) => {
    try {
        const token = localStorage.getItem('token');
        if (!token)
            return { status: false, message: "Token not found", data: null };
        if (!(await isTokenValid(token)))
            return { status: false, message: "session timeout", data: null };
        const queryParams = {
            token
        };
        const response = await getDataP(`alert/reportname`, { queryParams, signal: abortSignal });
        if (await isTokenExpired(response))
            return { status: false, message: "session timeout", data: null };
        if (abortSignal.aborted)
            return { status: false, message: "aborted", data: null };
        return { status: true, message: "success", data: response };
    } catch (error) {
        if (abortSignal.aborted)
            return { status: false, message: "aborted", data: null };
        return { status: false, message: "failed to get report names", data: null };
    }
}


export const getAlerts = async (alertID, abortSignal) => {
    try {
        const token = localStorage.getItem('token');
        if (!token)
            return { status: false, message: "Token not found", data: null };
        if (!(await isTokenValid(token)))
            return { status: false, message: "session timeout", data: null };
        const queryParams = {
            token
        };
        const response = await getDataP(`alert/alerts/${alertID}`, { queryParams, signal: abortSignal });
        if (await isTokenExpired(response))
            return { status: false, message: "session timeout", data: null };
        if (abortSignal.aborted)
            return { status: false, message: "aborted", data: null };
        return { status: true, message: "success", data: response };
    } catch (error) {
        if (abortSignal.aborted)
            return { status: false, message: "aborted", data: null };
        return { status: false, message: "failed to get alerts", data: null };
    }
}

export const getAlertDetails = async (alertID, abortSignal) => {
    try {
        const token = localStorage.getItem('token');
        if (!token)
            return { status: false, message: "Token not found", data: null };
        if (!(await isTokenValid(token)))
            return { status: false, message: "session timeout", data: null };
        const queryParams = {
            token
        };
        const response = await getDataP(`alert/alertdetails/${alertID}`, { queryParams, signal: abortSignal });
        if (await isTokenExpired(response))
            return { status: false, message: "session timeout", data: null };
        if (abortSignal.aborted)
            return { status: false, message: "aborted", data: null };
        return { status: true, message: "success", data: response };
    } catch (error) {
        if (abortSignal.aborted)
            return { status: false, message: "aborted", data: null };
        return { status: false, message: "failed to get alert trigger fields", data: null };
    }
}


export const getFilters = async (alertID, abortSignal) => {
    try {
        const token = localStorage.getItem('token');
        if (!token)
            return { status: false, message: "Token not found", data: null };
        if (!(await isTokenValid(token)))
            return { status: false, message: "session timeout", data: null };
        const queryParams = {
            token
        };
        const response = await getDataP(`alert/filter/${alertID}`, { queryParams, signal: abortSignal });
        if (await isTokenExpired(response))
            return { status: false, message: "session timeout", data: null };
        if (abortSignal.aborted)
            return { status: false, message: "aborted", data: null };
        return { status: true, message: "success", data: response };
    } catch (error) {
        if (abortSignal.aborted)
            return { status: false, message: "aborted", data: null };
        return { status: false, message: "failed to get alert filters", data: null };
    }
}


export const getFilterValues = async (filterId, abortSignal) => {
    try {
        const token = localStorage.getItem('token');
        if (!token)
            return { status: false, message: "Token not found", data: null };
        if (!(await isTokenValid(token)))
            return { status: false, message: "session timeout", data: null };
        const queryParams = {
            token
        };
        const response = await getDataP(`alert/filtervalues/${filterId}`, { queryParams, signal: abortSignal });
        if (await isTokenExpired(response))
            return { status: false, message: "session timeout", data: null };
        if (abortSignal.aborted)
            return { status: false, message: "aborted", data: null };
        return { status: true, message: "success", data: response };
    } catch (error) {
        if (abortSignal.aborted)
            return { status: false, message: "aborted", data: null };
        return { status: false, message: "failed to get filter values", data: null };
    }
}

export const getNotifications = async (alertID, abortSignal) => {
    try {
        const token = localStorage.getItem('token');
        if (!token)
            return { status: false, message: "Token not found", data: null };
        if (!(await isTokenValid(token)))
            return { status: false, message: "session timeout", data: null };
        const queryParams = {
            token
        };
        const response = await getDataP(`alert/notification/${alertID}`, { queryParams, signal: abortSignal });
        if (await isTokenExpired(response))
            return { status: false, message: "session timeout", data: null };
        if (abortSignal.aborted)
            return { status: false, message: "aborted", data: null };
        return { status: true, message: "success", data: response };
    } catch (error) {
        if (abortSignal.aborted)
            return { status: false, message: "aborted", data: null };
        return { status: false, message: "failed to get notifications", data: null };
    }
}

export const getNotificationFilters = async (notificationID, abortSignal) => {
    try {
        const token = localStorage.getItem('token');
        if (!token)
            return { status: false, message: "Token not found", data: null };
        if (!(await isTokenValid(token)))
            return { status: false, message: "session timeout", data: null };
        const queryParams = {
            token
        };
        const response = await getDataP(`alert/notificationfilter/${notificationID}`, { queryParams, signal: abortSignal });
        if (await isTokenExpired(response))
            return { status: false, message: "session timeout", data: null };
        if (abortSignal.aborted)
            return { status: false, message: "aborted", data: null };
        return { status: true, message: "success", data: response };
    } catch (error) {
        if (abortSignal.aborted)
            return { status: false, message: "aborted", data: null };
        return { status: false, message: "failed to get notification filters", data: null };
    }
}

export const postCreateNotification = async (payload) => {
    try {
        const token = localStorage.getItem('token');
        if (!token)
            return { status: false, message: "Token not found", data: null };
        if (!(await isTokenValid(token)))
            return { status: false, message: "session timeout", data: null };
        const queryParams = {
            token
        };
        const response = await postDataP(`alert/createnotification`, { queryParams, data: payload });
        if (await isTokenExpired(response))
            return { status: false, message: "session timeout", data: null };
        if (response.status === 'failed')
            return { status: false, message: response.message };
        return { status: true, message: response.message };
    } catch (error) {
        return { status: false, message: "failed to create notification", data: null };
    }
}

export const postUpdateNotification = async (notificationId, payload) => {
    try {
        const token = localStorage.getItem('token');
        if (!token)
            return { status: false, message: "Token not found", data: null };
        if (!(await isTokenValid(token)))
            return { status: false, message: "session timeout", data: null };
        const queryParams = {
            token
        };
        const response = await postDataP(`alert/updatenotification/${notificationId}`, { queryParams, data: payload });
        if (await isTokenExpired(response))
            return { status: false, message: "session timeout", data: null };
        if (response.status === 'failed')
            return { status: false, message: response.message };
        return { status: true, message: response.message };
    } catch (error) {
        return { status: false, message: "failed to update notification", data: null };
    }
}

export const postDeleteNotification = async (notificationId) => {
    try {
        const token = localStorage.getItem('token');
        if (!token)
            return { status: false, message: "Token not found", data: null };
        if (!(await isTokenValid(token)))
            return { status: false, message: "session timeout", data: null };
        const queryParams = {
            token
        };
        const response = await postDataP(`alert/deletenotification/${notificationId}`, { queryParams });
        if (await isTokenExpired(response))
            return { status: false, message: "session timeout", data: null };
        if (response.status === 'failed')
            return { status: false, message: response.message };
        return { status: true, message: response.message };
    } catch (error) {
        return { status: false, message: "failed to delete notification", data: null };
    }
}
