import Button from '@mui/material/Button';
import * as _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaChartBar, FaChartPie, FaSave } from "react-icons/fa";
import { FaChartLine } from "react-icons/fa6";
import { LuScatterChart } from "react-icons/lu";
import { MdCancel } from "react-icons/md";
import Select from 'react-select';
import { toast } from 'react-toastify';
import { UDContext } from '../../../../../App';
import ModalPopup from '../../../../CustomElements/ModalPopup/ModalPopup';
import { AIDashboardUpdate, getAIDashboardData, AIDashboardDeactivateReport } from './../../../../BaseModels/MasterData';
import colors from './Graphs/ColorsDetails';
import { formatHeader } from '../../../../BaseModels/utility';
import { FaTrash } from "react-icons/fa";
import st from './DashboardDetail.module.css';
import BarGraph from './Graphs/BarGraph';
import LineGraph from './Graphs/LineGraph';
import PieGraph from './Graphs/PieGraph';
import ScatterGraph from './Graphs/ScatterGraph';
import Tooltip from '@mui/material/Tooltip';

const DashboardDetail = ({ detail }) => {
    const UserDetail = useContext(UDContext);
    const [IsLoading, setIsLoading] = useState(false);
    const [IsEditGraphPopupVisible, setIsEditGraphPopupVisible] = useState(false);
    const [UniqId, setUniqId] = useState();
    const [conference_id, setconference_id] = useState();
    const [message_id, setmessage_id] = useState();
    const [Title, setTitle] = useState("");
    const [sub_title, setSub_title] = useState("");
    const [Description, setDescription] = useState("");
    const [XAxisList, setXAxisList] = useState([]);
    const [XAxisSelectedIndex, setXAxisSelectedIndex] = useState(-1);
    const [YAxisList, setYAxisList] = useState([]);
    const [YAxisSelectedList, setYAxisSelectedList] = useState([]);
    const [ChartTypeList, setChartTypeList] = useState([]);
    const [ChartTypeSelectedIndex, setChartTypeSelectedIndex] = useState(-1);
    const [ChartData, setChartData] = useState({});
    const [Options, setOptions] = useState({});

    const user_id = UserDetail?.user?.user_id;

    useEffect(() => {
        if (detail && user_id) {
            BindDetails(detail)
        }
    }, [detail, user_id])

    const BindDetails = async (_detail) => {
        try {
            setIsLoading(true);
            setUniqId(_detail.unique_id);
            setconference_id(_detail.conference_id)
            setmessage_id(_detail.message_id)
            setTitle(_detail.title);
            setSub_title(_detail.sub_title);
            setDescription(_detail.description);
            if (_detail.columns.length > 0) {
                let _ChartTypeList = [
                    { value: 'line', label: 'line', position: 0, icon: <FaChartLine /> },
                    { value: 'bar', label: 'bar', position: 1, icon: <FaChartBar /> },
                    { value: 'scatter', label: 'scatter', position: 2, icon: <LuScatterChart /> }
                ];
                let response = await getAIDashboardData(_detail.conference_id, _detail.message_id, _detail.unique_id)
                if (response.status) {
                    _detail["DataRows"] = response.data;
                    if (_detail.DataRows.length < 20) {
                        _ChartTypeList.push({ value: 'pie', label: 'pie', position: 3, icon: <FaChartPie /> })
                    }
                    let _ChartTypeSelectedIndex = 0;
                    setChartTypeList(_ChartTypeList);
                    let _XAxisList = _detail.columns.map(obj => ({ ...obj, value: obj.name, label: formatHeader(obj.name) }));
                    setXAxisList(_XAxisList);
                    if (_detail.yaxis.length === 0) {
                        let _XAxisSelectedIndex = defaultXAxisCalculate(_detail.columns, _detail.DataRows);
                        setXAxisSelectedIndex(_XAxisSelectedIndex);
                        let _YAxisList = [];
                        if (_detail.columns.length === 1) {
                            _YAxisList = _detail.columns.map(obj => ({ ...obj, value: obj.name, label: formatHeader(obj.name) }));
                            _ChartTypeSelectedIndex = 1;
                        } else {
                            _YAxisList = _detail.columns.map(obj => ({ ...obj, value: obj.name, label: formatHeader(obj.name) }));
                            _YAxisList.splice(_XAxisSelectedIndex, 1);
                        }
                        setChartTypeSelectedIndex(_ChartTypeSelectedIndex)
                        setYAxisList(_YAxisList);
                        let _YAxisSelectedIndex = defaultYAxisCalculate(_YAxisList, _detail.DataRows);
                        let _YAxisSelectedList = [_YAxisList[_YAxisSelectedIndex]];
                        setYAxisSelectedList(_YAxisSelectedList);
                        prepareDataForGraph(
                            _detail.unique_id,
                            _XAxisList,
                            _XAxisSelectedIndex,
                            _YAxisList,
                            _YAxisSelectedList,
                            _ChartTypeList,
                            _ChartTypeSelectedIndex,
                            _detail.DataRows,
                            _detail.columns,
                            _detail.title,
                            _detail.description
                        );
                    } else {
                        let _XAxisSelectedIndex = _detail.xaxis;
                        setXAxisSelectedIndex(_XAxisSelectedIndex);
                        let _YAxisList = [];
                        if (_detail.columns.length === 1) {
                            _YAxisList = _detail.columns.map(obj => ({ ...obj, value: obj.name, label: formatHeader(obj.name) }));
                        } else {
                            _YAxisList = _detail.columns.map(obj => ({ ...obj, value: obj.name, label: formatHeader(obj.name) }));
                            _YAxisList.splice(_XAxisSelectedIndex, 1);
                        }
                        _ChartTypeSelectedIndex = _detail.graphtype;
                        setChartTypeSelectedIndex(_ChartTypeSelectedIndex)
                        setYAxisList(_YAxisList);
                        let _YAxisSelectedList = _detail.yaxis;
                        setYAxisSelectedList(_YAxisSelectedList);
                        prepareDataForGraph(
                            _detail.unique_id,
                            _XAxisList,
                            _XAxisSelectedIndex,
                            _YAxisList,
                            _YAxisSelectedList,
                            _ChartTypeList,
                            _ChartTypeSelectedIndex,
                            _detail.DataRows,
                            _detail.columns,
                            _detail.title,
                            _detail.description
                        );
                    }
                }
                setIsLoading(false);
            }
        } catch (error) {

        }
    }

    const defaultXAxisCalculate = (_columns, _DataRows) => {
        let _XAxisSelectedIndex = -1;
        try {
            _columns.forEach((element, index) => {
                let ColumnName = element.name;
                if (element.type_name === "STRING") {
                    let _dataRowMap = _DataRows.map((item, index) => { return item[ColumnName]; });
                    let _dataRowMapFiltered = _dataRowMap.filter((x) => { if (x) return x });
                    let _dataRowMapFisrtChar = _dataRowMapFiltered.map((x) => x[0]);
                    let _dataRowMapLastChar = _dataRowMapFiltered.map((x) => x[x.length - 1]);
                    if (_dataRowMapFisrtChar.includes('$') ||
                        _dataRowMapLastChar.includes('%') ||
                        _dataRowMapLastChar.includes('B') ||
                        _dataRowMapLastChar.includes('K')
                    ) {

                    } else {
                        if (_XAxisSelectedIndex === -1)
                            _XAxisSelectedIndex = index;
                    }
                }
            });
        } catch (error) {
            toast.error("Error in default XAxis Calculate");
        }
        if (_XAxisSelectedIndex === -1) {
            _XAxisSelectedIndex = 0;
        }
        return _XAxisSelectedIndex;
    }

    const defaultYAxisCalculate = (_columns, _DataRows) => {
        let _YAxisSelectedIndex = -1;
        try {
            if (_DataRows && _columns) {
                for (let index = _columns.length - 1; index > 0; index--) {
                    let ColumnName = _columns[index].name;
                    let ColumnType = _columns[index].type_name;
                    if (ColumnType === "STRING") {
                        let _dataRowMap = _DataRows.map((item, index) => { return item[ColumnName]; });
                        let _dataRowMapFiltered = _dataRowMap.filter((x) => { if (x) return x });
                        let _dataRowMapFisrtChar = _dataRowMapFiltered.map((x) => x[0]);
                        if (_dataRowMapFisrtChar.includes('$')) {
                            let countDollar = _dataRowMapFisrtChar.filter((str) => str === '$').length
                            if (countDollar === _dataRowMapFiltered.length) {
                                if (_YAxisSelectedIndex === -1)
                                    _YAxisSelectedIndex = index;
                            }
                        }
                        let _dataRowMapLastChar = _dataRowMapFiltered.map((x) => x[x.length - 1]);
                        if (_dataRowMapLastChar.includes('%')) {
                            let countPercentage = _dataRowMapLastChar.filter((str) => str === '%').length
                            if (countPercentage === _dataRowMapFiltered.length) {
                                if (_YAxisSelectedIndex === -1)
                                    _YAxisSelectedIndex = index;
                            }
                        } else if (_dataRowMapLastChar.includes('M')) {
                            let countMillean = _dataRowMapLastChar.filter((str) => str === 'M').length
                            if (countMillean === _dataRowMapFiltered.length) {
                                if (_YAxisSelectedIndex === -1)
                                    _YAxisSelectedIndex = index;
                            }
                        } else if (_dataRowMapLastChar.includes('B')) {
                            let countBillion = _dataRowMapLastChar.filter((str) => str === 'B').length
                            if (countBillion === _dataRowMapFiltered.length) {
                                if (_YAxisSelectedIndex === -1)
                                    _YAxisSelectedIndex = index;
                            }
                        } else if (_dataRowMapLastChar.includes('K')) {
                            let countThousand = _dataRowMapLastChar.filter((str) => str === 'K').length
                            if (countThousand === _dataRowMapFiltered.length) {
                                if (_YAxisSelectedIndex === -1)
                                    _YAxisSelectedIndex = index;
                            }
                        }

                    } else if (_columns[index].type_name === "INT" || _columns[index].type_name === "INT64" || _columns[index].type_name === "DOUBLE" || _columns[index].type_name === "FLOAT64") {
                        if (String(ColumnName).toLowerCase().includes("month") || String(ColumnName).toLowerCase().includes("year")) {
                            let removeEmpty = _DataRows.filter((x) => { if (x[ColumnName]) return x[ColumnName] });
                            let lengthOfEachValue = removeEmpty.map((item, index) => { return String(item).length });
                            let uniqueLength = [...new Set(lengthOfEachValue)];
                            if (uniqueLength.length === 1) {

                            } else {
                                if (_YAxisSelectedIndex === -1)
                                    _YAxisSelectedIndex = index;
                            }
                        }
                    }
                }
            }
        } catch (error) {
            toast.error("Error in default YAxis Calculate");
        }
        if (_YAxisSelectedIndex === -1) {
            _YAxisSelectedIndex = _columns.length - 1;
        }
        return _YAxisSelectedIndex;
    }

    const ddlChartTypeList_onChange = (selectedOption) => {
        try {
            let _uniqueId = UniqId;
            let _Title = Title;
            let _Description = Description;
            let _XAxisList = [...XAxisList];
            let _YAxisList = [...YAxisList];
            let _YAxisSelectedList = [...YAxisSelectedList];
            let _ChartTypeList = [...ChartTypeList];
            let _XAxisSelectedIndex = XAxisSelectedIndex;
            setChartTypeSelectedIndex(selectedOption.hasOwnProperty('position') ? selectedOption.position : selectedOption.id);
            prepareDataForGraph(
                _uniqueId,
                _XAxisList,
                _XAxisSelectedIndex,
                _YAxisList,
                _YAxisSelectedList,
                _ChartTypeList,
                selectedOption.hasOwnProperty('position') ? selectedOption.position : selectedOption.id,
                detail.DataRows,
                detail.columns,
                _Title,
                _Description

            );
        } catch (error) {
            toast.error("Error in ddl Chart Type List");
        }
    }

    const ddlXAxisList_onChange = (selectedOption) => {
        try {
            let _YAxisSelectedList = [...YAxisSelectedList];
            let CalculateYAxis = false;
            if (_YAxisSelectedList.length === 1) {
                if (selectedOption.value === _YAxisSelectedList[0].value) {
                    let _YAxisList = detail.columns.map(obj => ({ ...obj, value: obj.name, label: formatHeader(obj.name) }));
                    _YAxisList.splice(selectedOption.hasOwnProperty('position') ? selectedOption.position : selectedOption.id, 1);
                    setYAxisList(_YAxisList);
                    let _YAxisSelectedIndex = defaultYAxisCalculate(_YAxisList);
                    _YAxisSelectedList = [_YAxisList[_YAxisSelectedIndex]];
                    setYAxisSelectedList(_YAxisSelectedList);
                } else {
                    CalculateYAxis = true;
                }
            } else {
                CalculateYAxis = true;
            }
            if (CalculateYAxis) {
                let _YAxisList = detail.columns.map(obj => ({ ...obj, value: obj.name, label: formatHeader(obj.name) }));
                _YAxisList.splice(selectedOption.hasOwnProperty('position') ? selectedOption.position : selectedOption.id, 1);
                let _NewYAxisSelectedList = [];
                _YAxisList.forEach(All_YAxis_items => {
                    _YAxisSelectedList.forEach(selectedYAxix_item => {
                        if (All_YAxis_items.value === selectedYAxix_item.value) {
                            _NewYAxisSelectedList.push(All_YAxis_items);
                        }
                    });
                });
                setYAxisList(_YAxisList);
                setYAxisSelectedList(_NewYAxisSelectedList);
            }
            setXAxisSelectedIndex(selectedOption.hasOwnProperty('position') ? selectedOption.position : selectedOption.id);
            let _XAxisList = [...XAxisList];
            let _YAxisList = [...YAxisList];
            let _ChartTypeList = [...ChartTypeList];
            let _XAxisSelectedIndex = selectedOption.hasOwnProperty('position') ? selectedOption.position : selectedOption.id;
            let _ChartTypeSelectedIndex = ChartTypeSelectedIndex;
            let _uniqueId = UniqId;
            let _Title = Title;
            let _Description = Description;
            prepareDataForGraph(
                _uniqueId,
                _XAxisList,
                _XAxisSelectedIndex,
                _YAxisList,
                _YAxisSelectedList,
                _ChartTypeList,
                _ChartTypeSelectedIndex,
                detail.DataRows,
                detail.columns,
                _Title,
                _Description
            );
        } catch (error) {
            toast.error("Error in ddl X Axis List");
        }
    }

    const ddlYAxisList_onChange = (selectedOption) => {
        try {
            let _YAxisSelectedList = selectedOption;
            if (_YAxisSelectedList.length === 0) {
                toast.error("Please select at least one Y-Axis");
                return;
            }
            let _XAxisList = [...XAxisList];
            let _YAxisList = [...YAxisList];
            let _ChartTypeList = [...ChartTypeList];
            let _XAxisSelectedIndex = XAxisSelectedIndex;
            let _ChartTypeSelectedIndex = ChartTypeSelectedIndex;
            let _Title = Title;
            let _Description = Description;
            setYAxisSelectedList(selectedOption);
            let _uniqueId = UniqId;
            prepareDataForGraph(
                _uniqueId,
                _XAxisList,
                _XAxisSelectedIndex,
                _YAxisList,
                _YAxisSelectedList,
                _ChartTypeList,
                _ChartTypeSelectedIndex,
                detail.DataRows,
                detail.columns,
                _Title,
                _Description
            );
        } catch (error) {
            toast.error("Error in ddl Y Axis List");
        }
    }


    const saveLocalStorage = async () => {
        try {
            let _uniqueId = UniqId;
            let _conference_id = conference_id;
            let _message_id = message_id;
            let _YAxisSelectedList = [...YAxisSelectedList];
            let _XAxisSelectedIndex = XAxisSelectedIndex;
            let _ChartTypeSelectedIndex = ChartTypeSelectedIndex;
            let _Columns = detail.columns;
            let _Title = Title;
            let _Description = Description;
            let _sub_title = sub_title;

            let obj = {
                "unique_id": _uniqueId,
                "conference_id": _conference_id,
                "message_id": _message_id,
                "title": _Title,
                "sub_title": _sub_title,
                "description": _Description,
                "columns_names": _Columns,
                "graphtype": _ChartTypeSelectedIndex,
                "xaxis": _XAxisSelectedIndex,
                "yaxis": _YAxisSelectedList,
                "user": user_id
            };
            let response = await AIDashboardUpdate(obj);
            if (response) {
                if (response.status) {
                    toast.success("Graph Updated Successfully");
                }
            }
            setIsEditGraphPopupVisible(false);
        } catch (error) {
            toast.error("Error in save Local Storage");
        }
    }

    const btnDelete_onClick = async () => {
        try {
            if (!window.confirm("Are you sure you want to delete this graph?")) return;
            let _uniqueId = UniqId;
            let response = await AIDashboardDeactivateReport(_uniqueId, user_id);
            if (response.status) {
                toast.success("Graph Deleted Successfully");
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            }
        } catch (error) {
            toast.error("Error in btn Delete on Click");
        }
    }

    const prepareDataForGraph = (
        _uniqueId,
        _XAxisList,
        _XAxisSelectedIndex,
        _YAxisList,
        _YAxisSelectedList,
        _ChartTypeList,
        _ChartTypeSelectedIndex,
        _DataRows,
        _Columns,
        _Title,
        _Description
    ) => {
        try {
            let ChartType = _ChartTypeList[_ChartTypeSelectedIndex].value;
            let _ChartXAxis = _DataRows.map((item) => item[_Columns[_XAxisSelectedIndex].name]);
            let _datasets = [];
            let _options = {
                responsive: true,
                maintainAspectRatio: false,
                interaction: {
                    intersect: false,
                    mode: 'index',
                },
                plugins: {
                    datalabels: {
                        display: true,
                        backgroundColor: function (context) {
                            return context.dataset.backgroundColor;
                        },
                        borderRadius: 1,
                        color: 'white',
                        font: {
                            weight: 'bold',
                            size: 11
                        },
                        padding: 1
                    },
                    zoom: {
                        pan: {
                            enabled: true,
                            mode: 'x',
                            threshold: 0,
                        },
                        zoom: {
                            wheel: {
                                enabled: true
                            },
                            pinch: {
                                enabled: true
                            },
                            mode: 'x',
                        },
                    }
                },
                scales: {
                    x: {
                        display: true,
                        title: {
                            display: true,
                            text: formatHeader(_Columns[_XAxisSelectedIndex]?.name),
                            font: {
                                size: 14,
                                weight: 'bold'
                            }
                        },
                        min: 0,
                        max: 20,
                    }, y: {
                        display: true,
                        title: {
                            display: true,
                            text: _YAxisSelectedList.length > 1 ? 'Value' : formatHeader(_YAxisSelectedList[0]?.name),
                            font: {
                                size: 14,
                                weight: 'bold'
                            }
                        }
                    }
                },
                layout: {
                    padding: {
                        top: 25,
                        right: 10,
                        bottom: 10,
                        left: 10
                    }
                }
            };
            if (_YAxisSelectedList.length > 1 && ChartType !== 'pie') {
                _options.plugins["legend"] = {
                    display: true,
                    responsive: true,
                    position: "bottom",
                    align: "center",
                    labels: {
                        generateLabels: (chart) => {
                            return chart.data.datasets.map((dataset, i) => ({
                                text: formatHeader(dataset?.label),
                                fillStyle: dataset.backgroundColor,
                                hidden: dataset.hidden,
                                index: i,
                            }));
                        },
                    },
                    onClick: function (e, legendItem) { }
                }
            } else {
                _options.plugins["legend"] = {
                    display: false,
                }
            }
            if (ChartType !== 'pie') {
                _options.plugins["tooltip"] = {
                    callbacks: {
                        title: (context) => {
                            return formatHeader(context[0].label);
                        },
                        label: function (context) {
                            let label = formatHeader(context.dataset.label) || '';
                            if (label) {
                                label += ': ' + context.parsed.y;
                            }
                            return label;
                        }
                    }
                }
            }

            switch (ChartType) {
                case 'bar':
                    _YAxisSelectedList.forEach((element, index) => {
                        let obj = {};
                        obj.label = element.name;
                        obj.data = DataRowsFormatting(_DataRows, element.name);
                        obj.borderColor = colors[index % colors.length];
                        if (_YAxisSelectedList.length > 1) {
                            obj.datalabels = {
                                align: 'center',
                                anchor: 'center'
                            }
                        } else {
                            obj.datalabels = {
                                align: 'top',
                                anchor: 'end'
                            }
                        }
                        obj.backgroundColor = colors[index % colors.length];
                        obj.hoverBackgroundColor = String(colors[index % colors.length]) + "95";
                        _datasets.push(obj);
                    });
                    setChartData({
                        labels: _ChartXAxis,
                        datasets: _datasets,
                    });
                    setOptions(_options);
                    break;
                case 'line':
                    _YAxisSelectedList.forEach((element, index) => {
                        let obj = {};
                        obj.label = element.name;
                        obj.data = DataRowsFormatting(_DataRows, element.name);
                        obj.borderColor = colors[index % colors.length];
                        if (_YAxisSelectedList.length > 1) {
                            obj.datalabels = {
                                align: 'center',
                                anchor: 'center'
                            }
                        } else {
                            obj.datalabels = {
                                align: 'top',
                                anchor: 'end'
                            }
                        }
                        obj.backgroundColor = colors[index % colors.length];
                        obj.hoverBackgroundColor = String(colors[index % colors.length]) + "95";
                        obj.pointStyle = 'circle';
                        obj.pointRadius = 8;
                        obj.pointHoverRadius = 15;
                        _datasets.push(obj);
                    });
                    setChartData({
                        labels: _ChartXAxis,
                        datasets: _datasets,
                    });
                    setOptions(_options);
                    break;
                case 'pie':
                    _YAxisSelectedList.forEach(element => {
                        let obj = {};
                        obj.label = element.name;
                        obj.data = DataRowsFormatting(_DataRows, element.name);
                        obj.borderColor = "#ffffff";
                        if (_YAxisSelectedList.length > 1) {
                            obj.datalabels = {
                                align: 'center',
                                anchor: 'center'
                            }
                        } else {
                            obj.datalabels = {
                                anchor: 'end'
                            }
                        }
                        obj.backgroundColor = _DataRows.map((item, index) => { return colors[index % colors.length]; });
                        obj.hoverBackgroundColor = _DataRows.map((item, index) => { return (colors[index % colors.length]) + "95"; });
                        _datasets.push(obj);
                    });
                    setChartData({
                        labels: _ChartXAxis,
                        datasets: _datasets,
                    });
                    _options.plugins.zoom = {};
                    _options.scales = {};
                    setOptions(_options);
                    break;
                case 'scatter':
                    _YAxisSelectedList.forEach((element, column_index) => {
                        let obj = {};
                        obj.label = element.name;
                        obj.data = DataRowsFormatting(_DataRows, element.name);
                        obj.borderColor = colors[column_index % colors.length];
                        obj.datalabels = {
                            align: 'top',
                            anchor: 'end'
                        }
                        obj.backgroundColor = colors[column_index % colors.length];
                        obj.hoverBackgroundColor = String(colors[column_index % colors.length]) + "95";
                        obj.pointStyle = 'circle';
                        obj.pointRadius = 8;
                        obj.pointHoverRadius = 15;
                        _datasets.push(obj);
                    });
                    let _NewChartXAxis = [];
                    var _XAxisGrouped = Object.keys(_.mapValues(_.groupBy(_DataRows, _Columns[_XAxisSelectedIndex].name), clist => clist.map(car => _.omit(car, _Columns[_XAxisSelectedIndex].name))));
                    _ChartXAxis.forEach(element => {
                        _NewChartXAxis.push(_XAxisGrouped.findIndex((x) => String(x) === String(element)));
                    });
                    setChartData({
                        labels: _NewChartXAxis,
                        datasets: _datasets,
                    });
                    _options.plugins["tooltip"] = {
                        callbacks: {
                            title: (xDatapoint) => {
                                var _XAxisGrouped = Object.keys(_.mapValues(_.groupBy(_DataRows, _Columns[_XAxisSelectedIndex].name), clist => clist.map(car => _.omit(car, _Columns[_XAxisSelectedIndex].name))));
                                if (xDatapoint.length > 0)
                                    return _XAxisGrouped[xDatapoint[0].label];
                                else return " ";
                            },
                            label: function (context) {
                                let label = formatHeader(context.dataset.label) || '';
                                if (label) {
                                    label += ': ' + context.parsed.y;
                                }
                                return label;
                            }
                        }
                    }
                    _options.scales.x = {
                        display: true,
                        title: {
                            display: true,
                            text: formatHeader(_Columns[_XAxisSelectedIndex]?.name)
                        },
                        ticks: {
                            callback: function (label) {
                                var _XAxisGrouped = Object.keys(_.mapValues(_.groupBy(_DataRows, _Columns[_XAxisSelectedIndex].name), clist => clist.map(car => _.omit(car, _Columns[_XAxisSelectedIndex].name))));
                                return _XAxisGrouped[label];
                            }
                        }
                    }
                    setOptions(_options);
                    break;
                default:
                    _Columns.forEach((element, column_index) => {
                        if (_YAxisSelectedList.map((x) => x.value).includes(element.name)) {
                            let obj = {};
                            obj.label = element.name;
                            obj.data = DataRowsFormatting(_DataRows, element.name);
                            obj.borderColor = colors[column_index % colors.length];
                            obj.backgroundColor = colors[column_index % colors.length];
                            obj.hoverBackgroundColor = String(colors[column_index % colors.length]) + "95";
                            obj.datalabels = {
                                align: 'center',
                                anchor: 'center'
                            }
                            _datasets.push(obj);
                        }
                    });
                    setChartData({
                        labels: _ChartXAxis,
                        datasets: _datasets,
                    });
                    setOptions(_options);
                    break;
            }
        } catch (error) {
            console.log(error);
            toast.error("Error in prepare Data For Graph");
        }
    }

    const DataRowsFormatting = (_dataRow, ColumnName) => {
        let _data = [];
        try {
            let _dataRowMap = _dataRow.map((item, index) => { return item[ColumnName]; });
            _dataRowMap.forEach(element => {
                if (element) {
                    let newValue = String(element).replace(/[^0-9\.-]/g, "");
                    if (newValue === "") _data.push(null);
                    else _data.push(Number(newValue));
                } else {
                    _data.push(element);
                }
            });
        } catch (error) {
            toast.error("Error in Data Rows Formatting");
        }
        return _data;
    }

    return (
        <>
            {IsLoading && <div className={st.loaderContainer}><span className={st.loader}></span></div>}
            {!IsLoading && <>
                <div className={`${st.itemRootContainer} ${st.dashboardItem}`}>
                    <div className={st.headerContainer}>
                        <Tooltip title={Description}>
                            <div>{Title}</div>
                        </Tooltip>
                        <BsThreeDotsVertical onClick={() => setIsEditGraphPopupVisible(true)} />
                    </div>
                    <small>{sub_title}</small>
                    <div className={st.graphRootContainer}>
                        {
                            Options &&
                            ChartData &&
                            ChartTypeList.length > 0 && ChartTypeSelectedIndex > -1 &&
                            XAxisList.length > 0 && XAxisSelectedIndex > -1 &&
                            YAxisList.length > 0 && YAxisSelectedList.length > 0 &&
                            <div className={st.graphContainer}>
                                {ChartTypeList[ChartTypeSelectedIndex].value === 'bar' ? <BarGraph msg_id={UniqId} data={ChartData} options={Options} /> : null}
                                {ChartTypeList[ChartTypeSelectedIndex].value === 'line' ? <LineGraph msg_id={UniqId} data={ChartData} options={Options} /> : null}
                                {ChartTypeList[ChartTypeSelectedIndex].value === 'pie' ? <PieGraph msg_id={UniqId} data={ChartData} options={Options} /> : null}
                                {ChartTypeList[ChartTypeSelectedIndex].value === 'scatter' ? <ScatterGraph msg_id={UniqId} data={ChartData} options={Options} /> : null}
                            </div>
                        }
                    </div>
                </div>
                <ModalPopup IsVisible={IsEditGraphPopupVisible} onClose={() => setIsEditGraphPopupVisible(false)}>
                    <div className={st.graphModifyDetail}>
                        <div className={st.editGraphRootContainer}>
                            <div className={st.editContainer}>
                                <div className={st.editName}>Title</div>
                                <input type="text" placeholder="Title" value={Title}
                                    onChange={(e) => { setTitle(e.target.value); }}
                                    className={`${'form-control form-control-sm'}`} />
                            </div>
                        </div>
                        <div className={st.editGraphRootContainer}>
                            <div className={st.editContainer}>
                                <div className={st.editName}>Sub-Title</div>
                                <input type="text" placeholder="Sub-Title" value={sub_title}
                                    onChange={(e) => { setSub_title(e.target.value); }}
                                    className={`${'form-control form-control-sm'}`} />
                            </div>
                        </div>
                        <div className={st.editGraphRootContainer}>
                            <div className={st.editContainer}>
                                <div className={st.editName}>Description</div>
                                <input type="text" placeholder="Description"
                                    onChange={(e) => { setDescription(e.target.value) }}
                                    value={Description} className={`${'form-control form-control-sm'}`} />
                            </div>
                        </div>
                        <div className={st.editGraphRootContainer}>
                            <div className={st.editContainer}>
                                <div className={st.editName}>Axis</div>
                                <div className={st.graphActionItemsRootContainer}>
                                    {
                                        XAxisList.length > 0 && XAxisSelectedIndex > -1 &&
                                        <div className={st.graphActionItemContainer}>
                                            <strong>X-Axis</strong>
                                            <Select
                                                className="Select-X-Axis"
                                                classNamePrefix="select"
                                                value={XAxisList[XAxisSelectedIndex]}
                                                isClearable={false}
                                                isSearchable={false}
                                                name="Select-X-Axis"
                                                options={XAxisList}
                                                onChange={ddlXAxisList_onChange}
                                            />
                                        </div>
                                    }
                                    {
                                        YAxisList.length > 0 && YAxisSelectedList.length > 0 &&
                                        <div className={st.graphActionItemContainer}>
                                            <strong>Y-Axis</strong>
                                            <Select
                                                className="Select-Y-Axis"
                                                classNamePrefix="select"
                                                isMulti
                                                value={YAxisSelectedList}
                                                isClearable={false}
                                                isSearchable={false}
                                                name="Select-Y-Axis"
                                                options={YAxisList}
                                                onChange={ddlYAxisList_onChange}
                                            />
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>
                        <div className={st.editGraphRootContainer}>
                            <div className={st.editContainer}>
                                <div className={st.editName}>Graph Type</div>
                                <div className={st.chartTypeContainer}>
                                    {
                                        ChartTypeList.length > 0 && ChartTypeSelectedIndex > -1 &&
                                        ChartTypeList.map((item, index) => {
                                            return (
                                                <button key={index} className={index === ChartTypeSelectedIndex ? st.btnChartTypeActive : st.btnChartType} onClick={() => ddlChartTypeList_onChange(item)}>
                                                    {item.icon} {item.label}
                                                </button>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={st.graphModifyFooter}>
                            <Button variant="outlined" size="medium" startIcon={<MdCancel />} onClick={() => setIsEditGraphPopupVisible(false)}>
                                Cancel
                            </Button>
                            <Button variant="contained" size="medium" color="error" startIcon={<FaTrash />} onClick={btnDelete_onClick}>
                                Delete
                            </Button>
                            <Button variant="contained" size="medium" startIcon={<FaSave />} onClick={saveLocalStorage}>
                                Save
                            </Button>
                        </div>
                    </div>
                </ModalPopup>
            </>
            }
        </>
    );
};

export default DashboardDetail;