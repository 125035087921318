import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import FlipCard from '../../FlipCard/FlipCard';

const Image = ({ src, loaderImage, alt, ...props }) => {
    const [loaded, setLoaded] = useState(false);
    return (
        <>
            <img src={src} alt={alt} {...props}
                style={loaded ? {} : { display: 'none' }}
                onLoad={() => { setLoaded(true); }} />
            {loaded ? null : <img src={loaderImage} alt={alt} {...props} />}
        </>

    );
};

const FeaturedDashboard4 = ({ user_fav_report }) => {
    const navigate = useNavigate();
    const btnfavouriteWidget_onClick = (item) => {
        navigate("/dashboard/" + item.slug);
    }

    const CustomDot = ({ onMove, index, onClick, active }) => {
        return (
            <li
                className={active ? "active" : "inactive"}
                onClick={() => onClick()}
            >
                {index + 1}
            </li>
        );
    };



    return (
        <>
            <Carousel additionalTransfrom={0}
                arrows={true}
                autoPlay
                autoPlaySpeed={5000}
                centerMode={false}
                className=""
                containerClass="container-with-dots"
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                pauseOnHover
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={{
                    desktop: {
                        breakpoint: { max: 3000, min: 1024 },
                        items: 6,
                        partialVisibilityGutter: 40 // this is needed to tell the amount of px that should be visible.
                    },
                    tablet: {
                        breakpoint: { max: 1024, min: 464 },
                        items: 3,
                        partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
                    },
                    mobile: {
                        breakpoint: { max: 464, min: 0 },
                        items: 1,
                        partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
                    }
                }}
                rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay
                showDots={true}
                sliderClass=""
                slidesToSlide={1}
                swipeable>
                {
                    user_fav_report?.map((item, index) => {
                        return (
                            <div key={index} onClick={() => btnfavouriteWidget_onClick(item)}>
                                <FlipCard dashboard={item} />
                            </div>
                        );
                    })
                }
            </Carousel>
        </>
    );
};

export default FeaturedDashboard4;
