import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UDContext } from '../../../App';
import BackendTopMenu from '../BackendTopMenu/BackendTopMenu';
import {
    getTabInfo
} from './../../BaseModels/MasterData';
import { useContainerDimensions } from './../../BaseModels/ResizeWindow';
import st from './TableauAlertOne.module.css';

const { tableau } = window;

const TableauAlertOne = () => {
    const { width, height } = useContainerDimensions()
    const UserDetail = useContext(UDContext);
    const navigate = useNavigate();
    const [AllDashboardData, setAllDashboardData] = useState([]);


    const refDashboard = useRef(null);
    const [viz, setViz] = useState(null);

    const [Workbook, setWorkbook] = useState(null);
    const [FilterList, setFilterList] = useState([]);
    const [SelectedMarksList, setSelectedMarksList] = useState([]);
    const [TableData, setTableData] = useState([]);


    useEffect(() => {
        if (UserDetail !== null) {
            getDashboardData();
        }
    }, [UserDetail]);

    const getDashboardData = async () => {
        try {
            getTabInfo().then(res => {
                if (res.status) {
                    let AllNavigationData = res.data;
                    let _AllDashboardData = [];
                    AllNavigationData.forEach(pages => {
                        pages.section.forEach(sections => {
                            sections.report.forEach(reports => {
                                let obj = {};
                                obj['page_name'] = pages.page_name;
                                obj['section_name'] = sections.section_name;
                                obj['value'] = reports.report_id;
                                obj['label'] = reports.title;
                                obj = { ...obj, ...reports };
                                _AllDashboardData.push(obj);
                            });
                        });
                    });
                    setAllDashboardData(_AllDashboardData);
                } else {
                    toast.error(res.message);
                }
            });
        } catch (error) {
            toast.error("Something went wrong");
        }
    }

    const ddlReport_onChange = (selectedOption) => {
        BindTableauDashboard(selectedOption.url);
        setFilterList([]);
        setSelectedMarksList([]);
    }

    const BindTableauDashboard = (url) => {
        try {
            let options = {
                width: '100%',
                height: '100vh'
            };
            options.hideTabs = false;
            options.hideToolbar = false;
            options.device = width > 992 ? "desktop" : "phone";
            options.onFirstInteractive = async function () {
                setWorkbook(newviz.getWorkbook())
            };
            if (viz) {
                viz.dispose();
            }
            let newviz = new tableau.Viz(refDashboard.current, url, options);
            setViz(newviz);
        } catch (error) {
            toast("Error: Loading Tableau Dashboard", { type: 'error', autoClose: 1000 });
        }
    }




    const formatOptionLabel = ({ value, label, page_name, section_name }) => (
        <div className={st.reportOptionRootContainer}>
            <div className={st.reportOptionLabel}>{label}</div>
            <div className={st.reportPageSectionContainer}>
                <div className={st.reportPageSectionItem}>{page_name}</div>
                <div className={st.reportPageSectionItem}><NavigateNextIcon /></div>
                <div className={st.reportPageSectionItem}>{section_name}</div>
            </div>
        </div>
    );

    return (
        <>
            <BackendTopMenu />
            <div className='mainContainer'>
                <div className='row mt-2 mb-2'>
                    <div className='col-lg-6'>
                        <Breadcrumbs aria-label='breadcrumb' separator={<NavigateNextIcon fontSize='small' />}>
                            <Link className={st.navigate_item} to={'/backend'}>Admin Home</Link>
                            <Link className={st.navigate_item} to={'/backend/alert'}>Alert</Link>
                        </Breadcrumbs>
                    </div>
                </div>
                <div className={`row`}>
                    <div className={`${st.page_title} col-12`}>Alert</div>
                </div>
                <div className='row mt-3 mb-1'>
                    <div>
                        <strong>Select Report: </strong>
                        <Select
                            defaultValue={[]}
                            placeholder={<div>Select Report</div>}
                            formatOptionLabel={formatOptionLabel}
                            options={AllDashboardData}
                            onChange={ddlReport_onChange}
                            className={st.selectReport}
                        />
                    </div>
                    {
                        AllDashboardData.length === 0 && <>
                            Loading...
                        </>
                    }
                    <br />
                    <br />
                    <br />
                    <div>
                        <div style={{ height: "100%", width: '100%' }}>
                            <div ref={refDashboard} style={{ height: '100%', width: '100%' }}></div>
                        </div>
                    </div>


                </div>

                <br />

            </div>
        </>
    );
};

export default TableauAlertOne;