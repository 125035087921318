import parse from 'html-react-parser';
import React, { useState } from 'react';
import ImageLogo from './../../../../images/ImageLogo.svg';
import st from './SummaryDetails2.module.css';



const Image = ({ src, alt, ...props }) => {
    const [loaded, setLoaded] = useState(false);
    return (
        <>
            <img src={src} alt={alt} {...props}
                style={loaded ? { ...props.style } : { ...props.style, display: 'none' }}
                onLoad={() => { setLoaded(true); }} />
            {loaded ? null : <img src={ImageLogo} alt={alt} {...props} />}
        </>

    );
};

const SummaryDetails2 = ({ description_information }) => {


    return (
        <React.Fragment>
            <div className={st.landingPageHeaderbg} style={{backgroundColor:description_information?.background_color}}>
                <div className="mainContainer">
                    <div className="row">
                        <div className="col-sm-12 col-lg-6">
                            <div>
                                <div className={st.titleContainer}>
                                    <span className={st.headerWelComeText}>
                                        Welcome to
                                    </span>&nbsp;
                                    <span className={st.headerWelComeNameText}>
                                        {description_information?.description_title}
                                    </span>
                                </div>
                                <p className={st.textSummery}>
                                    {
                                        parse('<span>' + description_information?.description + '</span>')
                                    }
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-6">
                            <div className={st.headerImageContainer}>
                                <Image src={description_information?.background_image}
                                    alt={"playVideoImage"} className={`${st.headerImage}`} />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment>
    );
};

export default SummaryDetails2;
