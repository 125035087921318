import React from 'react';

const GNP_Demand_Dashboard = (props) => {




    return (
        <>
            <h1>
                GNP_Demand_Dashboard
            </h1>
        </>
    );
};

export default GNP_Demand_Dashboard;