import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UDContext } from '../../../App';
import { getFavorite } from '../../BaseModels/MasterData';
import st from './FavoriteDashboard.module.css';
import FavoriteDashboard1 from './FavoriteDashboard1/FavoriteDashboard1';
import FavoriteDashboard2 from './FavoriteDashboard2/FavoriteDashboard2';



const FavoriteDashboard = ({ div_id, selected_design_id }) => {
    const UserDetail = useContext(UDContext);
    const [data, setData] = useState([]);
    const [IsVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (UserDetail !== null) {
            getCaroselData();
        }
    }, [UserDetail]);



    const getCaroselData = async () => {
        try {
            getFavorite().then((response) => {
                if (response.status) {
                    setData(response.data);
                    setIsVisible(true);
                } else {
                    toast.error(response.message);
                }
            });
        } catch (error) {
            toast.error("Something went wrong");
        }
    }


    return (
        IsVisible && <>
            {
                data.length === 0 ? <></> :
                    <>
                        <br />
                        <div className="mainContainer">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className={st.favouriteWidgetsTitle}>your favorite dashboard</div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-lg-12">
                                    {selected_design_id === 1 ? <FavoriteDashboard1 key="1" user_fav_report={data} /> : <></>}
                                    {selected_design_id === 2 ? <FavoriteDashboard2 key="2" user_fav_report={data} /> : <></>}
                                </div>
                            </div>
                        </div>
                    </>
            }
        </>
    );
};

export default FavoriteDashboard;