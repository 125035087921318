import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { FileUploader } from "react-drag-drop-files";
import { FaRegSave } from "react-icons/fa";
import { IoIosCloseCircle } from "react-icons/io";
import { MdCancel, MdOndemandVideo } from "react-icons/md";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BaseUrlAPI, ImageUpload, VideoUpload, getTabInfo, postReportInformationInsert, postReportUpdate } from '../../../BaseModels/MasterData';
import DetailShower from '../../../CustomElements/DetailShower/DetailShower';
import ModalPopup from '../../../CustomElements/ModalPopup/ModalPopup';
import BackendTopMenu from '../../BackendTopMenu/BackendTopMenu';
import { UDContext } from './../../../../App';
import ImageLogo from './../../../../images/ImageLogo.svg';
import playVideoImage from './../../../../images/playVideoImage.png';
import st from './SaveDashboard.module.css';

const { tableau } = window;

const Image = ({ src, alt, ...props }) => {
    const [loaded, setLoaded] = useState(false);
    return (
        <>
            <img src={src} alt={alt} {...props}
                style={loaded ? {} : { display: 'none' }}
                onLoad={() => { setLoaded(true); }} />
            {loaded ? null : <img src={ImageLogo} alt={alt} {...props} />}
        </>
    );
};

const toolbarOptions = {
    toolbar: {
        container: [
            [{ color: [] }, { background: [] }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
            ["link", "image", "video"],
            ["clean"],
            [{ list: "ordered" }, { list: "bullet" }],
            ["bold", "italic", "underline"],
        ],
    }
}


function SaveDashboard() {
    const UserDetail = useContext(UDContext);
    const { dashboardid } = useParams();
    const navigate = useNavigate();
    const [viz, setViz] = useState(null);
    const refDashboard = useRef(null);

    const [IsSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
    const [IsSaveButtonClick, setIsSaveButtonClick] = useState(false);
    const [AllDashboardData, setAllDashboardData] = useState([]);
    const [AllNavigationData, setAllNavigationData] = useState([]);
    const [DashboardData, setDashboardData] = useState({
        "report_id": 0,
        "title": "",
        "page_id": "",
        "section_id": "",
        "slug": "",
        "description": "",
        "url": "",
        "shared_url": "",
        "priority_feature": true,
        "report_height": "800",
        "report_type": "Tableau",
        "top_tool_bar": true,
        "bottom_tool_bar_visible": false,
        "share_tableau_viz_visible": true,
        "custom_views_visible": true,
        "download_visible": true,
        "download_csv_visible": true,
        "download_image_visible": true,
        "download_pdf_visible": true,
        "download_excel_visible": false,
        "download_ppt_visible": false,
        "report_builder_visible": true,
        "seq": 0,
        "report_image": "",
        "report_video": "",
        "tutorial_text": ""
    });
    const [TutorialText, setTutorialText] = useState("");
    const [PageName, setPageName] = useState([]);
    const [SectionName, setSectionName] = useState([]);
    const [DashboardImage, setDashboardImage] = useState(null);
    const [DashboardVideo, setDashboardVideo] = useState(null);
    const [DashboardVideoIsLoading, setDashboardVideoIsLoading] = useState(false);
    const [IsVideoPlayVisible, setIsVideoPlayVisible] = useState(false);
    const [IsTutorialVideo, setIsTutorialVideo] = useState(true);
    const [IsOtherActionVisible, setIsOtherActionVisible] = useState(false);


    useEffect(() => {
        if (UserDetail !== null) {
            if (dashboardid !== undefined) {
                getDashboardData(dashboardid);
            }
        }
    }, [UserDetail, dashboardid]);

    const getDashboardData = async (dashboardid) => {
        try {
            getTabInfo().then(res => {
                if (res.status) {
                    let AllNavigationData = res.data;
                    setAllNavigationData(AllNavigationData);
                    let _AllDashboardData = [];
                    let _PageName = [];
                    AllNavigationData.forEach(pages => {
                        if (pages.page_name !== "Home") {
                            let obj = {};
                            obj.page_id = pages.page_id;
                            obj.page_name = pages.page_name;
                            _PageName.push(obj);
                        }
                    });
                    setPageName(_PageName);

                    AllNavigationData.forEach(pages => {
                        pages.section.forEach(sections => {
                            sections.report.forEach(reports => {
                                _AllDashboardData.push(reports)
                                if (String(reports.report_id) === String(dashboardid)) {
                                    setDashboardData(reports);
                                }
                            });
                        });
                    });
                    setAllDashboardData(_AllDashboardData);
                } else {
                    toast.error(res.message);
                }
            });
        } catch (error) {
            toast.error("Error: Getting Dashboard Data", { type: 'error', autoClose: 1000 });
        }
    }

    useEffect(() => {
        if (AllNavigationData !== undefined) {
            let _SectionName = [];
            AllNavigationData.forEach(pages => {
                if (String(pages.page_id) === String(DashboardData.page_id)) {
                    pages.section.forEach(sections => {
                        let obj = {};
                        obj.section_id = sections.section_id;
                        obj.section_name = sections.section_name;
                        _SectionName.push(obj);
                    });
                }
            });
            setSectionName(_SectionName);
        }
    }, [AllNavigationData, DashboardData.page_id]);




    useEffect(() => {
        if (dashboardid !== undefined && DashboardData !== undefined) {
            let _DashboardData = { ...DashboardData };
            if (_DashboardData.report_type === "Tableau" && _DashboardData.url !== "" && _DashboardData.url !== "") {
                BindTableauDashboard(_DashboardData.url)
            }
            if (_DashboardData.report_video !== "") {
                setIsTutorialVideo(true);
            } else if (_DashboardData.tutorial_text !== "") {
                setIsTutorialVideo(false);
                setTutorialText(_DashboardData.tutorial_text);
            }
        }
    }, [DashboardData, dashboardid]);




    useEffect(() => {
        if (DashboardData !== undefined) {
            let _DashboardData = { ...DashboardData };
            let DownloadTrueCount = 0;
            if (_DashboardData.download_image_visible) {
                DownloadTrueCount++;
            }
            if (_DashboardData.download_csv_visible) {
                DownloadTrueCount++;
            }
            if (_DashboardData.download_pdf_visible) {
                DownloadTrueCount++;
            }
            if (_DashboardData.download_excel_visible) {
                DownloadTrueCount++;
            }
            if (_DashboardData.download_ppt_visible) {
                DownloadTrueCount++;
            }
            if (DownloadTrueCount > 0) {
                _DashboardData.download_visible = true;
                setDashboardData(_DashboardData);
            } else {
                _DashboardData.download_visible = false;
                setDashboardData(_DashboardData);
            }
        }
    }, [DashboardData?.download_image_visible,
    DashboardData?.download_csv_visible,
    DashboardData?.download_pdf_visible,
    DashboardData?.download_excel_visible,
    DashboardData?.download_ppt_visible]);

    useEffect(() => {
        if (DashboardData !== undefined) {
            let _DashboardData = { ...DashboardData };
            let OtherActionTrueCnt = 0;
            if (_DashboardData.top_tool_bar) {
                OtherActionTrueCnt++;
            }
            if (_DashboardData.bottom_tool_bar_visible) {
                OtherActionTrueCnt++;
            }
            if (_DashboardData.share_tableau_viz_visible) {
                OtherActionTrueCnt++;
            }
            if (_DashboardData.custom_views_visible) {
                OtherActionTrueCnt++;
            }
            if (_DashboardData.report_builder_visible) {
                OtherActionTrueCnt++;
            }
            if (OtherActionTrueCnt > 0) {
                setIsOtherActionVisible(true);
            } else {
                setIsOtherActionVisible(false);
            }
        }
    }, [DashboardData?.top_tool_bar,
    DashboardData?.bottom_tool_bar_visible,
    DashboardData?.share_tableau_viz_visible,
    DashboardData?.custom_views_visible,
    DashboardData?.report_builder_visible]);


    useEffect(() => {
        let _DashboardData = { ...DashboardData };
        if (IsTutorialVideo) {
            _DashboardData.tutorial_text = "";
            setTutorialText("");
        } else {
            _DashboardData.report_video = "";
            setDashboardVideo(null);
        }
        setDashboardData(_DashboardData);
    }, [IsTutorialVideo]);




    const BindTableauDashboard = (url) => {
        try {
            const options = {
                hideTabs: true,
                width: "100%",
                height: "100%",
                hideToolbar: true,
                onFirstInteractive: async function () {

                }
            };
            if (viz) {
                viz.dispose();
            }
            let newviz = new tableau.Viz(refDashboard.current, url, options);
            setViz(newviz);
        } catch (error) {
            toast("Error: Loading Tableau Dashboard", { type: 'error', autoClose: 1000 });
        }
    }


    const DashboardImage_onChange = async (file) => {
        try {
            if (file) {
                if (file.size > 1024 && file.size < 1024 * 1024) {//1kb to 1mb
                    setDashboardImage(file);
                    ImageUpload(file).then(res => {
                        if (res.status) {
                            let _DashboardData = { ...DashboardData };
                            _DashboardData.report_image = res.data;
                            setDashboardData(_DashboardData);
                        } else {
                            toast.error(res.message);
                        }
                    });
                } else {
                    toast.error("Image size should be between 1kb to 1mb");
                }
            }
        } catch (error) {
            toast.error("Something went wrong");
        }
    };
    const DashboardVideo_onChange = async (file) => {
        try {
            if (file) {
                if (file.size > 1024 && file.size < (1024 * 1024) * 50) {//1kb to 50mb
                    setDashboardVideoIsLoading(true);
                    setDashboardVideo(file);
                    VideoUpload(file).then(res => {
                        if (res.status) {
                            let _DashboardData = { ...DashboardData };
                            _DashboardData.report_video = res.data;
                            setDashboardData(_DashboardData);
                        } else {
                            toast.error(res.message);
                        }
                        setDashboardVideoIsLoading(false);
                    });
                } else {
                    toast.error("Video size should be between 1kb to 50mb");
                }
            }
        } catch (error) {
            toast("Error: Uploading Video", { type: 'error', autoClose: 1000 });
        }
    }
    const btnPlayVideo_onClick = () => {
        let _DashboardData = { ...DashboardData };
        if (_DashboardData.report_video !== "") {
            setIsVideoPlayVisible(true);
        }
    }
    const IsOtherActionVisible_onChange = (e) => {
        let _IsOtherActionVisible = e.target.checked;
        if (!_IsOtherActionVisible) {
            let _DashboardData = { ...DashboardData };
            _DashboardData.top_tool_bar = false;
            _DashboardData.bottom_tool_bar_visible = false;
            _DashboardData.share_tableau_viz_visible = false;
            _DashboardData.custom_views_visible = false;
            _DashboardData.report_builder_visible = false;
            setDashboardData(_DashboardData);
        }
        setIsOtherActionVisible(e.target.checked);
    };

    useEffect(() => {
        if (TutorialText !== "") {
            let _commentText = TutorialText.replace(/<[^>]+>/g, '');
            if (_commentText !== '') {
                let _DashboardData = { ...DashboardData };
                _DashboardData.tutorial_text = TutorialText;
                setDashboardData(_DashboardData);
            }
        }
    }, [TutorialText]);



    const control_onChange = (e) => {
        if (e.target.name === "page_id") {
            let _SectionName = [];
            AllNavigationData.forEach(pages => {
                if (String(pages.page_id) === String(e.target.value)) {
                    pages.section.forEach(sections => {
                        let obj = {};
                        obj.section_id = sections.section_id;
                        obj.section_name = sections.section_name;
                        _SectionName.push(obj);
                    });
                }
            });
            setSectionName(_SectionName);
        }
        if (e.target.name === "slug" && String(dashboardid) === "0") {
            let _AllDashboardData = [...AllDashboardData];
            let given_slug = e.target.value;
            let isExist = _AllDashboardData.filter(item => (item.slug).toLowerCase() === given_slug.toLowerCase());
            if (isExist.length > 0) {
                toast("Error: Slug already exist", { type: 'error', autoClose: 1000 });
            }
        }
        if (e.target.name === "slug" && String(dashboardid) !== "0") {
            let _AllDashboardData = [...AllDashboardData];
            let given_slug = e.target.value;
            let isExist = _AllDashboardData.filter(item => (item.slug).toLowerCase() === given_slug.toLowerCase() && String(item.report_id) !== String(dashboardid));
            if (isExist.length > 0) {
                toast("Error: Slug already exist", { type: 'error', autoClose: 1000 });
            }
        }
        setDashboardData({ ...DashboardData, [e.target.name]: e.target.value });
        if (e.target.name === "report_type") {
            if (e.target.value !== "Tableau") {
                let _DashboardData = { ...DashboardData };
                _DashboardData.custom_views_visible = false;
                _DashboardData.download_csv_visible = false;
                _DashboardData.download_pdf_visible = false;
                _DashboardData.download_excel_visible = false;
                _DashboardData.download_ppt_visible = false;
                _DashboardData[e.target.name] = e.target.value;
                setDashboardData(_DashboardData);
            } else {
                let _DashboardData = { ...DashboardData };
                _DashboardData.custom_views_visible = true;
                _DashboardData.download_csv_visible = true;
                _DashboardData.download_pdf_visible = true;
                _DashboardData.download_excel_visible = false;
                _DashboardData.download_ppt_visible = false;
                _DashboardData[e.target.name] = e.target.value;
                setDashboardData(_DashboardData);
            }
        }
        if (e.target.name === "title") {
            let _DashboardData = { ...DashboardData };
            _DashboardData[e.target.name] = e.target.value;
            _DashboardData.slug = e.target.value.trim().toLowerCase().replaceAll(" ", "-");
            setDashboardData(_DashboardData);
        }
    }

    const checkbox_onChange = (e) => {
        let _IsDownloadVisible = e.target.checked;
        let _DashboardData = { ...DashboardData };
        if (e.target.name === "download_visible") {
            if (!_IsDownloadVisible) {
                _DashboardData.download_image_visible = false;
                _DashboardData.download_csv_visible = false;
                _DashboardData.download_pdf_visible = false;
                _DashboardData.download_excel_visible = false;
                _DashboardData.download_ppt_visible = false;
                setDashboardData(_DashboardData);
            }
        }
        _DashboardData[e.target.name] = e.target.checked;
        setDashboardData(_DashboardData);
    }

    useEffect(() => {
        if (DashboardData !== undefined) {
            let _DashboardData = { ...DashboardData };
            let errorMsg = [];
            if (_DashboardData.report_id === "") {
                errorMsg.push("report_id is required");
            }
            if (_DashboardData.title === "") {
                errorMsg.push("title is required");
            }
            if (_DashboardData.page_id === "") {
                errorMsg.push("page_id is required");
            }
            if (_DashboardData.section_id === "") {
                errorMsg.push("section_id is required");
            }
            if (_DashboardData.slug === "") {
                errorMsg.push("slug is required");
            }
            if (_DashboardData.description === "") {
                errorMsg.push("description is required");
            }
            if (_DashboardData.url === "") {
                errorMsg.push("url is required");
            }
            if (_DashboardData.report_height === "") {
                errorMsg.push("report_height is required");
            }
            if (_DashboardData.report_type === "") {
                errorMsg.push("report_type is required");
            }
            if (_DashboardData.report_image === "") {
                errorMsg.push("report_image is required");
            }

            if (errorMsg.length > 0) {
                setIsSaveButtonDisabled(true);
            } else {
                setIsSaveButtonDisabled(false);
            }

        }
    }, [DashboardData]);

    const btnSaveDashboard_onClick = async (e) => {
        try {
            setIsSaveButtonClick(true);
            let _DashboardData = { ...DashboardData };
            let errorMsg = [];
            if (_DashboardData.report_id === "") {
                errorMsg.push("report_id is required");
            }
            if (_DashboardData.title === "") {
                errorMsg.push("title is required");
            }
            if (_DashboardData.page_id === "") {
                errorMsg.push("page_id is required");
            }
            if (_DashboardData.section_id === "") {
                errorMsg.push("section_id is required");
            }
            if (_DashboardData.slug === "") {
                errorMsg.push("slug is required");
            }
            if (_DashboardData.description === "") {
                errorMsg.push("description is required");
            }
            if (_DashboardData.url === "") {
                errorMsg.push("url is required");
            }
            if (_DashboardData.report_height === "") {
                errorMsg.push("report_height is required");
            }
            if (_DashboardData.report_type === "") {
                errorMsg.push("report_type is required");
            }
            if (_DashboardData.report_image === "") {
                errorMsg.push("report_image is required");
            }
            if (String(dashboardid) === "0") {
                let _AllDashboardData = [...AllDashboardData];
                let given_slug = _DashboardData.slug;
                let isExist = _AllDashboardData.filter(item => (item.slug).toLowerCase() === given_slug.toLowerCase());
                if (isExist.length > 0) {
                    errorMsg.push("Slug already exist");
                }
            }
            if (String(dashboardid) !== "0") {
                let _AllDashboardData = [...AllDashboardData];
                let given_slug = _DashboardData.slug;
                let isExist = _AllDashboardData.filter(item => (item.slug).toLowerCase() === given_slug.toLowerCase() && String(item.report_id) !== String(dashboardid));
                if (isExist.length > 0) {
                    toast("Error: Slug already exist", { type: 'error', autoClose: 1000 });
                }
            }
            let DownloadTrueCount = 0;
            if (_DashboardData.download_image_visible) {
                DownloadTrueCount++;
            }
            if (_DashboardData.download_csv_visible) {
                DownloadTrueCount++;
            }
            if (_DashboardData.download_pdf_visible) {
                DownloadTrueCount++;
            }
            if (_DashboardData.download_excel_visible) {
                DownloadTrueCount++;
            }
            if (_DashboardData.download_ppt_visible) {
                DownloadTrueCount++;
            }
            if (DownloadTrueCount > 0) {
                _DashboardData.download_visible = true;
            } else {
                _DashboardData.download_visible = false;
            }


            if (errorMsg.length > 0) {
                errorMsg.forEach(element => {
                    toast.error(element, { type: 'error', autoClose: 1000 });
                });
                setIsSaveButtonClick(false);
            } else {


                let obj = {
                    "title": _DashboardData.title,
                    "page_id": _DashboardData.page_id,
                    "section_id": _DashboardData.section_id,
                    "slug": _DashboardData.slug,
                    "description": _DashboardData.description,
                    "url": _DashboardData.url,
                    "shared_url": _DashboardData.url,
                    "priority_feature": true,
                    "report_height": _DashboardData.report_height,
                    "report_type": _DashboardData.report_type,
                    "top_tool_bar": _DashboardData.top_tool_bar,
                    "bottom_tool_bar_visible": _DashboardData.bottom_tool_bar_visible,
                    "share_tableau_viz_visible": _DashboardData.share_tableau_viz_visible,
                    "custom_views_visible": _DashboardData.custom_views_visible,
                    "download_visible": _DashboardData.download_visible,
                    "download_csv_visible": _DashboardData.download_csv_visible,
                    "download_image_visible": _DashboardData.download_image_visible,
                    "download_pdf_visible": _DashboardData.download_pdf_visible,
                    "download_excel_visible": _DashboardData.download_excel_visible,
                    "download_ppt_visible": _DashboardData.download_ppt_visible,
                    "report_builder_visible": _DashboardData.report_builder_visible,
                    "created_by": UserDetail?.user?.user_id,
                    "updated_by": UserDetail?.user?.user_id,
                    "seq": _DashboardData.seq,
                    "report_image": _DashboardData.report_image,
                    "report_video": IsTutorialVideo ? _DashboardData.report_video : "",
                    "tutorial_text": IsTutorialVideo ? "" : _DashboardData.tutorial_text
                }

                if (String(dashboardid) === "0") {
                    //insert
                    postReportInformationInsert(obj).then(res => {
                        if (res.status) {
                            toast.success(res.data);
                            setTimeout(() => {
                                navigate('/backend/dashboards');
                                setIsSaveButtonClick(false);
                            }, 2000);
                        } else {
                            toast.error("Error! Could not create dashboard!");
                            setIsSaveButtonClick(false);
                        }
                    });
                } else {
                    //update
                    postReportUpdate(dashboardid, obj).then(res => {
                        if (res.status) {
                            toast.success(res.data);
                            setTimeout(() => {
                                navigate('/backend/dashboards');
                                setIsSaveButtonClick(false);
                            }, 2000);
                        } else {
                            toast.error("Error! Could not update dashboard!");
                            setIsSaveButtonClick(false);
                        }
                    });
                }
            }
        } catch (error) {
            toast.error("Something went wrong");
            setIsSaveButtonClick(false);
        }



    }




    return (
        <>
            <BackendTopMenu />
            <div className="mainContainer">
                <div className="row mt-2 mb-2">
                    <div className="col-lg-6">
                        <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
                            <Link className={st.navigateItem} to={"/backend"}>Admin Home</Link>
                            <Link className={st.navigateItem} to={"/backend/dashboards"}>Reports</Link>
                            <Link className={st.navigateItem} to={"/backend/dashboards/" + dashboardid}>{String(dashboardid) === "0" ? "New" : dashboardid}</Link>
                        </Breadcrumbs>
                    </div>
                </div>
                <div className="row mt-2 mb-2">
                    <div className="col-lg-6">
                        <div className={st.pageTitle}>Report Detail</div>
                        <div className={st.pageTitleSummery}>Fill in the details</div>
                    </div>
                    <div className="col-lg-6">
                        <div className={st.topRightButtonContainer} style={AllNavigationData.length > 0 ? {} : { display: 'none' }}>
                            <Button variant="outlined" endIcon={<MdCancel />} onClick={() => navigate('/backend/dashboards')} size="small">
                                Cancel
                            </Button>
                            <Button variant="contained" endIcon={<FaRegSave />} disabled={IsSaveButtonDisabled || IsSaveButtonClick} onClick={btnSaveDashboard_onClick} size="small">
                                Save
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-4 col-sm-6">
                        <div className={st.formContainer}>
                            <div className={st.formLableContainer}>
                                <span className={st.lable}>Page Name </span>
                                {/* <AddNewPage /> */}
                                <span className={st.formRequied}>*</span>
                                <DetailShower>
                                    <h1>Page Name</h1>
                                </DetailShower>
                            </div>
                            <div className={st.formInputContainer}>
                                <select className={st.formInput} name="page_id"
                                    value={DashboardData.page_id} onChange={control_onChange}>
                                    <option selected disabled></option>
                                    {
                                        PageName.map((item, index) => {
                                            return (
                                                <option key={index} value={item.page_id}>{item.page_name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
                        <div className={st.formContainer}>
                            <div className={st.formLableContainer}>
                                <span className={st.lable}>Section Name </span>
                                {/* <AddNewSection /> */}
                                <span className={st.formRequied}>*</span>
                                <DetailShower>
                                    <h1>Page Title</h1>
                                </DetailShower>
                            </div>
                            <div className={st.formInputContainer}>
                                <select className={st.formInput} name="section_id"
                                    value={DashboardData.section_id} onChange={control_onChange}>
                                    <option selected disabled></option>
                                    {
                                        SectionName.map((item, index) => {
                                            return (
                                                <option key={index} value={item.section_id}>{item.section_name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
                        <div className={st.formContainer}>
                            <div className={st.formLableContainer}>
                                <span className={st.lable}>Page Title</span>
                                <span className={st.formRequied}>*</span>
                                <DetailShower>
                                    <h1>Page Title</h1>
                                </DetailShower>
                            </div>
                            <div className={st.formInputContainer}>
                                <input type="text" name='title' placeholder="Enter title Name" className={st.formInput}
                                    value={DashboardData.title} onChange={control_onChange} />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
                        <div className={st.formContainer}>
                            <div className={st.formLableContainer}>
                                <span className={st.lable}>Slug</span>
                                <span className={st.formRequied}>*</span>
                                <DetailShower>
                                    <h1>Slug</h1>
                                </DetailShower>
                            </div>
                            <div className={st.formInputContainer}>
                                <input type="text" name='slug' placeholder="Enter Slug Name" className={st.formInput}
                                    value={DashboardData.slug} onChange={control_onChange} />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6">
                        <div className={st.formContainer}>
                            <div className={st.formLableContainer}>
                                <span className={st.lable}>Page Type</span>
                                <span className={st.formRequied}>*</span>
                                <DetailShower>
                                    <h1>Page Type</h1>
                                </DetailShower>
                            </div>
                            <div className={st.formInputContainer}>
                                <select className={st.formInput} name="report_type" value={DashboardData.report_type} onChange={control_onChange}>
                                    <option value="" selected disabled></option>
                                    <option value="Tableau">Tableau</option>
                                    <option value="Embed_From_Other_Source">Embed From Other Source</option>
                                    {/* <option value="Embed_From_Current_Project">Embed From Current Project</option> */}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-6 col-sm-12">
                        <div className={st.formContainer}>
                            <div className={st.formLableContainer}>
                                <span className={st.lable}>Page URL</span>
                                <span className={st.formRequied}>*</span>
                                <DetailShower>
                                    <h1>Page URL</h1>
                                </DetailShower>
                            </div>
                            <div className={st.formInputContainer}>
                                <input type="text" name='url' placeholder="Enter URL" className={st.formInput}
                                    value={DashboardData.url} onChange={control_onChange} />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6">
                        <div className={st.formContainer}>
                            <div className={st.formLableContainer}>
                                <span className={st.lable}>Report Height</span>
                                <span className={st.formRequied}>*</span>
                                <DetailShower>
                                    <h1>Report Height</h1>
                                </DetailShower>
                            </div>
                            <div className={st.formInputContainer}>
                                <input type="number" name='report_height' placeholder="Enter height" className={st.formInput}
                                    value={DashboardData.report_height} onChange={control_onChange} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className={st.subTitle}>page description / overview</div>
                        <div className={st.subTitleSummery}>Max limit is 300 words</div>
                    </div>
                </div>
                <div className="row mt-1">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className={st.formContainer}>
                            <div className={st.formLableContainer}>
                                <span className={st.lable}>Description</span>
                                <span className={st.formRequied}>*</span>
                                <DetailShower>
                                    <h1>Description</h1>
                                </DetailShower>
                            </div>
                            <div className={st.formInputContainer}>
                                <textarea name='description' placeholder="Enter Description" className={st.formInputTextArea}
                                    value={DashboardData.description} onChange={control_onChange} />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className={st.formContainer}>
                            <div className={st.formLableContainer}>
                                <span className={st.lable}>Dashboard Image</span>
                                <span className={st.formRequied}>*</span>
                                <DetailShower>
                                    <h1>Dashboard Image</h1>
                                </DetailShower>
                            </div>
                            <div className={st.formInputContainer}>
                                <div className={st.formInputFileUploadContainer}>
                                    <div className={st.formInputFileUpload}>
                                        <FileUploader handleChange={DashboardImage_onChange} name="file"
                                            label="Upload or drop a file right here" types={["JPG", "JPEG", "PNG"]} />
                                    </div>
                                    <div className={st.uploadedImageContainer} style={
                                        DashboardData?.report_image === "" ? { display: "none" } : {}
                                    }>
                                        <Image src={BaseUrlAPI + "images" + DashboardData?.report_image + "?token=" + UserDetail?.user?.token}
                                            alt={DashboardData?.report_image} className={st.uploadedImg} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div>
                            <div className={st.subTitle}>Tutorial {IsTutorialVideo ? "Video" : "Text"}
                                <DetailShower>
                                    <h1>Tutorial</h1>
                                </DetailShower>
                                <Switch
                                    checked={IsTutorialVideo}
                                    disabled={DashboardVideoIsLoading}
                                    onChange={(e) => setIsTutorialVideo(e.target.checked)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </div>
                            <div>
                                <span className={st.subTitleSummery}>It will appear when the dashboard is open.</span>
                                <span className={st.subTitleSummeryNote}> Note:Tutorials should be either video or text.</span>
                            </div>
                        </div>
                        {
                            IsTutorialVideo ?
                                <div className={st.formInputContainer}>
                                    <div className={st.formInputFileUploadContainer}>
                                        {
                                            DashboardVideoIsLoading ? <CircularProgress /> :
                                                <>
                                                    <div className={st.formInputFileUpload}>
                                                        <FileUploader handleChange={DashboardVideo_onChange} name="file"
                                                            label="Upload or drop a file right here" types={["MP4"]} />
                                                    </div>
                                                    <div className={st.uploadedVideoContainer} style={DashboardData?.report_video === "" ? { display: "none" } : {}}>
                                                        <Tooltip title="Click to play video">
                                                            <IconButton onClick={btnPlayVideo_onClick}>
                                                                <MdOndemandVideo />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </div>
                                                    <ModalPopup IsVisible={IsVideoPlayVisible} onClose={() => setIsVideoPlayVisible(false)}>
                                                        <div className={st.uploadedVideoViewContainer}>
                                                            <div className={st.uploadedVideoViewHeaderContainer}>
                                                                <div>Tutorial Video</div>
                                                                <div>
                                                                    <IconButton onClick={() => setIsVideoPlayVisible(false)}>
                                                                        <IoIosCloseCircle />
                                                                    </IconButton>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <video className={st.uploadedVideo} poster={playVideoImage} controls>
                                                                    <source src={BaseUrlAPI + "videos" + DashboardData?.report_video + "?token=" + UserDetail?.user?.token} type="video/mp4" />
                                                                </video>
                                                            </div>
                                                        </div>
                                                    </ModalPopup>
                                                </>
                                        }
                                    </div>
                                </div>
                                :
                                <ReactQuill value={TutorialText} modules={toolbarOptions} onChange={setTutorialText}
                                    className={st.inputCommentData + " ReactQuill-DashboardComment bg-white"}></ReactQuill>
                        }
                    </div>
                </div>
                <div className="row mt-1">
                    <div className="col-lg-6">
                        <div>
                            <div className={st.subTitle}>Download Links
                                <DetailShower>
                                    <h1>Download Links</h1>
                                </DetailShower>
                                <Switch
                                    name="download_visible"
                                    checked={DashboardData.download_visible}
                                    onChange={checkbox_onChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />

                            </div>
                            <div className={st.subTitleSummery}>Checkbox the items below to add them to the download list</div>
                        </div>
                        {DashboardData.download_visible ? (<div className={st.checkboxOptionsBodyContainer}>
                            <div className={st.checkboxOptionContainer}>
                                <label className={st.checkboxOption}>
                                    <input type="checkbox" name="download_image_visible" checked={DashboardData.download_image_visible}
                                        onChange={checkbox_onChange} />
                                    <span>Image</span>
                                </label>
                            </div>
                            <div className={st.checkboxOptionContainer}>
                                <label className={st.checkboxOption}>
                                    <input type="checkbox" name="download_csv_visible" checked={DashboardData.download_csv_visible}
                                        onChange={checkbox_onChange} />
                                    <span>CSV</span>
                                </label>
                            </div>
                            <div className={st.checkboxOptionContainer}>
                                <label className={st.checkboxOption}>
                                    <input type="checkbox" name="download_pdf_visible" checked={DashboardData.download_pdf_visible}
                                        onChange={checkbox_onChange} />
                                    <span>PDF</span>
                                </label>
                            </div>
                            <div className={st.checkboxOptionContainer}>
                                <label className={st.checkboxOption}>
                                    <input type="checkbox" name="download_ppt_visible" checked={DashboardData.download_ppt_visible}
                                        onChange={checkbox_onChange} />
                                    <span>PPT</span>
                                </label>
                            </div>
                            <div className={st.checkboxOptionContainer}>
                                <label className={st.checkboxOption}>
                                    <input type="checkbox" name="download_excel_visible" checked={DashboardData.download_excel_visible}
                                        onChange={checkbox_onChange} />
                                    <span>Excel</span>
                                </label>
                            </div>
                        </div>) : (<></>)}

                    </div>
                    <div className="col-lg-6">
                        <div>
                            <div className={st.subTitle}>Other Action
                                <DetailShower>
                                    <h1>Other Action</h1>
                                </DetailShower>
                                <Switch
                                    checked={IsOtherActionVisible}
                                    onChange={IsOtherActionVisible_onChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </div>
                            <div className={st.subTitleSummery}>Checkbox the items below to add them to the download list</div>
                        </div>
                        {IsOtherActionVisible ? (<div className={st.checkboxOptionsBodyContainer}>
                            <div className={st.checkboxOptionContainer}>
                                <label className={st.checkboxOption}>
                                    <input type="checkbox" name="top_tool_bar" checked={DashboardData.top_tool_bar}
                                        onChange={checkbox_onChange} />
                                    <span>Visible Tableau Tab/Hide E2EViz Tab</span>
                                </label>
                            </div>
                            <div className={st.checkboxOptionContainer}>
                                <label className={st.checkboxOption}>
                                    <input type="checkbox" name="bottom_tool_bar_visible" checked={DashboardData.bottom_tool_bar_visible}
                                        onChange={checkbox_onChange} />
                                    <span>Visible Tableau Tool Bar</span>
                                </label>
                            </div>
                            <div className={st.checkboxOptionContainer}>
                                <label className={st.checkboxOption}>
                                    <input type="checkbox" name="share_tableau_viz_visible" checked={DashboardData.share_tableau_viz_visible}
                                        onChange={checkbox_onChange} />
                                    <span>Share Tableau</span>
                                </label>
                            </div>
                            <div className={st.checkboxOptionContainer}>
                                <label className={st.checkboxOption}>
                                    <input type="checkbox" name="custom_views_visible" checked={DashboardData.custom_views_visible}
                                        onChange={checkbox_onChange} />
                                    <span>Custom View</span>
                                </label>
                            </div>
                            <div className={st.checkboxOptionContainer}>
                                <label className={st.checkboxOption}>
                                    <input type="checkbox" name="report_builder_visible" checked={DashboardData.report_builder_visible}
                                        onChange={checkbox_onChange} />
                                    <span>Report Builder</span>
                                </label>
                            </div>
                        </div>) : (<></>)}

                    </div>
                </div>
                <div className="row mt-2 mb-2">
                    <div className="col-lg-6">

                    </div>
                    <div className="col-lg-6">
                        <div className={st.topRightButtonContainer} style={AllNavigationData.length > 0 ? {} : { display: 'none' }}>
                            <Button variant="outlined" endIcon={<MdCancel />} onClick={() => navigate('/backend/dashboards')} size="small">
                                Cancel
                            </Button>
                            <Button variant="contained" endIcon={<FaRegSave />} disabled={IsSaveButtonDisabled || IsSaveButtonClick} onClick={btnSaveDashboard_onClick} size="small">
                                Save
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-lg-12">
                        <div style={{ display: DashboardData.url.length > 0 ? 'block' : 'none' }} className={st.reportPreview}>
                            {
                                DashboardData.report_type === "Tableau" ? (<>
                                    <div style={{ height: DashboardData !== null ? DashboardData.report_height + 'px' : '800px', width: '100%' }}>
                                        <div ref={refDashboard} style={{ height: '100%', width: '100%' }}></div>
                                    </div>
                                </>) : DashboardData.report_type === "Embed_From_Other_Source" ? (<>
                                    <iframe src={DashboardData.url} style={{ height: DashboardData !== null ? DashboardData.report_height + 'px' : '800px', width: '100%' }}></iframe>
                                </>) : (<></>)
                            }
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
        </>
    );
}

export default SaveDashboard;
