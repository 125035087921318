import SearchIcon from '@mui/icons-material/Search';
import * as _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { IoIosCloseCircle } from "react-icons/io";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getDRT_ProjectList, getTabInfo, getUserAccessInfo } from '../../../BaseModels/MasterData';
import { UDContext } from './../../../../App';
import st from './AdminSearchBoxFullInput.module.css';


const AdminSearchBoxFullInput = () => {
    const UserDetail = useContext(UDContext);

    const [NavigationLinks, setNavigationLinks] = useState([]);
    const [FilteredData, setFilteredData] = useState([]);
    const [InputSearchText, setInputSearchText] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();


    useEffect(() => {
        if (UserDetail !== null) {
            getAllAdminData();
        }
    }, [UserDetail]);


    const getAllAdminData = async () => {
        try {
            let allNavigationLinks = [];
            getTabInfo().then(async res => {
                if (res.status) {
                    let allTabs = res.data;
                    allTabs.forEach(pages => {
                        pages.section.forEach(sections => {
                            sections.report.forEach(reports => {
                                let hashtags = ((reports?.title?.replace(/[^a-zA-Z0-9 ]/g, "")).match(/\b(\w)/g)).join('');
                                hashtags += reports.url;
                                hashtags += reports.slug;
                                allNavigationLinks.push({
                                    slug: "/backend/dashboards/" + reports.report_id,
                                    title: reports.title,
                                    page: 'Backend',
                                    section: 'Reports',
                                    hashtags: hashtags
                                });
                                allNavigationLinks.push({
                                    slug: "/backend/pageList/" + pages.page_id,
                                    title: pages.page_name,
                                    page: 'Backend',
                                    section: 'Page List',
                                    hashtags: pages.page_name + " " + pages.page_id + " " + pages.page_title
                                });
                                allNavigationLinks.push({
                                    slug: "/backend/pageList",
                                    title: sections.section_name,
                                    page: 'Backend',
                                    section: pages.page_name + ' Section',
                                    hashtags: sections.section_name
                                });
                            });
                        });
                    });
                    getUserAccessInfo().then(response => {
                        if (response.status) {
                            response.data.forEach(admininfo => {
                                let hashtags = admininfo.user_name;
                                let obj = {
                                    slug: "/backend/adminUser",
                                    title: admininfo.user_id,
                                    page: 'Backend',
                                    section: 'Admin Access',
                                    hashtags: hashtags
                                }
                                allNavigationLinks.push(obj);
                            });
                        }
                    });
                    getDRT_ProjectList().then(response => {
                        if (response.status) {
                            let _data = response.data;
                            _data.forEach(item => {
                                let hashtags = item.project_name;
                                let obj = {
                                    slug: "/backend/adminUser",
                                    title: item.project_name,
                                    page: 'Backend',
                                    section: 'Admin Access',
                                    hashtags: hashtags
                                }
                                let obj1 = {
                                    slug: "/backend/dashboards",
                                    title: item.project_name,
                                    page: 'Backend',
                                    section: 'Reports/Manage DRT Report List',
                                    hashtags: hashtags
                                }
                                allNavigationLinks.push(obj);
                                allNavigationLinks.push(obj1);
                            });
                        }
                    });


                    setNavigationLinks(allNavigationLinks);
                } else {
                    toast.error(res.message);
                }
            });
        } catch (error) {
            toast.error("Something went wrong");
        }
    }




    const inputSearchBox_onChange = (e) => {
        setInputSearchText(e.target.value);
    }

    useEffect(() => {
        if (NavigationLinks.length > 0) {
            if (InputSearchText !== '') {
                var toSearch = InputSearchText.toLowerCase();
                var results = [];
                var objects = NavigationLinks;
                objects.forEach(element => {
                    for (var key in element) {
                        if (element[key]) {
                            if (String(element[key]).toLowerCase().includes(toSearch)) {
                                results.push(element);
                            }
                        }
                    }
                });
                let _FilteredData = results.filter((obj, index) => {
                    return index === results.findIndex(o => obj.title === o.title);
                });
                setFilteredData(_FilteredData);
            } else {
                setInputSearchText('');
                setFilteredData([]);
            }
        } else {
            setInputSearchText('');
            setFilteredData([]);
        }
    }, [InputSearchText, NavigationLinks]);

    const btnsearchResultItem_onClick = (slug) => {
        navigate(slug);
        setInputSearchText('');
        setFilteredData([]);
    }

    return (
        <>
            <div className={st.searchBoxInputRootContainer} onMouseLeave={() => setInputSearchText("")}>
                <div className={st.searchBoxInputContainer}>
                    <SearchIcon fontSize="inherit" className={st.inputSearchBoxIcon} />
                    <input type="text" placeholder="Search" value={InputSearchText}
                        onChange={(e) => { inputSearchBox_onChange(e) }}
                        className={st.inputSearchBox} />
                    {
                        InputSearchText !== '' ? <IoIosCloseCircle onClick={() => setInputSearchText("")} /> : null
                    }
                </div>
                {
                    InputSearchText !== '' ? (<div className={st.searchResultRootContainer}>
                        <div className={st.searchResultWrapper}>
                            {
                                FilteredData.length > 0 ?
                                    FilteredData.map((data, index) => (
                                        <div key={index} className={st.searchResultItem} onClick={() => { btnsearchResultItem_onClick(data.slug) }}>
                                            <div className={st.searchResultItemTitle}>{data.title}</div>
                                            <div className={st.searchResultItemDetail}>
                                                <div className={st.searchResultItemDetailText}>{data.page + " > " + data.section}</div>
                                            </div>
                                        </div>
                                    ))
                                    : InputSearchText !== '' ?
                                        (<div className={st.searchResultItem}>
                                            <div className={st.searchResultItemTitle}>No Result Found</div>
                                        </div>) : null
                            }
                        </div>
                    </div>) : (<></>)
                }

            </div>
        </>
    );
};

export default AdminSearchBoxFullInput;
