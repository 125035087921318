import React, { useEffect, useState } from 'react';
import { Box, Container, Typography } from "@mui/material"
import { useLocation, useNavigate, useParams } from "react-router-dom";
import st from './Authentication.module.css';
const Authentication = () => {
    const { slug } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [timeLeft, setTimeLeft] = useState(10);


    // useEffect(() => {
    //     const login_count = localStorage.getItem('login_count');
    //     if (!login_count){
    //         localStorage.setItem('login_count', 1);
    //     }else{
    //         localStorage.setItem('login_count', parseInt(login_count) + 1);
    //     }
    // }, []);






    return (
        // <div className={st.pageNotFoundRootContainer}>
        //     <div className={st.pageNotFoundContainer}>
        //         <span className={st.pageNotFoundTitle}>You don't have access</span>
        //         <br />
        //         <span className={st.pageNotFoundDetail}>For questions concerning this site or to submit new requests, please raise a service request to this <strong> ENTBI-EDL-APP-SUP</strong> resolver group</span>

        //     </div>

        // </div>
        <Box>
            <Container maxWidth='md'>
                <Box mt={16}>
                    <Typography variant='h4' component='h1' gutterBottom sx={{ fontSize: 'clamp(1.6rem, 5vw, 2.8rem)', fontWeight: 'bold', color: 'skyblue' }}>
                        You are not authorised to access this page
                    </Typography>
                    <br />
                    <Typography variant='body1' gutterBottom sx={{ fontSize: 'clamp(0.7rem, 3vw, 1.5rem)', fontWeight: 'bold', color: 'gray' }}>
                        Please ensure that you have the necessary permissions.
                        <br />
                        For questions concerning this site or to submit new requests, please raise a service request to this ENTBI-EDL-APP-SUP resolver group
                        <br />
                        Contact your administration for assistance.
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
};

export default Authentication;
