import React, { useEffect, useState, useContext } from 'react';
import TopMenu from '../../TopMenu/TopMenu';
import { BsThreeDotsVertical } from "react-icons/bs";
import st from './AIDashboard.module.css';
import { UDContext } from '../../../../App';
import DashboardDetail from './DashboardDetail/DashboardDetail';
import { getAIDashboard } from './../../../BaseModels/MasterData';

const AIDashboard = () => {
    const UserDetail = useContext(UDContext);
    const [AIDashboardList, setAIDashboardList] = useState([]);

    const user_id = UserDetail?.user?.user_id;


    useEffect(() => {
        if (user_id) {
            BindAIDashboard(user_id)
        }
    }, [user_id])

    const BindAIDashboard = async (_user_id) => {
        let _AIDashboardList = [...AIDashboardList];
        let response = await getAIDashboard(_user_id);
        if (response.status) {
            if (response.data.length > 0) {
                response?.data?.forEach(element => {
                    let obj = element;
                    obj["columns"] = JSON.parse(element.columns_names)
                    obj["yaxis"] = JSON.parse(element.yaxis)
                    obj["DataRows"] = [];
                    _AIDashboardList.push(obj)
                });
                setAIDashboardList(_AIDashboardList);
            }
        }
    }


    return (
        <>
            <TopMenu />
            <div className="mainContainer">
                <br />
                <div className={'row'}>
                    {
                        AIDashboardList.map((item, index) => {
                            return (
                                <div key={index} className={`${'col-sm-12 col-lg-6 p-2'}`}>
                                    <DashboardDetail detail={item} />
                                </div>
                            )
                        })
                    }
                </div>

            </div>
        </>
    );
};

export default AIDashboard;