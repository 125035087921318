import React, { useContext, useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { UDContext } from '../../../../App';
import { BaseUrlAPI } from '../../../BaseModels/MasterData';
import ImageLogo from './../../../../images/ImageLogo.svg';
import st from './Carousel1.module.css';
const Image = ({ src, alt, ...props }) => {
  const [loaded, setLoaded] = useState(false);
  return (
      <>
          <img src={src} alt={alt} {...props}
              style={loaded ? { ...props.style } : { ...props.style, display: 'none' }}
              onLoad={() => { setLoaded(true); }} />
          {loaded ? null : <img src={ImageLogo} alt={alt} {...props} />}
      </>

  );
};
const Carousel1 = ({ carosel_information }) => {
  const UserDetail = useContext(UDContext);
  return (
    <>
      <div className="mainContainer">
        <div className="row">
          <div className="col-lg-12">
            <Carousel additionalTransfrom={0}
              arrows={false}
              autoPlay
              autoPlaySpeed={3000}
              centerMode={false}
              className=""
              containerClass="container-with-dots"
              dotListClass=""
              draggable
              focusOnSelect={false}
              infinite
              itemClass=""
              keyBoardControl
              minimumTouchDrag={80}
              pauseOnHover
              renderArrowsWhenDisabled={false}
              renderButtonGroupOutside={false}
              renderDotsOutside={false}
              responsive={{
                desktop: {
                  breakpoint: { max: 3000, min: 1024 },
                  items: 1,
                  partialVisibilityGutter: 40 // this is needed to tell the amount of px that should be visible.
                },
                tablet: {
                  breakpoint: { max: 1024, min: 464 },
                  items: 1,
                  partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
                },
                mobile: {
                  breakpoint: { max: 464, min: 0 },
                  items: 1,
                  partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
                }
              }}
              rewind={false}
              rewindWithAnimation={false}
              rtl={false}
              shouldResetAutoplay
              showDots={true}
              sliderClass=""
              slidesToSlide={1}
              swipeable>
              {
                carosel_information.map((item, index) => {
                  return (
                    <div key={index} className={st.ImageContainer}>
                      <img
                        src={BaseUrlAPI + "images" + item?.image_link + "?token=" + UserDetail?.user?.token}
                        alt={item.carosel_id} className={st.carouselImage}
                        style={{height:item?.horizontal_padding+"px"}} />
                    </div>
                  );
                })
              }
            </Carousel>
          </div>
        </div>
      </div>
    </>
  );
};

export default Carousel1;
