import Avatar from '@mui/material/Avatar';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UDContext } from './../../App';
import { getTabInfo } from './../BaseModels/MasterData';
import { useContainerDimensions } from './../BaseModels/ResizeWindow';
import st from './Backend.module.css';
import BackendTopMenu from './BackendTopMenu/BackendTopMenu';

function Backend() {
    const { width, height } = useContainerDimensions()
    const UserDetail = useContext(UDContext);
    const navigate = useNavigate();
    const [UserDetailData, setUserDetailData] = useState({});
    const [AllNavigationData, setAllNavigationData] = useState([]);

    useEffect(() => {
        if (UserDetail !== null) {
            setUserDetailData(UserDetail.user);
            if (!UserDetail?.user?.admin) {
                navigate("/404");
            } else {
                getAllNavigationTabs();
            }
        }
    }, [UserDetail]);

    const getAllNavigationTabs = async () => {
        try {
            getTabInfo().then(res => {
                if (res.status) {
                    let _AllNavigationData = res.data;
                    _AllNavigationData = _AllNavigationData.filter(x => x.page_name !== "Home");
                    setAllNavigationData(_AllNavigationData);
                } else {
                    toast.error(res.message);
                }
            });
        } catch (error) {
            toast.error("Something went wrong");
        }
    }

    function imageToBase64(report, url, callback) {
        fetch(url)
            .then((response) => response.blob())
            .then((blob) => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                    const base64String = reader.result;
                    report.report_image = base64String;
                    callback(report);
                };
            });
    }



    return (
        <div>
            <BackendTopMenu />
            <br />
            <div className="mainContainer">
                <div className={`${st.wellcomeTextContainer} ${'scale-up-hor-left'}`}>
                    <span>
                        Welcome
                        <span className={st.userName}>{UserDetail?.user?.given_name}</span>
                        to E2EViz Admin Console
                    </span>
                </div>
                <br />
                <div className={st.title}>Report Summary</div>
                <div className={st.pagesContainer}>
                    {
                        AllNavigationData.map((data, index) => {
                            return (
                                <div key={index} className={`${st.pageContainer} ${'scale-up-hor-left'}`}>
                                    <div className={st.pageTitleContainer}>
                                        <span className={st.pageTitle}>{data.page_name}</span>
                                    </div>
                                    <div className={st.pageDetailContainer}>
                                        <div className={st.pageDetailsHeader}>
                                            <div>Sections</div>
                                            <div>Reports </div>
                                        </div>
                                        {
                                            data.section.map((section, index) => {
                                                return (
                                                    <div key={index} className={st.sectionContainer}>
                                                        <div>{section.section_name}</div>
                                                        <Avatar sx={{ width: 20, height: 20, bgcolor: '#cccccc', color: '#01234a', fontSize: '12px' }}>
                                                            {section.report.length}
                                                        </Avatar>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    );
}

export default Backend;
