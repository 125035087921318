import React, { useState, useMemo, useEffect } from 'react';
import st from './AIAssistantFeedback.module.css';
import BackendTopMenu from '../BackendTopMenu/BackendTopMenu';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link, useNavigate } from "react-router-dom";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import ModalPopup from '../../CustomElements/ModalPopup/ModalPopup';
import { IoMdCloseCircle } from "react-icons/io";
import parse from 'html-react-parser';
import { getHistoryAdmin } from './../../BaseModels/MasterData'
import { toast } from 'react-toastify';

const AIAssistantFeedback = () => {
    const [rowData, setRowData] = useState();
    const [IsDetailVisible, setIsDetailVisible] = useState(false);
    const [DetailData, setDetailData] = useState(null);


    // Column Definitions: Defines & controls grid columns.
    const [colDefs, setColDefs] = useState([
        { field: 'msg_id', headerName: 'ID', sortable: true, filter: true, width: 80, },
        {
            field: 'ai_type', headerName: 'Type', sortable: true, filter: true, width: 110,
            cellStyle: params => {
                if (params.value === "Business") {
                    return { color: '#4c62d9' };
                } else if (params.value === "TalkToData") {
                    return { color: '#f1a824' };
                } else {
                    return { color: 'black' };
                }
            }
        },
        { field: 'message', headerName: 'Message', sortable: true, filter: true, width: 300, },
        {
            field: 'feedback_flag', headerName: 'Feedback Type', sortable: true, filter: true, width: 150,
            cellStyle: params => {
                if (params.value === 'Dislike') {
                    return { color: 'red' };
                } else if (params.value === 'Like') {
                    return { color: 'green' };
                } else {
                    return { color: 'blue' };
                }
            }
        },
        { field: 'feedback_msg', headerName: 'Feedback', sortable: true, filter: true, width: 130, },
        { field: 'ai_response', headerName: 'AI Response', sortable: true, filter: true, width: 400, },
        { field: 'created_by', headerName: 'User Id', sortable: true, filter: true, width: 110, },
        { field: 'created_date', headerName: 'Date', sortable: true, filter: true, width: 110, },
        {
            headerName: 'Action', field: 'rawData',
            cellRenderer:
                function (params) {
                    return (
                        <>
                            <strong className={st.showDetail} onClick={() => btnShowDetail_onClick(params.value)}>Show</strong>
                        </>
                    )
                },
            sortable: false,
            filter: false,
            width: 110,
            pinned: 'right'
        }
    ]);

    const defaultColDef = useMemo(() => {
        let obj = {
            width: 170,
            filter: true,
            editable: false,
            sortable: true
        }
        return obj;
    }, []);

    useEffect(() => {
        getHistoryAdmin()
            .then((response) => {
                if (!response.status) {
                    toast.error('Could not fetch chat history from API')
                    return;
                }
                let _rowData = [];
                response.data.forEach(element => {
                    let obj = {};
                    obj = Object.assign({}, element);
                    if (element.ai_type === "TalkToData" && element.ai_response !== "") {
                        let _ai_response = JSON.parse(element.ai_response)
                        let response = '';
                        switch (_ai_response.status) {
                            case "COMPLETED":
                                _ai_response?.attachments?.forEach(attachment => {
                                    response += attachment?.text?.content;
                                });
                                break;
                            case "EXECUTING_QUERY":
                                _ai_response?.attachments?.forEach(attachment => {
                                    response += attachment?.query?.description;
                                    response += ' || ' + attachment?.query?.query;
                                });
                                break;
                            default:
                                break;
                        }
                        obj.ai_response = response;
                    }
                    obj.rawData = Object.assign({}, element);
                    _rowData.push(obj);
                });
                setRowData(_rowData);
            })
    }, [])

    const btnShowDetail_onClick = (data) => {
        try {
            let obj = {};
            obj = Object.assign({}, data);
            if (obj.ai_type === "TalkToData") {
                if (data.ai_response !== "") {
                    let _ai_response = JSON.parse(data.ai_response)
                    let response = '<span>';
                    switch (_ai_response.status) {
                        case "COMPLETED":
                            _ai_response?.attachments?.forEach(attachment => {
                                response += '<strong>' + attachment?.text?.content + '</strong>';
                            });
                            break;
                        case "EXECUTING_QUERY":
                            _ai_response?.attachments?.forEach(attachment => {
                                response += '<strong>' + attachment?.query?.description + '</strong>';
                                response += ' <br/><details><summary>Query</summary><code style="color:blue;">' + attachment?.query?.query + '</code></details> ';
                            });
                            break;
                        default:
                            break;
                    }
                    response += ' </span>';
                    obj.ai_response = response;
                }
                if (data.ai_data !== "") {
                    let content = JSON.parse(data.ai_data);
                    if (content) {
                        switch (content?.statement_response?.manifest?.format) {
                            case "PROTOBUF_ARRAY":
                                {
                                    let dataTable = '';
                                    dataTable += '<table class="table table-bordered table-striped table-hover table-sm">';
                                    let _data = [];
                                    let _columns = content?.statement_response?.manifest?.schema?.columns;
                                    let _ColumnsList = [];
                                    if (_columns.length > 0) {
                                        dataTable += '<thead><tr>';
                                        _columns.forEach(element => {
                                            let obj = { headerName: element.name, field: element.name, sortable: true, filter: true }
                                            let objCol = { ...element, ...obj };
                                            _ColumnsList.push(objCol)
                                            dataTable += '<th>' + element.name + '</th>';
                                        });
                                        dataTable += '</tr></thead>';
                                    }
                                    let _rows = content?.statement_response?.result?.data_typed_array;
                                    if (content?.statement_response?.result?.row_count > 0) {
                                        dataTable += '<tbody>';
                                        _rows.forEach((item, row_index) => {
                                            dataTable += '<tr>';
                                            let obj = {};
                                            item.values.forEach((value, value_index) => {
                                                dataTable += '<td>' + value?.str + '</td>';
                                            });
                                            dataTable += '</tr>';
                                            _data.push(obj);
                                        });
                                        dataTable += '</tbody>';
                                    }
                                    if (_columns.length > 0 && _data.length === 0) {
                                        dataTable += '<tr><td colspan="' + _columns.length + '">No data found</td></tr>';
                                    }
                                    dataTable += '</table>';
                                    obj.ai_data = dataTable;
                                }
                                break;

                            default:
                                break;
                        }
                    }
                }
            } else if (obj.ai_type === "Business") {
                obj.ai_data = '<span></span>';
            }
            setDetailData(obj);
            setIsDetailVisible(true);
        } catch (error) {
            toast.error('Could not fetch chat history from API')
        }
    }


    return (
        <>
            <BackendTopMenu />
            <div className="mainContainer">
                <div className="row mt-2 mb-2">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
                            <Link className={st.navigateItem} to={"/backend"}>Admin Home</Link>
                            <Link className={st.navigateItem} to={"/backend/ai-feedback"}>AI Assistant Feedback</Link>
                        </Breadcrumbs>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">

                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-sm-12 col-lg-12">
                        <div className={"ag-theme-quartz"} style={{ width: '100%', height: 'calc(100vh - 150px)' }}>
                            <AgGridReact rowData={rowData} columnDefs={colDefs} defaultColDef={defaultColDef} />
                        </div>
                    </div>
                </div>

            </div>
            {
                DetailData && <ModalPopup IsVisible={IsDetailVisible} onClose={() => setIsDetailVisible(false)}>
                    <div className={st.chatDetailRootContainer}>
                        <div className={st.chatDetailHeader}>
                            <div className={st.userName}>
                                {DetailData?.created_by}
                            </div>
                            <IoMdCloseCircle className={st.closeIcon} onClick={() => setIsDetailVisible(false)} />
                        </div>
                        <div className={st.chatDetailBody}>
                            <h4>{DetailData?.message}</h4>
                            <p>{parse(DetailData?.ai_response)}</p>
                            <br />
                            {parse(DetailData?.ai_data)}
                            <div style={
                                DetailData?.feedback_flag === "Like" ?
                                    { color: 'green' } :
                                    DetailData?.feedback_flag === "Dislike" ?
                                        { color: 'red' } :
                                        { color: 'blue' }
                            }>
                                <h5>Feedback: {DetailData?.feedback_flag}</h5>
                                <strong>{DetailData?.feedback_msg}</strong>
                            </div>
                        </div>
                        <div className={st.chatDetailFooter}></div>
                    </div>
                </ModalPopup>
            }

        </>
    );
};

export default AIAssistantFeedback;