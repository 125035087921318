import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip, } from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import zoomPlugin from "chartjs-plugin-zoom";
import React, { useCallback, useEffect, useState } from 'react';
import { Bar } from "react-chartjs-2";
import { updateAIAssistantGraph } from '../../../../BaseModels/MasterData';


const BarGraph = ({ data, options, msg_id }) => {
    ChartJS.register(CategoryScale, LinearScale, BarElement, ChartDataLabels, Title, Tooltip, Legend, zoomPlugin);

    const [ChartData, setChartData] = useState(data);
    const [ChartOptions, setChartOptions] = useState(options);

    useEffect(() => {
        if (data && options && msg_id) {
            setChartData(data);
            setChartOptions(options);
            DownloadGraph();
        }
    }, [data, options, msg_id]);

    const DownloadGraph = useCallback(() => {
        try {
            setTimeout(() => {
                const canvasSave = document.getElementById('stackD' + msg_id);
                if(!canvasSave) return;
                canvasSave.toBlob(function (blob) {
                    var reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onloadend = function () {
                        var base64data = reader.result;
                        updateAIAssistantGraph(msg_id, base64data)
                    }
                })
            }, 500);
        } catch (error) {

        }
    }, [msg_id])

    return (
        <>
            <Bar id={"stackD" + msg_id} data={ChartData} options={ChartOptions} width={"100%"} height="200px" />
        </>
    );
};

export default BarGraph;