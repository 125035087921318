import React, { useEffect, useRef, useState } from "react";
import st from './ModalPopup.module.css';




const ModalPopup = ({ IsVisible, onClose, children }) => {
    const [showModal, setShowModal] = useState(false);
    const wrapperRef = useRef(null);

    useEffect(() => {
        if (IsVisible) {
            setShowModal(true);
        } else {
            setShowModal(false);
        }
    }, [IsVisible]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                onClose();
                setShowModal(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);



    return (
        showModal &&
        <div className={st.rootContainer}>
            <div className={st.bodyWrapper}>
                <div className={st.bodyContainer} ref={wrapperRef}>{children}</div>
            </div>
        </div>
    );
};

export default ModalPopup;
