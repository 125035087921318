import { ArcElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, PointElement, Title, Tooltip } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React, { useCallback, useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { updateGraph } from './../../../../../BaseModels/MasterData';



const PieGraph = ({ data, options, msg_id }) => {
    ChartJS.register(CategoryScale, LinearScale, PointElement, ArcElement, ChartDataLabels, Title, Tooltip, Legend);

    const [ChartData, setChartData] = useState(data);
    const [ChartOptions, setChartOptions] = useState(options);

    useEffect(() => {
        if (data && options && msg_id) {
            setChartData(data);
            setChartOptions(options);
        }
    }, [data, options, msg_id]);

    return (
        <>
            <Doughnut id={"stackD" + msg_id} data={ChartData} plugins={[ChartDataLabels]} options={ChartOptions} width={"100%"} height="300px" />
        </>
    );
};

export default PieGraph;