import React, { useContext, useEffect, useState } from 'react';
import { UDContext } from '../../../../../App';
import FavoriteDashboard2 from '../../../../CustomElements/FavoriteDashboard/FavoriteDashboard2/FavoriteDashboard2';

const FavoriteDashboardCreation = () => {
    const UserDetail = useContext(UDContext);
    const [YourFavoriteDashboardList, setYourFavoriteDashboardList] = useState([]);

    useEffect(() => {
        if (UserDetail !== null) {
            let _AllFavorites = UserDetail.AllFavorites;
            setYourFavoriteDashboardList(UserDetail.AllFavorites);
        }
    }, [UserDetail]);
    
    return (
        <>
            <FavoriteDashboard2 YourFavoriteDashboardList={YourFavoriteDashboardList} />
        </>
    );
};

export default FavoriteDashboardCreation;
