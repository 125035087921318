import SearchIcon from '@mui/icons-material/Search';
import React, { useContext, useEffect, useState } from 'react';
import { IoIosCloseCircle } from "react-icons/io";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ModalPopup from '../../../CustomElements/ModalPopup/ModalPopup';
import { UDContext } from './../../../../App';
import st from './DashboardSearchBox.module.css';

const DashboardSearchBox = () => {
    const UserDetail = useContext(UDContext);

    const [AllDashboardData, setAllDashboardData] = useState([]);
    const [FilteredData, setFilteredData] = useState([]);
    const [InputSearchText, setInputSearchText] = useState('');
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();


    useEffect(() => {
        if (UserDetail !== null) {
            setAllDashboardData(UserDetail.AllDashboardData);
        }
    }, [UserDetail]);


    const btnOpenModel = () => {
        setShowModal(true);
    }
    const btnCloseModel = () => {
        setShowModal(false);
    }

    const inputSearchBox_onChange = (e) => {
        setInputSearchText(e.target.value);
    }

    useEffect(() => {
        if (AllDashboardData.length > 0) {
            if (InputSearchText !== '') {
                var toSearch = InputSearchText.toLowerCase();
                var results = [];
                var objects = AllDashboardData;
                objects.forEach(element => {
                    for (var key in element) {
                        if (element[key]) {
                            if (String(element[key]).toLowerCase().includes(toSearch)) {
                                results.push(element);
                            }
                        }
                    }
                });
                let _FilteredData = results.filter((obj, index) => {
                    return index === results.findIndex(o => obj.dashboardid === o.dashboardid);
                });
                setFilteredData(_FilteredData);
            } else {
                setInputSearchText('');
                setFilteredData([]);
            }
        } else {
            setInputSearchText('');
            setFilteredData([]);
        }
    }, [InputSearchText, AllDashboardData]);

    const btnsearchResultItem_onClick = (slug) => {
        navigate("/dashboard/" + slug);
        setShowModal(false);
        setInputSearchText('');
        setFilteredData([]);
    }

    return (
        <>
            <SearchIcon fontSize="inherit" onClick={btnOpenModel} />
            <ModalPopup IsVisible={showModal} onClose={btnCloseModel}>
                <div className={st.searchBoxRootContainer} onMouseLeave={() => setInputSearchText("")}>
                    <div className={st.searchBoxContainer}>
                        <div className={st.searchBoxHeader}>
                            <div className={st.searchBoxHeaderTitle}>
                                <SearchIcon /> Search
                            </div>
                            <IoIosCloseCircle onClick={btnCloseModel} className={st.searchBoxHeaderCloseButton} />
                        </div>
                        <div className={st.searchBoxBody}>
                            <div className={st.searchBoxBodyContainer}>
                                <div className={st.searchBoxBodyContainerSearchBox}>
                                    <input type="text" placeholder="Search"
                                        value={InputSearchText}
                                        onChange={(e) => { inputSearchBox_onChange(e) }}
                                        className={st.inputSearchBox} />
                                        {
                                            InputSearchText !== '' ? <IoIosCloseCircle className={st.cancelSearch} onClick={() => setInputSearchText("")} /> : null
                                        }
                                    <div className={st.inputSearchBoxIconContainer}>
                                        <SearchIcon fontSize="inherit" className={st.inputSearchBoxIcon} />
                                    </div>
                                </div>
                            </div>
                            <div className={st.searchResultContainer}>
                                <div className={st.searchResultWrapper}>
                                    {
                                        FilteredData.length > 0 ?
                                            FilteredData.map((data, index) => (
                                                <div key={index} className={st.searchResultItem} onClick={() => { btnsearchResultItem_onClick(data.slug) }}>
                                                    <div className={st.searchResultItemTitle}>{data.title}</div>
                                                    <div className={st.searchResultItemDetail}>
                                                        <div className={st.searchResultItemDetailText}>{data.page + " > " + data.section}</div>
                                                    </div>
                                                </div>
                                            ))
                                            : InputSearchText !== '' ?
                                                (<div className={st.searchResultItem}>
                                                    <div className={st.searchResultItemTitle}>No Result Found</div>
                                                </div>) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalPopup>
        </>
    );
};

export default DashboardSearchBox;
