import React from 'react';
import { FaCopy } from "react-icons/fa";
import { FaPencil } from "react-icons/fa6";
import userImage from '../user.svg';
import st from './UserMessage.module.css';

const UserMessage = ({ content, username }) => {
    return (
        <div className={st.userMessageRootContainer}>
            <div className={st.userIconContainer}>
                <img src={userImage} alt="User" className={st.userIcon} />
            </div>
            <div className={st.messageRootContainer}>
                <div className={st.userNameActionContainer}>
                    <span className={st.username}>{username}</span>
                    <div className={st.actionButtonContainer}>
                        <div className={st.actionButton} style={{ display: 'none' }}>
                            <FaPencil className={st.actionButtonIcon} /> Edit
                        </div>
                        <div className={st.actionButton} style={{ display: 'none' }}>
                            <FaCopy className={st.actionButtonIcon} /> Copy
                        </div>
                    </div>
                </div>
                <div className={st.userMessage}>{content}</div>
            </div>
        </div>
    );
};

export default UserMessage;