import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TopMenu from '../TopMenu/TopMenu';
import st from './PageNotFound.module.css';
const PageNotFound = () => {
    const { slug } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [timeLeft, setTimeLeft] = useState(10);


    // useEffect(() => {
    //     if (timeLeft === 0) {
    //         setTimeLeft(null)
    //         navigate('/');
    //     }
    //     if (!timeLeft) return;
    //     const intervalId = setInterval(() => {
    //         setTimeLeft(timeLeft - 1);
    //     }, 1000);
    //     return () => clearInterval(intervalId);
    // }, [timeLeft]);



    return (
        <div>
            <TopMenu />
            <div className={st.pageNotFoundContainer}>
                <span className={st.pageNotFoundTitle}>404</span>
                <br />
                <span className={st.pageNotFoundDetail}>Page Not Found</span>
                <br />
                {/* <span className={st.redirectToHome}>Redirect to home page after {timeLeft} second</span> */}
                
            </div>

        </div>
    );
};

export default PageNotFound;
