import React, { useEffect, useRef, useState } from "react";
import { FaExclamationCircle } from "react-icons/fa";
import { IoIosCloseCircle } from "react-icons/io";
import st from './DetailShower.module.css';


const DetailShower = ({ children }) => {

    const [showModal, setShowModal] = useState(false);
    const wrapperRef = useRef(null);



    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setShowModal(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);


    return (
        <>
            <FaExclamationCircle style={{display:'none'}} className={st.exclamationSymbol} onClick={() => setShowModal(true)} />
            {showModal &&
                <div className={st.rootContainer}>
                    <div className={st.bodyWrapper}>
                        <IoIosCloseCircle className={st.closeButton} />
                        <div className={st.bodyContainer} ref={wrapperRef}>{children}</div>
                    </div>
                </div>}
        </>
    );
};

export default DetailShower;
