import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import FlipCard2 from '../../FlipCard2/FlipCard2';
import st from './DashboardPageItem2.module.css';

const Image = ({ src, loaderImage, alt, ...props }) => {
    const [loaded, setLoaded] = useState(false);
    return (
        <>
            <img src={src} alt={alt} {...props}
                style={loaded ? {} : { display: 'none' }}
                onLoad={() => { setLoaded(true); }} />
            {loaded ? null : <img src={loaderImage} alt={alt} {...props} />}
        </>

    );
};

const DashboardPageItem2 = ({ sections }) => {
    const navigate = useNavigate();
    const btnfavouriteWidget_onClick = (item) => {
        navigate("/dashboard/" + item.slug);
    }
    return (
        <>
            <div className="mainContainer">
                {
                    sections?.map((section, index) => (
                        <div key={index} className={st.sectionContainer}>
                            <div className="row mt-3">
                                <div className="col-lg-12">
                                    <div className={st.mainTitle}>
                                        {section.section_name}
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                {
                                    section.report.map((report, index) => (
                                        <div className="col-lg-2 col-md-4 col-sm-6" key={index} onClick={() => btnfavouriteWidget_onClick(report)}>
                                            <FlipCard2 dashboard={report} />
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    ))
                }
            </div>
        </>
    );
};

export default DashboardPageItem2;