import { CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Title, Tooltip } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import zoomPlugin from "chartjs-plugin-zoom";
import React, { useCallback, useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { updateGraph } from './../../../../../BaseModels/MasterData';


const LineGraph = ({ data, options, msg_id }) => {
    ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, ChartDataLabels, Title, Tooltip, Legend, zoomPlugin);

    const [ChartData, setChartData] = useState(data);
    const [ChartOptions, setChartOptions] = useState(options);

    useEffect(() => {
        if (data && options && msg_id) {
            setChartData(data);
            setChartOptions(options);           
        }
    }, [data, options, msg_id]);

   
    return (
        <>
            <Line id={"stackD"+msg_id} data={ChartData} plugins={[ChartDataLabels]} options={ChartOptions} width={"100%"} height={"300px"} />
        </>
    );
};

export default LineGraph;