import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import React, { useContext, useEffect, useState } from 'react';
import { FaPlus, FaEdit, FaTimes, FaEnvelope, FaUser, FaFilter, FaDatabase, 
    FaCaretLeft, FaBell, FaExternalLinkAlt } from 'react-icons/fa';
import CircularProgress from '@mui/material/CircularProgress';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UDContext } from '../../../App';
import BackendTopMenu from '../BackendTopMenu/BackendTopMenu';
import {
    getUserSearch, getSearchNameByUserId
} from './../../BaseModels/MasterData';
import {
    getReportNames, getAlerts, getFilters, getFilterValues, getAlertDetails, getNotifications,
    getNotificationFilters, postCreateNotification, postUpdateNotification, postDeleteNotification
} from './../../BaseModels/AlertData';
import { useContainerDimensions } from './../../BaseModels/ResizeWindow';
import Select, { components } from 'react-select';
import Async from 'react-select/async';
import { delay } from '../../BaseModels/utility';
import ReportSelect from './ReportSelect';
import st from './TableauAlert.module.css';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
// import Chip from '@mui/material/Chip';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={2} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    marginBottom: 6,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));



const optionCacheUsers = {};
const PAGE_STATE = {
    LOADING: 'loading',
    ALERT_SENDING: 'sending',
    DATA_VALIDATED: 'valid',
    DEFAULT: 'default',
}
const userCache = {};
const SECTIONS = {
    LIST: 'list',
    CREATE: 'new',
    EDIT: 'edit',
    EMPTY: 'empty',
}

const customSelectStyles = {
    control: base => ({
        ...base,
        minHeight: 30
    }),
    dropdownIndicator: base => ({
        ...base,
        padding: 4
    }),
    clearIndicator: base => ({
        ...base,
        padding: 4
    }),
    valueContainer: base => ({
        ...base,
        padding: '0px 6px'
    }),
    input: base => ({
        ...base,
        margin: 0,
        padding: 0
    })
};

const customSelectMVStyles = {
    valueContainer: base => ({
        ...base,
        padding: '0px 6px',
        textOverflow: 'ellipsis',
        maxWidth: '90%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        display: 'initial'
    }),
}

const CustomMVContainerFilter = ({ selectProps, data }) => {
    const allSelected = selectProps.value;
    const index = allSelected.findIndex(selected => selected.value === data.value);
    const isLastSelected = index === allSelected.length - 1;
    const optionsLength = selectProps.options.length;
    const countText = optionsLength === allSelected.length ? 'All' : String(allSelected.length);
    if (isLastSelected)
        return `${countText} option${allSelected.length !== 1 ? 's' : ''} selected`;
    return '';
};

const CustomOptionUser = ({ innerProps, innerRef, isDisabled, data }) =>
    isDisabled ? null : (
        <div ref={innerRef} {...innerProps} className={st.userSearchItem} >
            <span className={st.username}>{data.fullName}</span>
            <span className={st.usermail}>{data.mail}</span>
        </div>
    );

const getUserNameById = async (uid) => {
    const res = await getSearchNameByUserId(uid);
    if (res.status)
        res.data = `${res.data.first_name} ${res.data.last_name}`;
    return res;
}

const LoadingScreen = () => {
    return (
        <div className='w-100 h-100' style={{ minHeight: '50vh' }}>
            <div className='w-100 h-100 d-flex flex-column justify-content-center'>
                <div className='row'>
                    <div className={`col-12 text-center`}>
                        <CircularProgress style={{ color: 'var(--gray-color-4)' }} />
                    </div>
                    <div className={`col-12 text-center`}>
                        <span className={`${st.notificationItemLabel1}`}>Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    )
}


const useSubscribeModal = (setNotifications) => {
    const userDetails = useContext(UDContext);
    const [open, setOpen] = useState(false);
    const [notification, setNotification] = useState(null);
    const [userSelection, setUserSelection] = useState({
        input: '',
        value: null,
    });
    const [recipients, setRecipients] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const openForm = (n) => {
        setNotification(n);
        setOpen(true);
        setUserSelection({
            input: '',
            value: null
        });
        setRecipients(n.recipients.reverse());
    }
    const closeForm = () => {
        setNotification(null);
        setOpen(false);
    }

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const fetchRef = React.useRef(null);
    const loadUserOptions = async (str) => {
        const name = str.replace(/[/:?#[\]!~$&''()*,; ]/g, '');
        if (name.length < 2) return [];
        if (fetchRef.current) fetchRef.current.abort();
        const ac = new AbortController();
        fetchRef.current = ac;
        await delay(350);
        if (ac.signal.aborted) return [];
        if (optionCacheUsers[name]) return optionCacheUsers[name];
        const response = await getUserSearch(name, ac.signal);
        if (ac.signal.aborted) return [];
        if (!response.status && !Array.isArray(response.data)) return [];
        const options = response.data.slice(0, 15)
            .map((item) => ({
                fullName: item.givenName + ' ' + item.surname,
                mail: item.mail,
            }));
        optionCacheUsers[name] = options;
        return options;
    };
    
    const handleUserInputChange = (str) => {
        const name = str.replace(/[/:?#[\]!~$&''()*,; ]/g, '');
        setUserSelection(obj => ({ ...obj, input: name }));
    };
    const handleUserValueChange = (list) => {
        setUserSelection(obj => ({ ...obj, value: list }));
    };

    const updateSubscribers = () => {
        if (!notification) return;
        const n = notification;
        if (!recipients.length) {
            toast.warn('Add at least one subscriber! OR, delete the notification.', { autoClose: 3000 });
            return;
        }
        const recipient = recipients.reverse().join(';');
        const user = userDetails.user.user_id;
        const report_id = n.report_id;
        const alert_id = n.alert_id;
        const tab_name = n.tab_name;
        const alert_value_id = n.alert_value_id;
        const alert_value_name = n.alert_value_name;
        const alert_value = n.alert_value;
        const filters = n.filters;
        const payload = {
            report_id, alert_id, tab_name, alert_value_id,
            alert_value_name, alert_value, recipient, filters, user
        };
        const notificationId = n.notification_id;
        const resPromise  = postUpdateNotification(notificationId, payload);
        setIsLoading(true);
        resPromise.then((res) => {
            toast.success(`Subscribers updated successfully`);
            setNotifications((ns) => ns.map((ni) => {
                const new_n = { ...ni };
                if (ni.notification_id === notificationId) {
                    new_n.recipient = recipient;
                    new_n.recipients = recipient.split(';');
                    new_n.updated_by = user;
                }
                return new_n;
            }));
            closeForm();
        }).catch(() => {
            toast.error('Something went wrong!');
        }).finally(() => setIsLoading(false));
    }
    
    const addButtonText = (userSelection.value && notification 
        && notification.recipients.includes(userSelection.value.mail))
        ? 'Remove': 'Add';

    const addSubscriber = () => {
        if (!userSelection.value) return;
        const selectedUserMail = userSelection.value.mail;
        if (addButtonText === 'Add') {
            setRecipients(arr => [selectedUserMail, ...arr]);
        }
        else {
            setRecipients(arr => arr.filter(x => x !== selectedUserMail));
        }
        setUserSelection({
            input: '',
            value: null
        });
    }

    const deleteSubscriber = (mail) => {
        setRecipients(arr => arr.filter(x => x !== mail));
    }

    const modalForm = (
        notification &&
        <Modal
            open={open}
            onClose={closeForm}
        >
            <Box sx={modalStyle}>
                    <div className={st.page_title}>
                        Subscribe to #{notification.notification_id}
                    </div>
                    <div className='d-flex flex-column mt-4'>
                        <div>
                            Search user by name or email
                        </div>
                        <div className='d-flex'>
                            <Async
                                className='flex-fill me-2'
                                loadOptions={loadUserOptions}
                                inputValue={userSelection.input}
                                onInputChange={handleUserInputChange}
                                value={userSelection.value}
                                onChange={handleUserValueChange}
                                getOptionValue={x => x['mail']}
                                getOptionLabel={x => x['mail']}
                                // isMulti
                                components={{ Option: CustomOptionUser }}
                                isClearable
                                captureMenuScroll
                                placeholder={'Search...'}
                                disabled={isLoading}
                            />
                            <Button
                                size='extraSmall' variant='contained'
                                color={addButtonText === 'Add' ? 'primary' : 'error'}
                                onClick={addSubscriber}
                                disabled={!userSelection.value}
                            >{addButtonText}</Button>
                        </div>
                        <div className={`mt-4 ${st.recipientsList}`}>
                            {
                                recipients.map(r => (
                                //     <Chip variant='outlined'
                                //         className={st.recipientsItem}
                                //         key={r}
                                //         label={r}
                                //         onDelete={() => deleteSubscriber(r)}
                                //     />
                                    <div className={`d-flex justify-content-between ${st.recipientsItem}`} key={r}>
                                        <span>{r}</span>
                                        <IconButton aria-label='delete' size='small'
                                            sx={{borderRadius: '5px'}}
                                            onClick={() => deleteSubscriber(r)}
                                        >
                                            <FaTimes style={{fontSize:'10px'}} />
                                        </IconButton>
                                    </div>
                                ))
                            }
                        </div>
                        <div className={`d-flex justify-content-end gap-4 mt-4`}>
                            <Button
                                size='small' color='error' variant='contained'
                                onClick={closeForm}
                                disabled={isLoading}
                            >{'Cancel'}</Button>
                            <Button
                                size='small' color='primary' variant='contained'
                                onClick={updateSubscribers}
                                disabled={isLoading}
                            >{'Save'}</Button>
                        </div>
                    </div>
                </Box>
        </Modal>
    );
    return [openForm, modalForm];
}


const Notifications = ({ pageConfig, setPageConfig }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [notifications, setNotifications] = useState([]);
    const [isNotificationsLoading, setIsNotificationsLoading] = useState({});
    const { alertId } = pageConfig.data;

    const getConsolidatedNotifications = async (ac) => {
        let res = await getNotifications(alertId, ac.signal);
        if (!res.status) {
            toast.error(res.message);
            return;
        }
        const _notifications = res.data;
        const nfRes = await Promise.all(_notifications
            .map(n => getNotificationFilters(n.notification_id, ac.signal)));
        res = nfRes.find(r => !r.status);
        if (res) {
            if (res.message !== 'aborted')
                toast.error(res.message);
            return;
        }
        // let users = new Set();
        _notifications.forEach((n, idx) => {
            const _filters = nfRes[idx].data;
            _filters.sort((a, b) => b.filter_id - a.filter_id); // order should be same as in notification form
            n.filters = _filters;
            // if (!userCache[n.created_by])
            //     users.add(n.created_by);
            n.recipients = n.recipient.split(';');
        });
        // users = Array.from(users);
        // const uRes = await Promise.all(users.map(u => getUserNameById(u)));
        // res = uRes.find(r => !r.status);
        // if (res) return;
        // users.forEach((uid, idx) => userCache[uid] = uRes[idx].data);
        // _notifications.forEach((n) => {
        //     n.author = userCache[n.created_by];
        // });
        if (ac.signal.aborted) return;
        _notifications.sort((a, b) => a.notification_id - b.notification_id);
        return _notifications;
    }
    useEffect(() => {
        if (!alertId) return;
        setIsLoading(true);
        const ac = new AbortController();

        getConsolidatedNotifications(ac)
            .then(_notifications => {
                if (_notifications) {
                    setNotifications(_notifications);
                }
                setIsLoading(false);
            });
        return () => ac.abort();

    }, [alertId, setIsLoading, setNotifications]);

    const handleDeleteNotification = async (n, e) => {
        e.stopPropagation();
        const notificationId = n.notification_id;
        if (!window.confirm(`Do you really want to delete notification #${notificationId}?`))
            return;
        setIsNotificationsLoading((obj) => ({ ...obj, [notificationId]: true }));
        const res = await postDeleteNotification(notificationId);
        if (!res.status) {
            toast.error(res.message);
        }
        else {
            setNotifications((obj) => obj.filter(ni => ni.notification_id !== notificationId));
            toast.success(`Notification #${notificationId} deleted successfully`);
        }
        setIsNotificationsLoading((obj) => ({ ...obj, notificationId: false }));
    }

    const handleEditNotification = (notificationInfo) => {
        setPageConfig({ section: SECTIONS.EDIT, data: { notification: notificationInfo } });
    }

    const handleCreateNotification = () => {
        setPageConfig({ section: SECTIONS.CREATE, data: { alertId } });
    }
    

    // Accordion
    const [expanded, setExpanded] = useState(null);

    const handleAccordionExpansion = (notification, expanded = true) => {
        if (!expanded) {
            setExpanded(null);
            return;
        }
        const notificationId = notification.notification_id;
        setExpanded(notificationId);
    };


    useEffect(() => {
        if (notifications.length > 0 && !expanded) {
            handleAccordionExpansion(notifications[0]);
        }
    }, [notifications])

    const [openForm, ModalForm] = useSubscribeModal(setNotifications);

    const handleAddSubscriber = (n, e) => {
        e.stopPropagation();
        handleAccordionExpansion(n);
        openForm(n);
    }

    return (
        <>
            <div className='d-flex justify-content-between mb-2'>
                <span className={st.page_title}>Notifications</span>
                <Button size='small' color='primary' variant='contained'
                    endIcon={<FaBell />}
                    onClick={() => handleCreateNotification()}
                >New Alert</Button>
            </div>
            {isLoading ?
                <LoadingScreen />
                :
                <>
                    {
                        notifications.map(n => (
                            <Accordion
                                key={n.notification_id}
                                expanded={expanded === n.notification_id}
                                onChange={(ev, ex) => handleAccordionExpansion(n, ex)}
                            >
                                <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
                                    <div className={`col-1 d-flex align-items-center ${st.notificationItemLabel1}`}>#{n.notification_id}</div>
                                    <div className={`col-6 d-flex align-items-center ${st.notificationItemLabel1}`}>
                                        <FaDatabase />
                                        <div className={`ms-1 ${st.notificationItem}`}>{`${n.alert_value_name} greater than ${n.alert_value}`}</div>
                                    </div>
                                    <div className={`col-5 d-flex justify-content-end gap-1`}>
                                        <Button
                                            variant='contained'
                                            size='extraSmall'
                                            color='success'
                                            endIcon={<FaEnvelope style={{ marginLeft: '-5px', width: '20px' }} />}
                                            onClick={(e) => handleAddSubscriber(n, e)}
                                        >Subscribe</Button>
                                        <Button
                                            variant='contained'
                                            size='extraSmall'
                                            color='primary'
                                            endIcon={<FaEdit />}
                                            onClick={(e) => handleEditNotification(n)}
                                        >Edit</Button>
                                        <Button
                                            variant='contained'
                                            size='extraSmall'
                                            color='error'
                                            endIcon={<FaTimes style={{ marginLeft: '-5px', width: '20px' }} />}
                                            onClick={(e) => handleDeleteNotification(n, e)}
                                        >Delete</Button>
                                    </div>

                                    {/* <div className={`col-1 ${st.notificationItem}`}>
                                        <div className={`d-flex align-items-center ${st.notificationFooterAction} ${st.pointer}`}
                                            onClick={(e) => e.stopPropagation()}
                                        >
                                            <FaEnvelope />
                                            <span>Subscribe</span>
                                        </div>
                                    </div>

                                    <div className={`col-1 ${st.notificationItem}`}>
                                        <div className={`d-flex align-items-center ${st.notificationFooterAction} ${st.pointer}`}
                                            onClick={() => handleEditNotification(n)}
                                        >
                                            <FaEdit />
                                            <span>Edit</span>
                                        </div>
                                        
                                    </div>
                                    <div className={`col-2 ${st.notificationItem}`}>
                                        <div className={`d-flex align-items-center ${st.notificationFooterAction} ${st.pointer}`}
                                            onClick={() => handleDeleteNotification(n.notification_id)}
                                        >
                                            <FaTimes />
                                            <span>Delete</span>
                                        </div>
                                        
                                    </div> */}
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className='col-sm-12 col-md-12'>
                                        <div className={`p-1 ${st.notificationCard}`}>
                                            {/* <div className='row mb-1'>
                                                <div className={`col-2 d-flex align-items-center ${st.notificationItemLabel1}`}>
                                                    <FaEnvelope />
                                                    <span className='ms-1'>{'Subscribers'}</span>
                                                </div>
                                                <div className={`col-10 ${st.notificationItem}`}>
                                                    {
                                                        `[${n.recipients.length}] ${n.recipients.join(', ')}`
                                                    }
                                                </div>
                                                <div className={`col-3`}>
                                                    <Button size='small' sx={{ mt: 0.3 }} color='primary' variant='outlined'
                                                        onClick={() => handleSendMail(n)}
                                                    >{'Save'}</Button>
                                                </div>
                                            </div> */}
                                            <div className='row mt-1 mb-1'>

                                                <div className={`col-2`}>
                                                    <div className={`d-flex align-items-center ${st.notificationItemLabel1}`}>
                                                        <FaFilter />
                                                        <span className='ms-1'>Filters</span>
                                                    </div>
                                                </div>
                                                <div className={`col-10 ${st.notificationItem}`}>{
                                                    n.filters.map(f => {
                                                        let filterValueStr = '';
                                                        try {
                                                            const filterValue = JSON.parse(f.filter_value);
                                                            if (Array.isArray(filterValue)) {
                                                                if (filterValue.length === 1)
                                                                    filterValueStr = String(filterValue[0]);
                                                                else
                                                                    filterValueStr = `${filterValue.length} items`;
                                                            }
                                                            else
                                                                filterValueStr = f.filter_value;
                                                        }
                                                        catch {
                                                            filterValueStr = f.filter_value;
                                                        }
                                                        return (
                                                            <div className='row' key={`${n.notification_id}-${f.filter_id}`}>
                                                                <div className={`col-4  ${st.notificationItemLabel2}`}>{f.filter_name}</div>
                                                                <div className={`col-8 ${st.notificationItem}`}>{filterValueStr}</div>
                                                            </div>
                                                        )
                                                    }

                                                    )
                                                }</div>
                                                {/* <div className={`col-2 ${st.notificationItemLabel1}`}>
                                                    <FaUser />
                                                    <span className='ms-1'>Author</span>
                                                </div>
                                                <div className={`col-3 ${st.notificationItem}`}>{n.author}</div> */}
                                            </div>
                                            {
                                                isNotificationsLoading[n.notification_id] &&
                                                <div className={`w-100 h-100 d-flex flex-column justify-content-center ${st.notificationOverlay}`}>
                                                    <div className='row'>
                                                        <div className={`col-12 text-center`}>
                                                            <CircularProgress style={{ color: 'var(--gray-color-4)' }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        ))
                    }
                    {ModalForm}
                </>

            }
        </>
    )
}

const NotificationForm = ({ pageConfig, setPageConfig, selectValues, viewUrl }) => {
    const userDetails = useContext(UDContext);
    const reportInfo = selectValues.report;
    const alertInfo = selectValues.alert;

    const [formState, setFormState] = useState(PAGE_STATE.LOADING);
    const _triggerConditions = ['Greater than', 'Less than', 'Equals'].map(v => ({ label: v, value: v }));
    const [notificationOptions, setNotificationOptions] = useState({
        filters: [],
        triggerNames: [],
        triggerConditions: _triggerConditions,
    });
    const [triggerSelection, setTriggerSelection] = useState({
        name: null,
        value: '',
        condition: _triggerConditions[0],
    });
    const [filterSelectionById, setFilterSelectionById] = useState({});
    const [userSelection, setUserSelection] = useState({
        input: '',
        value: [],
    });

    const alertId = alertInfo.alert_id;
    useEffect(() => {
        const ac = new AbortController();
        const getNotificationData = async () => {
            let res = await getAlertDetails(alertId, ac.signal);
            if (!res.status) {
                if (res.message !== 'aborted')
                    toast.error(res.message);
                return;
            }
            const alertTriggerOptions = res.data;
            res = await getFilters(alertId, ac.signal);
            if (!res.status) {
                if (res.message !== 'aborted')
                    toast.error(res.message);
                return;
            }
            const filters = res.data;
            filters.sort((a, b) => b.filter_id - a.filter_id); // order should be same as in notifications list
            const valueRes = await Promise.all(filters.map(f => getFilterValues(f.filter_id, ac.signal)));
            res = valueRes.find(r => !r.status);
            if (res) {
                if (res.message !== 'aborted')
                    toast.error(res.message);
                return;
            }
            const initialFilterSelections = {};
            valueRes.forEach((v, idx) => {
                const options = v.data;
                filters[idx].options = options;
                let initialValue = (filters[idx].filter_type === 'Multi') ? options : options[0];
                if (pageConfig.section === SECTIONS.EDIT) {
                    const { notification } = pageConfig.data;
                    if (filters[idx].filter_type === 'Multi') {
                        try {
                            const valueArray = JSON.parse(notification.filters[idx].filter_value);
                            if (!Array.isArray(valueArray))
                                throw new Error('invalid');
                            const valueSet = new Set(valueArray);
                            initialValue = options.filter(opt => valueSet.has(opt.value));
                            if (initialValue.length !== valueArray.length)
                                throw Error('invalid');
                        }
                        catch {
                            toast.error(`Invalid value for filter #${filters[idx].filter_id}`);
                            initialValue = [];
                        }
                    }
                    else {
                        initialValue = options.find(opt => opt.value === notification.filters[idx].filter_value);
                        if (!initialValue)
                            toast.error(`Invalid value for filter #${filters[idx].filter_id}`);
                    }
                }
                initialFilterSelections[filters[idx].filter_id] = initialValue;
            });
            const initialTriggerValues = {
                name: alertTriggerOptions[0],
                value: '',
            }
            let initialUsers = [];
            if (pageConfig.section === SECTIONS.EDIT) {
                const { notification } = pageConfig.data;
                initialTriggerValues.name = alertTriggerOptions.find(obj => obj.column_value_name === notification.alert_value_name);
                initialTriggerValues.value = notification.alert_value;
                initialUsers = notification.recipients.map(uid => ({
                    mail: uid,
                }))
            }
            if (ac.signal.aborted) return;
            return { alertTriggerOptions, initialTriggerValues, filters, initialFilterSelections, initialUsers };
        }
        getNotificationData()
            .then((data) => {
                setFormState(PAGE_STATE.DEFAULT);
                if (!data) return;
                const { alertTriggerOptions, initialTriggerValues, filters, initialFilterSelections, initialUsers } = data;
                setNotificationOptions(obj => ({ ...obj, filters, triggerNames: alertTriggerOptions }));
                setTriggerSelection(obj => ({ ...obj, ...initialTriggerValues }));
                setFilterSelectionById(initialFilterSelections);
                handleUserValueChange(initialUsers);
            });
        return () => ac.abort();

    }, [pageConfig, alertId]);

    const DEBOUNCE_TIMER = 350; // ms
    const fetchRef = React.useRef(null);

    // It debounces user input and cache it for later use
    const loadUserOptions = async (str) => {
        const name = str.replace(/[/:?#[\]!~$&''()*,; ]/g, '');
        if (name.length < 2) return [];
        if (fetchRef.current) fetchRef.current.abort();
        const ac = new AbortController();
        fetchRef.current = ac;
        await delay(DEBOUNCE_TIMER);
        if (ac.signal.aborted) return [];
        if (optionCacheUsers[name]) return optionCacheUsers[name];
        const response = await getUserSearch(name, ac.signal);
        if (ac.signal.aborted) return [];
        if (!response.status && !Array.isArray(response.data)) return [];
        const options = response.data.slice(0, 15)
            .map((item) => ({
                fullName: item.givenName + ' ' + item.surname,
                mail: item.mail,
            }));
        optionCacheUsers[name] = options;
        return options;
    }

    const handleUserInputChange = (str) => {
        const name = str.replace(/[/:?#[\]!~$&''()*,; ]/g, '');
        setUserSelection(obj => ({ ...obj, input: name }));
    };

    const handleUserValueChange = (list) => {
        setUserSelection(obj => ({ ...obj, value: list }));
    };

    const handleBack = () => {
        setPageConfig(obj =>
        ({
            section: SECTIONS.LIST,
            data: {
                alertId: obj.section === SECTIONS.EDIT ?
                    pageConfig.data.notification.alert_id :
                    pageConfig.data.alertId
            }
        }))
    }

    const getFormData = () => {
        const report_id = reportInfo.report_id;
        const alert_id = alertId;
        const tab_name = alertInfo.tab_name;
        const alert_value_id = triggerSelection.name.ac_id;
        const alert_value_name = triggerSelection.name.column_value_name;
        const alert_value = triggerSelection.value;
        const recipient = userSelection.value.map(x => x.mail).join(';');
        const user = userDetails.user.user_id;
        const filters = notificationOptions.filters.map(f => {
            return {
                filter_id: f.filter_id,
                filter_name: f.filter_name,
                alert_id,
                filter_value: f.filter_type === 'Multi' ?
                    JSON.stringify(filterSelectionById[f.filter_id].map(x => x.value)) :
                    filterSelectionById[f.filter_id].value,
            }
        });
        const payload = {
            report_id, alert_id, tab_name, alert_value_id,
            alert_value_name, alert_value, recipient, filters, user
        }
        return payload;
    }

    useEffect(() => {
        if (formState === PAGE_STATE.LOADING) return;
        let allFiltersSelected = notificationOptions.filters
            .every(f => {
                const x = filterSelectionById[f.filter_id];
                return f.filter_type === 'Multi' ? (Array.isArray(x) && x.length) : x;
            });
        let triggerSelected = triggerSelection.name;
        if (triggerSelection.name && triggerSelection.name.column_value_type === 'Numeric') {
            triggerSelected = /^\d+$/.test(triggerSelection.value);
        }
        let mailSelected = userSelection.value.length;
        if (!allFiltersSelected || !triggerSelected || !mailSelected) {
            setFormState(PAGE_STATE.DEFAULT);
        }
        else {
            setFormState(PAGE_STATE.DATA_VALIDATED);
        }
    }, [filterSelectionById, triggerSelection, notificationOptions, userSelection, formState, setFormState]);

    const handleFormSubmit = () => {
        if (formState !== PAGE_STATE.DATA_VALIDATED) {
            toast.error('Invalid input!');
            return;
        }
        setFormState(PAGE_STATE.ALERT_SENDING);
        const payload = getFormData();
        let resPromise = null;
        if (pageConfig.section === SECTIONS.CREATE) {
            resPromise = postCreateNotification(payload);
        }
        else if (pageConfig.section === SECTIONS.EDIT) {
            const notificationId = pageConfig.data.notification.notification_id;
            resPromise = postUpdateNotification(notificationId, payload);
        }
        if (!resPromise) {
            toast.error('Invalid form section!');
            return;
        }
        resPromise.then((res) => {
            if (!res.status) {
                toast.error(res.message);
                setFormState(PAGE_STATE.DATA_VALIDATED);
            }
            else {
                toast.success(`Notification saved successfully`);
                setPageConfig({ section: SECTIONS.LIST, data: { alertId } });
            }
        }).catch(() => {
            toast.error('Something went wrong!');
            setFormState(PAGE_STATE.DATA_VALIDATED);
        });
    }

    const handleFormDelete = () => {
        const notificationId = pageConfig.data.notification.notification_id;
        if (!window.confirm(`Do you really want to delete notification #${notificationId}?`))
            return;
        const lastState = formState;
        setFormState(PAGE_STATE.ALERT_SENDING);
        postDeleteNotification(notificationId)
            .then((res) => {
                if (!res.status) {
                    toast.error(res.message);
                    setFormState(lastState);
                }
                else {
                    toast.success(`Notification deleted successfully`);
                    setPageConfig({ section: SECTIONS.LIST, data: { alertId } });
                }
            }).catch(() => {
                toast.error('Something went wrong!');
                setFormState(lastState);
            });
    }

    const handleNewWindow = () => {
        window.open(viewUrl, '_blank')
    }

    return (
        <>
            <div className='row'>
                <div className={'col-lg-6 order-lg-2'}>
                    {/* iframe table */}
                    <div className='mb-2 d-flex align-items-end justify-content-between'>
                        <span className={st.page_title}>
                            {reportInfo.title}
                        </span>
                        <div className={`d-flex align-items-center ${st.page_new_tab_action}`}
                            onClick={handleNewWindow}
                        >
                            <span>Open in new tab</span>
                            <FaExternalLinkAlt style={{ fontSize: '12px', marginLeft: '5px' }} />
                        </div>
                    </div>
                    <div className='d-flex justify-content-center mb-2'>
                        <div
                            style={{
                                width: '1162px',
                                height: '385px',
                                overflowX: 'auto',
                                overflowY: 'hidden',
                                backgroundColor: 'white',
                                border: '1px solid rgba(0,0,0,.125)',
                            }}
                        >
                            <iframe src={`${reportInfo.url.split('?')[0]}?:embed=y&:showVizHome=n&:tabs=n&:toolbar=n&:device=desktop&:apiID=host0#navType=0&navSrc=Parse`}
                                title='tableau data'
                                style={{
                                    height: '730px',
                                    width: '1180px',
                                    marginTop: '-340px',
                                    marginLeft: '-20px',
                                    pointerEvents: 'none',
                                }}
                            >
                            </iframe>
                        </div>
                    </div>
                </div>

                <div className={'col-lg-6'}>
                    <div className='mb-2'>
                        <span className={st.page_title}>
                            {
                                pageConfig.section === SECTIONS.CREATE ? 'Create new notification' : 'Edit notification'
                            }
                        </span>
                    </div>
                    {
                        formState === PAGE_STATE.LOADING ?
                            <LoadingScreen />
                            :
                            <div className='d-flex justify-content-center'>
                                <div className={'col-sm-12'}>
                                    <div className='card ps-4 pe-4 pt-2 pb-2'>
                                        <div className='d-flex justify-content-between'>
                                            <div className={`d-flex align-items-center mb-4 ${st.notificationItemLabel1} ${st.pointer}`}
                                                onClick={handleBack}
                                            >
                                                <FaCaretLeft />
                                                <span className='ms-1'>Back</span>
                                            </div>
                                            {
                                                pageConfig.section === SECTIONS.EDIT &&
                                                <div className={st.selectLabel2}>Notification #{pageConfig.data.notification.notification_id}</div>
                                            }
                                        </div>
                                        <div className={`d-flex align-items-center ${st.selectLabel2}`}>
                                            <FaFilter />
                                            <span className='ms-1'>Filters</span>
                                        </div>
                                        <div className='row mb-4'>
                                            {
                                                notificationOptions.filters.map((f) =>
                                                (
                                                    <div className='col-12' key={f.filter_id}>
                                                        <span className={st.selectLabel1}>{f.filter_name}</span>
                                                        <Select
                                                            options={f.options}
                                                            value={filterSelectionById[f.filter_id]}
                                                            onChange={(v) => setFilterSelectionById(obj => ({ ...obj, [f.filter_id]: v }))}
                                                            className={'w-100'}
                                                            getOptionLabel={x => x['value']}
                                                            isMulti={f.filter_type === 'Multi'}
                                                            closeMenuOnSelect={f.filter_type !== 'Multi'}
                                                            captureMenuScroll
                                                            placeholder={''}
                                                            components={f.filter_type === 'Multi' && {
                                                                MultiValueContainer: CustomMVContainerFilter,
                                                            }}
                                                            styles={{ ...customSelectStyles, ...(f.filter_type === 'Multi' ? customSelectMVStyles : {}) }}
                                                            hideSelectedOptions={false}
                                                        />
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        <div className={`d-flex align-items-center ${st.selectLabel2}`}>
                                            <FaDatabase />
                                            <span className='ms-1'>Threshold</span>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <span className={st.selectLabel1}>Threshold Field</span>
                                                <Select
                                                    options={notificationOptions.triggerNames}
                                                    value={triggerSelection.name}
                                                    getOptionValue={x => x['column_value_name']}
                                                    getOptionLabel={x => x['column_value_name']}
                                                    onChange={(v) => setTriggerSelection(obj => ({ ...obj, name: v }))}
                                                    className={'w-100'}
                                                    styles={customSelectStyles}
                                                />
                                            </div>
                                        </div>
                                        <div className='row mb-4'>
                                            <div className='col-6'>
                                                <span className={st.selectLabel1}>Condition</span>
                                                <Select
                                                    options={notificationOptions.triggerConditions}
                                                    value={triggerSelection.condition}
                                                    onChange={(v) => setTriggerSelection(obj => ({ ...obj, condition: v }))}
                                                    className={'w-100'}
                                                    isOptionDisabled={() => true}
                                                    styles={customSelectStyles}
                                                />
                                            </div>
                                            <div className='col-6'>
                                                <span className={st.selectLabel1}>Threshold Value</span>
                                                <TextField
                                                    size='small'
                                                    sx={{ width: '100%' }}
                                                    InputProps={{ sx: { height: '30px' } }}
                                                    value={triggerSelection.value}
                                                    onChange={(e) => setTriggerSelection(obj => ({ ...obj, value: e.target.value }))}
                                                    placeholder={`Enter...`}
                                                    autoComplete='off'
                                                />
                                            </div>
                                        </div>
                                        <div className={`d-flex align-items-center ${st.selectLabel2}`}>
                                            <FaEnvelope />
                                            <span className='ms-1'>Subscribers</span>
                                        </div>
                                        <div className='row mb-4'>
                                            <Async
                                                className='w-100'
                                                loadOptions={loadUserOptions}
                                                inputValue={userSelection.input}
                                                onInputChange={handleUserInputChange}
                                                value={userSelection.value}
                                                onChange={handleUserValueChange}
                                                getOptionValue={x => x['mail']}
                                                getOptionLabel={x => x['mail']}
                                                isMulti
                                                components={{ Option: CustomOptionUser }}
                                                hideSelectedOptions
                                                isClearable={false}
                                                captureMenuScroll
                                            />
                                        </div>
                                        <div className='text-end'>
                                            {
                                                pageConfig.section === SECTIONS.EDIT &&
                                                <Button
                                                    size='small'
                                                    variant='contained'
                                                    color='error'
                                                    className='me-3'
                                                    onClick={handleBack}
                                                    disabled={formState === PAGE_STATE.ALERT_SENDING}
                                                >Cancel</Button>
                                            }
                                            <Button
                                                size='small'
                                                variant='contained'
                                                color='primary'
                                                onClick={handleFormSubmit}
                                                disabled={formState !== PAGE_STATE.DATA_VALIDATED}
                                            >Save</Button>
                                        </div>
                                        {
                                            (formState === PAGE_STATE.ALERT_SENDING) &&
                                            <div className={`w-100 h-100 d-flex flex-column justify-content-center ${st.notificationOverlay}`}>
                                                <div className='row'>
                                                    <div className={`col-12 text-center`}>
                                                        <CircularProgress style={{ color: 'var(--gray-color-4)' }} />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                    }
                </div>

            </div>
        </>
    )
}


const TableauAlert = () => {
    const userDetails = useContext(UDContext);

    const [selectOptions, setSelectOptions] = useState({
        report: null,
        alert: null,
    });
    const [selectValues, setSelectValues] = useState({
        report: null,
        alert: null,
    });
    const [pageConfig, setPageConfig] = useState({
        section: null,
        data: null,
    });
    //View Zoom iframe
    const [viewUrl, setViewUrl] = useState('')

    const handleReportChange = (dashboardObj) => {
        setViewUrl(window.location.href.split('backend/')[0] + 'dashboard/' + dashboardObj?.slug)
        const selectedReport = selectOptions.report.find(x => x.report_id === dashboardObj.dashboardid);
        setSelectValues((obj) => ({ ...obj, report: { ...dashboardObj, ...selectedReport }, alert: null }));
        setPageConfig({ section: SECTIONS.EMPTY });
    }

    const handleAlertChange = (selectedOption) => {
        setSelectValues((obj) => ({ ...obj, alert: selectedOption }));
        setPageConfig({ section: SECTIONS.LIST, data: { alertId: selectedOption.alert_id } });
    }

    const validReportIds = Array.isArray(selectOptions.report) ?
        new Set(selectOptions.report.map(x => x.report_id)) : new Set();

    useEffect(() => {
        if (!userDetails) return;
        const ac = new AbortController();
        getReportNames(ac.signal)
            .then(res => {
                if (!res.status) {
                    if (res.message !== 'aborted')
                        toast.error(res.message);
                    return;
                }
                const reportOptions = res.data;
                setSelectOptions((obj) => ({ ...obj, report: reportOptions }));
            });
        return () => ac.abort();
    }, [userDetails]);

    useEffect(() => {
        if (!selectValues.report) return;
        const ac = new AbortController();
        const reportId = selectValues.report.report_id;
        getAlerts(reportId, ac.signal)
            .then(res => {
                if (!res.status) {
                    if (res.message !== 'aborted')
                        toast.error(res.message);
                    return;
                }
                const alertOptions = res.data;
                setSelectOptions((obj) => ({ ...obj, alert: alertOptions }));
                if (alertOptions.length)
                    handleAlertChange(alertOptions[0]);
            });
        return () => ac.abort();
    }, [selectValues.report]);

    const menuData = userDetails ? userDetails.AllMenu : [];

    return (
        <>
            <BackendTopMenu />
            <div className='mainContainer'>
                <div className='row mt-2 mb-2'>
                    <div className='col-lg-6'>
                        <Breadcrumbs aria-label='breadcrumb' separator={<NavigateNextIcon fontSize='small' />}>
                            <Link className={st.navigate_item} to={'/backend'}>Admin Home</Link>
                            <Link className={st.navigate_item} to={'/backend/alert'}>Alert</Link>
                        </Breadcrumbs>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-12'>
                        <ReportSelect menuData={menuData} setSelectedDashboard={handleReportChange} validReportIds={validReportIds} />
                    </div>
                </div>
                {
                    // SideMenu
                    Array.isArray(selectOptions.alert) &&
                    <div className='row'>
                        <div className='col-3 flex-grow-1'>
                            <div className={`mb-2`}>
                                <span className={`${st.page_title}`}>
                                    {'Available alerts'}
                                </span>
                            </div>
                            <div className={`w-100 h-100 d-flex flex-column ${st.alertItemContainer}`}>
                                {
                                    selectOptions.alert.map((alertData) => {
                                        return (
                                            <div key={alertData.alert_id}
                                                className={`w-100 mb-2 p-2 ${st.alertItem} ${(selectValues.alert && selectValues.alert.alert_id === alertData.alert_id) ? st.alertItemActive : ''}`}
                                                onClick={() => handleAlertChange(alertData)}
                                            >
                                                {alertData.alert_name}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className='col-9'>
                            <div className={`w-100`}>
                                {
                                    pageConfig.section === SECTIONS.LIST &&
                                    <Notifications pageConfig={pageConfig} setPageConfig={setPageConfig} />
                                }
                                {
                                    (pageConfig.section === SECTIONS.CREATE || pageConfig.section === SECTIONS.EDIT) &&
                                    <NotificationForm pageConfig={pageConfig} setPageConfig={setPageConfig}
                                        selectValues={selectValues} viewUrl={viewUrl}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    );
};

export default TableauAlert;


// TO DO:
// - in iframe handle tabs dynamically (currently default tab)
// - in iframe determine area to crop dynamically (currently hard coded)