import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import CampaignIcon from '@mui/icons-material/Campaign';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonIcon from '@mui/icons-material/Person';
import VideocamIcon from '@mui/icons-material/Videocam';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import React, { useEffect, useRef, useState, useContext } from 'react';
import { FaExternalLinkAlt } from "react-icons/fa";
import { MdMenu } from "react-icons/md";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BaseUrlAPI } from '../../../BaseModels/MasterData';
import ModalPopup from '../../../CustomElements/ModalPopup/ModalPopup';
import DashboardSearchBox from '../DashboardSearchBox/DashboardSearchBox';
import logo from './../../../../images/e2eviz-header-image.png';
import playVideoImage from './../../../../images/playVideoImage.png';
import tutorial_video from './../../../../videos/Platform_Overview.mp4';
import { MdDashboardCustomize } from "react-icons/md";
import st from './MobileNav2.module.css';
import GlobalContext from '../../store';

function MobileNav2(props) {
    const { storeData } = useContext(GlobalContext);

    const ref = useRef(null)
    const { slug } = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);
    const [height, setHeight] = useState(0)
    useEffect(() => {
        setHeight(ref.current.clientHeight)
    })

    const [UserDetailData, setUserDetailData] = useState({});
    const [AllNewsData, setAllNewsData] = useState([]);
    const [AllDashboardData, setAllDashboardData] = useState([]);
    const [MenuData, setMenuData] = useState([]);

    const [IsUserMenuVisible, setIsUserMenuVisible] = useState(false);
    const [IsNewsMenuVisible, setIsNewsMenuVisible] = useState(false);
    const [IsNavMenuVisible, setIsNavMenuVisible] = useState(false);
    const [IsSectionVisible, setIsSectionVisible] = useState(false);

    const [IsTutorialVideoVisible, setIsTutorialVideoVisible] = useState(false);

    const [PageName, setPageName] = useState('');
    const [PageNameSlug, setPageNameSlug] = useState('');
    const [SectionData, setSectionData] = useState([]);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);



    useEffect(() => {
        if (props.AllDashboardData.length > 0) {
            setAllDashboardData(props.AllDashboardData);
        }
        if (props.MenuData.length > 0) {
            setMenuData(props.MenuData);
        }
        if (props.NewsData.length > 0) {
            setAllNewsData(props.NewsData);
        }
        if (props.UserDetailData !== null) {
            setUserDetailData(props.UserDetailData);
        }
    }, [props.AllDashboardData, props.MenuData, props.NewsData, props.UserDetailData]);



    const btnNewsMenuOptionVisibleToggle_onClick = () => {
        setIsNewsMenuVisible(!IsNewsMenuVisible);
        setIsUserMenuVisible(false);
        setIsNavMenuVisible(false);
    }
    const btnNewsMenuOptionVisibleOpen_onClick = () => {
        setIsNewsMenuVisible(true);
        setIsUserMenuVisible(false);
        setIsNavMenuVisible(false);
    }
    const btnNewsMenuOptionVisibleClose_onClick = (news) => {
        setIsNewsMenuVisible(false);
        setIsUserMenuVisible(false);
        setIsNavMenuVisible(false);
        navigate("/newsletter/" + news.news_id);
    }

    const btnUserMenuOptionVisibleToggle_onClick = () => {
        setIsUserMenuVisible(!IsUserMenuVisible);
        setIsNewsMenuVisible(false);
        setIsNavMenuVisible(false);
    }
    const btnUserMenuOptionVisibleOpen_onClick = () => {
        setIsUserMenuVisible(true);
        setIsNewsMenuVisible(false);
        setIsNavMenuVisible(false);
    }
    const btnUserMenuOptionVisibleClose_onClick = () => {
        setIsUserMenuVisible(false);
        setIsNewsMenuVisible(false);
        setIsNavMenuVisible(false);
    }

    const btnSideMenuOpen_onClick = () => {
        setIsSectionVisible(!IsSectionVisible);
    }


    const btnExpanded_onClick = (page) => {
        if (page.pageName === PageName) {
            setPageName('');
        } else {
            setPageName(page.pageName);
        }
    }


    const btnNavMenuOptionVisibleClose_onClick = (page) => {
        setPageName(page.pageName);
        setPageNameSlug(page.slug);
        setSectionData(page.data);
        setIsNavMenuVisible(false);
        setIsUserMenuVisible(false);
        setIsNewsMenuVisible(false);
        setIsSectionVisible(true);
    }

    const btnSectionClose = () => {
        setIsSectionVisible(false);
    }
    const btnHomePage_onClick = () => {
        navigate("/");
        window.location.reload();
    }
    const btnPage_onClick = (slug) => {
        navigate("/page/" + slug);
        window.location.reload();
    }
    const redirectToDashboard = (dashboard) => {
        setIsNavMenuVisible(false);
        setIsUserMenuVisible(false);
        setIsNewsMenuVisible(false);
        setIsSectionVisible(false);
        navigate(`/dashboard/${dashboard.slug}`);
    }





    return (
        <>
            <div className={st.AppBar} ref={ref}>
                <div className={st.rootContainer}>
                    <div className={st.menuActionButtonContainer}>
                        <IconButton aria-label="Menu" size="large" sx={{ color: '#ffffff' }} onClick={btnSideMenuOpen_onClick}>
                            <MoreVertIcon fontSize="inherit" />
                        </IconButton>
                    </div>
                    <div onClick={btnHomePage_onClick} className={st.logoLinkContainer}>
                        <img src={logo} alt="logo" className={st.logo} style={{ width: windowSize[0] < 500 ? windowSize[0] / 4 : '' }} />
                    </div>
                    <div className={st.actionButtonRootContainer}>
                        <div className={st.actionButtonContainer}>
                            <div className={st.actionButtonWrapper}>
                                <IconButton aria-label="Search" size="large" sx={{ color: '#ffffff' }}>
                                    <DashboardSearchBox />
                                </IconButton>
                            </div>
                            <IconButton aria-label="Announcement" size="large" sx={{ color: '#ffffff' }} onClick={() => navigate("/newsletter/0")}>
                                <CampaignIcon fontSize="inherit" />
                            </IconButton>                            

                            <div className={st.actionButtonWrapper}>
                                {/* <IconButton aria-label="User" size="large" sx={{ color: '#ffffff' }} > */}
                                <IconButton aria-label="User" size="large" sx={{ color: '#ffffff' }} onClick={btnUserMenuOptionVisibleToggle_onClick}>
                                    <PersonIcon fontSize="inherit" />
                                    <div className={st.userPopupMenuContainer} style={{ display: IsUserMenuVisible ? 'block' : 'none' }}
                                        onMouseLeave={() => setIsUserMenuVisible(false)}>
                                        <div className={st.userPopuMenuDetail}>
                                            {/* <div onClick={btnUserMenuOptionVisibleClose_onClick} className={st.userPopuMenuItem}><HomeIcon /> Home</div> */}
                                            <div onClick={btnUserMenuOptionVisibleClose_onClick} className={st.userPopuMenuItem}><AccountBoxIcon /> {UserDetailData?.user_id}</div>
                                            <div onClick={() => setIsTutorialVideoVisible(true)} className={st.userPopuMenuItem}><VideocamIcon /> Take a Tour</div>
                                            {/* <div onClick={btnUserMenuOptionVisibleClose_onClick} className={st.userPopuMenuItem}><SettingsIcon /> Setting</div> */}
                                            {/* <div onClick={btnUserMenuOptionVisibleClose_onClick} className={st.userPopuMenuItem}><LogoutIcon /> Log Out</div> */}
                                            <div style={{ display: storeData.showAIDashboard ? 'flex' : 'none' }} onClick={() => { navigate('/AIDashboard'); }} className={st.userPopuMenuItem}><MdDashboardCustomize /> AI Dashboard</div>
                                            <div style={{ display: UserDetailData?.admin ? 'flex' : 'none' }} onClick={() => { navigate('/backend'); }} className={st.userPopuMenuItem}><AdminPanelSettingsIcon /> Admin</div>
                                        </div>
                                    </div>
                                </IconButton>
                            </div>
                            <div className={st.actionButtonWrapper} style={{ display: 'none' }}>
                                <IconButton aria-label="Menu" size="large" sx={{ color: '#ffffff' }} onClick={btnSideMenuOpen_onClick}>
                                    <MdMenu fontSize="inherit" />
                                </IconButton>
                            </div>
                            <div className={st.actionButtonWrapper}></div>
                            <div className={st.actionButtonWrapper}></div>
                            <div className={st.actionButtonWrapper}></div>
                        </div>
                    </div>
                </div>
            </div>
            <Drawer BackdropProps={{ invisible: true }}
                anchor={'left'} open={IsSectionVisible} onClose={btnSectionClose}
                sx={{
                    '&.MuiDrawer-root .MuiDrawer-paper': { marginTop: '60px', boxShadow: '2px 2px 2px 0px #01235a' },
                    height: 'calc(100% - 60px)',
                }}>
                <Box sx={{ width: 280 }} className={st.sideMenuRootContainer}>
                    {
                        MenuData.map((page, index) => (
                            <div key={index} className={st.pageSideMenuContainer}>
                                <Accordion className={`${st.pageAccordion}`} expanded={page.pageName === PageName} onChange={() => btnExpanded_onClick(page)}>
                                    <AccordionSummary className={st.pageAccordionSummary}
                                        expandIcon={<ExpandMoreIcon className={`${st.pageExpendIcon} ${page.pageName === PageName ? st.ActivePage : ""}`} />}
                                        aria-controls={"panel" + index + "bh-content"} id={"panel" + index + "bh-header"}>
                                        <div className={`${st.sidemenuPageTitle} ${page.pageName === PageName ? st.ActivePage : ""}`}>
                                            <img src={BaseUrlAPI + "images" + page.page_icon + "?token=" + UserDetailData?.token}
                                                alt={page.pageName + " icon"} className={st.pageIconImage} />
                                            {page.pageName}
                                        </div>
                                        <FaExternalLinkAlt onClick={() => btnPage_onClick(page.slug)} className={st.pageLinkIcon} />
                                    </AccordionSummary>
                                    <AccordionDetails className={st.pageAccordionDetails}>
                                        {
                                            page.data.map((section, index) => (
                                                <Accordion key={section.sectionName} className={st.sectionAccordion}>
                                                    <AccordionSummary className={st.sectionAccordionSummary} expandIcon={<ExpandMoreIcon className={`${st.sectionExpendIcon}`} />}>
                                                        <div className={st.sidemenuSectionTitle}>
                                                            {/* <i className={`${'fas fa-tv'} ${st.sectionIcon}`}></i>  */}
                                                            {section.sectionName}</div>
                                                    </AccordionSummary>
                                                    <AccordionDetails className={st.sectionAccordionDetails}>
                                                        {
                                                            section.data.map((data, index) => (
                                                                <div key={index} className={st.sidemenuTitle} onClick={() => { redirectToDashboard(data) }}>
                                                                    <i className="far fa-file"></i> {data.title}
                                                                </div>
                                                            ))
                                                        }
                                                    </AccordionDetails>
                                                </Accordion>
                                            ))
                                        }
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        ))
                    }
                </Box>
            </Drawer>
            <ModalPopup IsVisible={IsTutorialVideoVisible} onClose={() => setIsTutorialVideoVisible(false)}>
                <div className={st.tutorialVideoContainer}>
                    <div className={st.tutorialVideoHeader}>
                        <div className={st.tutorialVideoTitle}>E2EViz Platform Tutorial</div>
                        <HighlightOffIcon className={st.tutorialVideoClose} onClick={() => setIsTutorialVideoVisible(false)} />
                    </div>
                    <div className={st.tutorialVideoBody}>
                        <video className={st.tutorialVideo} poster={playVideoImage} controls>
                            <source src={tutorial_video} type="video/mp4" />
                        </video>
                    </div>
                    <div className={st.tutorialVideoFooter}>
                        <Button variant="contained" size="small" onClick={() => setIsTutorialVideoVisible(false)}>
                            Close
                        </Button>
                    </div>
                </div>
            </ModalPopup>
            <div style={{ height: height - 5 }}></div>
        </>
    );
}
export default MobileNav2;
